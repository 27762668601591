
const initialState = {
    addEarning: [],
    listEarning: [],
    earning: {
        name: '',
	},
}
function earningReducer(state = initialState, action) {
    switch (action.type) {
        case 'LIST_EARNING':
            return {
                ...state,
                listEarning: action.payload
            }
            break;
        case 'VIEW_EARNING':
            return {
                ...state,
                earning: action.payload
            }
        case 'RESET_EARNING':
			return Object.assign({}, state, {
				earning: initialState.earning
			})
        case 'HANDLE_EARNING_CHANGE': 
            return Object.assign({}, state, {
                earning: {
                    ...state.earning,
                    [action.name]: action.value
                }
            })
        default:
            return state;
            break;
    }
}

export default earningReducer;