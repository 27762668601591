const initialState = {
    listLeavetype: [],
    leavetype: {
        leavetype: '',
        mindays: '',
        maxdays: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_LEAVE_TYPE_CHANGE':
            return Object.assign({}, state, {
                leavetype: {
                    ...state.leavetype,
                    [action.name]: action.value
                }
            })
        case 'RESET_LEAVE_TYPE':
            return Object.assign({}, state, {
                leavetype: initialState.leavetype,
            })
        case 'LIST_LEAVE_TYPE':
            return {
                ...state,
                listLeavetype: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_LEAVE_TYPE':
            return Object.assign({}, state, {
                leavetype: action.payload,
                spinner: false,
            })

        case 'AC_EMPTY_LEAVE_TYPE':
            return Object.assign({}, state, {
                leavetype: {
                    title: '',
                    description: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}