const initialState = {
    listLeavestransection: [],
    leavetransection: {
        name: '',
        employeeId: '',
        salary:'',
        date: '',
        workingdays: '',
        lopdays: '',
        // netsalary: '',
        paiddays: '',
        // lopamount: '',
        totalleave: '',
        takenleave: '',
        remainingleave: '',
        status: '',
        isDeleted: ''
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_LEAVE_TRANSECTION_CHANGE':
            return Object.assign({}, state, {
                leavetransection: {
                    ...state.leavetransection,
                    [action.name]: action.value
                }
            })
        case 'RESET_LEAVE_TRANSECTION':
            return Object.assign({}, state, {
                leavetransection: initialState.leavetransection,
            })
        case 'LIST_LEAVE_TRANSECTION':
            return {
                ...state,
                listLeavestransection: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_LEAVE_TRANSECTION':
            return Object.assign({}, state, {
                leavetransection: action.payload,
                spinner: false,
            })

        case 'AC_EMPTY_LEAVES':
            return Object.assign({}, state, {
                leavetransection: {
                    name: '',
                    employeeId: '',
                    salary: '',
                    date: '',
                    workingdays: '',
                    lopdays: '',
                    // netsalary: '',
                    paiddays: '',
                    // lopamount: '',
                    totalleave: '',
                    takenleave: '',
                    remainingleave: '',
                    status: '',
                    isDeleted: ''
                },
                spinner: true
            })
        default:
            return state;
    }
}