const initialState = {
    listTaskBoard: [],
    taskboard: {
        project: '',
        boardname: '',
        boardcolor: '#42a5f5',
    },
    // ---------------- Task -------------------
    listTask: [],
    task: {
        project: '',
        name: '',
        priority: '',
        date: '',
        followers: '',
        type: '',
        reporter: '',
        selectedData : []
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_TASK_BOARD_CHANGE':
            return Object.assign({}, state, {
                taskboard: {
                    ...state.taskboard,
                    [action.name]: action.value
                }
            })
        case 'RESET_TASK_BOARD':
            return Object.assign({}, state, {
                taskboard: initialState.taskboard,
            })
        case 'LIST_TASK_BOARD':
            return {
                ...state,
                listTaskBoard: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_TASK_BOARD':
            return Object.assign({}, state, {
                taskboard: action.payload,
                spinner: false,
            })

        // ================== TASK ====================
        case 'HANDLE_TASK_CHANGE':
            return Object.assign({}, state, {
                task: {
                    ...state.task,
                    [action.name]: action.value
                }
            })
        case 'RESET_TASK':
            return Object.assign({}, state, {
                task: initialState.task,
            })
        case 'LIST_TASK':
            return {
                ...state,
                listTask: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_TASK':
            return Object.assign({}, state, {
                task: action.payload,
                spinner: false,
            })

        default:
            return state;
    }
}