const initialState = {
    listManageGrades: [],
    manageGrades: {
        grade: '',
        level: '',
        department: '',
        designation: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_MANAGE_GRADES_CHANGE':
            return Object.assign({}, state, {
                manageGrades: {
                    ...state.manageGrades,
                    [action.name]: action.value
                }
            })
        case 'RESET_MANAGE_GRADES':
            return Object.assign({}, state, {
                manageGrades: initialState.manageGrades,
            })
        case 'LIST_MANAGE_GRADES':
            return {
                ...state,
                listManageGrades: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_MANAGE_GRADES':
            return Object.assign({}, state, {
                manageGrades: action.payload,
                spinner: false,
            })

        case 'AC_EMPTY_MANAGE_GRADES':
            return Object.assign({}, state, {
                manageGrades: {
                    grade: '',
                    level: '',
                    department: '',
                    designation: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}