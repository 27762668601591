import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import { AC_EMPTY_PROJECT, AC_LIST_PROJECT, AC_VIEW_PROJECT } from '../../actions/projectAction';
import DatePicker, { Calendar } from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
let todaydate = new Date();

const initialState = {
    modalType: "Add",
    columnDefs: [
        // { headerName: '#', valueGetter: "node.rowIndex+1", hide: true, width: 50, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', hide: 'true' },
        {
            headerName: 'Date', field: 'date', width: 150, headerClass: 'ag-center-header',
            valueGetter: function (params) {
                if (params.data.date) return moment(params.data.date).format('MM/DD/YYYY')
                else return '-'
            }
        },
        { headerName: 'Employee ID', field: 'employeeId', width: 200, headerClass: 'ag-center-header', },
        { headerName: 'Employee Name', field: 'employeename', width: 200, headerClass: 'ag-center-header', },
        {
            headerName: 'Reporting Manager', field: 'rm', width: 200, headerClass: 'ag-center-header',
            valueGetter: function (params) {
                if (params.data.rm) return params.data.rm
                else return '-'
            }
        },
        {
            headerName: 'Status', field: 'count', width: 200, headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                if (params.data.count) return '<span class="tag tag-warning">Pending</span>'
                else return '<span class="tag tag-success">Approved</span>'
            }
        },
        // {
        //     headerName: 'Rating', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
        //     valueGetter: function (params) {
        //         if (params.value) return params.value
        //         else return '-'
        //     }
        // },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                // return params.value
                return '<div><button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModalView" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View" data-toggle="tooltip" title="View"></i>'
            }
        },
    ],
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
    },
    nameError: false,
    id: '',
    hotelError: false,
    saving: false,
    searchhoteloption: '',
    searchhotel: '',
    viewRedirect: false,
    editRedirect: false,
    viewRedirectId: '',
    closed: false,
    editSpi: false,
    viewSpin: false,
    hotelName: '',
    employeeprojects: [],
    taskdata: {},
    taskList: [],
    timesheetdate: moment(new Date(todaydate.getFullYear(), todaydate.getMonth() - 0, 1)).format('YYYY-MM-DD'),
}
class ListProject extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            let { history } = this.props;
            history.push(`/employee-timesheet-review?id=${event.data.employee}&date=${moment(event.data.date).format('YYYY-MM-DD')}`)
            // this.ResetTask();
            // this.setState({ modalType: "View", taskdata: event.data });
        }
    }

    componentDidMount() {

        this.getEmployeeEntry()
        localStorage.setItem('invoicetoken', "");
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }



    getEmployeeEntry = (date = this.state.timesheetdate, todate = this.state.totimesheetdate) => {
        axios.post(ImportedURL.API.getemployeeEntryReview, { date: date, todate: todate })
            .then((res) => {
                this.setState({ taskList: res.data })
            }).catch((err) => {
                console.log(err);
            });
    }




    datechange = (e) => {
        if (e.target.name == 'timesheetdate') {

            this.setState({ timesheetdate: e.target.value ? e.target.value : initialState.timesheetdate })
            this.getEmployeeEntry(e.target.value ? e.target.value : initialState.timesheetdate)
        } else {
            this.setState({ totimesheetdate: e.target.value })
            this.getEmployeeEntry(this.state.timesheetdate, e.target.value)
        }
    }

    render() {
        const { AccountState } = this.props;
        const rowData = this.state.taskList;
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-department"><h6 className="highlights_breadcrump">Project List</h6></Link></p>
                </div>
                <div className="section-body user_sec">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">

                                <div className="row clearfix row-cards">
                                    {/* <div className="card">
                                        <div className="card-body">

                                            <div className="row">
                                                <div className='col-4'>
                                                    <Calendar
                                                        value={this.state.timesheetdate}
                                                        onChange={this.setDateValue}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                                <div className='col-8'>
                                                    <div className="row">
                                                        <div className='col-6'>
                                                            <div className="h6">Employee ID</div>
                                                            <p>{account.employeeId}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Name</div>
                                                            <p>{account.name}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Department</div>
                                                            <p>{account.department}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Division</div>
                                                            <p>{account.division}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Designation</div>
                                                            <p>{account.designation}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Reporting Manager</div>
                                                            <p>{account.manager} ({account.managerID})</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="card">
                                        <div className="card-header">
                                            <div className='card-title d-flex'>
                                                <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                                <h3 className="card-title " style={{ marginTop: '10px' }}> Task Review </h3>
                                            </div>
                                            {/* <div className="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="header-action" >
                                                        {moment(this.state.timesheetdate).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD') ?
                                                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={this.ResetTask} ><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                                                    <label className="form-label">Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='timesheetdate' onChange={this.datechange} placeholder="Module" value={this.state.timesheetdate} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                                                    <label className="form-label">To Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='totimesheetdate' onChange={this.datechange} placeholder="Module" value={this.state.totimesheetdate} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        onGridReady={this.onGridReady}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    ProjectState: state.project,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ViewProject: AC_VIEW_PROJECT,
            EmptyProject: AC_EMPTY_PROJECT,
            ListProject: AC_LIST_PROJECT
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListProject);