const initialState = {
    listLeaveTemplate: [],
    leaveTemplate: {
        title: '',
        description: '',
        slug: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_LEAVE_TEMPLATE_CHANGE':
            return Object.assign({}, state, {
                leaveTemplate: {
                    ...state.leaveTemplate,
                    [action.name]: action.value
                }
            })
        case 'RESET_LEAVE_TEMPLATE':
            return Object.assign({}, state, {
                leaveTemplate: initialState.leaveTemplate,
            })
        case 'LIST_LEAVE_TEMPLATE':
            return {
                ...state,
                listLeaveTemplate: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_LEAVE_TEMPLATE':
            return Object.assign({}, state, {
                leaveTemplate: action.payload,
                spinner: false,
            })

        case 'AC_EMPTY_LEAVE_TEMPLATE':
            return Object.assign({}, state, {
                leaveTemplate: {
                    title: '',
                    description: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}