const initialState = {
    listinvoicecategory: [],
    invoicecategory: {
        name: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INVOICE_CATEGORY_CHANGE':
            return Object.assign({}, state, {
                invoicecategory: {
                    ...state.invoicecategory,
                    [action.name]: action.value
                }
            })
        case 'RESET_INVOICE_CATEGORY':
            return Object.assign({}, state, {
                invoicecategory: initialState.invoicecategory,
            })
        case 'LIST_INVOICE_CATEGORY':
            return {
                ...state,
                listinvoicecategory: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_INVOICE_CATEGORY':
            return Object.assign({}, state, {
                invoicecategory: action.payload,
                spinner: false,
            })

        case 'EMPTY_INVOICE_CATEGORY':
            return Object.assign({}, state, {
                invoicecategory: {
                    name: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}