import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import moment from 'moment';
import { AC_HANDLE_EMPLOYEE_SALARY_CHANGE, AC_LIST_EMPLOYEE_SALARY, AC_VIEW_EMPLOYEE_SALARY, AC_RESET_EMPLOYEE_SALARY, AC_LIST_EMPLOYEE_PAYROLL__SALARY, AC_HANDLE_PAYROLL_STATUS_CHANGE, AC_HANDLE_EMPLOYEE_SALARY_SEARCH } from '../../actions/employeesalaryAction';
import { AC_LIST_EMPLOYEE, } from '../../actions/employeeAction';
import { AC_HANDLE_PAYROLL_CHANGE, AC_LIST_PAYROLL, AC_VIEW_PAYROLL, AC_RESET_PAYROLL, AC_HANDLE_PAYROLL_SEARCH } from '../../actions/payrollAction';
import DatePicker from "react-multi-date-picker"
import { AC_LIST_EMPLOYEE_LEAVE } from '../../actions/employeeLeaveAction';
import { AC_LIST_LEAVES } from '../../actions/leavesAction';
import { AC_ACCOUNT_DETAILS } from '../../actions/accountAction';
import { AC_LIST_EARNING } from '../../actions/earningAction';
import { AC_LIST_DEDUCTION } from '../../actions/deductionAction';


const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    empId: '',
    nameError: false,
    salaryError: false,
    workingdaysError: false,
    dateError: false,
    lopdaysError: false,
    yearList: [],
    isFilterShow: false,
    month: '',
    year: '',
    columnDefs: [
        {
            headerName: 'Employee ID', field: 'payrollempId', width: 130, floatingFilter: true,
        },
        { headerName: 'Name', field: 'payrollempName', width: 130, floatingFilter: true },
        {
            headerName: 'DOJ', field: 'joindate', width: 130, floatingFilter: true, hide: true,
            cellRenderer: function (params) {
                return moment(params.data.joindate).format('DD/MM/YYYY')
            }
        },
        { headerName: 'Category', field: 'role', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Department', field: 'departmentName', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Division', field: 'divisionName', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Designation', field: 'designationName', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Grade', field: 'grade', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Level', field: 'level', width: 130, floatingFilter: true, hide: true },

        // =============
        { headerName: 'Annual CTC', field: 'AnnualCTC', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Monthly Gross', field: 'salary', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Basic + DA', field: 'basicsalary', width: 130, floatingFilter: true, hide: true },
        { headerName: 'HRA', field: 'hra', width: 130, floatingFilter: true, hide: true },
        // ====
        { headerName: 'Conveyance Allowance', field: 'conveyance', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Medical Allowance', field: 'medical', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Telephone Allowance', field: 'telephone', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Internet Allowance', field: 'internet', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Fuel allowance', field: 'fuel', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Driver allowance', field: 'driver', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Food Allowance', field: 'food', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Children Education Allowance', field: 'childeducation', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Children Hostel Allowance', field: 'childhostel', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Uniform Allowance', field: 'uniform', width: 130, floatingFilter: true, hide: true },

        // ====
        { headerName: 'Statutory Bonus', field: 'statutorybonus', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Special Allowance', field: 'specialallowance', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Monthly Gross (A)', field: 'monthlygrossA', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Variable Pay *', field: 'specialallowance', width: 130, floatingFilter: true, hide: true },
        { headerName: 'ESI', field: 'esiepmloyer', width: 130, floatingFilter: true, hide: true },
        { headerName: 'EPF', field: 'pfemployer', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Gratuity', field: 'gratuity', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Toatl Benifits (B)', field: 'totalbenifits', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Monthly CTC', field: 'monthlyCTC', width: 130, floatingFilter: true, hide: true },
        { headerName: 'TDS', field: 'pf', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Profeesional Tax', field: 'professionaltax', width: 130, floatingFilter: true, hide: true },
        { headerName: 'ESI', field: 'esi', width: 130, floatingFilter: true, hide: true },
        { headerName: 'EPF', field: 'pf', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Total Deductions', field: 'totaldeduction', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Other Deductions', field: 'totaldeduction', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Working Days', field: 'workingdays', width: 130, floatingFilter: true, hide: true },
        { headerName: 'LOP days', field: 'lopdays', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Paid days', field: 'paiddays', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Basic + DA', field: 'currentBasicsalary', width: 130, floatingFilter: true, hide: true },
        { headerName: 'HRA', field: 'currentHra', width: 130, floatingFilter: true, hide: true },
        // ====
        { headerName: 'Conveyance Allowance', field: 'Currentallowanceconveyance', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Medical Allowance', field: 'Currentallowancemedical', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Telephone Allowance', field: 'Currentallowancetelephone', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Internet Allowance', field: 'Currentallowanceinternet', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Fuel allowance', field: 'Currentallowancefuel', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Driver allowance', field: 'Currentallowancedriver', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Food Allowance', field: 'Currentallowancefood', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Children Education Allowance', field: 'Currentallowancechildeducation', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Children Hostel Allowance', field: 'Currentallowancechildhostel', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Uniform Allowance', field: 'Currentallowanceuniform', width: 130, floatingFilter: true, hide: true },

        // ====
        { headerName: 'Statutory Bonus *', field: 'currentStatutory', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Special Allowance', field: 'currentSpecialallowance', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Monthly Gross A', field: 'currentMonthGrossA', width: 130, floatingFilter: true, hide: true },
        { headerName: 'TDS', field: 'pf', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Profeesional Tax', field: 'currentProfessionaltax', width: 130, floatingFilter: true, hide: true },
        { headerName: 'ESI', field: 'currentEsi', width: 130, floatingFilter: true, hide: true },
        { headerName: 'EPF', field: 'currentEpf', width: 130, floatingFilter: true, hide: true },
        { headerName: 'other Deductions', field: 'pf', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Total Deductions', field: 'currenttotaldeduction', width: 130, floatingFilter: true, hide: true },
        { headerName: 'Net Take Home', field: 'nettakehome', width: 130, floatingFilter: true, hide: true },

        {
            headerName: 'Date', field: 'payrollempMonth', width: 130, floatingFilter: true,
            cellRenderer: function (params) {
                return params.data.payrollempMonth + "/" + params.data.payrollempYear
            }
        },
        {
            headerName: 'Earning', field: 'payrollempEarning', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Earning Amount', field: 'payrollempEaramount', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Deduction', field: 'payrollempDeduction', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Deduction Amount', field: 'payrollempDedamount', width: 130, floatingFilter: true,
        },
        // ==========
        {
            headerName: 'Payslip', width: 150, cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return `<button type="button" class="btn payslip_btn" data-action-type="Payslip">
                Genarate Payslip
              </button>`;
            }
        },
        {
            headerName: 'Status', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    // excelStyles: [
    //     {
    //         id: 'indent-1',
    //         alignment: {
    //             horizontal: "Justify",
    //         },
    //     }
    // ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class MonthlyPayroll extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.props.listpayroll();
        this.props.resetpayroll();

    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.viewpayroll(event.data._id);
        }
        if (value === 'Payslip') {
            this.setState({ id: event.data._id, viewRedirect: true });
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeletePayroll + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            this.props.listpayroll();
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'payrolls' })
                        .then((data) => {
                            Success('Status updated successfully')
                            this.props.listpayroll();
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['payrollempId', 'payrollempName', 'joindate', 'role', 'departmentName', 'divisionName', 'designationName', 'grade', 'level', 'AnnualCTC', 'salary', 'basicsalary', 'hra', 'conveyance', 'medical', 'telephone', 'internet', 'fuel', 'driver', 'food', 'childeducation', 'childhostel', 'uniform', 'statutorybonus', 'specialallowance', 'monthlygrossA', 'specialallowance', 'esiepmloyer', 'pfemployer', 'gratuity', 'totalbenifits', 'monthlyCTC', 'pf', 'professionaltax', 'esi', 'pf', 'totaldeduction', 'totaldeduction', 'workingdays', 'lopdays', 'paiddays', 'currentBasicsalary', 'currentHra', 'Currentallowanceconveyance', 'Currentallowancemedical', 'Currentallowancetelephone', 'Currentallowanceinternet', 'Currentallowancefuel', 'Currentallowancedriver', 'Currentallowancefood', 'Currentallowancechildeducation', 'Currentallowancechildhostel', 'Currentallowanceuniform', 'currentStatutory', 'currentSpecialallowance', 'currentMonthGrossA', 'payrollempDedamount', 'currentProfessionaltax', 'currentEsi', 'currentEpf', 'payrollempDedamount', 'currenttotaldeduction', 'nettakehome'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { payrollState } = this.props;
        const exportData = payrollState.listPayroll;
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Monthly Payroll";
        const headers = [['#', 'Employee ID', 'Name', 'JoinDate', 'Role', 'Department', 'Division', 'Designation', 'Grade', 'Level', 'AnnualCTC', 'Salary', 'Basic Salary', 'HRA', 'Conveyence Allowance', 'Medical Allowance', 'Telephone Allowance', 'Internet Allowance', 'Fuel Allowance', 'Driver Allowance', 'Food Allowance', 'Child Education Allowance', 'Child Hostel Allowance', 'Uniform Allowance', 'Statutory Bonus', 'Special Allowance', 'Monthly Gross A', 'Special Allowance', 'ESI', 'EPF', 'Gratuity', 'Total Benifits', 'Monthly CTC', 'PF', 'Professional Tax', 'ESI', 'PF', 'Total Deduction', 'Total Deduction', 'Working Days', 'LOP Days', 'Paid Days', 'Basic Salary', 'HRA', 'Conveyence Allowance', 'Medical Allowance', 'Telephone Allowance', 'Internet Allowance', 'Fuel Allowance', 'Driver Allowance', 'Food Allowance', 'Child Education Allowance', 'Child Hostel Allowance', 'Uniform Allowance', 'Statutory Bonus', 'Special Allowance', 'MonthlyGrosss A', 'TDS', 'Professional Tax', 'ESI', 'EPF', 'TDS', 'Total Deduction', 'Net Take Home']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.payrollempId,
            elt.payrollempName,
            elt.joindate,
            elt.role,
            elt.departmentName,
            elt.divisionName,
            elt.designationName,
            elt.grade,
            elt.level,
            elt.AnnualCTC,
            elt.salary,
            elt.basicsalary,
            elt.hra,
            elt.conveyance,
            elt.medical,
            elt.telephone,
            elt.internet,
            elt.fuel,
            elt.driver,
            elt.food,
            elt.childeducation,
            elt.childhostel,
            elt.uniform,
            elt.statutorybonus,
            elt.specialallowance,
            elt.monthlygrossA,
            elt.specialallowance,
            elt.esiepmloyer,
            elt.pfemployer,
            elt.gratuity,
            elt.totalbenifits,
            elt.monthlyCTC,
            elt.pf,
            elt.professionaltax,
            elt.esi,
            elt.pf,
            elt.totaldeduction,
            elt.totaldeduction,
            elt.workingdays,
            elt.lopdays,
            elt.paiddays,
            elt.currentBasicsalary,
            elt.currentHra,
            elt.Currentallowanceconveyance,
            elt.Currentallowancemedical,
            elt.Currentallowancetelephone,
            elt.Currentallowanceinternet,
            elt.Currentallowancefuel,
            elt.Currentallowancedriver,
            elt.Currentallowancefood,
            elt.Currentallowancechildeducation,
            elt.Currentallowancechildhostel,
            elt.Currentallowanceuniform,
            elt.currentStatutory,
            elt.currentSpecialallowance,
            elt.currentMonthGrossA,
            elt.payrollempDedamount,
            elt.currentProfessionaltax,
            elt.currentEsi,
            elt.currentEpf,
            elt.payrollempDedamount,
            elt.currenttotaldeduction,
            elt.nettakehome,





        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Payroll.pdf")
    }
    handleSelectChange = (e) => {
        this.setState({ spinner: false })
        const { name, value } = e;
        this.setState({ empId: value })
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (name == 'name') {
            if (value) {
                this.setState({ nameError: false })
                axios.get(ImportedURL.API.viewleavetransecsalary + "/" + value)
                    .then(({ data }) => {
                        this.props.handleChange('leavetransecsalary', data.salary);
                        // dispatch({ type: "VIEW_DEDUCTION", payload: data });
                    });
            }
            else {
                this.setState({ nameError: true })
            }
            this.props.handleChange('name', value);
        }

        if (name == 'earning') {
            if (value) {
                this.setState({ nameError: false })
            }
            else {
                this.setState({ nameError: true })
            }
            this.props.handleChange(name, value);
        }
        if (name == 'deduction') {
            if (value) {
                this.setState({ nameError: false })
            }
            else {
                this.setState({ nameError: true })
            }
            this.props.handleChange(name, value);
        }



    }
    handleOnchange = (e) => {
        const { name, value } = e.target;
        if (name == 'earningamount') {
            if (value) {
                this.setState({ earningamountError: false })
            }
            else {
                this.setState({ earningamountError: true })
            }
            this.props.handleChange(name, value);
        }
        if (name == 'deductionamount') {
            if (value) {
                this.setState({ deductionamountError: false })
            }
            else {
                this.setState({ deductionamountError: true })
            }
            this.props.handleChange(name, value);
        }
    }
    componentDidMount() {
        this.props.listemployee();
        this.props.listpayroll();
        this.props.listemployeeleave();
        this.props.ListLeaves();
        this.props.AC_ACCOUNT_DETAILS();
        this.props.listearning();
        this.props.listdeducton();
        this.props.resetpayroll();
        this.getYears();
        localStorage.setItem('invoicetoken', "");
    }
    getYears = (e) => {
        for (let i = 0; i < 15; i++) {
            let date = new Date();
            date.setFullYear(date.getFullYear() + i);
            const year = moment(date).format("YYYY")
            const value = { value: year, label: year, name: 'year' }
            this.state.yearList.push(value)
        }
    }
    submit = () => {
        const { leavetransectionState, employeeReducer, EmployeeLeaveState, LeaveState, payrollState } = this.props;
        const data = payrollState.payroll;
        const listemployee = employeeReducer.listEmployee;
        const employeeLeavedata = EmployeeLeaveState.listEmployeeLeave;
        const account = this.props.account.account;
        const listLeaves = LeaveState.listLeaves;
        var EmloyeeId = '';
        listemployee.length && listemployee.map(item => {

            if (item._id === data.name) {
                EmloyeeId = item.employeeId
            }
        })
        let valid = 1
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0
        }
        if (!data.month) {
            this.setState({ monthError: true });
            valid = 0
        }
        if (!data.year) {
            this.setState({ yearError: true });
            valid = 0
        }
        // if (!data.earning) {
        //     this.setState({ earningError: true });
        //     valid = 0
        // }
        // if (!data.earningamount) {
        //     this.setState({ earningamountError: true });
        //     valid = 0
        // }
        // if (!data.deduction) {
        //     this.setState({ deductionError: true });
        //     valid = 0
        // }
        // if (!data.deductionamount) {
        //     this.setState({ deductionamountError: true });
        //     valid = 0
        // }



        var formdata = {
            name: data.name,
            employeeId: EmloyeeId,
            month: data.month,
            year: data.year,
            earning: data.earning,
            earningamount: data.earningamount,
            deduction: data.deduction,
            deductionamount: data.deductionamount,

        }

        if (valid) {
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addPayroll, formdata)
                    .then((formdata) => {
                        Success(formdata.statusText);
                        this.setState({ save: false });
                        this.props.listpayroll();
                        // this.props.resetEmployeesalary();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updatePayroll + '/' + this.state.id, formdata)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.listpayroll();
                        // this.props.resetEmployeesalary();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch((response) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }

    onChangeDate = (e) => {
        const { name, value } = e;
        this.props.handleChange('date', e);
        if (e) {
            if (e) {
                this.setState({ dateError: false })
            }
            else {
                this.setState({ dateError: true })
            }
        }
    }
    handleChangeDate = (e) => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (name == 'year') {
            this.props.handleChange("year", value)
        }
        if (name == 'month') {
            this.props.handleChange("month", value)
        }
    }
    handleChangeMonth = (e) => {
        var value = e.value;
        const rowData = this.props.payrollState.listEmpfilter;
        this.setState({ month: value })
        this.setState({ isFilterShow: true });
        if (value) {
            if (rowData != undefined && rowData.length > 0) {
                const data = rowData.filter((item) =>
                    item.payrollempMonth.toLowerCase().includes(value.toLowerCase())
                )
                this.props.handleSearch(data)
            }
            else {
                this.props.handleSearch([])

            }
        }
        else {
            this.props.listpayroll()
        }
    }
    handleChangeYear = (e) => {
        var value = e.value;
        const rowData = this.props.payrollState.listEmpfilter;
        this.setState({ year: value })
        this.setState({ isFilterShow: true });
        if (value) {
            if (rowData != undefined && rowData.length > 0) {
                const data = rowData.filter((item) =>
                    item.payrollempYear.toLowerCase().includes(value.toLowerCase())
                )
                this.props.handleSearch(data)
            }
            else {
                this.props.handleSearch([])

            }
        }
        else {
            this.props.listpayroll()
        }
    }
    // filterReset = (e) => {
    //     this.setState({
    //         month: '',
    //         year: '',
    //         isFilterShow: false
    //     });
    //     this.props.listpayroll()
    // }


    render() {
        if (this.state.viewRedirect) {
            return <Redirect to={'/monthlypayslip/' + this.state.id} />
        }
        const { employeesalaryState, employeeReducer, leavetransectionState, EmployeeLeaveState, LeaveState, deductionReducer, earningReducer, payrollState } = this.props;
        const listemployeesalary = employeesalaryState.listemployeesalary;
        const listemployee = employeeReducer.listEmployee;
        const listEarning = earningReducer.listEarning;
        const listDeduction = deductionReducer.listDeduction;
        const data = payrollState.payroll;
        const account = this.props.account.account;
        const rowData = payrollState.listPayroll;

        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            editOption
            + deleteOption
            + '</div>'
        if (rowData && rowData.length > 0) {
            rowData.forEach(object => {
                object.actions = template;
            });
        }
        let salaryAdd = Number(data.leavetransecsalary) + ((data.earningamount && data.earningamount != undefined) ? Number(data.earningamount) : 0)
        let salary = Number(salaryAdd) - ((data.deductionamount && data.deductionamount != undefined) ? Number(data.deductionamount) : 0)



        var EmloyeeId = '';
        listemployee.length && listemployee.map(item => {
            if (item._id === data.name) {
                EmloyeeId = item.employeeId
            }
        })

        let selectedName = '';
        listemployee.length && listemployee.map(item => {
            if (item._id === data.name) {
                selectedName = item.employeeName
            }
        })

        let earningName = '';
        listEarning.length && listEarning.map(item => {
            if (item._id === data.earning) {
                earningName = item.name
            }
        })

        let deductionName = '';
        listDeduction.length && listDeduction.map(item => {
            if (item._id === data.deduction) {
                deductionName = item.name
            }
        })
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const StartDate = data.date;
        const monthOptions = [
            { value: 'January', label: 'January', name: 'month' },
            { value: 'February', label: 'February', name: 'month' },
            { value: 'March', label: 'March', name: 'month' },
            { value: 'April', label: 'April', name: 'month' },
            { value: 'May', label: 'May', name: 'month' },
            { value: 'June', label: 'June', name: 'month' },
            { value: 'July', label: 'July', name: 'month' },
            { value: 'August', label: 'August', name: 'month' },
            { value: 'September', label: 'September', name: 'month' },
            { value: 'October', label: 'October', name: 'month' },
            { value: 'November', label: 'November', name: 'month' },
            { value: 'December', label: 'December', name: 'month' },
        ]


        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6>Monthly Payroll List</h6></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">List Monthly Payroll</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <div className="col-sm-12 col-md-12">
                                                        <label className="form-label">Month</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={this.handleChangeMonth}
                                                                options={monthOptions && monthOptions?.map((item) => {
                                                                    return (
                                                                        { label: item.label, value: item.value, name: 'month' }
                                                                    )
                                                                })}
                                                            />
                                                            <div className="invalid-feedback" style={{ display: this.state.monthError ? "block" : 'none' }}>Month is required</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='col-lg-3'>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Year</label>
                                                            <Select
                                                                onChange={this.handleChangeYear}
                                                                options={this.state.yearList && this.state.yearList.map((item, i) => {
                                                                    return {
                                                                        label: item.label, value: item.value, name: 'year',
                                                                    }
                                                                })} />
                                                            <div className="invalid-feedback" style={{ display: this.state.yearError ? "block" : 'none' }}>Year is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='col-lg-2'>
                                                    {this.state.isFilterShow &&
                                                        <div className='emp_reset_btn'>
                                                            <button className='btn topsocial_rest_btn btn-primarys ' type='button' onClick={this.filterReset} style={{ height: '45px !important', width: '115pxpx !important' }}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                        </div>
                                                    }
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                        {/* excelStyles={this.state.excelStyles} */}
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>{this.state.modalType} Monthly Payroll</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                        <img src='../../assets/images/cancel.png' />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        {/* <div className='col-lg-12'>
                                                            <div className="form-group leave_transec_date">
                                                                <label className="form-label">Date</label>
                                                                <DatePicker
                                                                    onlyMonthPicker
                                                                    name="date"
                                                                    value={StartDate}
                                                                    format="MMMM YYYY"
                                                                    placeholder='MM-YYYY'
                                                                    onChange={this.onChangeDate}
                                                                />
                                                                <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}>Date is required</div>
                                                            </div>
                                                        </div> */}
                                                        <div className='row'>
                                                            <div className="col-sm-6 col-md-6">
                                                                <label className="form-label">Month<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                <div className="form-group">
                                                                    <Select
                                                                        value={data.month && { label: data.month, value: data.month }}
                                                                        onChange={this.handleChangeDate}
                                                                        options={monthOptions && monthOptions?.map((item) => {
                                                                            return (
                                                                                { label: item.label, value: item.value, name: 'month' }
                                                                            )
                                                                        })}
                                                                    />
                                                                    <div className="invalid-feedback" style={{ display: this.state.monthError ? "block" : 'none' }}>Month is required</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Year<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                    <Select
                                                                        value={data.year && { label: moment(data.year).format("YYYY"), value: moment(data.year).format("YYYY") }}
                                                                        onChange={this.handleChangeDate}
                                                                        options={this.state.yearList && this.state.yearList.map((item, i) => {
                                                                            return {
                                                                                label: item.label, value: item.value, name: 'year',
                                                                            }
                                                                        })} />
                                                                    <div className="invalid-feedback" style={{ display: this.state.yearError ? "block" : 'none' }}>Year is required</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Employee Name</label>
                                                                <Select
                                                                    value={selectedName ? { label: selectedName } : ''}
                                                                    onChange={this.handleSelectChange}
                                                                    options={listemployee ? listemployee.filter(filterItem => filterItem.status === true).map(item => {
                                                                        return {
                                                                            label: item.employeeName, value: item._id, name: 'name', _id: item._id
                                                                        }
                                                                    }) : ''}
                                                                />
                                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className="form-group">
                                                                        <label className="form-label">Add Deduction</label>
                                                                        <Select
                                                                            value={deductionName ? { label: deductionName } : ''}
                                                                            onChange={this.handleSelectChange}
                                                                            options={listDeduction ? listDeduction.filter(filterItem => filterItem.status === true).map(item => {
                                                                                return {
                                                                                    label: item.name, value: item._id, name: 'deduction', _id: item._id
                                                                                }
                                                                            }) : ''}
                                                                        />
                                                                        <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className="form-group">
                                                                        <label className="form-label">Deduction Amount </label>
                                                                        <input type="text" className="form-control" placeholder="Deduction Amount" name="deductionamount" onChange={this.handleOnchange} value={data.deductionamount} />
                                                                        <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Employee ID</label>
                                                                <input type="text" className="form-control" placeholder="Employee ID" name="empid" value={EmloyeeId ? EmloyeeId : ''} disabled="disabled" />
                                                                <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className="form-group">
                                                                        <label className="form-label">Add Earning</label>
                                                                        <Select
                                                                            value={earningName ? { label: earningName } : ''}
                                                                            onChange={this.handleSelectChange}
                                                                            options={listEarning ? listEarning.filter(filterItem => filterItem.status === true).map(item => {
                                                                                return {
                                                                                    label: item.name, value: item._id, name: 'earning', _id: item._id
                                                                                }
                                                                            }) : ''}
                                                                        />
                                                                        <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className="form-group">
                                                                        <label className="form-label">Earnig Amount </label>
                                                                        <input type="text" className="form-control" placeholder="Earning Amount" name="earningamount" onChange={this.handleOnchange} value={data.earningamount} />
                                                                        <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right payrollgen_btn">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i class="fa fa-spinner fa-spin mr-2"></i>Genarating</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Genarate</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    employeeReducer: state.employeeReducer,
    payrollitemState: state.payrollitem,
    employeesalaryState: state.employeesalary,
    leavetransectionState: state.leavetransection,
    EmployeeLeaveState: state.employeeleave,
    LeaveState: state.leaves,
    account: state.account,
    earningReducer: state.earningReducer,
    deductionReducer: state.deductionReducer,
    payrollState: state.payroll
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            listEmployeesalary: AC_LIST_EMPLOYEE_SALARY,
            listemployee: AC_LIST_EMPLOYEE,
            handleChange: AC_HANDLE_PAYROLL_CHANGE,
            listpayroll: AC_LIST_PAYROLL,
            viewpayroll: AC_VIEW_PAYROLL,
            resetpayroll: AC_RESET_PAYROLL,
            listemployeeleave: AC_LIST_EMPLOYEE_LEAVE,
            ListLeaves: AC_LIST_LEAVES,
            AC_ACCOUNT_DETAILS,
            listdeducton: AC_LIST_DEDUCTION,
            listearning: AC_LIST_EARNING,
            handleSearch: AC_HANDLE_PAYROLL_SEARCH
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(MonthlyPayroll);


