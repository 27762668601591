export function Emailvalidate(email) {
    const regemail = /^\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3})+$/;
    if (regemail.test(email)) {
        return 1;
    }
    else {
        return 0;
    }
}

export function NumberOnly(num) {
    const number = /^\d+$/;
    if (number.test(num)) {
        return 1
    } else {
        return 0
    }
}
export function Urlvalidate(url) {
    const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    if (regex.test(url)) {
        return 1;
    }
    else {
        return 0;
    }
}

export function Validpassword(password) {
    const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})");
    const passwordvalid = pattern.test(password)
    return passwordvalid
}

export function Facebookvalidate(facebook_link) {
    const facebook_pattern = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/;
    const isvalidFacebooklink = facebook_pattern.test(facebook_link);
    return isvalidFacebooklink;
}

export function Twittervalidate(twitter_link) {
    const twitter_pattern = /^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/;
    const isvalidTwitterlink = twitter_pattern.test(twitter_link);
    return isvalidTwitterlink;
}

export function Googleplusvalidate(googleplus_link) {
    const googleplus_pattern = /plus\.google\.com\/.?\/?.?\/?([0-9]*)/;
    const isvalidGooglepluslink = googleplus_pattern.test(googleplus_link);
    return isvalidGooglepluslink;
}

export function Phonenumber(number) {
    const phoneno = /^\d{10}$/;
    if (phoneno.test(number)) {
        return 1;
    } else {
        return 0;
    }
}
export function Imagevalidation(logo) {
    const fileInfo = logo;
    const fileType = fileInfo.type;
    const type = fileType.split('/');
    if (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png') {
        return 1;
    } else {
        return 0;
    }
}

export function Filevalidation(file) {
    const fileInfo = file;
    const fileType = fileInfo.type;
    const type = fileType.split('/');
    if (type[1] === 'pdf' || type[1] === 'doc' || type[1] === 'docx') {
        return 1;
    } else {
        return 0;
    }
}
export function Currency(amount) {
    if (amount) {
        amount = amount.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');
        if (amount.indexOf('.') === -1)
            return amount + '.00';
        var decimals = amount.split('.')[1];
        return decimals.length < 2 ? amount + '0' : amount;

    } else {
        return amount;
    }

};

export function ImageFilevalidation(file) {
    const fileInfo = file;
    const fileType = fileInfo.type;
    const type = fileType.split('/');
    if (type[1] === 'pdf' || type[1] === 'doc' || type[1] === 'docx' || type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'msword') {
        return 1;
    } else {
        return 0;
    }
}

export function Zip(zip) {
    const zippattern = /^\d{5}[-\s]?(?:\d{4})?$/;
    const patternnumner = /(^\d{6}$)|(^\d{6}-\d{4}$)/;
    if ((zippattern.test(zip)) || (patternnumner.test(zip))) {
        return 1;
    } else {
        return 0;
    }
}
export function Hostvalid(host) {
    const pattern = /^\w+\.\w+\.[a-zA-z]{1,3}$/    //xxx.domain.in/com/...
    const hostnamevalid = pattern.test(host)
    return hostnamevalid
}
export function Portvalid(port) {  //takes port number with in the range of 1-65535
    const patt = /^((((([1-9])|([1-9][0-9])|([1-9][0-9][0-9])|([1-9][0-9][0-9][0-9])|([1-6][0-5][0-5][0-3][0-5])))))$/   //range from (1-65535)
    const postnamevalid = patt.test(port)
    return postnamevalid
}
export function convertToSlug(Text) {
    return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
}

export function FileAndImagevalidation(file) {
    const fileInfo = file;
    const fileType = fileInfo.type;
    const type = fileType.split('/');
    if (type[1] === 'pdf' || type[1] === 'doc' || type[1] === 'docx' || type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png') {
        return 1;
    } else {
        return 0;
    }
}

export function Textareavalidation(text) {
    var res = {}
    if (text.length <= 0) {
        res.notextErr = true;
        res.value = text
        res.textlimitErr = false;

    }
    else if (text.length < 256 && text.length >= 0) {
        res.notextErr = false;
        res.textlimitErr = false;
        res.value = text
    }
    else {
        res.notextErr = false;
        res.textlimitErr = true;
        res.value = text
    }
    return res
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const arr = x => Array.from(x);
const num = x => Number(x) || 0;
const str = x => String(x);
const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0, n);
const drop = n => xs => xs.slice(n);
const reverse = xs => xs.slice(0).reverse();
const comp = f => g => x => f(g(x));
const not = x => !x;
const chunk = n => xs =>
    isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];



export function numToWords(n) {
    let a = [
        '', 'one', 'two', 'three', 'four',
        'five', 'six', 'seven', 'eight', 'nine',
        'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
        'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];

    let b = [
        '', '', 'twenty', 'thirty', 'forty',
        'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];

    let g = [
        '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
        'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion'
    ];

    // this part is really nasty still
    // it might edit this again later to show how Monoids could fix this up
    let makeGroup = ([ones, tens, huns]) => {
        return [
            num(huns) === 0 ? '' : a[huns] + ' hundred ',
            num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
            a[tens + ones] || a[ones]
        ].join('');
    };

    let thousand = (group, i) => group === '' ? group : `${group} ${g[i]}`;

    if (typeof n === 'number')
        return numToWords(String(n));
    else if (n === '0')
        return 'zero';
    else
        return comp(chunk(3))(reverse)(arr(n))
            .map(makeGroup)
            .map(thousand)
            .filter(comp(not)(isEmpty))
            .reverse()
            .join(' ');
};