import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_EMAIL_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMAIL_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_EMAIL() {
    return function (dispatch) {
        dispatch({ type: "RESET_EMAIL" })
    };
}


export function AC_LIST_EMAIL() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listEmail)
                .then((res) => {
                    dispatch({ type: "LIST_EMAIL", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_LIST_TRASH_EMAIL() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listTrashEmail)
                .then((res) => {
                    dispatch({ type: "LIST_TRASH_EMAIL", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_EMAIL(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewEmail + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_EMAIL", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


export function AC_EMPTY_EMAIL() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_JOBTEMPLATE" })
    }
}



// ==============================  REPLAY MAIL ====
export function AC_HANDLE_REPLAYMAIL_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_REPLAYMAIL_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_REPLAYMAIL() {
    return function (dispatch) {
        dispatch({ type: "RESET_REPLAYMAIL" })
    };
}


export function AC_LIST_REPLAYMAIL() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listEmail)
                .then((res) => {
                    dispatch({ type: "LIST_REPLAYMAIL", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_LIST_TRASH_REPLAYMAIL() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listTrashEmail)
                .then((res) => {
                    dispatch({ type: "LIST_TRASH_REPLAYMAIL", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_REPLAYMAIL(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewEmail + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_REPLAYMAIL", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}