import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_LIST_DEPARTMENT, AC_VIEW_DEPARTMENT, AC_HANDLE_DEPARTMENT_CHANGE, AC_RESET_DEPARTMENT } from '../../../actions/departmentAction';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import { Success, Error } from '../../../common/swal';
import Swal from 'sweetalert2';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import { AC_LIST_EMPLOYEE } from '../../../actions/employeeAction';
import { Filevalidation } from '../../../common/validate';
import moment from 'moment';


const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "Add",
    save: false,
    sopdata: {}
}
class ListDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.changeModal = this.changeModal.bind(this);
    }
    changeModal = (e) => {
        this.ResetData();
        this.setState({ modalType: e.target.id });
    }

    ResetData = () => {
        this.setState({ sopdata: { name: '', version: '', effectivedate: '' } })
    }

    onChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false, sopdata: { ...this.state.sopdata, [name]: value } })
    }
    onChangeSelect = (e) => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false, sopdata: { ...this.state.sopdata, [name]: value } })

    }

    handleChangeLeader = (e, name) => {
        const { value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false, sopdata: { ...this.state.sopdata, [name]: value } })

    }
    submit = () => {
        const data = this.state.sopdata;
        let valid = 1
        if (!data.name) {
            this.setState({ nameError: true })
            valid = 0;
        }
        if (!data.department) {
            this.setState({ departmentError: true })
            valid = 0;
        }
        if (!data.version) {
            this.setState({ versionError: true })
            valid = 0;
        }
        if (!data.effectivedate) {
            this.setState({ effectivedateError: true })
            valid = 0;
        }
        if (!data.preparedby) {
            this.setState({ preparedbyError: true })
            valid = 0;
        }
        if (!data.recommendedby) {
            this.setState({ recommendedbyError: true })
            valid = 0;
        }
        if (!data.approvedby) {
            this.setState({ approvedbyError: true })
            valid = 0;
        }

        if (!data.file && !this.state.file) {
            this.setState({ fileError: true })
            valid = 0;
        }
        if (valid) {
            const formData = new FormData();
            for (var key in data) {
                formData.append(key, data[key]);
            }

            if (this.state.modalType === "Add") {
                this.setState({ save: true });

                axios.post(ImportedURL.API.addSOP, formData)
                    .then((data) => {
                        Success(data.statusText);
                        this.props.history.push('/company-sop')
                        this.setState({ save: false });
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                const urlParams = new URLSearchParams(window.location.search);
                const type = urlParams.get('type');
                axios.post(ImportedURL.API.updateSOP + '/' + this.state.id, formData)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.history.push(type == 'approval' ? '/approve-company-sop' : type == 'requests' ? '/company-sop-requests' : '/company-sop')
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }

    componentDidMount() {
        this.props.AC_LIST_DEPARTMENT();
        const { params, path } = this.props.match;
        if (path === "/edit-company-sop/:id") {
            this.setState({ modalType: "Edit", id: params.id })
            this.getSOPData();
        } else {
            this.setState({ modalType: "Add" });
            this.ResetData();
        }
        this.props.ListEmployee();
        localStorage.setItem('invoicetoken', "");
    }

    getSOPData = () => {
        const { params } = this.props.match;
        axios.get(`${ImportedURL.API.viewSOP}/${params.id}`)
            .then((res) => {
                this.setState({ sopdata: res.data })
            }).catch((err) => {
                console.log(err);
            });
    }

    uploadhandleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'file') {
            if (Filevalidation(event.target.files[0]))
                this.setState({ file: event.target.files[0], sopdata: { ...this.state.sopdata, file: event.target.files[0] }, fileError: false })
            else {
                Error('Please upload valid file')
                document.getElementById('fileset').value = ''
                this.setState({ file: '', sopdata: { ...this.state.sopdata, file: '' }, fileError: true })

            }
        }
    }

    render() {
        const { employeeState, departmentReducer } = this.props;
        const data = this.state.sopdata;
        const employeeOptions = [];
        const employeeData = employeeState.listEmployee;
        let selectedpreparedby = '';
        let selectedrecommendedby = '';
        let selectedapprovedby = '';
        employeeData.filter(filterItem => filterItem.status === true).map(item => {
            if (item._id == data.preparedby) selectedpreparedby = { label: `${item.employeeName} (${item.employeeId})`, value: item._id, name: 'preparedby' }
            if (item._id == data.recommendedby) selectedrecommendedby = { label: `${item.employeeName} (${item.employeeId})`, value: item._id, name: 'preparedby' }
            if (item._id == data.approvedby) selectedapprovedby = { label: `${item.employeeName} (${item.employeeId})`, value: item._id, name: 'preparedby' }

            employeeOptions.push({ label: `${item.employeeName} (${item.employeeId})`, value: item._id, name: 'preparedby' });
        })

        let selectedDepartment = '';

        const listDepartment = departmentReducer.listDepartment;
        const departmentOptions = [];
        listDepartment.filter(filterItem => filterItem.status === true).map(item => {
            if (data.department == item._id) selectedDepartment = { label: item.name, value: item._id, name: 'department' }
            departmentOptions.push({ label: item.name, value: item._id, name: 'department' });
        })
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6> Add SOP</h6></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i class="fa-sharp fa-solid fa-file-text mr-2"></i>
                                            </div>
                                            <h3 className="card-title">Company SOP</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="header-action">
                                                        <Link to={type == 'approval' ? '/approve-company-sop' : type == 'requests' ? '/company-sop-requests' : '/company-sop'}>
                                                            <button type="button" className="btn btn-primary" id='Add'><i className="fa fa-chevron-left mr-2 mt-2" id='Add' />Back</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                {/* <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row clearfix">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">SOP Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onChange} value={data.name} placeholder="SOP Name" />
                                                        <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>SOP Name is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Version<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='version' onChange={this.onChange} value={data.version} placeholder="SOP Version" />
                                                        <div className="invalid-feedback" style={{ display: this.state.versionError ? "block" : 'none' }}>Version is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Department Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            onChange={this.onChangeSelect}
                                                            value={selectedDepartment}
                                                            options={departmentOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.departmentError ? "block" : 'none' }}>Department Name is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Effective Date<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <input type="date" className="form-control" disabled={this.state.modalType == "View"} name='effectivedate' onChange={this.onChange} value={data.effectivedate ? moment(data.effectivedate).format('YYYY-MM-DD') : ''} placeholder="SOP Name" />
                                                        <div className="invalid-feedback" style={{ display: this.state.effectivedateError ? "block" : 'none' }}>Effective Date is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">SOP Prepared by<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={selectedpreparedby}
                                                            onChange={(e) => this.handleChangeLeader(e, 'preparedby')}
                                                            options={employeeOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.preparedbyError ? "block" : 'none' }}>Prepared By is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">SOP Recommended by<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={selectedrecommendedby}
                                                            onChange={(e) => this.handleChangeLeader(e, 'recommendedby')}
                                                            options={employeeOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.recommendedbyError ? "block" : 'none' }}>Recommended By is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">SOP Approved by<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={selectedapprovedby}
                                                            onChange={(e) => this.handleChangeLeader(e, 'approvedby')}
                                                            options={employeeOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.approvedbyError ? "block" : 'none' }}>Approved By is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group ">
                                                        <label className="form-label">
                                                            Upload File<span className='ml-1' style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="file" className="form-control" disabled={this.state.modalType === "View"} placeholder="Staff Image" name='file' onChange={this.uploadhandleChange} id="fileset" />
                                                        <div className="invalid-feedback" style={{ display: this.state.fileError ? "block" : 'none' }}>File is required</div>
                                                    </div>
                                                    {this.state.file || data.file ? <div className="form-group ">
                                                        <div>
                                                            <a href={this.state.file ? URL.createObjectURL(this.state.file) : ImportedURL.LIVEURL + data.file} class="btn btn-icon" target='_blank'><i class="fa fa-file-pdf" style={{ fontSize: '55px' }} ></i></a>
                                                            {/* <img style={{ width: '100px', height: '100px' }} src={this.state.file ? URL.createObjectURL(this.state.file) : ImportedURL.LIVEURL + data.file} alt='' /> */}
                                                        </div>
                                                    </div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right mandatory">
                                            <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                            <div className="text-right">
                                                {this.state.save ?
                                                    <button type="button" className="btn btn-primary"><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Modal */}
                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel"><i class="fa-sharp fa-solid fa-building-circle-arrow-right mr-2"></i>{this.state.modalType} Department</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }} >x</span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}



const mapStateToProps = (state) => ({
    departmentReducer: state.departmentReducer,
    employeeState: state.employeeReducer,
    departmentReducer: state.departmentReducer
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AC_LIST_DEPARTMENT, AC_VIEW_DEPARTMENT,
            AC_HANDLE_DEPARTMENT_CHANGE,
            ListEmployee: AC_LIST_EMPLOYEE,
            AC_RESET_DEPARTMENT,
            AC_LIST_DEPARTMENT
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListDepartment);