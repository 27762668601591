import { combineReducers } from 'redux';
import settings from './settings';
import account from './account';
import category from './category';
import user from './user';
import inventoryReducer from './inventory';
import assetReducer from './asset';
import countryReducer from './country';
import inventoryAssetReducer from './inventoryAsset';
import employeeReducer from './employee';
import departmentReducer from './department';
import designationReducer from './designation';
import assignInventoryReducer from './assignInventory';
import holiday from './holiday';
import payrollitem from './payrollitem';
import employeesalary from './employeesalary';
import leavetemplate from './leavetemplate';
import managegrades from './managegrades';
import project from './project';
import email from './email';
import taskboard from './taskboard';
import leaves from './leaves';
import leavetype from './leavetype';
import employeeleave from './employeeleave';
import kycdocument from './kycdocument';
import leavetransection from './leavetransection';
import divisionReducer from './division';
import earningReducer from './earning';
import deductionReducer from './deduction';
import payroll from './payroll';
import invoicecategory from './invoicecategory';
import invoicesubcategory from './invoicesubcategory';
import invoicedetail from './invoicedetail';
import invoice from './invoice';
import invoicenumber from './invoicenumber';






export default combineReducers({
    settings,
    account,
    category,
    user,
    inventoryReducer,
    assetReducer,
    countryReducer,
    inventoryAssetReducer,
    employeeReducer,
    departmentReducer,
    designationReducer,
    assignInventoryReducer,
    holiday,
    payrollitem,
    employeesalary,
    leavetemplate,
    managegrades,
    project,
    email,
    taskboard,
    leaves,
    leavetype,
    employeeleave,
    kycdocument,
    leavetransection,
    divisionReducer,
    earningReducer,
    deductionReducer,
    payroll,
    invoicecategory,
    invoicesubcategory,
    invoicedetail,
    invoice,
    invoicenumber,
});