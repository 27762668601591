import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import moment from 'moment';
import { Currency, Emailvalidate } from '../../../common/validate';
import { AC_HANDLE_INVOICE_CHANGE, AC_LIST_INVOICE, AC_VIEW_INVOICE, AC_RESET_INVOICE, AC_EMPTY_INVOICE, AC_SPINNER_INVOICE_ALL } from '../../../actions/invoiceAction';
import { Urlvalidate, NumberOnly } from '../../../common/validate';
import { AC_LIST_INVOICE_DETAIL } from '../../../actions/invoicedetail';
import { AC_LIST_INVOICE_CATEGORY } from '../../../actions/invoicecategoryAction';
import { AC_LIST_INVOICE_SUB_CATEGORY } from '../../../actions/invoicesubcategoryAction';
import ImportedUrl from '../../../common/api';
import Spinner from 'react-bootstrap/Spinner';

let date = new Date();
let nextmonth = date.setMonth(date.getMonth() + 1);
const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    image: '',
    email: '',
    eye: false,
    changepwdshow: false,
    changepwdinvoice: false,
    password: '',
    newpassword: '',
    confirmpassword: '',
    perPage: 25,
    tocompanyValue: '',
    passwordError: false,
    newpasswordError: false,
    confirmpasswordError: false,
    nameError: false,
    categoryError: false,
    subcategoryError: false,
    addressError: false,
    toaddressError: false,
    amountError: false,
    invoicedateError: false,
    duedateError: false,
    urlError: false,
    discountError: false,
    companyError: false,
    tocompanyError: false,
    categoryError: false,
    subcategoryError: false,
    invalidurlError: false,
    communicationemailValidError: false,
    invoicepasswordError: false,
    companyData: [],
    companydropdown: [],
    listinvoiceData: [],
    companyDrop: false,
    editSpi: false,
    allOption: true,
    pdfList: [],
    columnDefs: [
        {
            headerName: 'Image', field: 'tocompanyimage', width: 100, filter: false, cellStyle: { textAlign: 'center' },
            cellRendererFramework: function (params) {
                if (params.data) {
                    return <div>
                        <img style={{ borderRadius: '0px' }} width='65px' height='65px' src={ImportedURL.LIVEURL + params.data.tocompanyimage} alt="" />
                    </div>
                }
            }
        },
        { headerName: 'Transaction ID', field: 'transactionid', width: 130, floatingFilter: true, },
        { headerName: 'Invoice #', field: 'invoicenum', width: 130, floatingFilter: true, },
        { headerName: 'Client', field: 'tocompany', width: 130, floatingFilter: true, },

        {
            headerName: 'Amount Paid', field: 'amountpaid', width: 130, floatingFilter: true,
        },



        // {
        //     headerName: 'Actions', width: 200, field: 'actions', filter: false, sortable: false, cellStyle: { textAlign: 'center' },
        //     cellRenderer: function (params) {
        //         return params.value;
        //     }
        // },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
    allOptionCategory: true,
    allOptionSubCategory: true,
    pdfData: '',
    datefilter: [],
    companyfilter: [],
    datefilterdata: '',
    companyfilterdata: { name: 'company', label: "All", value: '' },
    client: {
        category: [],
        subcategory: [],
        discounttype: 'flat',
        invoicedate: moment(new Date()).format("YYYY-MM") + "-25",
        duedate: moment(nextmonth).format("YYYY-MM") + "-05"
    },

}
class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }


    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id, client: event.data, editSpi: true });
            this.setState({ nameError: false, cityError: false, stateError: false, codeError: false })
            this.setState({ categoryError: false })
            this.setState({ subcategoryError: false })
            this.setState({ addressError: false })
            this.setState({ amountError: false })
            this.setState({ urlError: false })
            this.setState({ discountError: false })
            this.setState({ companyError: false })
            this.setState({ categoryError: false })
            this.setState({ subcategoryError: false })
            this.setState({ invalidurlError: false })
            this.setState({ imageError: false })
            this.setState({ image: '' })
            document.getElementById('imageSet').value = '';
            this.setState({ newpasswordError: false })
            this.setState({ confirmpasswordError: false })
        }

        if (value === 'History') {
            this.props.history.push('/clients-history/' + event.data._id)
        }

        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteInvoice + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'clients' })
                        .then((data) => {
                            // this.props.listinvioce();
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['transactionid', 'invoicenum', 'tocompany', 'amountpaid'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { listinvoiceData } = this.state;
        const exportData = listinvoiceData;

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Transactions";
        const headers = [['#', 'Trasaction ID', 'Invoice #', 'Client', 'Amount paid']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.transactionid,
            elt.invoicenum,
            elt.tocompany,
            elt.amountpaid,

        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Transactions.pdf")
    }


    componentDidMount() {
    }


    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {
                this.setState({ spinner: true })

                const page = params.endRow / this.state.perPage;
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                var invoiceCode = localStorage.getItem("invoicetoken") ? localStorage.getItem("invoicetoken") : '';
                await axios.post(ImportedURL.API.getTransactions,
                    {
                        ...query,
                        perPage: this.state.perPage,
                        page: page, filter: filter,
                        sort: sortModel.length ? sortModel[0] : {},
                        invoicecode: invoiceCode,
                        clientid: this.props.match.params.id
                    }
                )
                    .then((res) => {
                        const rowData = res.data.data;
                        this.setState({ listinvoiceData: rowData, spinner: false });
                        const total = res.data.total;
                        const previleges = this.props.AccountState.previleges;
                        const Previlege = previleges.find(obj => { return obj.name == "Invoice" });
                        const editOption = Previlege.edit ? '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>' : '';
                        // const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';

                        const template =
                            editOption
                            // + deleteOption
                            + '<button type="button" class="btn btn-icon" data-action-type="History"><i class="fa fa-history" style="color: #2D3377 !important;font-size:20px;" data-action-type="History" data-toggle="tooltip" title="Client history"></i></button>'
                            + '</div>'

                        rowData.forEach(object => {
                            object.actions = template;
                        });
                        this.setState({ spinner: false });
                        params.successCallback(rowData, total);
                    }).catch(({ response }) => {
                        this.setState({ spinner: false });
                        params.successCallback([], 0);
                        if (response.status == 405) {
                            localStorage.setItem('invoicetoken', "");
                            window.location.href = "/invoicepwdClient";
                        }
                    });


            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                this.setState({ spinner: true })
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };


    ResetFilters = () => {
        this.gridApi.setFilterModel(null);
        this.setState({ resetshow: false, })
        const dataSource = this.getDataSource({})
        this.gridApi.setDatasource(dataSource);
    }
    render() {

        const { invoiceState, invoicedetailState, invoicesubcategoryState, invoicecategoryState } = this.props;
        const spinner = this.state.spinner;
        const listinvoicesubcategory = invoicesubcategoryState.listinvoicesubcategory;
        const listinvoicecategory = invoicecategoryState.listinvoicecategory;
        const data = this.state.client;
        if (data !== undefined) {
            var imageData = data.image;
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]



        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6>Transactions</h6></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">Transactions</h3>
                                            {this.state.listinvoiceData.length ? <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>

                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div> : ''}
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.resetshow &&
                                                    <button type="button" className="btn btn-primary change_btn_invoice mx-3 mt-4" onClick={() => this.ResetFilters()}>Reset</button>
                                                }

                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={this.state.perPage}
                                                        cacheBlockSize={this.state.perPage}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        onGridReady={this.onGridReady}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        wrapText={true}
                                                        animateRows={true}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        onFilterChanged={(e) => { if (e.api.isAnyFilterPresent()) this.setState({ resetshow: true }) }}
                                                        rowModelType={'infinite'}>
                                                    </AgGridReact>
                                                </div>
                                                {spinner ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../../assets/images/banner-logo.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ==============detail============== */}




            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    invoiceState: state.invoice,
    invoicedetailState: state.invoicedetail,
    invoicesubcategoryState: state.invoicesubcategory,
    invoicecategoryState: state.invoicecategory,
    AccountState: state.account,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            handleChange: AC_HANDLE_INVOICE_CHANGE,
            listinvioce: AC_LIST_INVOICE,
            viewinvioce: AC_VIEW_INVOICE,
            resetinvioce: AC_RESET_INVOICE,
            listinvoicedetail: AC_LIST_INVOICE_DETAIL,
            listinvoicecategory: AC_LIST_INVOICE_CATEGORY,
            listinvoicesubcategory: AC_LIST_INVOICE_SUB_CATEGORY,
            emptyinvoice: AC_EMPTY_INVOICE,
            AC_SPINNER_INVOICE_ALL
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);


