import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Select from 'react-select';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';
import { AC_LIST_ASSIGNINVENTORY, AC_VIEW_ASSIGNINVENTORY, AC_HANDLE_ASSIGNINVENTORY_CHANGE, AC_RESET_ASSIGNINVENTORY } from '../../actions/assignInventoryAction';
import jsPDF from "jspdf";
import moment from 'moment';
import { capitalizeFirstLetter } from '../../common/validate';



const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    viewFlag: false,
    flagError: false,
    pushValue: '',
    activeClick: false,
    inactiveClick: false,
    page: '',

    columnDefs: [
        { headerName: 'Asset name', field: 'assetname', width: 200, floatingFilter: true },
        { headerName: 'Category', field: 'category', width: 200, floatingFilter: true },
        { headerName: 'Subcategory', field: 'subcategory', width: 200, floatingFilter: true },
        {
            headerName: 'Employee Name', field: 'employeeName', width: 200, floatingFilter: true, cellStyle: { textAlign: 'left' },
            cellRenderer: function (params) {
                if (params.data.employeeName) {
                    return params.data.employeeName;
                } else return '---'
            }
        },
        // {
        //     headerName: 'Purchase Date', width: 200, field: 'dateofpurchase', cellStyle: { textAlign: 'center' },
        //     cellRenderer: function (params) {
        //         if (params.data.dateofpurchase) {
        //             return moment(params.data.dateofpurchase).format("MM/DD/YYYY");
        //         }
        //     }
        // },
        {
            headerName: 'Status', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status == 'stock') {
                    return `<span type="button" class="tag ticket_open" style="background-color: #a8e1ff !important;cursor: auto !important" data-action-type="Status">Stock</span>`;
                } else if (params.data.status == 'assigned') {
                    return '<span type="button" class="tag ticket_completed" style="background-color: #c4e4a5  !important;cursor: auto !important" data-action-type="Status">Assigned</span>';
                } else if (params.data.status == 'service') {
                    return '<span type="button" class="tag ticket_completed" style="background-color: #eeeaaf  !important;cursor: auto !important" data-action-type="Status">Service</span>';
                } else if (params.data.status == 'damage') {
                    return '<span type="button" class="tag ticket_inreview" style="background-color: #f5d7bb   !important;cursor: auto !important" data-action-type="Status">Damage</span>';

                }
            }
        },
        {
            headerName: 'Actions', width: 270, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.data.actions
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },

    assigndata: {},
    servicedata: {},
    damagedata: {},
    status: ''
}
class ListAssignedInventory extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.changeModal = this.changeModal.bind(this);
        // this.onChange = this.onChange.bind(this);
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
    }
    setGroup = e => {
        const { name, value } = e;
        this.props.AC_LIST_ASSIGNINVENTORY(value);
        this.setState({ [name + 'option']: e })

    }

    ResetStatus = () => {
        this.setState({ status: '', reason: '', date: '', condition: '' });

    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Update') {
            this.ResetStatus()
            this.setState({ id: event.data._id });
        }
        if (value === 'Assign') {
            this.ResetStatus()
            this.setState({ id: event.data._id });
        }
        if (value === 'Service') {
            this.ResetStatus()
            this.setState({ id: event.data._id });
        }
        if (value === 'Edit') {
            this.props.history.push(`edit-asset/${event.data._id}`)
        }
        if (value === 'View') {
            this.props.history.push(`view-asset/${event.data._id}`)
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.deleteAsset + "/" + event.data._id)
                        .then((data) => {
                            this.GetAssets();
                            Success('Asset deleted successfully')
                        }).catch(({ response }) => { if (response) Error('Asset deletion failed') });
                }
            })
        }

    }

    selectChangeAssign = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false, assigndata: { ...this.state.assigndata, [name]: value } })
    }
    handleChangeAssign = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false, assigndata: { ...this.state.assigndata, [name]: value } })

    }
    handleChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false, [name]: value })
    }

    updateStatus = () => {
        const data = this.state;
        let valid = 1;
        if (!data.status) {
            this.setState({ statusError: true });
            valid = 0;
            return;
        }
        if (!data.reason) {
            this.setState({ reasonError: true });
            valid = 0;
        }
        if (data.status != 'stock' && !data.date) {
            this.setState({ dateError: true });
            valid = 0;
        }
        if (valid) {
            let formdata = {
                status: data.status,
                reason: data.reason,
                date: data.date
            }
            axios.post(`${ImportedURL.API.assetStatusUpdate}/${this.state.id}`, formdata)
                .then((data) => {
                    this.GetAssets();
                    Success('Status updated successfully')
                    let btn = document.getElementById("closeModal1");
                    btn.click();
                }).catch(({ response }) => { if (response) Error('Status update failed') });

        }
    }
    assignAsset = () => {
        const data = this.state.assigndata;
        let valid = 1;
        if (!data.employee) {
            this.setState({ employeeError: true });
            valid = 0;
        }
        if (!data.assignedDate) {
            this.setState({ assignedDateError: true });
            valid = 0;
        }
        if (valid) {
            axios.post(`${ImportedURL.API.assignasset}/${this.state.id}`, data)
                .then((data) => {
                    this.GetAssets();
                    Success('Status updated successfully')
                    let btn = document.getElementById("assignAssetClose");
                    btn.click();
                }).catch(({ response }) => { if (response) Error('Status update failed') });

        }
    }
    handleSelectChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false, status: value })
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    searchUser = e => {
        const { name, value } = e;
        this.setState({
            [name]: value,
        });
    }

    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['inventory.name', 'asset.assetName', 'serialNo'],
            processCellCstockback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { assignInventoryReducer, } = this.props;
        const exportData = assignInventoryReducer.listAssignInventory;
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Inventory Assets";
        const headers = [['#', 'Inventory Type', 'Asset Type', 'Serial No']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.inventory.name,
            elt.asset.assetName,
            elt.serialNo,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("inventoryassets.pdf")
    }

    componentDidMount() {
        this.GetAssets();
        this.props.AC_LIST_EMPLOYEE();
    }

    GetAssets = () => {
        axios.get(ImportedURL.API.listAsset)
            .then((res) => {
                this.setState({ assetslist: res.data });

            }).catch(({ response }) => {
            });
    }

    radioHandleChange = (e) => {
        this.setState({ condition: e.target.value });

    }

    handleUpdate = (e) => {
        const data = this.state;
        let valid = 1;
        if (this.state.condition) {
            if (this.state.condition == 'damage') {

                if (!data.reason) {
                    this.setState({ reasonError: true });
                    valid = 0;
                }
                if (!data.date) {
                    this.setState({ dateError: true });
                    valid = 0;
                }
                if (valid) {
                    let formdata = {
                        status: 'damage',
                        reason: data.reason,
                        date: data.date
                    }
                    axios.post(`${ImportedURL.API.assetStatusUpdate}/${this.state.id}`, formdata)
                        .then((data) => {
                            this.GetAssets();
                            Success('Status updated successfully')
                            let btn = document.getElementById("modalClose");
                            btn.click();
                        }).catch(({ response }) => { if (response) Error('Status update failed') });

                }
            } else {
                let btn = document.getElementById("modalClose");
                btn.click();
                setTimeout(() => { this.props.history.push('/service/' + this.state.id) }, 1000)
            }
        } else {
            this.setState({ conditionError: true })
        }


    }


    render() {
        const { AccountState } = this.props;
        const rowData = this.state.assetslist;
        const employee = this.props.employeeReducer.listEmployee;

        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Asset" });

        const employeeOptions = [];
        employee.filter(filterItem => filterItem.status === true).map(item => {
            employeeOptions.push({ label: `${item.employeeName} (${item.employeeId})`, value: item._id, name: 'employee' });
        })

        if (rowData) {
            rowData.forEach(object => {
                const editOption = Previlege.edit && object.status != 'damage' ?
                    '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" ><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
                const assignOption = Previlege.edit && object.status == 'stock' ? '<button title="Assign" type="button" class="btn btn-icon" data-action-type="Assign" data-toggle="modal" data-target="#assignAsset" ><i class="fa fa-user-plus" style="color: #a8e1ff" data-toggle="tooltip" title="Assign" data-action-type="Assign"></i></button>' : '';
                const serviceOption = Previlege.edit && object.status == 'service' ? '<button type="button" class="btn btn-icon" data-toggle="modal" data-target="#modalInvoice" data-action-type="Service" ><i class="fa fa-wrench" style="color: #eeeaaf" data-toggle="tooltip" title="Service"  data-action-type="Service"></i></button>' : '';
                const updateOption = Previlege.edit && object.status == 'assigned' ? '<button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModalCenter1" data-action-type="Update" ><i class="fa fa-refresh" style="color: #1DC9B7" data-toggle="tooltip" title="Update" data-action-type="Update"></i></button>' : '';
                const deleteOption = AccountState.role == 'admin' ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
                const template = '<button  title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'


                object.actions = template +
                    editOption +
                    assignOption +
                    serviceOption +
                    updateOption +
                    deleteOption
            });
        }

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        const options = [
            { value: 'stock', label: 'Stock', name: 'status' },
            { value: 'service', label: 'Service', name: 'status' },
            { value: 'damage', label: 'Damage', name: 'status' }
        ]
        return (
            <div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                                            </div>
                                            <h3 className="card-title">List Asset</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>

                                                    <div className="header-action">
                                                        {Previlege.add ?
                                                            <Link to='/add-asset'> <button type="button" className="btn btn-primary" id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button></Link>
                                                            : ''}
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine" id="content">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={10}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="assignAsset" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"><i class="far fa-department"></i>Status Change</h5>
                                <button type="button" id="assignAssetClose" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" id="assignAssetClose" onClick={this.clear} style={{ fontSize: "23px" }}>x</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row clearfix">
                                    <div className="col-lg-12">
                                        <div className="col-lg-12">

                                            <form id='clear'>
                                                <div className="form-group col-lg-12">
                                                    <label className="form-label">Employee Name<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        onChange={this.selectChangeAssign}
                                                        options={employeeOptions} />
                                                    <div className="invalid-feedback" style={{ display: this.state.employeeError ? "block" : 'none' }}>Employee Name is required</div>
                                                </div>
                                                <div className="form-group col-lg-12">
                                                    <label className="form-label">Date Assigned<span style={{ color: 'red' }}>*</span></label>
                                                    <input type="date" className="form-control" name="assignedDate" onChange={this.handleChangeAssign} />
                                                    <div className="invalid-feedback" style={{ display: this.state.assignedDateError ? "block" : 'none' }}>Please Select Date</div>
                                                </div>
                                                <div className="form-group col-lg-12">
                                                    <button type="button" className="btn btn-primary" id="closeModal" aria-hidden="true" onClick={this.assignAsset}><i class="fa-sharp fa-solid fa-file-arrow-up mr-2"></i>Update</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"><i class="far fa-department"></i>Status Change</h5>
                                <button type="button" id="closeModal1" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" id="closeModal" onClick={this.clear} style={{ fontSize: "23px" }}>x</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row clearfix">
                                    <div className="col-lg-12">
                                        <div className="col-lg-12">

                                            <form id='clear'>
                                                <div className="form-group col-lg-12">
                                                    <label className="form-label">Select Status<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        className="react-select"
                                                        styles={{ border: '1px solid #868686' }}
                                                        placeholder='Select Status...'
                                                        onChange={this.handleSelectChange}
                                                        value={this.state.status ? { value: this.state.status, label: capitalizeFirstLetter(this.state.status) } : ''}
                                                        options={options}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.statusError ? "block" : 'none' }}>Status is Required</div>
                                                </div>

                                                {this.state.status ? <div className="form-group col-lg-12">
                                                    <label className="form-label">Reason For {capitalizeFirstLetter(this.state.status)}<span style={{ color: 'red' }}>*</span></label>
                                                    <textarea className="form-control" name="reason" placeholder="Reason For Stock" onChange={this.handleChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.reasonError ? "block" : 'none' }}>Reason is required</div>
                                                </div> : ''}

                                                {this.state.status == 'damage' || this.state.status == 'service' ?
                                                    <div className="form-group col-lg-12">
                                                        <label className="form-label">Date<span style={{ color: 'red' }}>*</span></label>
                                                        <input type="date" className="form-control" name="date" onChange={this.handleChange} />
                                                        <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}>Please Select Date</div>
                                                    </div>
                                                    : ''
                                                }
                                                <div className="form-group col-lg-12">
                                                    <button type="button" className="btn btn-primary" id="closeModal" aria-hidden="true" onClick={this.updateStatus}><i class="fa-sharp fa-solid fa-file-arrow-up mr-2"></i>Update</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalInvoice" isShowing={true} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h5 className="modal-title" id="exampleModalLabel"><i className="far fa-department"></i>Asset Condition</h5> */}
                                <div className='rounded_icon'><i className="fa fa-exchange mr-2"></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>Item Condition</h5>
                                <button type="button" id="modalClose" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }} onClick={this.clear}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row clearfix">
                                    <form id='form'>
                                        <div className="col-lg-12">
                                            <div className='form-group'>
                                                <label className="form-label">Item Condition<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="radio" className="radio-button" checked={this.state.condition == "service-invoice"} id="Service Invoice" value="service-invoice" name="condition" onChange={this.radioHandleChange} /> <label className="radio-button-label" for="Service Invoice" style={{ cursor: 'pointer' }}>Service Invoice</label>
                                                <input type="radio" className="radio-button" checked={this.state.condition == "damage"} id="Damaged" value="damage" name="condition" onChange={this.radioHandleChange} style={{ marginLeft: "30px" }} /> <label className="radio-button-label" for="Damaged" style={{ cursor: 'pointer' }}>Damaged</label>
                                                <div className="invalid-feedback" style={{ display: this.state.conditionError ? "block" : 'none' }}>Item Condition is required</div>
                                            </div>
                                            {this.state.condition == "damage" ?
                                                <>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Reason For Damage<span className='ml-1' style={{ color: 'red' }}>*</span> <span>(Only accept 255 characters)</span></label>
                                                            <textarea className="form-control" name="reason" placeholder="Reason For Damage" rows="3" cols="50" onChange={this.handleChange} value={this.state.reason} />
                                                            {/* <div className='d-flex justify-content-end' style={{ height: "10px" }}> */}
                                                            <div className="invalid-feedback" style={{ display: this.state.reasonError ? "block" : 'none' }}>Reason For Damage is required</div>
                                                            {/* <p className='mt-1' style={{ width: "75px", textAlign: "right" }}><span>{datamodel.reasonDamage.length}</span>/ <span>255</span></p> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mt-4">
                                                            <label className="form-label">Date Damaged<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <div className='site_pass_calander'>
                                                                <input type="date" className="form-control" name="date" onChange={this.handleChange} />
                                                                {/* <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i> */}
                                                                <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}>Date Damaged is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ''}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                {this.state.save ?
                                    <button type="button" className="btn commor_save" id="closeModal" aria-hidden="true" onClick={this.handleUpdate}><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                    :
                                    <button type="button" className="btn commor_save" id="closeModal" aria-hidden="true" onClick={this.handleUpdate}><i className="fe fe-save mr-2"></i>Save</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </div >
        )
    }
}



const mapStateToProps = (state) => ({
    inventoryReducer: state.inventoryReducer,
    assignInventoryReducer: state.assignInventoryReducer,
    assetReducer: state.assetReducer,
    countryReducer: state.countryReducer,
    employeeReducer: state.employeeReducer,
    AccountState: state.account,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AC_LIST_ASSIGNINVENTORY, AC_VIEW_ASSIGNINVENTORY, AC_HANDLE_ASSIGNINVENTORY_CHANGE,
            AC_LIST_EMPLOYEE,
            AC_RESET_ASSIGNINVENTORY
            // AC_LIST_COUNTRY, AC_LIST_INVENTORY, AC_LIST_ASSET, AC_VIEW_MANAGEASSIGNINVENTORY
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListAssignedInventory);

