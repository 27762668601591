import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';

export function AC_RESET_EMPLOYEE_SALARY() {
    return function (dispatch) {
        dispatch({ type: "RESET_EMPLOYEE_SALARY" })
    };
}

export function AC_LIST_EMPLOYEE_SALARY() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listEmployeesalary)
            .then(({ data }) => {
                dispatch({ type: "LIST_EMPLOYEE_SALARY", payload: data });
            })
    }
}
export function AC_LIST_EMPLOYEE_PAYROLL__SALARY() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listEmployepayrollitem)
            .then(({ data }) => {
                dispatch({ type: "LIST_EMPLOYEE_PAYROLL__SALARY", payload: data });
            })
    }
}
export function AC_HANDLE_EMPLOYEE_SALARY_SEARCH(value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMPLOYEE_SALARY_SEARCH", value: value })
    };
}
export function AC_VIEW_EMPLOYEE_SALARY(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewEmployeesalary + "/" + id)
            .then(({ data }) => {
                dispatch({ type: "VIEW_EMPLOYEE_SALARY", payload: data });
            });
    }
}
export function AC_HANDLE_EMPLOYEE_SALARY_CHANGE(name, value, i) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMPLOYEE_SALARY_CHANGE", name: name, value: value, i: i })
    };
}
export function AC_HANDLE_PAYROLL_STATUS_CHANGE(value, i) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_PAYROLL_STATUS_CHANGE", value: value, i: i })
    };
}