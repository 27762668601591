import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import ImportedUrl from '../../common/api';
import { Success, Error } from '../../common/swal';
import { Emailvalidate } from '../../common/validate';
import { Phonenumber } from '../../common/validate';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import Select from 'react-select';
import { Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { AC_SETTING_DETAIL, AC_UPDATE_DETAIL, AC_ACCOUNT_DETAILS } from '../../actions/accountAction';
import { AC_HANDLE_CHANGE } from '../../actions/accountAction';
import ImportedURL from '../../common/api';

class SiteSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      contact: '',
      cc: '',
      // address: '',
      // favicon: '',
      logo: '',
      // faviconSrc: '',
      logoSrc: '',
      nameError: false,
      emailError: false,
      emailPattern: false,
      contactError: false,
      phonePattern: false,
      addressError: false,
      faviconError: false,
      logoError: false,
      imageChanged: false,

      id: ''
    };
  }
  onChange = e => {
    const { name, value } = e.target;
    const Error = name + "Error";
    this.props.AC_HANDLE_CHANGE(name, value);

    if (name === 'name') {
      if (value) {
        this.setState({ [Error]: false });
      } else {
        this.setState({ [Error]: true })
      }
    }
    if (name === 'contact') {
      if (value) {
        if (Phonenumber(value)) {
          this.setState({ phoneNumberPattern: false });
        } else {
          this.setState({ phoneNumberPattern: true })
        }
        this.setState({ [Error]: false });
      } else {
        this.setState({ [Error]: true })
      }
    }
    if (name === 'email') {
      if (value) {
        this.setState({ [Error]: false });
      } else {
        this.setState({ [Error]: true })
      }
    }
    if (name === 'logo') {
      this.props.AC_HANDLE_CHANGE('logo', e.target.files[0])
      this.setState({ imageChanged: true })

      this.setState({ logo: e.target.files[0], logoError: false })

    }
    if (name === 'image') {
      this.props.AC_HANDLE_CHANGE('image', e.target.files[0])
      this.setState({ imageChanged: true })

      this.setState({ logo: e.target.files[0], logoError: false })

    }
  }



  handlePhoneCode = e => {
    const { name, value, label } = e;
    this.props.AC_HANDLE_CHANGE(name, value);
    const Error = name + "Error";
    this.setState({ [Error]: false });
  }

  componentDidMount() {
    this.props.AC_SETTING_DETAIL();
    this.props.AC_LIST_COUNTRY();
    this.props.AC_ACCOUNT_DETAILS();
    localStorage.setItem('invoicetoken', "");
  }


  submit = () => {
    // const { cc, name, email, contact, address, favicon, logo } = this.state;


    var data = this.props.account.fields;
    let valid = 1

    if (!data.name) {
      this.setState({ nameError: true });
      valid = 0
    }
    if (!data.email) {
      this.setState({ emailError: true });
      valid = 0
    }
    if (!data.phonenumber) {
      this.setState({ contactError: true });
      valid = 0
    }
    if (!data.phonecode) {
      this.setState({ ccError: true });
      valid = 0
    }
    if (data.role == "admin") {
      if (!data.logo) {
        this.setState({ logoError: true });
        valid = 0
      }
    } else {
      if (!data.image) {
        this.setState({ logoError: true });
        valid = 0
      }
    }
    if (valid) {
      var _id = this.state.data;
      const formData = new FormData();
      formData.append('id', data._id)
      formData.append('cc', data.phonecode)
      formData.append('name', data.name)
      formData.append('email', data.email)
      formData.append('contact', data.phonenumber)
      if (data.role == "admin") {
        formData.append('logo', data.logo);
      } else {
        formData.append('logo', data.image);
      }

      // localStorage.setItem("countryCode", data.phonecode);
      axios.post(ImportedURL.API.updateAdmin, formData)
        .then((res) => {
          this.setState({ searchhoteloption: '', searchhotel: '' })
          this.setState({ saving: false, closed: true })
          Success('updated successfully');
          this.props.AC_SETTING_DETAIL();
        }).catch(({ response }) => {
          this.setState({ saving: false })
          if (response.status == 500) {
            Error(response.status + ' Internal Server Error')
          } else if (response.status == 502) {
            Error(response.status + ' Bad Gateway')
          } else if (response.status == 400) {
            Error('Bad request')
          } else {
            Error(response.statusMessage)
          }
        });
      // this.props.AC_UPDATE_DETAIL(formData);
    }
    // if (data.name && data.cc && data.email && data.contact && data.logo) {
    //   axios.post(ImportedUrl.API.updateAdmin, formData)
    //     .then((res) => {
    //       Success(res.statusText);
    //       // window.location.reload();
    //     }).catch(({ response }) => { if (response) Error(response.statusText) });
    // }
  }

  render() {
    const account = this.props.account.account;
    var phoneData = this.props.countryReducer.listCountry;
    var data = this.props.account.fields;

    var ccData = '';
    if (data.phonecode) {
      ccData = phoneData.find(e => e._id === data.phonecode)
    }
    // if (phoneData) {
    //   phoneData.map(item => {
    //     if (data.phonecode) {
    //       if (item._id == data.phonecode) {
    //         ccData = { label: item.countryCode, value: item._id, flag: item.flag }
    //       }
    //       if (item.phonecode == data.phonecode) {
    //         ccData = { label: item.countryCode, value: item._id, flag: item.flag }
    //       }
    //     } else {
    //       if (item._id === account.phonecode) {
    //         ccData = { label: item.countryCode, value: item._id, flag: item.flag }
    //       }
    //       if (item.phonecode === account.phonecode) {
    //         ccData = { label: item.countryCode, value: item._id, flag: item.flag }
    //       }
    //     }
    //   })
    // }

    if (data !== undefined) {
      var logoData = data.logo;
    }
    return (
      <>
        <div className="section-body pt-3">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <div className='rounded_icon'><i className="fa fa-gear mr-2 "></i></div>
                    <h3 className="card-title">Site Settings</h3>
                  </div>
                  <div className="card-body">
                    <form id='clear_form'>

                      <div className='row'>
                        <div className="form-group col-lg-6">
                          <label className="form-label">Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <input className="form-control" placeholder="Name" name='name' value={data.name} onChange={this.onChange} />
                          <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                        </div>
                        <div className="form-group col-lg-6">
                          <label className="form-label">Email<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <input className="form-control" name='email' value={data.email} disabled onChange={this.onChange} />
                          <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                          <div className="invalid-feedback" style={{ display: this.state.emailPattern ? "block" : 'none' }}>Enter valid email</div>
                        </div>


                        <div className="form-group col-lg-6">
                          <Row>
                            <div className="form-group col-lg-3" >
                              <label className="form-label">Country Code<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                              <Select
                                value={ccData ? { label: <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + ccData.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + ccData.countryCode }}></span> } : ''}
                                placeholder='+'
                                onChange={this.handlePhoneCode}
                                options={phoneData && phoneData.filter(filterItem => filterItem.status === true).map(item => {
                                  return {
                                    label: <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.countryCode }} />
                                    , value: item._id, name: 'phonecode'
                                  }
                                })} />
                              <div className="invalid-feedback" style={{ display: this.state.ccError ? "block" : 'none' }}>Country Code required</div>
                            </div>

                            <div className="form-group col-lg-9" >
                              <label className="form-label">Phone Number<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                              <input type='text' className="form-control" placeholder="Phone Number" name='phonenumber' value={data.phonenumber} onChange={this.onChange} maxLength={10} />
                              <div className="invalid-feedback" style={{ display: this.state.contactError ? "block" : 'none' }}>Phone Number is required</div>
                              <div className="invalid-feedback" style={{ display: this.state.phoneNumberPattern ? "block" : 'none' }}>Phone Number should contain 10 digits</div>
                            </div>
                          </Row>
                        </div>
                        <div className="form-group col-lg-6">
                          <label className="form-label">Profile<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <input
                            name={data.role == 'admin' ? "logo" : 'image'}
                            type="file"
                            onChange={this.onChange}
                            className="form-control"
                          />
                          <div className="invalid-feedback" style={{ display: this.state.logoError ? "block" : 'none' }}>Profile is required</div>

                        </div>
                        <div className="form-group col-lg-6">
                          {
                            data.role == 'admin'
                              ?
                              <img style={{ marginTop: '-22px', marginLeft: '590px', width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.imageChanged ? URL.createObjectURL(this.state.logo) : ImportedUrl.LIVEURL + logoData} alt="" />
                              :
                              <img style={{ marginTop: '-22px', marginLeft: '590px', width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.imageChanged ? URL.createObjectURL(this.state.logo) : ImportedUrl.LIVEURL + data.image} alt="" />
                          }
                        </div>
                      </div>

                    </form>
                  </div>
                  <div className="card-footer text-right">
                    <button type="submit" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  fixNavbar: state.settings.isFixNavbar,
  countryReducer: state.countryReducer,
  account: state.account,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    AC_LIST_COUNTRY, AC_SETTING_DETAIL, AC_HANDLE_CHANGE, AC_ACCOUNT_DETAILS
  }, dispatch)

}
export default connect(mapStateToProps, mapDispatchToProps)(SiteSettings);