const initialState = {
    listemppayrollitem: [],
    listemployeesalary: [],
    listEmpfilter: [],
    employeesalary: {
        name: '',
        salary: '',
        itemvalue: [
            {
                name: '',
                itemstatus: '',
            }
        ],
        basicsalary: '',
        hra: '',
        statutorybonus: '',
        specialallowance: '',
        esi: '',
        professionaltax: '',
        pf: '',
        pfemployer: '',
        esiepmloyer: '',
        gratuity: ''
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LIST_EMPLOYEE_SALARY':
            return {
                ...state,
                listemployeesalary: action.payload,
                listEmpfilter: action.payload
            }
        case 'LIST_EMPLOYEE_PAYROLL__SALARY':
            return Object.assign({}, state, {
                employeesalary: {
                    ...state.employeesalary,
                    itemvalue: action.payload
                }
            })
        case 'VIEW_EMPLOYEE_SALARY':
            return {
                ...state,
                employeesalary: action.payload,
            }
        case 'HANDLE_EMPLOYEE_SALARY_SEARCH':
            return Object.assign({}, state, {
                listemployeesalary: action.value
            })
        case 'RESET_EMPLOYEE_SALARY':
            return Object.assign({}, state, {
                employeesalary: initialState.employeesalary
            })
        case 'HANDLE_EMPLOYEE_SALARY_CHANGE':
            return Object.assign({}, state, {
                employeesalary: {
                    ...state.employeesalary,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_PAYROLL_STATUS_CHANGE':
            return {
                ...state,
                employeesalary: {
                    ...state.employeesalary,
                    itemvalue:
                        [
                            ...state.employeesalary.itemvalue.slice(0, action.i),
                            { ...state.employeesalary.itemvalue[action.i], "itemstatus": action.value },
                            ...state.employeesalary.itemvalue.slice(action.i + 1)
                        ]
                }
            }

        default:
            return state;
    }
}