import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../../common/api';
import { Error, Success } from '../../../common/swal';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import { AC_EMPTY_PROJECT, AC_LIST_PROJECT, AC_VIEW_PROJECT } from '../../../actions/projectAction';
import DatePicker, { Calendar } from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const initialState = {
    modalType: "Add",
    columnDefs: [
        // { headerName: '#', valueGetter: "node.rowIndex+1", hide: true, width: 50, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', hide: 'true' },
        {
            headerName: 'Date', field: 'date', width: 150, headerClass: 'ag-center-header',
            valueGetter: function (params) {
                if (params.data.date) return moment(params.data.date).format('MM/DD/YYYY')
                else return '-'
            }
        },
        { headerName: 'Project', field: 'projectname', width: 150, headerClass: 'ag-center-header', },
        { headerName: 'Project Manager', field: 'managername', width: 150, headerClass: 'ag-center-header', },
        { headerName: 'Module', field: 'module', width: 150, headerClass: 'ag-center-header', },
        {
            headerName: 'Sub Module', field: 'submodule', width: 170, headerClass: 'ag-center-header',
            valueGetter: function (params) {
                if (params.data.submodule) return params.data.submodule
                else return '-'
            }
        },
        {
            headerName: 'Rating', width: 80, field: 'rating', cellStyle: { textAlign: 'center' },
            valueGetter: function (params) {
                if (params.data.rating) return params.data.rating
                else return '-'
            }
        },
        {
            headerName: 'Status', width: 150, field: 'approved', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.approved) return '<span class="tag tag-success">Approved</span>'
                else return '<span class="tag tag-warning">Pending</span>'
            }
        },
        // {
        //     headerName: 'Comments', width: 150, field: 'comments',
        //     valueGetter: function (params) {
        //         if (params.data.comments) return params.data.comments
        //         else return '-'
        //     }
        // },
        {
            headerName: 'Actions', width: 100, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return params.value
                // return '<div><button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModalView" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View" data-toggle="tooltip" title="View"></i></button><button type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button><button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>'
            }
        },
    ],
    defaultColumDef: {
        editable: false,
        // sortable: true,
        resizable: true,
        // filter: true,
        flex: 1,
    },
    nameError: false,
    id: '',
    hotelError: false,
    saving: false,
    searchhoteloption: '',
    searchhotel: '',
    viewRedirect: false,
    editRedirect: false,
    viewRedirectId: '',
    closed: false,
    editSpi: false,
    viewSpin: false,
    hotelName: '',
    employeeprojects: [],
    taskdata: {},
    taskList: [],
    timesheetdate: new Date(),
    employeedata: {}
}
class ListProject extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.setState({ modalType: "View", taskdata: event.data });
        }
        // if (value === 'Edit') {
        //     this.setState({ modalType: "Edit", taskdata: event.data, rowIndex: event.rowIndex });

        // }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const date = urlParams.get('date');
        this.getEmployeeEntry(date)
        this.setState({ timesheetdate: date, fromdate: date, todate: date })
        localStorage.setItem('invoicetoken', "");
    }

    getEmployeeEntry = (date = this.state.timesheetdate ? moment(this.state.timesheetdate).format('YYYY-MM-DD') : '', todate = '') => {
        const urlParams = new URLSearchParams(window.location.search);
        const employee = urlParams.get('id');
        axios.post(ImportedURL.API.employeeEntryReviewadmin, { date: date, employee: employee, todate: todate })
            .then((res) => {
                if (res.data)
                    this.setState({
                        entryexist: true,
                        entryid: res.data._id,
                        taskList: res.data.tasks ? res.data.tasks : [],
                    })

                else
                    this.setState({ entryexist: false, entryid: '', taskList: [] })
                if (res.data && res.data.employee)
                    this.setState({
                        employeedata: res.data.employee
                    })

            }).catch((err) => {
                console.log(err);
            });
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    setDateValue = e => {
        this.setState({ timesheetdate: e.toDate(), month: '', fromdate: moment(e.toDate()).format('YYYY-MM-DD'), todate: moment(e.toDate()).format('YYYY-MM-DD') })
        this.getEmployeeEntry(moment(e.toDate()).format('YYYY-MM-DD'))
    }


    getoptions = () => {
        let options = []
        if (this.state.employeeprojects) {
            for (let i = 0; i < this.state.employeeprojects.length; i++) {
                let item1 = this.state.employeeprojects[i],
                    found = false;

                for (let j = 0; j < this.state.taskList.length; j++) {
                    if (this.state.modalType == 'Add') {
                        if (item1._id == this.state.taskList[j].project) found = true
                    }
                    else {
                        if (item1._id == this.state.taskList[j].project && item1._id != this.state.taskdata.project) found = true
                    }
                }

                if (!found) { // isUnion is coerced to boolean
                    options.push({
                        label: item1.name, value: item1._id, item: item1
                    });
                }
            }
        }

        return options
    }
    datechange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, timesheetdate: '', month: '' })
        if (name == 'fromdate') {
            this.getEmployeeEntry(value, this.state.toDate)

        } else {
            this.getEmployeeEntry(this.state.fromdate, value)

        }
    }


    getMonths = (mon) => {
        let date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - mon);
        let monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        return `${monthName}`;
    }

    getMonthlyReport = e => {
        const { value } = e.target;
        const newval = value == 0 ? 0 : value == 1 ? 1 : 2;
        let date = new Date();
        let startDate = new Date(date.getFullYear(), date.getMonth() - newval, 1);
        let endDate = new Date(date.getFullYear(), date.getMonth() - newval + 1, 0);
        this.setState({ fromdate: moment(startDate).format('YYYY-MM-DD'), todate: moment(endDate).format('YYYY-MM-DD'), month: value, timesheetdate: '' });
        this.getEmployeeEntry(startDate, endDate)
    }
    render() {

        const { ProjectState, AccountState } = this.props;
        const account = this.state.employeedata;
        const rowData = this.state.taskList;
        const editOption = true ? '<button type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-message" style="font-size: 20px" data-action-type="Edit" data-toggle="tooltip" title="Write review"></i></button>' : '';
        // const deleteOption = moment(this.state.timesheetdate).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD') ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>' : '';
        const template = '<div><button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModalView" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View" data-toggle="tooltip" title="View"></i></button>'
            // + editOption
            // + deleteOption
            + '</div>'

        rowData.forEach(object => {
            object.actions = template;
        });
        const taskdata = this.state.taskdata;
        // const spinner = LeaveTemplateState.spinner;
        const previleges = AccountState.previleges;

        // let projectoptions = this.getoptions

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-department"><h6 className="highlights_breadcrump">Project List</h6></Link></p>
                </div>
                <div className="section-body user_sec">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='card-title d-flex'>
                                            <div className='rounded_icon'><i className="fa fa-project-diagram mr-2"></i></div>
                                            <h3 className="card-title " style={{ marginTop: '10px' }}> Timesheet REview</h3>
                                        </div>
                                        <div className="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <ul className="nav nav-tabs page-header-tab">
                                                </ul>
                                                <div className="header-action">
                                                    <Link to='/timesheet-review'><button type="button" className="btn btn-primary mr-3" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="row clearfix row-cards">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <div className="customDatePickerWidth">
                                                <DatePicker
                                                    inline
                                                />
                                            </div> */}

                                            <div className="row">
                                                <div className='col-4'>
                                                    <Calendar
                                                        value={this.state.timesheetdate}
                                                        onChange={this.setDateValue}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                                <div className='col-8'>
                                                    <div className="row">
                                                        <div className='col-6'>
                                                            <div className="h6">Employee ID</div>
                                                            <p>{account.employeeId}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Name</div>
                                                            <p>{account.name}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Department</div>
                                                            <p>{account.department}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Division</div>
                                                            <p>{account.division}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="h6">Designation</div>
                                                            <p>{account.designation}</p>
                                                        </div>
                                                        {account.managerID ? <div className="col-6">
                                                            <div className="h6">Reporting Manager</div>
                                                            <p>{account.manager} ({account.managerID})</p>
                                                        </div> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header">
                                            <div className='card-title d-flex'>
                                                <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                                <h3 className="card-title " style={{ marginTop: '10px' }}> Task </h3>
                                            </div>

                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                                                    <label className="form-label">From Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='fromdate' onChange={this.datechange} placeholder="Module" value={this.state.fromdate} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                                                    <label className="form-label">To Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='todate' onChange={this.datechange} placeholder="Module" value={this.state.todate} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3">
                                                    <div className="form-group">
                                                        <label className='form-label'>Archived Reports</label>
                                                        <div className="selectgroup w-100">
                                                            <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                <input type="radio" name="month" className="selectgroup-input" value={0} onChange={this.getMonthlyReport} checked={this.state.month === '0'} />
                                                                <span className="selectgroup-button first_month">{this.getMonths(0)}</span>
                                                            </label>
                                                            <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                <input type="radio" name="month" className="selectgroup-input" value={1} onChange={this.getMonthlyReport} checked={this.state.month === '1'} />
                                                                <span className="selectgroup-button">{this.getMonths(1)}</span>
                                                            </label>
                                                            <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                <input type="radio" name="month" className="selectgroup-input" value={2} onChange={this.getMonthlyReport} checked={this.state.month === '2'} />
                                                                <span className="selectgroup-button last_month">{this.getMonths(2)}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        onGridReady={this.onGridReady}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="exampleModalView" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-calendar mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalcls" style={{ marginTop: '5px' }}>View Task</h5>
                                <button type="button" id="leaveModel" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }} id="leaveModel" onClick={this.clear}>
                                        <img src='../../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row clearfix">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Project</label>
                                            <p>{taskdata.projectname}</p>
                                            {/* <div className="invalid-feedback" style={{ display: this.state.projectError ? "block" : 'none' }}>Project is required</div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Project Manager</label>
                                            <p>{taskdata.managername}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Module</label>
                                            <p>{taskdata.module}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Sub module</label>
                                            <p>{taskdata.submodule ? taskdata.submodule : '-'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Remarks</label>
                                            <p>{taskdata.remarks ? taskdata.remarks : '-'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Rating</label>
                                            <p>{taskdata.rating ? taskdata.rating : '-'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Status</label>
                                            <p>{taskdata.approved ? 'Approved' : 'Pending'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Comments</label>
                                            <p>{taskdata.comments ? taskdata.comments : '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    ProjectState: state.project,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ViewProject: AC_VIEW_PROJECT,
            EmptyProject: AC_EMPTY_PROJECT,
            ListProject: AC_LIST_PROJECT
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListProject);