import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';
import { Link, Redirect } from 'react-router-dom';


const ADD_COUNTRY = 'ADD_COUNTRY';
const LIST_COUNTRY = 'LIST_COUNTRY';
const VIEW_COUNTRY = 'VIEW_COUNTRY';
const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
const DELETE_COUNTRY = 'DELETE_COUNTRY';
const RESET_COUNTRY = 'RESET_COUNTRY';

export function AC_ADD_COUNTRY(data) {
    return function (dispatch) {
        axios.post(ImportedURL.API.addCountry, data)
            .then((data) => {
                Success('Added successfully');
                dispatch({ type: ADD_COUNTRY, payload: data });
                // window.location='/listinventory'
            }).catch(function (error) {
                if (error) {
                    Error('Data already exists')
                }
            });
    }
}

export function AC_LIST_COUNTRY() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listCountry)
            .then(({ data }) => {
                dispatch({ type: LIST_COUNTRY, payload: data });
            })
    }
}

export function AC_LIST_TAX() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listTax)
            .then(({ data }) => {
                dispatch({ type: 'LIST_TAX', payload: data });
            })
    }
}

export function AC_VIEW_COUNTRY(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewCountry + "/" + id)
            .then(({ data }) => {
                dispatch({ type: VIEW_COUNTRY, payload: data });
            });
    }
}

export function AC_RESET_COUNTRY() {
    return function (dispatch) {
        dispatch({ type: "RESET_COUNTRY" })
    };
}

export function AC_UPDATE_COUNTRY(formData) {
    return function (dispatch) {
        axios.post(ImportedURL.API.updateCountry, formData)
            .then((formData) => {
                Success(' updated successfully');
                dispatch({ type: UPDATE_COUNTRY, payload: formData })
                // window.location='/listcountry'
            }).catch(function (error) {
                if (error) {
                    Error('Data already exists')
                }
            });
    }
}

export function AC_DELETE_COUNTRY(id) {
    return function (dispatch) {
        axios.post(ImportedURL.API.deleteCountry + "/" + id)
            .then((data) => {
                dispatch({ type: LIST_COUNTRY, payload: data.data })
            })
    }
}

export function AC_HANDLE_COUNTRY_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_COUNTRY_CHANGE", name: name, value: value })
    };
}



