import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { Link, Redirect } from 'react-router-dom';
import { AC_EMPTY_PROJECT, AC_HANDLE_PROJECT_CHANGE, AC_RESET_PROJECT, AC_VIEW_PROJECT } from '../../actions/projectAction';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';
import CKEditor from "react-ckeditor-component";

class AddProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            id: "",
            saving: false,
            images: [],
            imageArray: [],
            existingImage: [],
            nameError: false,
            dateError: false,
            leaderError: false,
            teamError: false,
            imageError: false,
            imagesArr: [],
            listRedirect: false,
            moduledata: {},
            submoduledata: {},
            verificationdata: {}
        }
    }

    componentDidMount() {
        const { params, path } = this.props.match;

        if (path === "/edit-verification/:id") {
            this.setState({ modalType: "Edit", id: params.id })
            axios.get(`${ImportedURL.API.viewVerification}/${params.id}`)
                .then((res) => {
                    this.setState({ verificationdata: res.data })
                }).catch(({ response }) => {
                });
        } else {
            this.setState({ modalType: "Add", verificationdata: {} });
        }

        localStorage.setItem('invoicetoken', "");
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ verificationdata: { ...this.state.verificationdata, [name]: value }, [Error]: false })
    }

    addQuestion = () => {
        if (this.state.question) {
            if (this.state.verificationdata.questions) {
                let arr = [...this.state.verificationdata.questions]
                arr.push({ question: this.state.question, type: 'yes/no' })
                this.setState({ verificationdata: { ...this.state.verificationdata, questions: arr }, question: '' })
            } else {
                this.setState({ verificationdata: { ...this.state.verificationdata, questions: [{ question: this.state.question, type: 'yes/no' }] }, question: '' })
            }
        } else {
            Error('Type question before adding')
        }
    }

    removeQuestion = (i) => {
        let arr = [...this.state.verificationdata.questions]
        arr.splice(i, 1)
        this.setState({ verificationdata: { ...this.state.verificationdata, questions: arr } })
    }

    submit = () => {
        const data = this.state.verificationdata;
        let valid = 1
        if (!data.title) {
            this.setState({ titleError: true })
            valid = 0;
        };

        if (valid) {
            const formData = {
                title: data.title,
                questions: data.questions ? JSON.stringify(data.questions) : JSON.stringify([]),
                id: this.state.id
            }

            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addEditVerification, formData)
                    .then((res) => {
                        this.setState({ saving: false, closed: true })
                        Success('Verification process added successfully');
                        this.props.history.push('/verification-process')
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        Error('Verification addition failed')
                    });
            } else {
                axios.post(ImportedURL.API.addEditVerification, formData)
                    .then((res) => {
                        this.setState({ saving: false, closed: true })
                        Success('Verification process updated successfully');
                        this.props.history.push('/verification-process')
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        Error('Verification update failed')

                    });
            }
        }
    }

    onChangeEditor = e => {
        // this.props.HandleInputChange("description", e.editor.getData());
        this.setState({ question: e.editor.getData() })
    }
    render() {
        const data = this.state.verificationdata;

        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump"> {this.state.modalType} Verification</h6> </p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'><i className="fa fa-project-diagram mr-2 "></i></div>
                                        <h3 className="card-title">{this.state.modalType} Verification</h3>
                                        <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                            <Link to='/verification-process'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Title<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" name='title' onChange={this.handleChange} value={data.title} placeholder="First Name" />
                                                    <div className="invalid-feedback" style={{ display: this.state.titleError ? "block" : 'none' }}> Title is required</div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className="row">
                                                    <div className='col-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Question<span className="ml-1" style={{ color: 'red' }}>*</span> (Type and press + to add question)</label>
                                                            {/* <textarea type="text" style={{ whiteSpace: 'pre-wrap' }} className="form-control" name='question' onChange={(e) => { this.setState({ question: e.target.value }) }} value={this.state.question} placeholder="Add Question" /> */}
                                                            <CKEditor
                                                                activeclassName="p10"
                                                                content={this.state.question}
                                                                events={{
                                                                    "change": this.onChangeEditor
                                                                }}
                                                                config={{
                                                                    removePlugins: 'toolbar,elementspath',
                                                                    height: 70,

                                                                    // removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                                                }}

                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-4'>
                                                        <div className="form-group">
                                                            <label className="form-label">type<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <select name='type' className="form-control">
                                                                <option value='yes/no'>Yes/No</option>
                                                            </select>
                                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}> Name is required</div>
                                                        </div>
                                                    </div> */}
                                                    <div className='col-1 mt-3'>
                                                        <div className="form-group float-right ">
                                                            <label className="form-label mb-0">&nbsp;</label>
                                                            <button type="button" className="btn commor_save pl-4 pr-4" onClick={this.addQuestion} ><i className="fe fe-plus "></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                data.questions && data.questions.length ?
                                                    data.questions.map(((item, i) => {
                                                        return <div className='col-12 mt-4'>
                                                            <div className="row">
                                                                <div className='col-6'>
                                                                    <div className="form-group" dangerouslySetInnerHTML={{ __html: item.question }}>
                                                                        {/* {item.question} */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-1'>
                                                                    <div className="form-group float-right">
                                                                        <button type="button" className="btn commor_save  pl-4 pr-4" onClick={() => this.removeQuestion(i)}><i className="fe fe-minus "></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className='col-6' />
                                                        </div>
                                                    }))

                                                    : ''
                                            }

                                        </div>
                                    </div>


                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                        <div className="text-right">
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                        <i className="fe fe-save mr-2"></i>Save
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    ProjectState: state.project,
    employeeState: state.employeeReducer,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            EmptyProject: AC_EMPTY_PROJECT,
            HandleInputChange: AC_HANDLE_PROJECT_CHANGE,
            ViewProject: AC_VIEW_PROJECT,
            ResetProject: AC_RESET_PROJECT,
            ListEmployee: AC_LIST_EMPLOYEE,
            // HandleModuleInputChange: AC_HANDLE_MODULE_CHANGE
        }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);