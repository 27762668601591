import { TestHeadless } from 'ag-grid-community';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import moment from 'moment';
import { AC_SETTING_DETAIL } from '../../actions/accountAction';
import { AC_LIST_EMAIL } from '../../actions/emailAction';
import ImportedUrl from '../../common/api';
import ImportedURL from '../../common/api';


class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			viewRedirect: false,
			viewRedirectId: '',

		}
	}
	signOut = (e) => {
		e.preventDefault();
		localStorage.removeItem('mytechaitoken');
		window.location.href = "/login";
	}
	componentDidMount() {
		this.props.AC_SETTING_DETAIL();
		this.props.ListEmail();
	}
	viewMail = async (id, item) => {
		const { AccountState } = this.props;
		const account = AccountState?.account;
		const userId = account._id;
		const emailId = id;
		const seen = !item.seenBy.find((seenBy) => seenBy.userId === userId).seen;
		if (seen) {
			await axios.post(`${ImportedURL.API.updateEmail}/${userId}/${emailId}/${seen}`)
				.then((res) => {
					this.props.ListEmail();
				})
		}
		this.setState({ viewRedirect: true, viewRedirectId: id })

	}
	render() {
		const { fixNavbar, darkHeader, AccountState, EmailState } = this.props;
		const logo = AccountState.fields.logo;
		const temp = AccountState.fields;
		const data = typeof logo == 'string' ? logo : '';
		const image = AccountState.account?.image;
		const username = AccountState.account?.name;
		const usermail = AccountState.account?.email;
		const role = AccountState.account?.role;
		const account = AccountState.account;

		const listEmail = EmailState.listEmail;
		const userId = AccountState?.account._id; // Replace with actual user ID
		let linkPath = ''
		if (this.state.viewRedirect) {
			linkPath = '/view-inbox/' + this.state.viewRedirectId
		}

		const mailArr = [];
		{
			listEmail.length > 0 && listEmail.map((data, i) => {
				let reciveToMail = data.toValue && data.toValue.includes(AccountState.account.email);
				let reciveCcMail = data.ccValue && data.ccValue.includes(AccountState.account.email);
				if (reciveToMail || reciveCcMail) {
					const seen = data.seenBy.find((seenBy) => seenBy.userId === userId)?.seen;
					mailArr.push(
						<Link to={linkPath} key={i}>
							<li style={{ cursor: 'pointer' }} onClick={(e) => this.viewMail(data._id, data)}>
								<div className="feeds-left">
									<i className="fa fa-envelope-o mr-2" />
								</div>
								<div className="feeds-body" style={{ width: '92%' }}>
									<div className='d-flex justify-content-between'>
										<h4 className="title" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}>{data.subject}</h4>
										<small className="float-right text-muted">{data.date}</small>
									</div>
									<div className='d-flex justify-content-between'>
										<small style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px', fontSize: '14px' }}>{data.description && data.description.replace(/<[^>]+>/g, '')}</small>
										<small className='seen '>{seen ? 'seen' : 'unseen'}</small>
									</div>
								</div>
							</li>
						</Link >
					)
				}

			})
		}



		return (
			<div>
				<div
					id="page_top"
					className={`section-body ${fixNavbar ? "sticky-top" : ""} ${darkHeader ? "top_dark" : ""}`}
				>
					<div className="container-fluid">
						<div className="page-header">
							<div className="left">
								<h1 className="page-title">
									<div className="input-group">
										<div className="input-group-prepend">
											<div className="input-group-text custom-top-search">
												<i className='fa fa-search'></i>
											</div>
										</div>
										<input type="text" className="form-control custom-input" aria-label="Text input with checkbox" placeholder='Enter Your Keywords' />
									</div>
								</h1>
							</div>
							<div className="right header_icon" >
								<div className="notification d-flex">
									<div className="dropdown d-flex">
										<a
											href="#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-envelope" />
											<span className="badge badge-primary nav-unread" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style={{ width: '370px', left: '145px' }}>
											<ul className="list-unstyled feeds_widget">
												{mailArr}
											</ul>
											<div className="dropdown-divider" />
											<Link to="/list-email"
												className="dropdown-item text-center text-muted-dark readall"
											>
												See all notifications
											</Link>
										</div>
									</div>
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-bell" />
											<span className="badge badge-primary nav-unread" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<ul className="list-unstyled feeds_widget">
												<li>
													<div className="feeds-left">
														<i className="fa fa-user" />
													</div>
													<div className="feeds-body">
														<h4 className="title">
															New User{' '}
															<small className="float-right text-muted">10:45</small>
														</h4>
														<small>I feel great! Thanks team</small>
													</div>
												</li>
											</ul>
											<div className="dropdown-divider" />
											<Link to="/notifications"
												className="dropdown-item text-center text-muted-dark readall"
											>
												See all notifications
											</Link>
										</div>
									</div>
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											{
												account.role == 'admin' ?
													<img className="avatar" src={account.logo ? ImportedURL.LIVEURL + account.logo : ImportedURL.LIVEURL + "../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
													:
													<img className="avatar" src={account.image ? ImportedURL.LIVEURL + account.image : ImportedURL.LIVEURL + "../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
											}
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<div className="card-body">
												<div className="form-group d-flex">
													<i class="icon-user  mr-2" style={{ position: 'relative', top: '3px' }}></i>
													<label className="form-label">{username}</label>
												</div>
												<div className="form-group d-flex">
													<i class="fe fe-mail  mr-2" style={{ position: 'relative', top: '3px' }}></i>
													<label className="form-label">{usermail}</label>
												</div>
											</div>
											{/* <Link to="/change-password" className="dropdown-item">
												<i className="dropdown-icon fe fe-settings" /> Settings
											</Link> */}
											<Link to="/login" className="dropdown-item" onClick={this.signOut}>
												<i className="dropdown-icon fe fe-log-out" /> Sign out
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar,
	darkHeader: state.settings.isDarkHeader,
	AccountState: state.account,
	EmailState: state.email
})

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		AC_SETTING_DETAIL,
		ListEmail: AC_LIST_EMAIL
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);