import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { Row } from 'react-bootstrap';
import { AC_HANDLE_TASK_BOARD_CHANGE, AC_HANDLE_TASK_CHANGE, AC_LIST_TASK, AC_LIST_TASK_BOARD, AC_RESET_TASK, AC_RESET_TASK_BOARD, AC_VIEW_TASK, AC_VIEW_TASK_BOARD } from '../../actions/taskboardAction';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';
import { AC_LIST_PROJECT, AC_VIEW_PROJECT } from '../../actions/projectAction';
class TaskBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            taskBoardNameError: false,
            nameError: false,
            priorityError: false,
            followersError: false,
            projecttaskboardError: false,
            projecttaskError: false,
            reporterError: false,
            closed: false,
            taskId: '',
            projectParamId: '',
            reporterSearch: false,
            searchColor: false,
            selectedData: []
        }
    }
    componentDidMount() {
        const { id } = this.props.match.params
        this.props.ListTaskBoard();
        this.props.ListEmployee();
        this.props.ListProject();
        this.props.ListTask();
        var today = new Date()
        var todayDate = moment(today).format("YYYY-MM-DD");
        this.props.HandleTaskInputChange('date', todayDate);
        this.props.ListTaskBoard({ project: id });
        this.setState({ projectParamId: id })
        this.props.ViewProject(id);
        localStorage.setItem('invoicetoken', "");
        // const selectedData = this.props.TaskBoardState.task.selectedData;
        // this.setState({ selectedData: selectedData })
    }

    leadSearchClick = (id) => {
        this.setState(prevState => ({
            reporterSearch: !prevState.reporterSearch
        }))
        if (this.state.reporterSearch) {
            this.props.ListTask({ followers: '' });
        } else {
            this.props.ListTask({ followers: id });
        }
    }

    teamSearch = (id) => {
        const { selectedData } = this.state
        let status = selectedData && selectedData.find((e) => { return e == id })
        if (status) {
            this.setState({ selectedData: [...selectedData.filter((e) => e != id)] })
            this.props.ListTask({ followers: [...selectedData.filter((e) => e != id)] });

        } else {
            this.setState({ selectedData: [...this.state.selectedData, id] })
            this.props.ListTask({ followers: [...selectedData, id] });
        }
    }

    handleTaskBoardChange = (e) => {
        const { name, value } = e.target;
        if (name == 'name') {
            if (value) {
                this.setState({ taskBoardNameError: false })
                this.props.HandleTaskBoardInputChange('boardname', value)
            } else {
                this.setState({ taskBoardNameError: true })
                this.props.HandleTaskBoardInputChange('boardname', value)
            }
        }
        if (name == 'color') {
            if (value) {
                this.props.HandleTaskBoardInputChange('boardcolor', value)
            } else {
                this.props.HandleTaskBoardInputChange('boardcolor', value)
            }
        }
    }
    handleTaskChange = (e) => {
        const { name, value } = e.target;
        if (name == 'name') {
            if (value) {
                this.setState({ nameError: false })
                this.props.HandleTaskInputChange('name', value)
            } else {
                this.setState({ nameError: true })
                this.props.HandleTaskInputChange('name', value)
            }
        }
        if (name == 'date') {
            this.props.HandleTaskInputChange('date', value)
        }
    }
    handleChangeTaskSelect = (e) => {
        const { name, value } = e;
        if (name == 'priority') {
            if (value) {
                this.props.HandleTaskInputChange('priority', value)
                this.setState({ priorityError: false })
            } else {
                this.props.HandleTaskInputChange('priority', value)
                this.setState({ priorityError: true })
            }
        }
        if (name == 'followers') {
            if (value) {
                this.props.HandleTaskInputChange('followers', value)
                this.setState({ followersError: false })
            } else {
                this.props.HandleTaskInputChange('followers', value)
                this.setState({ followersError: true })
            }
        }
        if (name == 'reporter') {
            if (value) {
                this.props.HandleTaskInputChange('reporter', value)
                this.setState({ reporterError: false })
            } else {
                this.props.HandleTaskInputChange('reporter', value)
                this.setState({ reporterError: true })
            }
        }
    }
    submitTaskBoard = (e) => {
        const data = this.props.TaskBoardState.taskboard;
        let valid = 1
        if (!data.boardname) {
            this.setState({ taskBoardNameError: true })
            valid = 0;
        };
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addTaskBoard, data)
                    .then((res) => {
                        this.setState({ saving: false, closed: true })
                        Success('Task Board created successfully');
                        // Success(res.statusText);
                        this.props.ResetTaskBoard();
                        this.props.ListTaskBoard();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Task Board already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            } else {
                axios.post(ImportedURL.API.updateTaskBoard + "/" + this.state.id, data)
                    .then((res) => {
                        this.setState({ searchhoteloption: '', searchhotel: '' })
                        this.setState({ saving: false, closed: true })
                        Success('Task Board updated successfully');
                        // Success(res.statusText);
                        this.props.ListTaskBoard();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        // Error(response.statusText)
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Task Board already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
                // this.props.UpdateJobTitle(data, this.state.id);
            }
        }
    }
    updateTaskBoard = (id) => {
        this.setState({ modalType: "Edit", id: id });
        this.props.ViewTaskBoard(id);
    }
    updateTask = (id) => {
        this.setState({ modalType: "Edit", id: id });
        this.props.ViewTask(id);
    }
    addNewTask = (id, project) => {
        this.setState({ modalType: "Add" });
        this.props.ResetTask();
        this.props.HandleTaskInputChange('type', id);
        this.props.HandleTaskInputChange('project', project);
        var today = new Date()
        var todayDate = moment(today).format("YYYY-MM-DD");
        this.props.HandleTaskInputChange('date', todayDate);
        this.props.HandleTaskBoardInputChange('project', this.state.projectParamId)
    }
    createlink = (editable) => {
        this.setState({ modalType: "Add" });
        this.props.ResetTaskBoard();
        this.props.HandleTaskBoardInputChange('project', this.state.projectParamId)
    }
    submitTask = (e) => {
        const data = this.props.TaskBoardState.task;
        let valid = 1
        if (!data.name) {
            this.setState({ nameError: true })
            valid = 0;
        };
        if (!data.priority) {
            this.setState({ priorityError: true })
            valid = 0;
        };
        if (!data.followers) {
            this.setState({ followersError: true })
            valid = 0;
        };
        if (valid) {
            this.setState({ saving: true, closed: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addTask, data)
                    .then((res) => {
                        this.setState({ saving: false, closed: true })
                        Success('Task created successfully');
                        // Success(res.statusText);
                        this.props.ResetTask();
                        this.props.ListTask();
                        let btn = document.getElementById("closeModalTask");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Task  already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            } else {
                axios.post(ImportedURL.API.updateTask + "/" + data._id, data)
                    .then((res) => {
                        this.setState({ searchhoteloption: '', searchhotel: '' })
                        this.setState({ saving: false, closed: true })
                        Success('Task updated successfully');
                        // Success(res.statusText);
                        this.props.ListTask();
                        let btn = document.getElementById("closeModalTask");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        // Error(response.statusText)
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Task  already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            }
        }
    }
    clearFilter = (e) => {
        this.props.ListTask({ followers: this.setState({ selectedData: [] }) });
    }
    handleDragStart = (event, id) => {
        event.dataTransfer.setData('id', id);
    };
    handleDrop = (event, type) => {
        const id = event.dataTransfer.getData('id');
        axios.post(ImportedURL.API.updateTypeChangeTask + '/' + id, { type: type }).then(() => {
            this.props.ListTask({ followers: this.state.selectedData });
        });
    };



    render() {
        const { AccountState, TaskBoardState, employeeState, ProjectState } = this.props;
        const account = AccountState?.account;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Task_Board" });

        // ---------Needed List 
        const employeeData = employeeState.listEmployee;
        const projectListData = ProjectState.listProject;
        const projectData = ProjectState.project;

        // ----------task board
        const taskboardData = TaskBoardState.taskboard;
        const listTaskBoard = TaskBoardState.listTaskBoard;
        // ----------task 
        const taskData = TaskBoardState.task;
        const listTask = TaskBoardState.listTask;


        const priorityOptions = [
            { value: 'High', label: 'High', name: 'priority' },
            { value: 'Normal', label: 'Normal', name: 'priority' },
            { value: 'Low', label: 'Low', name: 'priority' },
        ]

        // -------------Employeee assignee
        let selectedEmployeeData = '';
        if (taskData.followers) {
            selectedEmployeeData = employeeData.find(e => e._id === taskData.followers)
        }
        const employeeOptions = [];
        employeeData.filter(filterItem => filterItem.status === true).map(item => {
            if (item._id == projectData.leader) {
                employeeOptions.push({ label: item.employeeName, value: item._id, name: 'followers' });
            }
            (projectData.team) && (projectData.team).map((team) => {
                if ((item._id == team)) {
                    employeeOptions.push({ label: item.employeeName, value: item._id, name: 'followers' });
                }
            })
        })
        // -------------Employeee reporter
        let selectedEmployeeReporterData = '';
        if (taskData.reporter) {
            selectedEmployeeReporterData = employeeData.find(e => e._id === taskData.reporter)
        }
        const employeeReporterOptions = [];
        employeeData.filter(filterItem => filterItem.status === true).map(item => {
            if (item._id == projectData.leader) {
                employeeReporterOptions.push({ label: item.employeeName, value: item._id, name: 'reporter' });
            }
        })
        // ---------------- borderColor 
        var borderColorSet = '';
        const { selectedData } = this.state;
        {
            selectedData && selectedData.map((shadow) => {
                if (shadow == projectData.leader) {
                    borderColorSet = '3px solid #2CBAEC '
                }
            })
        }
        const balanceEmployee = [];
        let balanceEmployeeCount = 0
        if (projectData.teamData) {
            for (let i = 4; i < projectData.teamData.length; i++) {
                balanceEmployeeCount = projectData.teamData.length - 4;
                let status = selectedData && selectedData.includes(projectData.teamData[i]._id);
                balanceEmployee.push(
                    <p class="dropdown-item mb-0" style={{ cursor: 'pointer' }} onClick={(e) => this.teamSearch(projectData.teamData[i]._id)}>
                        <div className='d-flex'>
                            <img src={ImportedURL.LIVEURL + projectData.teamData[i].image} alt="" style={{ height: '40px', width: '40px', objectFit: 'cover', cursor: 'pointer', marginRight: '10px', borderRadius: '50%', border: status ? '3px solid #2CBAEC' : '' }} />
                            <p className='mb-0 d-flex align-items-center'>{projectData.teamData[i].employeeName}</p>
                        </div>
                    </p>
                )
            }
        }
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/project-category"><h6 className="highlights_breadcrump">Tasks</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/task-board"><h6 className="highlights_breadcrump">Task Board</h6></Link></p>
                </div>
                <div className="section-body task_board_sec ">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='card-title d-flex'>
                                            {/* <div className='rounded_icon'><i className="fa fa-tasks mr-2"></i></div> */}
                                            <h3 className="" style={{ marginTop: '10px', fontSize: '24px', fontWeight: '600' }}> {projectData.name} </h3>
                                        </div>
                                        {/* <div className="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="header-action" >
                                                    <div className='top_button'>
                                                        <button type='button' className='btn'><i className="fa fa-link mr-2"></i></button>
                                                        <button type='button' className='btn' data-toggle="modal" data-target="#exampleModal" onClick={this.createlink}><i className="fe fe-plus mr-2"></i>Create Link</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div class="card-body ">
                                        <div className='lt_img_create_link'>
                                            <Row>
                                                <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'>
                                                    <div className='d-flex'>
                                                        <div className='text_img mr-5'>
                                                            <div className='d-flex '>
                                                                <div className='text'>Lead</div>
                                                                <ul class="list-unstyled team-info sm margin-0 w150" style={{ overflow: 'inherit' }}>
                                                                    <li onClick={(e) => this.teamSearch(projectData.leader)}>
                                                                        <img src={ImportedURL.LIVEURL + projectData.leaderImage} alt="Avatar" style={{ height: '40px', width: '40px', objectFit: 'cover', cursor: 'pointer', border: borderColorSet }} />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='text_img mr-5'>
                                                            <div className='d-flex'>
                                                                <div className='text'>Team</div>
                                                                <ul class="list-unstyled team-info sm margin-0 w150" style={{ overflow: 'inherit' }}>
                                                                    {
                                                                        (projectData.teamData) && (projectData.teamData).slice(0, 4).map((emimdata) => {
                                                                            let status = selectedData && selectedData.includes(emimdata._id);
                                                                            return <li key={emimdata._id} onClick={(e) => this.teamSearch(emimdata._id)}>
                                                                                <img src={ImportedURL.LIVEURL + emimdata.image} alt="Avatar" style={{ height: '40px', width: '40px', objectFit: 'cover', cursor: 'pointer', border: status ? '3px solid #2CBAEC' : '' }} />
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                                {projectData.teamData && projectData.teamData.length > 4 ?
                                                                    <div class=" dropdown extra_em">
                                                                        <p data-toggle="dropdown" aria-expanded="false" className='mb-0 d-flex justify-content-center align-items-center ' style={{ width: '40px', height: '40px', background: '#e9ecef', color: '#000', borderRadius: '50%', marginLeft: '-20px', cursor: 'pointer', fontWeight: '600' }}>
                                                                            {balanceEmployeeCount}+
                                                                        </p>
                                                                        <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', transform: 'translate3d(-174px, 25px, 0px)', top: '5px', willChange: 'transform' }}>
                                                                            {balanceEmployee}
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                                }


                                                                {/* <p className=' d-flex justify-content-center align-items-center mb-0' style={{ width: '40px', height: '40px', background: '#2cbaec', color: '#fff', borderRadius: '50%', marginLeft: '-28px', }}>2+</p> */}
                                                                {/* <i className="fe fe-plus mr-2" data-toggle="modal" data-target="#leaderModal" /> */}
                                                            </div>
                                                        </div>
                                                        {selectedData && selectedData.length > 0 ?
                                                            <div>
                                                                <button type='button' className='btn clear_filter' onClick={this.clearFilter}>Clear filters</button>
                                                            </div>
                                                            : []}
                                                    </div>
                                                </div>
                                                <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
                                                    <div className='top_button'>
                                                        <button type='button' className='btn'><i className="fa fa-link mr-2"></i></button>
                                                        <button type='button' className='btn' data-toggle="modal" data-target="#exampleModal" onClick={this.createlink}><i className="fe fe-plus mr-2"></i>Create Link</button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        <div className='ticket_tracker'>
                                            <div class="outer-wrapper">
                                                <div class="inner-wrapper">
                                                    {/* ============ Task Board =============== */}
                                                    {listTaskBoard && listTaskBoard.map((taskboard) => {
                                                        if (taskboard.project == this.state.projectParamId) {
                                                            return <div class="pseudo-item"
                                                                onDrop={(event) => this.handleDrop(event, taskboard._id)}
                                                                onDragOver={(event) => event.preventDefault()}
                                                            >
                                                                <div class="card top_box_title">
                                                                    <div className='top_box_title1'>
                                                                        <div class="card-header " style={{ background: taskboard.boardcolor, padding: '10px' }}>
                                                                            <h3 class="card-title">{taskboard.boardname}</h3>
                                                                            <div class="card-options">
                                                                                <div class="item-action dropdown ml-2">
                                                                                    <a href="fake_url" data-toggle="dropdown" aria-expanded="false">
                                                                                        <i class="fe fe-more-vertical"></i>
                                                                                    </a>
                                                                                    <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', transform: 'translate3d(-174px, 25px, 0px)', top: '0px', willChange: 'transform' }}>
                                                                                        <button class="dropdown-item" onClick={(e) => this.updateTaskBoard(taskboard._id)} data-toggle="modal" data-target="#exampleModal">
                                                                                            <i class="dropdown-icon fa fa-edit"></i> Edit </button>
                                                                                        <button class="dropdown-item">
                                                                                            <i class="dropdown-icon fa fa-trash-o"></i> Delete </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='ticket_out_side' >
                                                                        {/* ============ Task List =============== */}
                                                                        {listTask && listTask.map((task) => {
                                                                            let background_color = '';
                                                                            let colour = ''
                                                                            if (task.priority == 'High') {
                                                                                background_color = 'rgba(242, 17, 54,0.12)';
                                                                                colour = '#e63c3c'
                                                                            } else if (task.priority == 'Normal') {
                                                                                background_color = 'rgba(255, 152, 0, 0.12)';
                                                                                colour = '#f39c12 '
                                                                            } else {
                                                                                background_color = 'rgba(15, 183, 107,0.12)';
                                                                                colour = '#26af48 '
                                                                            }

                                                                            if (taskboard._id == task.type) {
                                                                                return <div className='ticket'
                                                                                    draggable
                                                                                    key={task._id}
                                                                                    onDragStart={(event) => this.handleDragStart(event, task._id)}
                                                                                >
                                                                                    <div className='task_icon'>
                                                                                        <p class="task_text">{task.name} </p>
                                                                                        <div class=" dropdown ml-2 icon">
                                                                                            <a href="fake_url" data-toggle="dropdown" aria-expanded="false">
                                                                                                <i class="fe fe-more-vertical"></i>
                                                                                            </a>
                                                                                            <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', transform: 'translate3d(-174px, 25px, 0px)', top: '0px', willChange: 'transform' }}>
                                                                                                <Link class="dropdown-item" onClick={(e) => this.updateTask(task._id)} data-toggle="modal" data-target="#addTaskModal">
                                                                                                    <i class="dropdown-icon fa fa-edit"></i> Edit </Link>
                                                                                                <Link class="dropdown-item">
                                                                                                    <i class="dropdown-icon fa fa-trash-o"></i> Delete </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Row>
                                                                                        <div className='col-10'>
                                                                                            <div class="progress progress-xs " style={{ marginTop: '10px' }}>
                                                                                                <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%', background: taskboard.boardcolor }}></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-2'>
                                                                                            <small class="text-muted">70%</small>
                                                                                        </div>
                                                                                    </Row>
                                                                                    <div className='time_userlogo'>
                                                                                        <div>
                                                                                            <div className='d-flex  align-items-center' style={{ fontSize: '12px', fontWeight: '500' }}>
                                                                                                <i class="fa fa-clock-o mr-2" aria-hidden="true"></i> <span>{moment(task.date).format(" MMMM DD  ")}</span>
                                                                                            </div>
                                                                                            <span class="tag" style={{ background: background_color, fontSize: '8px', color: colour, fontWeight: '600', letterSpacing: '1px' }}>{task.priority}</span>
                                                                                        </div>
                                                                                        <div className='img_size'>
                                                                                            <img src={task.image ? ImportedURL.LIVEURL + task.image : '../assets/images/xs/avatar1.jpg'} alt='' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        })}

                                                                        <span className='add_task_link' data-toggle="modal" data-target="#addTaskModal" onClick={(e) => this.addNewTask(taskboard._id, taskboard.project)}><i class="fa fa-plus mr-2 mt-1" aria-hidden="true"></i>Add New Task</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        }

                                                    })}

                                                </div>
                                            </div>
                                            <div class="pseduo-track"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Modal -Task Board Model-> */}
                <div class="modal fade task_board_model" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-ticket mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} Task Board</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className="form-group">
                                    <label className="form-label">Task Board Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name='name' onChange={this.handleTaskBoardChange} value={taskboardData.boardname} placeholder="Task Board Name" />
                                    <div className="invalid-feedback" style={{ display: this.state.taskBoardNameError ? "block" : 'none' }}> Task Board Name is required</div>
                                </div>
                                <label className="form-label">Task Board Color</label>
                                <div className='task_board_radio'>
                                    <div>
                                        <input type="radio" id="3" name="color" value="#42a5f5" onChange={this.handleTaskBoardChange} checked={taskboardData.boardcolor === '#42a5f5'} />
                                        <label for="3" style={{ background: '#42a5f5' }}>{taskboardData.boardcolor === '#42a5f5' ? <i class="fa fa-check" aria-hidden="true"></i> : ''}</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="1" name="color" value="#ff9b44" onChange={this.handleTaskBoardChange} />
                                        <label for="1" style={{ background: '#ff9b44' }}>{taskboardData.boardcolor === '#ff9b44' ? <i class="fa fa-check" aria-hidden="true"></i> : ''}</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="2" name="color" value="#4caf50" onChange={this.handleTaskBoardChange} />
                                        <label for="2" style={{ background: '#4caf50' }}>{taskboardData.boardcolor === '#4caf50' ? <i class="fa fa-check" aria-hidden="true"></i> : ''}</label>
                                    </div>

                                    <div>
                                        <input type="radio" id="4" name="color" value="#7460ee" onChange={this.handleTaskBoardChange} />
                                        <label for="4" style={{ background: '#7460ee' }}>{taskboardData.boardcolor === '#7460ee' ? <i class="fa fa-check" aria-hidden="true"></i> : ''}</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="5" name="color" value="#ffb300" onChange={this.handleTaskBoardChange} />
                                        <label for="5" style={{ background: '#ffb300' }}>{taskboardData.boardcolor === '#ffb300' ? <i class="fa fa-check" aria-hidden="true"></i> : ''}</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="6" name="color" value="#ef5350" onChange={this.handleTaskBoardChange} />
                                        <label for="6" style={{ background: '#ef5350' }}>{taskboardData.boardcolor === '#ef5350' ? <i class="fa fa-check" aria-hidden="true"></i> : ''}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={this.submitTaskBoard}><i class="fe fe-save mr-2"></i>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Modal -Leader-> */}
                <div class="modal fade task_board_model" id="leaderModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-users mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} Assign Leader to this project</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <label className="form-label">Add Leaders <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                <div className="form-group">
                                    <Select
                                        isMulti
                                    // value={selectedTeamArray}
                                    // onChange={this.handleChangeTeam}
                                    // options={employeeTeamOptions}
                                    />
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"><i class="fe fe-save mr-2"></i>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Modal -Team-> */}
                <div class="modal fade task_board_model" id="teamModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-users mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} Assign the user to this project</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <label className="form-label">Add Team Members<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                <div className="form-group">
                                    <Select
                                        isMulti
                                    // value={selectedTeamArray}
                                    // onChange={this.handleChangeTeam}
                                    // options={employeeTeamOptions}
                                    />
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"><i class="fe fe-save mr-2"></i>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Modal -Add Task-> */}
                <div class="modal fade task_board_model" id="addTaskModal" tabindex="-1" role="dialog" aria-labelledby="addTaskModal" aria-hidden="true">
                    <div class="modal-dialog " role="document">
                        <div class="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-ticket mr-2 "></i></div>
                                <h5 className="modal-title" id="addTaskModal" style={{ marginTop: '5px' }}>{this.state.modalType} Task</h5>
                                <button type="button" id="closeModalTask" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }} id="closeModalTask">
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <Row>
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label className="form-label">Task Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" name='name' onChange={this.handleTaskChange} value={taskData.name} placeholder="Task Board Name" />
                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}> Task Name is required</div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label className="form-label">Task Priority<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                        <div className="form-group">
                                            <Select
                                                value={taskData.priority ? { label: taskData.priority, value: taskData.priority, name: "priority" } : ''}
                                                onChange={this.handleChangeTaskSelect}
                                                options={priorityOptions}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.priorityError ? "block" : 'none' }}> Task Priority is required</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Due Date<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                        <div className='site_pass'>
                                            <input type="date" className="form-control" name="date" value={taskData.date} onChange={this.handleTaskChange} />
                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                            {/* <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}> Date is required</div> */}
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label className="form-label">Assignee<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                        <div className="form-group">
                                            <Select
                                                value={selectedEmployeeData ? { label: selectedEmployeeData.employeeName, value: selectedEmployeeData._id, name: "followers" } : ''}
                                                onChange={this.handleChangeTaskSelect}
                                                options={employeeOptions}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.followersError ? "block" : 'none' }}> Task Followers is required</div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label className="form-label">Reporter<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                        <div className="form-group">
                                            <Select
                                                value={selectedEmployeeReporterData ? { label: selectedEmployeeReporterData.employeeName, value: selectedEmployeeReporterData._id, name: "reporter" } : ''}
                                                onChange={this.handleChangeTaskSelect}
                                                options={employeeReporterOptions}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.reporterError ? "block" : 'none' }}> Reporter is required</div>
                                        </div>
                                    </div>
                                </Row>
                                {
                                    this.state.modalType == "View" ?
                                        ''
                                        :
                                        (this.state.modalType == "Edit" ?
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="" >
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                            :
                                                            <button type="button" className="btn commor_save" onClick={this.submitTask}><i className="fe fe-save mr-2"></i>Update</button>
                                                    }
                                                    <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                                </div>
                                            </div>
                                            : <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="" >
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                            :
                                                            <button type="button" className="btn commor_save" onClick={this.submitTask}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                    <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                                </div>
                                            </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    TaskBoardState: state.taskboard,
    employeeState: state.employeeReducer,
    ProjectState: state.project,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ListTaskBoard: AC_LIST_TASK_BOARD,
            HandleTaskBoardInputChange: AC_HANDLE_TASK_BOARD_CHANGE,
            ViewTaskBoard: AC_VIEW_TASK_BOARD,
            ResetTaskBoard: AC_RESET_TASK_BOARD,
            ListEmployee: AC_LIST_EMPLOYEE,

            HandleTaskInputChange: AC_HANDLE_TASK_CHANGE,
            ViewTask: AC_VIEW_TASK,
            ResetTask: AC_RESET_TASK,
            ListTask: AC_LIST_TASK,

            ListProject: AC_LIST_PROJECT,
            ViewProject: AC_VIEW_PROJECT,
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskBoard);