
const initialState = {
    listDivision: [],
    division: {
        division: '',
        department: '',
    },
}
function divisionReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_DIVISION':
            return {
                ...state,
                division: action.payload
            }
            break;

        case 'LIST_DIVISION':
            return {
                ...state,
                listDivision: action.payload
            }
            break;
        case 'VIEW_DIVISION':
            return {
                ...state,
                division: action.payload
            }
        case 'RESET_DIVISION':
            return Object.assign({}, state, {
                division: initialState.division
            })
        case 'HANDLE_DIVISION_CHANGE':
            return Object.assign({}, state, {
                division: {
                    ...state.division,
                    [action.name]: action.value
                }
            })
        default:
            return state;
            break;
    }
}

export default divisionReducer;