import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportedURL from '../../common/api';
import { AC_VIEW_ASSIGNINVENTORY, AC_RESET_ASSIGNINVENTORY, AC_LIST_ASSIGNINVENTORY, AC_LIST_STOCK, AC_LIST_SERVICE, AC_LIST_DAMAGE, AC_VIEW_SERVICE } from '../../actions/assignInventoryAction';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import { AC_LIST_INVENTORY } from '../../actions/inventoryAction';
import { AC_LIST_ASSET } from '../../actions/assetAction';

import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import jsPDF from "jspdf";
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';




class ViewAssignInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdf: false,
            all: true,
            viewServiceRedirect: false,
            assignId: '',
            columnDefs: [
                { headerName: 'Employee Name', field: 'employeeName', width: 270, floatingFilter: true },
                { headerName: 'Employee ID', field: 'employeeid', width: 150, floatingFilter: true },
                {
                    headerName: 'Date assigned', width: 270, field: 'assignedDate',
                    cellRenderer: function (params) {
                        if (params.data.assignedDate) {
                            return moment(params.data.assignedDate).format("MM/DD/YYYY");
                        }
                    }
                },
                {
                    headerName: 'Reason for Stock', field: 'reasonforstock', width: 270, floatingFilter: true,
                    cellRenderer: function (params) {
                        if (params.data.reasonforstock) {
                            return params.data.reasonforstock;
                        }
                        else return '---'
                    }
                },
            ],
            columnDefs2: [
                { headerName: 'Reason for Service', field: 'reasonService', width: 200, floatingFilter: true },
                {
                    headerName: 'Service Date', width: 150, field: 'dateofservice', cellStyle: { textAlign: 'left' },
                    cellRenderer: function (params) {
                        if (params.data.dateofservice) {
                            return moment(params.data.dateofservice).format("MM/DD/YYYY");
                        }
                        else return '---'
                    }
                },
                {
                    headerName: 'Invoice Date', width: 150, field: 'invoiceDate',
                    cellRenderer: function (params) {
                        if (params.data.invoiceDate) {
                            return moment(params.data.invoiceDate).format("MM/DD/YYYY");
                        }
                        else return '---'
                    }
                },
                {
                    headerName: 'Delivery Date', width: 150, field: 'deliveryDate', cellStyle: { textAlign: 'left' },
                    cellRenderer: function (params) {
                        if (params.data.deliveryDate) {
                            return moment(params.data.deliveryDate).format("MM/DD/YYYY");
                        }
                        else return '---'
                    }
                },
                {
                    headerName: 'Amount', field: 'amount', width: 150, floatingFilter: true,
                    cellRenderer: function (params) {
                        if (params.data.amount) {
                            return params.data.amount;
                        }
                        else return '---'
                    }
                },
                {
                    headerName: 'Bill', width: 100, field: 'bill', cellStyle: { textAlign: 'left' },
                    cellRenderer: function (params) {
                        if (params.data.billUpload.length > 0) {
                            return `<a href=#exampleModal class="btn btn-icon" data-toggle="modal"  target='_blank' data-action-type="billPreview"><i class="fa fa-file-text-o" data-action-type="billPreview"></i></a>`
                        }
                        else return '---'
                    }
                },
                {
                    headerName: 'View', width: 150, field: 'actions',
                    cellRenderer: function (params) {
                        return params.value;
                    }
                },
            ],
            columnDefs3: [
                { headerName: 'Reason', field: 'reasonDamage', width: 580, floatingFilter: true, cellStyle: { textAlign: 'left' } },
                {
                    headerName: 'Date', width: 270, field: 'dateDamage',
                    cellRenderer: function (params) {
                        if (params.data.dateDamage) {
                            return moment(params.data.dateDamage).format("MM/DD/YYYY");
                        }
                    }
                },
            ],
            defaultColumDef: {
                "width": 200,
                "filter": true,
                "sortable": true,
                "resizable": true
            },
            asset: {},
            history: [],
            service: [],
            damage: [],
            servicedata: {}
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        axios.get(`${ImportedURL.API.viewAssetDetails}/${id}`)
            .then((res) => {
                this.setState({ asset: res.data.asset, history: res.data.history, damage: res.data.damage, service: res.data.service })
            }).catch((err) => {
                console.log(err);
            });
    }

    onServiceRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value == 'billPreview') {
            this.setState({ billdata: event.data.billUpload });
        }

        if (value == 'serviceView') {
            this.setState({ servicedata: event.data, });
        }
    }

    view = e => {
        this.setState({ pdf: true });
        // window.location='blank';
    }

    // -------------------------------------history export
    onGridReadyHistory = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    exportHistoryToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['employeeName', 'propertyId', 'assignedDate', 'reason'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportHistoryPDF = () => {
        const rowStockData = this.state.history

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "History";
        const headers = [['#', 'Employee Name', 'Employee ID', 'Date assigned', 'Reason for stock']];

        const data = rowStockData.map((elt, i) => [
            i + 1,
            elt.employeeName,
            elt.employeeid,
            moment(elt.assignedDate).format("MM/DD/YYYY"),
            elt.reasonforstock,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("History.pdf")
    }

    // -------------------------------------service export
    onGridReadyService = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApiService = params.api;
        this.gridApiService.sizeColumnsToFit();
    }

    // onBtnExport = () => {
    //     this.gridApi.exportDataAsCsv();
    // };

    exportServiceToCSV = () => {
        this.gridApiService.exportDataAsCsv({
            columnKeys: ['reasonService', 'dateofservice', 'invoiceDate', 'deliveryDate', 'amount'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportServicePDF = () => {
        const rowServiceData = this.state.service
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Service";
        const headers = [['#', 'Reason', 'Service Date', 'Delivery Date', 'Invoice Date', 'Amount']];

        const data = rowServiceData.map((elt, i) => [
            i + 1,
            elt.reasonService,
            moment(elt.dateofservice).format("MM/DD/YYYY"),
            moment(elt.invoiceDate).format("MM/DD/YYYY"),
            moment(elt.deliveryDate).format("MM/DD/YYYY"),
            elt.amount,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Service.pdf")
    }

    // -------------------------------------Damage export
    onGridReadyDamage = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApiDamage = params.api;
        this.gridApiDamage.sizeColumnsToFit();
    }

    // onBtnExport = () => {
    //     this.gridApi.exportDataAsCsv();
    // };

    exportDamageToCSV = () => {
        this.gridApiDamage.exportDataAsCsv({
            columnKeys: ['reasonDamage', 'dateDamage'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportDamagePDF = () => {
        const rowDamageData = this.state.damage

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Damage";
        const headers = [['#', 'Reason', 'Date']];

        const data = rowDamageData.map((elt, i) => [
            i + 1,
            elt.reasonDamage,
            moment(elt.dateDamage).format("MM/DD/YYYY"),
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Damage.pdf")
    }

    render() {

        var assetdata = this.state.asset;



        // ---------aggrid service
        const serviceData = this.state.service;
        const billdata = this.state.billdata;


        // ---------aggrid damage
        const damageData = this.state.damage;

        var arr = []
        var nameArr = []
        if (billdata && billdata.length)
            for (var i = 0; i < billdata.length; i++) {

                arr.push(<div><a href={ImportedURL.LIVEURL + billdata[i]} class="btn btn-icon" target='_blank'><i class="fa fa-file-text-o" data-action-type="View"></i></a></div>)
                nameArr.push(<div style={{ marginBottom: '14px' }}>{billdata[i].split('\\').slice(-1).pop().replace(/[0-9]/g, '')}</div>)
            }
        // serviceData.forEach(object => {
        //     object.bill = billUploadData
        // });

        serviceData.forEach(object => {
            object.actions = '<button type="button" data-toggle="modal" data-target="#exampleModalService" class="btn btn-icon" data-action-type="serviceView"><i class="fa-solid fa-eye" style="color: #d55d3d !important" data-action-type="serviceView"></i></button>'
        });

        // var viewAssignId = this.props.match.params.id;
        // if (this.state.viewServiceRedirect) return <Redirect to={'/viewservice/' + viewAssignId + '/' + this.state.id} />

        return (
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="Project-OnGoing" role="tabpanel">
                            <div className='row'>
                                <div className="col-lg-12   col-md-12">
                                    <div className="card ">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                                            </div>
                                            <h3 className="card-title">View Asset</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>

                                                    <div className="header-action">
                                                        <Link to='/list-asset'>
                                                            <button type="button" className="btn btn-round btn-primary">
                                                                <i className="fa fa-solid fa-chevron-left mr-2"></i>Back
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div className="card-body">
                                            {this.state.all ?
                                                <div className="row">
                                                    <div className='col-lg-6 '>
                                                        <div className="row">
                                                            <div className="col-4 py-2"><strong>Asset Name</strong></div>
                                                            <div className="col-1 py-2"><strong>:</strong></div>
                                                            <div className="col-7 py-2">{assetdata?.assetname}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 '>
                                                        <div className="row">
                                                            <div className="col-4 py-2"><strong>Category</strong></div>
                                                            <div className="col-1 py-2"><strong>:</strong></div>
                                                            <div className="col-7 py-2">{assetdata?.category}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 '>
                                                        <div className='row'>
                                                            <div className="col-4 py-2"><strong>Sub Category</strong></div>
                                                            <div className="col-1 py-2"><strong>:</strong></div>
                                                            <div className="col-7 py-2">{assetdata?.subcategory}</div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6 '>
                                                        <div className='row'>
                                                            <div className="col-4 py-2"><strong>Purchase Date</strong></div>
                                                            <div className="col-1 py-2"><strong>:</strong></div>
                                                            <div className="col-7 py-2">{moment(assetdata.dateofpurchase).format("MM/DD/YYYY")}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 '>
                                                        <div className='row'>
                                                            <div className="col-4 py-2"><strong>Purchase Cost</strong></div>
                                                            <div className="col-1 py-2"><strong>:</strong></div>
                                                            <div className="col-7 py-2">${assetdata?.cost}</div>
                                                        </div>
                                                    </div>
                                                    {assetdata.features ? <div className='col-lg-6 '>
                                                        <div className='row'>
                                                            <div className="col-4 py-2"><strong>Features</strong></div>
                                                            <div className="col-1 py-2"><strong>:</strong></div>
                                                            <div className="col-7 py-2">{assetdata?.features}</div>
                                                        </div>
                                                    </div> : ''}

                                                    {assetdata.warrantyexist ? <div className='col-lg-6 '>
                                                        <div className='row'>
                                                            <div className="col-4 py-2"><strong>Warranty Expiry</strong></div>
                                                            <div className="col-1 py-2"><strong>:</strong></div>
                                                            <div className="col-7 py-2">{moment(assetdata.warrantyexpiry).format("MM/DD/YYYY")} Year</div>
                                                        </div>
                                                    </div> : ''}

                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                {/* -----------servive bnill preview---model */}
                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel"><i class="fa-regular fa-note-sticky mr-2"></i>Bill Preview</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }} >x</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className='row'>
                                                            <div className="col-8 py-2" style={{ paddingLeft: '100px' }}><strong><label>Bill Name</label></strong>{nameArr}</div>
                                                            <div className="col-4 py-2"><strong><label>Bill</label></strong>{arr}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="exampleModalService" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel"><i class="fa-regular fa-note-sticky mr-2"></i>Service View</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }} >x</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row clearfix">
                                                    <div className="col-4 py-1"><strong>Reason for Service</strong></div>
                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                    <div className="col-7 py-1">{this.state.servicedata.reasonService}</div>

                                                    <div className="col-4 py-1"><strong>Service Date</strong></div>
                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                    <div className="col-7 py-1">{moment(this.state.servicedata.dateofservice).format("MM/DD/YYYY")}</div>

                                                    <div className="col-4 py-1"><strong>Invoice Number</strong></div>
                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                    <div className="col-7 py-1">{this.state.servicedata.invoiceNo}</div>

                                                    <div className="col-4 py-1"><strong>Amount</strong></div>
                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                    <div className="col-7 py-1">{this.state.servicedata.amount}</div>

                                                    <div className="col-4 py-1"><strong>Invoice Date</strong></div>
                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                    <div className="col-7 py-1">{this.state.servicedata.invoiceDate ? moment(this.state.servicedata.invoiceDate).format("MM/DD/YYYY") : ''}</div>

                                                    <div className="col-4 py-1"><strong>Delivery Date</strong></div>
                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                    <div className="col-7 py-1">{this.state.servicedata.deliveryDate ? moment(this.state.servicedata.deliveryDate).format("MM/DD/YYYY") : ''}</div>

                                                    <div className="col-4 py-1"><strong>Remarks</strong></div>
                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                    <div className="col-7 py-1">{this.state.servicedata.remarks}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* ------------------------stock---history---------------------------- */}
                                {this.state.history.length ? <div className="col-lg-12   col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i class="fa-solid fa-book mr-2"></i>
                                            </div>
                                            <h3 className="card-title">History</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportHistoryToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportHistoryPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine" id="content">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={10}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={this.state.history}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReadyHistory}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* ------------------------service------------------------------- */}
                                {this.state.service.length ? <div className="col-lg-12   col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i class="fa-solid fa-wand-magic-sparkles mr-2"></i>
                                            </div>
                                            <h3 className="card-title">Service Detail</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportServiceToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportServicePDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine" id="content">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={10}
                                                        pagination={true}
                                                        onRowClicked={this.onServiceRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={serviceData}
                                                        columnDefs={this.state.columnDefs2}
                                                        onGridReady={this.onGridReadyService}>

                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* ------------------------damage------------------------------- */}
                                {this.state.damage.length ? <div className="col-lg-12   col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i class="fa-solid fa-circle-exclamation mr-2"></i>
                                            </div>
                                            <h3 className="card-title">Damage Detail</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportDamageToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportDamagePDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine" id="content">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={10}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={damageData}
                                                        columnDefs={this.state.columnDefs3}
                                                        onGridReady={this.onGridReadyDamage}>

                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    assignInventoryReducer: state.assignInventoryReducer,
    countryReducer: state.countryReducer,
    inventoryReducer: state.inventoryReducer,
    assetReducer: state.assetReducer,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        AC_VIEW_ASSIGNINVENTORY, AC_RESET_ASSIGNINVENTORY, AC_LIST_COUNTRY, AC_LIST_INVENTORY, AC_LIST_ASSET, AC_LIST_ASSIGNINVENTORY,
        AC_LIST_STOCK,
        AC_LIST_SERVICE, AC_VIEW_SERVICE,
        AC_LIST_DAMAGE,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewAssignInventory);

