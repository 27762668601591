import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';

export function AC_RESET_PAYROLL_ITEM() {
	return function (dispatch) {
		dispatch({ type: "RESET_PAYROLL_ITEM" })
	};
}

export function AC_LIST_PAYROLL_ITEM() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listPayrollitem)
            .then(({ data }) => {
                dispatch({ type: "LIST_PAYROLL_ITEM", payload: data });
            })
    }
}
export function AC_VIEW_PAYROLL_ITEM(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewPayrollitem + "/" + id)
            .then(({ data }) => { 
                dispatch({ type: "VIEW_PAYROLL_ITEM", payload: data });
            });
    }
}
export function AC_HANDLE_PAYROLL_ITEM_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_PAYROLL_ITEM_CHANGE", name: name, value: value })
    };
}