import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_VIEW_EMPLOYEE_SALARY } from '../../../actions/employeesalaryAction';
import ImportedURL from '../../../common/api';
import moment from 'moment';
import { numToWords } from '../../../common/validate';


class Payslip extends Component {
    printFun = () => {
        window.print();
    }
    componentDidMount() {
        this.props.ViewEmployeePayslip(this.props.match.params.id);
        localStorage.setItem('invoicetoken', "");
    }


    render() {
        const { employeesalaryState } = this.props;
        const data = employeesalaryState.employeesalary;
        var grossA = data.salary;
        var grossB = Math.round(data.pf) + Math.round(data.esi) + Math.round(data.professionaltax);
        var grossC = Math.round(data.pfemployer) + Math.round(data.esiepmloyer) + Math.round(data.gratuity);
        // const monthNames = ["January", "February", "March", "April", "May", "June",
        //     "July", "August", "September", "October", "November", "December"
        // ];

        const d = new Date();
        let year = d.getFullYear();

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6>Payslip</h6></p>
                </div>

                <div className='download_options'>
                    <div className="header-action" style={{ marginTop: '5px' }}>
                        <Link to='/listemployeesalary'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                    </div>
                    <nav aria-label="Page navigation example" >
                        <ul class="pagination" style={{ marginRight: "150px", marginTop: "10px" }}>
                            <li class="page-item"><a class="page-link" href="#">CSV</a></li>
                            <li class="page-item"><a class="page-link" href="#">PDF</a></li>
                            <li class="page-item"><a class="page-link" href="#" onClick={this.printFun}> <span><i class="fa fa-print" aria-hidden="true"></i></span> Print</a></li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">Payslip</h3>

                                        </div>
                                        <div className='card-body emppayslip' id='print_page'>
                                            <div className='payslip_content'>
                                                <p className='payslip_heading'>
                                                    EMPLOYEE CTC FOR THE YEAR OF  {year}
                                                </p>
                                                <div className='payslip_company_intro'>
                                                    <div className='company_logo'>
                                                        <img src='../../assets/images/banner-logo.png' />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='payslip_tables pt-5'>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <h4>Earnings</h4>
                                                        <ul>
                                                            <li>
                                                                <strong>Basic Salary</strong>
                                                                <p>{Math.round(data.basicsalary ? data.basicsalary : '')}</p>
                                                            </li>
                                                            <li>
                                                                <strong>House Rent Allowance (H.R.A.)</strong>
                                                                <p>{Math.round(data.hra ? data.hra : '')}</p>
                                                            </li>
                                                            {data.itemvalue != undefined && data.itemvalue.filter(item => item.itemstatus === true).map((item) => {
                                                                return (
                                                                    <>
                                                                        <li>
                                                                            <strong>{item.itemname}</strong>
                                                                            <p>{item.amount}</p>
                                                                        </li>
                                                                    </>
                                                                )
                                                            })}

                                                            <li>
                                                                <strong>Statutory Bonus</strong>
                                                                <p>{Math.round(data.statutorybonus ? data.statutorybonus : '')}</p>
                                                            </li>
                                                            <li>
                                                                <strong>Special Allowance</strong>
                                                                <p>{Math.round(data.specialallowance ? data.specialallowance : '')}</p>
                                                            </li>
                                                            <li className='payslip_highlight_bg'>
                                                                <strong>Gross Earnings (A)</strong>
                                                                <p>{Math.round(data.salary ? data.salary : '')} <i class="fa-solid fa-indian-rupee-sign ml-2"></i></p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='col-lg-6 media_print_deductions'>
                                                        <h4>Employee Deductions</h4>
                                                        <ul>
                                                            <li>
                                                                <strong>EPF Employee Contribution</strong>
                                                                <p>{Math.round(data.pf ? Math.round(data.pf) : '')}</p>
                                                            </li>
                                                            <li>
                                                                <strong>ESI Employee Contribution</strong>
                                                                <p>{Math.round(data.esi ? data.esi : '')}</p>
                                                            </li>
                                                            <li>
                                                                <strong>Professional Tax</strong>
                                                                <p>{Math.round(data.professionaltax ? data.professionaltax : '')}</p>
                                                            </li>
                                                            <li className='payslip_highlight_bg'>
                                                                <strong>Gross Earnings (B)</strong>
                                                                <p>{Math.round(grossB ? grossB : '')} <i class="fa-solid fa-indian-rupee-sign ml-2"></i></p>
                                                            </li>
                                                        </ul>
                                                        <div className='media_print_deductions mt-4'>
                                                            <h4> Employer Deductions</h4>
                                                            <ul>
                                                                <li>
                                                                    <strong>EPF Employer Contribution</strong>
                                                                    <p>{Math.round(data.pfemployer ? data.pfemployer : '')}</p>
                                                                </li>
                                                                <li>
                                                                    <strong>ESI Employer Contribution</strong>
                                                                    <p>{Math.round(data.esiepmloyer ? data.esiepmloyer : '')}</p>
                                                                </li>
                                                                <li>

                                                                    <strong>Gratuity**</strong>
                                                                    <p>{data.gratuity ? Math.round(data.gratuity) : ''}</p>
                                                                </li>
                                                                <li className='payslip_highlight_bg'>
                                                                    <strong>Gross Earnings (C)</strong>
                                                                    <p>{Math.round(grossC ? grossC : '')} <i class="fa-solid fa-indian-rupee-sign ml-2"></i></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='media_print_deductions mt-4'>
                                                            <ul>
                                                                <li className='payslip_highlight_bg'>
                                                                    <strong>CTC (A+C)</strong>
                                                                    <p>{grossA && grossC ? Math.round(grossA) + Math.round(grossC) : ''} <i class="fa-solid fa-indian-rupee-sign ml-2"></i></p>
                                                                </li>
                                                                <li className='payslip_highlight_bg'>
                                                                    <strong>GrossA</strong>
                                                                    <p>{Math.round(data.salary ? data.salary : '')} <i class="fa-solid fa-indian-rupee-sign ml-2"></i></p>
                                                                </li>
                                                                <li className='payslip_highlight_bg'>
                                                                    <strong>NET TAKE HOME (A) - (B)</strong>
                                                                    <p>{Math.round(grossA && grossB ? grossA - grossB : '')} <i class="fa-solid fa-indian-rupee-sign ml-2"></i></p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='net_salary pt-5 pb-3'>
                                                <p><strong>Net Salary <span className='px-2'>:</span></strong> <span style={{ fontWeight: "bolder" }}>{data.salary} <i class="fa-solid fa-indian-rupee-sign ml-2"></i></span> - (  {numToWords(data.salary)}. )</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    employeeReducer: state.employeeReducer,
    payrollitemState: state.payrollitem,
    employeesalaryState: state.employeesalary
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ViewEmployeePayslip: AC_VIEW_EMPLOYEE_SALARY,
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Payslip);