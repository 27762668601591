const CONSTANTS = {};

CONSTANTS.previleges = [
    { "name": "Category", "view": false, "add": false, "edit": false, "delete": false },
    { "name": "Employee_Category", "view": false, "add": false, "edit": false, "delete": false },
    { "name": "Country", "view": false, "add": false, "edit": false },
    { "name": "Project", "view": false, "add": false, "edit": false },
    { "name": "Employee", "view": false, "add": false, "edit": false },
    { "name": "Holidays", "view": false, "add": false, "edit": false },
    { "name": "PayrollItem", "view": false, "add": false, "edit": false },
    { "name": "Employee_salary", "view": false, "add": false, "edit": false },
    { "name": "Payslip", "view": false, "add": false, "edit": false },
    { "name": "Email", "view": false, "add": false, "edit": false },
    { "name": "Task_Board", "view": false, "add": false, "edit": false },
    { "name": "Leaves", "view": false, "add": false, "edit": false },
    { "name": "Employee_Leave", "view": false, "add": false, "edit": false },
    { "name": "Kycdocument", "view": false, "add": false, "edit": false },
    { "name": "Leave_Transection", "view": false, "add": false, "edit": false },
    { "name": "Monthly_payroll", "view": false, "add": false, "edit": false },
    { "name": "Earning", "view": false, "add": false, "edit": false },
    { "name": "Deduction", "view": false, "add": false, "edit": false },
    { "name": "Invoice_category", "view": false, "add": false, "edit": false },
    { "name": "Invoice_subcategory", "view": false, "add": false, "edit": false },
    { "name": "Invoice_detail", "view": false, "add": false, "edit": false },
    { "name": "Invoice", "view": false, "add": false, "edit": false },
    { "name": "Invoice_Generate", "view": false, "add": false, "edit": false },
    { "name": "Invoice_number", "view": false, "add": false, "edit": false },
    { "name": "Invoice_password", "view": false, "add": false, "edit": false },
    { "name": "Timesheet", "view": false },
    { "name": "Policy", "view": false, "add": false, "edit": false },
    { "name": "SOP", "view": false, "add": false, "edit": false },
    { "name": "Asset_category", "view": false, "add": false, "edit": false },
    { "name": "Asset_Subcategory", "view": false, "add": false, "edit": false },
    { "name": "Asset", "view": false, "add": false, "edit": false },
    { "name": "Expense_Category", "view": false, "add": false, "edit": false },
    { "name": "Expense", "view": false, "add": false, "edit": false },
    { "name": "Expense_Payment", "view": false, "add": false, "edit": false },
    { "name": "Verification", "view": false, "add": false, "edit": false },
    { "name": "Employee_Verifications", "view": false, "add": false, "edit": false },

]

CONSTANTS.allprevileges = [
    { "name": "Category", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Employee_Category", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Country", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Project", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Employee", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Holidays", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "PayrollItem", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Employee_salary", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Payslip", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Email", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Task_Board", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Leaves", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Employee_Leave", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Kycdocument", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Leave_Transection", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Monthly_payroll", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Earning", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Deduction", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Invoice_category", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Invoice_subcategory", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Invoice_detail", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Invoice", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Invoice_Generate", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Invoice_number", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Invoice_password", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Timesheet", "view": true },
    { "name": "Policy", "view": true, "add": true, "edit": true },
    { "name": "SOP", "view": true, "add": true, "edit": true },
    { "name": "Asset_category", "view": true, "add": true, "edit": true },
    { "name": "Asset_Subcategory", "view": true, "add": true, "edit": true },
    { "name": "Asset", "view": true, "add": true, "edit": true },
    { "name": "Expense_Category", "view": true, "add": true, "edit": true },
    { "name": "Expense", "view": true, "add": true, "edit": true },
    { "name": "Expense_Payment", "view": true, "add": true, "edit": true },
    { "name": "Verification", "view": true, "add": true, "edit": true },
    { "name": "Employee_Verifications", "view": true, "add": true, "edit": true },

]

export default CONSTANTS;
