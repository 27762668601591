
import React, { Component, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_HANDLE_ASSIGNINVENTORY_CHANGE, AC_RESET_ASSIGNINVENTORY, AC_LIST_ASSIGNINVENTORY } from '../../actions/assignInventoryAction';
import ImportedURL from '../../common/api';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import { Success, Error } from '../../common/swal';
import { FileAndImagevalidation, } from '../../common/validate';
import { AC_LIST_SERVICE } from '../../actions/assignInventoryAction';
import Swal from 'sweetalert2';


class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pushValue: '',
            modalType: '',
            redirect: false,
            // mainImage: [],
            redirect: false,
            pushValue: '',
            billUpload: [],
            billUploadArray: [],
            bill: false,
            billUploadError: false,
            save: false,
            service: {}

        }

    }

    componentDidMount() {
        this.props.AC_LIST_SERVICE();
    }

    AddDateChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false, service: { ...this.state.service, [name]: value } })
        // this.props.AC_HANDLE_ASSIGNINVENTORY_CHANGE(name, value);
        // if (name === 'invoiceDate', 'deliveryDate') {
        //     if (value) {
        //         this.setState({ [Error]: false });
        //     } else {
        //         this.setState({ [Error]: true })
        //     }
        // }
    }
    AddHandleChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        // this.props.AC_HANDLE_ASSIGNINVENTORY_CHANGE(name, value);
        this.setState({ [Error]: false, service: { ...this.state.service, [name]: value } })

        // if (name === 'invoiceNo', 'amount', 'remarks') {
        //     if (value) {
        //         this.setState({ [Error]: false });
        //     } else {
        //         this.setState({ [Error]: true })
        //     }
        // }
    }

    handleRemove = (i, value) => {
        this.setState({ spinner: false })
        const file = this.state.billUploadArray.filter((item, index) => index !== i);
        const Store = this.state.billUpload.filter((item, index) => index !== i);
        this.setState({ billUploadArray: file, billUpload: Store })
        if (value == 'editFirst') {
            const data = this.props.InventoryState.inventory.billUpload;
            const file = data.filter((item, index) => index !== i);
            this.props.AC_HANDLE_INVENTORY_CHANGE('billUpload', file);
        }
    }

    fileChange = (event) => {
        const { name, value } = event.target;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        this.setState({ existingImage: this.state.billUpload });
        // this.props.AC_HANDLE_MODELINVENTORY_CHANGE(name, event.target.files);

        const files = Array.from(event.target.files);
        const newfiles = [];
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            const imgvalidate = FileAndImagevalidation(file);
            if (imgvalidate) {
                newfiles.push(file)
            } else {
                Error((languageData && languageData.INVALID_FILE_DISCARDED ? languageData.INVALID_FILE_DISCARDED : 'Invalid file is discarded'));
            }
        }
        this.setState({ billUpload: [...this.state.billUpload, ...newfiles], billUploadError: false });


        Promise.all(newfiles.map(file => {
            return (new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', (ev) => {
                    resolve(ev.target.result);
                });
                reader.addEventListener('error', reject);
                reader.readAsDataURL(file);
            }));
        }))
            .then(images => {
                if (images.length > 0) {
                    // images.map((item) => {
                    this.setState({ billUploadArray: [...this.state.billUploadArray, ...images] })
                    // })
                }
            }, error => {
                console.error(error);
            });
    }

    onSubmit = e => {
        const data = this.state.service;
        const assetid = this.props.match.params.id;


        var valid = 1;
        if (!data.invoiceNo) {
            this.setState({ invoiceNoError: true });
            valid = 0;
        }
        if (!data.deliveryDate) {
            this.setState({ deliveryDateError: true });
            valid = 0;
        }
        if (!data.invoiceDate) {
            this.setState({ invoiceDateError: true });
            valid = 0;
        }
        if (!data.amount) {
            this.setState({ amountError: true });
            valid = 0;
        }
        // if (!data.remarks) {
        //     this.setState({ remarksError: true });
        //     valid = 0;
        // }
        if (!this.state.billUpload.length) {
            this.setState({ billUploadError: true });
            valid = 0;
        }



        if (valid == 1) {
            var formData = new FormData();
            formData.append("invoiceNo", data.invoiceNo);
            formData.append("amount", data.amount);
            formData.append("invoiceDate", data.invoiceDate);
            formData.append("deliveryDate", data.deliveryDate);
            formData.append("remarks", data.remarks ? data.remarks : '');
            formData.append("assetid", assetid);
            if (this.state.billUpload.length) {
                for (const file of this.state.billUpload) {
                    formData.append('billUpload', file);
                };
            }
            this.setState({ save: true })
            axios.post(ImportedURL.API.updateService, formData)
                .then((res) => {
                    Success(res.statusText);
                    this.props.history.push('/list-asset')
                    this.setState({ redirect: true, save: false });
                })
        }
    }


    render() {

        let data = this.state.service
        const photos = [];

        if (this.state.billUploadArray.length) {
            this.state.billUploadArray.map((image, i) => {
                let isImgtype = false;
                let name = (this.state.billUpload[i].name).split(".");
                isImgtype = name[name.length - 1] !== 'pdf'
                photos.push(
                    <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                        <div style={{ position: 'relative' }}>
                            <img src={isImgtype ? image : '../../assets/images/pdf.png'} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative', objectFit: 'contain' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                        </div>
                    </div>
                )
            })
        }

        return (
            <>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'>
                                            <i class="fa-solid fa-wand-magic-sparkles mr-2"></i>
                                        </div>
                                        <h3 className="card-title">Service Details</h3>
                                        <div class="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <ul className="nav nav-tabs page-header-tab"></ul>

                                                <div className="header-action">
                                                    <Link to='/list-asset'>
                                                        <button type="button" className="btn btn-primary" onClick={this.back}><i class="fa fa-solid fa-chevron-left mr-2"></i>Back</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form id='clear_form'>
                                            <div className="row clearfix">
                                                <div className="form-group col-lg-6 col-md-6">
                                                    <label className="form-label">Invoice Number<span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Invoice Number" name="invoiceNo" onChange={this.AddHandleChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.invoiceNoError ? "block" : 'none' }}>Invoice Number is required</div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6">
                                                    <label className="form-label">Amount<span style={{ color: 'red' }}>*</span></label>
                                                    <input type="number" className="form-control" placeholder="Amount" name="amount" onChange={this.AddHandleChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.amountError ? "block" : 'none' }}>Amount is required</div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6">
                                                    <label className="form-label">Invoice Date<span style={{ color: 'red' }}>*</span></label>
                                                    <input type="date" className="form-control" name="invoiceDate" onChange={this.AddDateChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.invoiceDateError ? "block" : 'none' }}>Invoice Date is required</div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6">
                                                    <label className="form-label">Delivery Date<span style={{ color: 'red' }}>*</span></label>
                                                    <input type="date" className="form-control" name="deliveryDate" onChange={this.AddDateChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.deliveryDateError ? "block" : 'none' }}>Delivery Date is required</div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Bill Upload<span style={{ color: 'red' }}>*</span>{this.state.bill ? <i className="fa fa-file-text-o documentIcon" data-action-type="View"></i> : ''}</label>
                                                        <input type="file" className="form-control" name="billUpload" accept="application/pdf,image/png,image/jpg,image/jpeg" multiple onChange={this.fileChange} />
                                                        <div className="invalid-feedback" style={{ display: this.state.billUploadError ? "block" : 'none' }}>Please Upload Bill</div>
                                                    </div>
                                                    <div className="photos_wid">
                                                        {photos}
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-6 col-md-6">
                                                    <label className="form-label">Remarks</label>
                                                    <textarea className="form-control" name="remarks" placeholder="Remarks" onChange={this.AddHandleChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.remarksError ? "block" : 'none' }}>Remarks is required</div>
                                                </div>

                                            </div>
                                            <div className="form-group" >
                                                {this.state.save ?
                                                    <button type="button" className="btn btn-primary" ><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn btn-primary" onClick={this.onSubmit}><i className="fe fe-save mr-2"></i>Save</button>
                                                }
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        )
    }
}
const mapStateToProps = state => ({
    assignInventoryReducer: state.assignInventoryReducer,
    AccountState: state.account
})
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        AC_HANDLE_ASSIGNINVENTORY_CHANGE, AC_LIST_SERVICE
    }, dispatch)

}
export default connect(mapStateToProps, mapDispatchToProps)(Service);
