
const initialState = {
    addDepartment: [],
    listDepartment: [],
    deleteDepartment: [],
    department: {
        name: '',
	},
}
function departmentReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_DEPARTMENT':
            return {
                ...state,
                department: action.payload
            }
            break;

        case 'LIST_DEPARTMENT':
            return {
                ...state,
                listDepartment: action.payload
            }
            break;
        case 'VIEW_DEPARTMENT':
            return {
                ...state,
                department: action.payload
            }
        case 'RESET_DEPARTMENT':
			return Object.assign({}, state, {
				department: initialState.department
			})
        case 'DELETE_DEPARTMENT':
            return {
                ...state,
                deleteDepartment: action.payload
            }
            break;
        case 'HANDLE_DEPARTMENT_CHANGE': 
            return Object.assign({}, state, {
                department: {
                    ...state.department,
                    [action.name]: action.value
                }
            })
        default:
            return state;
            break;
    }
}

export default departmentReducer;