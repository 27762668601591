import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import moment from 'moment';
import { Currency, Emailvalidate } from '../../../common/validate';
import { Urlvalidate, NumberOnly } from '../../../common/validate';
import ImportedUrl from '../../../common/api';
import Spinner from 'react-bootstrap/Spinner';

const initialState = {
    id: '',
    invoicedata: {},
    paymentdata: { paymentmethod: 'creditcard' }
}
class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id, editSpi: false });
        this.props.resetinvioce();
        // this.props.listEmppayrollitem();

        let date = new Date();
        let nextmonth = date.setMonth(date.getMonth() + 1);
        let invoicedate = moment(new Date()).format("YYYY-MM") + "-25"
        let duedate = moment(nextmonth).format("YYYY-MM") + "-05"
        this.props.handleChange('invoicedate', invoicedate)
        this.props.handleChange('duedate', duedate)

        const ListInvoice = this.props.invoicedetailState.listinvoicecdetail;
        let defaultCompany = ListInvoice.find(o => o.default && o.status)
        if (defaultCompany)
            this.props.handleChange('company', defaultCompany._id)

        this.setState({ nameError: false, discountnameError: false, previousbalance: 0 })
        this.setState({ categoryError: false })
        this.setState({ subcategoryError: false })
        this.setState({ addressError: false })
        this.setState({ toaddressError: false })
        this.setState({ amountError: false })
        this.setState({ invoicedateError: false })
        this.setState({ duedateError: false })
        this.setState({ urlError: false })
        this.setState({ discountError: false })
        this.setState({ companyError: false })
        this.setState({ tocompanyError: false })
        this.setState({ categoryError: false })
        this.setState({ subcategoryError: false })
        this.setState({ invalidurlError: false })
        this.setState({ tocompanyimageError: false })
        this.setState({ newpasswordError: false })
        this.setState({ confirmpasswordError: false })
        this.setState({ image: '' })
        // document.getElementById('imageSet').value = '';
        this.setState({ newpassword: '' });
        this.setState({ confirmpassword: '' })

    }


    handleSelectChange = (e) => {
        this.setState({ spinner: false })
        const { name, value } = e;

        const data = this.props.invoiceState.invoice;
        this.props.handleChange(name, value)
        if (name == "company") {
            this.setState({ companyError: false })

        }

    }

    componentDidMount() {
        axios.get(ImportedURL.API.getInvoice + '/' + this.props.match.params.id)
            .then((res) => {
                if (res.data && res.data.success) {
                    var discountAmount = 0;
                    if (res.data.data.discounttype && res.data.data.discounttype === 'percentage')
                        discountAmount = res.data.data.amount - ((res.data.data.discount / 100) * res.data.data.amount);
                    else
                        discountAmount = res.data.data.amount - res.data.data.discount;

                    if (res.data.data.previousbalance)
                        discountAmount += res.data.data.previousbalance

                    this.setState({ invoicedata: res.data.data, paymentdata: { ...this.state.paymentdata, amountpaid: discountAmount } })
                }
            }).catch((err) => { console.log(err); })


    }
    phonenumberformat = (number) => {
        if (number) {

            var newArray = number.match
                (/^(91|)?(\d{3})(\d{3})(\d{4})$/);

            // Checking the international code
            var intlCountryCode = (newArray[1] ? '+91' : '');
            // the international number
            var internationalNumber = intlCountryCode +
                ' (' + newArray[2] + ') ' + newArray[3]
                + '-' + newArray[4];

            return internationalNumber
        } else return ''
    }

    handleOnchange = (e) => {
        this.setState({ spinner: false })
        this.setState({ editSpi: false })
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        if (name === 'creditcardexpirydate') {
            const expDateFormatter = value.replace(/\//g, "").substring(0, 2) +
                (value.length > 2 ? '/' : '') +
                value.replace(/\//g, "").substring(2, 6);

            this.setState({ paymentdata: { ...this.state.paymentdata, [name]: expDateFormatter } })
        }
        else {
            this.setState({ paymentdata: { ...this.state.paymentdata, [name]: value } })
        }
    }

    submitattachment = () => {
        let valid = 1;
        let data = this.state.paymentdata;
        let reuqireddatachequeach = ['bankaccountname', 'bankname', 'bankroutingnumber', 'bankaccountnumber'];
        let reuqireddatacredit = [
            // 'creditcardname', 'creditcardissuer', 
            'creditcardnumber', 'creditcardexpirydate', 'creditcardcvv'];
        if (data.paymentmethod) {

            if (data.paymentmethod === 'creditcard')
                for (let item of reuqireddatacredit)
                    if (!data[item]) {
                        this.setState({ [item + 'Error']: true });
                        valid = 0
                    }


            if (!data.amountpaid) {
                this.setState({ amountpaidError: true });
                valid = 0
            }

            if (valid) {
                var formData = {
                    amountpaid: data.amountpaid,
                    creditcardname: data.creditcardname,
                    creditcardissuer: data.creditcardissuer,
                    creditcardnumber: data.creditcardnumber,
                    creditcardexpirydate: data.creditcardexpirydate,
                    creditcardcvv: data.creditcardcvv,
                    paymentmethod: 'creditcard',
                    client: this.state.invoicedata.tocompanyid
                };
                // formData.append("amountpaiddate", data.amountpaiddate);
                Swal.fire({
                    title: 'Are you sure to proceed with the payment?',
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                    imageUrl: '../../../assets/images/status.png',
                    customClass: {
                        popup: 'swal_pop',
                        title: 'swal_title',
                        image: 'swal_image',
                        actions: 'swal_action',
                        confirmButton: 'swal_confirm',
                        cancelButton: 'swal_close',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ saving: true })

                        axios.post(ImportedURL.API.addPaymentInvoice + '/' + this.props.match.params.id, formData)
                            .then((res) => {
                                this.setState({ saving: false })
                                if (res.data.success) {
                                    axios.get(ImportedURL.API.getInvoice + '/' + this.props.match.params.id)
                                        .then((res) => {
                                            if (res.data && res.data.success) {

                                                this.setState({ invoicedata: res.data.data })
                                            }
                                        }).catch((err) => { console.log(err); })

                                    Success(res.data.responsetext)
                                    let btn = document.getElementById("closeModalAttachment");
                                    btn.click();
                                } else {
                                    Error(res.data.responsetext)
                                }

                                // this.setState({ paymentattachment: '' })

                            }).catch((err) => { console.log(err); this.setState({ saving: false }) })
                    }
                })



            }
        }
        else {
            this.setState({ 'paymentmethodError': true });

        }


    }

    render() {

        const spinner = this.state.spinner;


        let data = this.state.paymentdata
        let invoiceData = this.state.invoicedata
        const d = new Date(invoiceData.invoicedate);
        let year = d.getFullYear();
        var discountAmount = 0;
        if (invoiceData.discounttype && invoiceData.discounttype === 'percentage')
            discountAmount = invoiceData.amount - ((invoiceData.discount / 100) * invoiceData.amount);
        else
            discountAmount = invoiceData.amount - invoiceData.discount;

        if (invoiceData.previousbalance)
            discountAmount += invoiceData.previousbalance

        return (
            <div>
                {/* ==================== Update paid amount ===================== */}
                <div className="modal fade" id="amountpaidupdate" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ zIndex: 1030 }}>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>Credit card details</h5>
                                <button type="button" id="closeModalAttachment" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    {/* <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Payment Method</label>
                                            <select type="text" className="form-control" disabled placeholder="Discount" name="paymentmethod" onChange={this.handleOnchange} value={data.paymentmethod ? data.paymentmethod : ''} >
                                                <option value=''>Select Payment Method</option>
                                                <option value='cash'>Cash</option>
                                                <option value='cheque'>Cheque</option>
                                                <option value='creditcard'>Credit Card</option>
                                                <option value='ach'>ACH</option>
                                            </select>
                                            <div className="invalid-feedback" style={{ display: this.state.paymentmethodError ? "block" : 'none' }}>Payment method is required</div>
                                        </div>
                                    </div> */}


                                    <>
                                        {/* <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Credit card name</label>
                                                <input type="text" className="form-control" placeholder="Name of account" name="creditcardname" onChange={this.handleOnchange} value={data.creditcardname} />
                                                <div className="invalid-feedback" style={{ display: this.state.creditcardnameError ? "block" : 'none' }}>Name of account is required</div>
                                            </div>
                                        </div> */}
                                        {/* <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Credit card issuer <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="text" className="form-control" placeholder="Credit card issuer" name="creditcardissuer" onChange={this.handleOnchange} value={data.creditcardissuer} />
                                                <div className="invalid-feedback" style={{ display: this.state.creditcardissuerError ? "block" : 'none' }}>Credit card issuer  is required</div>
                                            </div>
                                        </div> */}
                                        <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Credit card number <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="number" className="form-control" placeholder="Credit card number" name="creditcardnumber" onChange={this.handleOnchange} value={data.creditcardnumber} />
                                                <div className="invalid-feedback" style={{ display: this.state.creditcardnumberError ? "block" : 'none' }}>Credit card number is required</div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Expiry date<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="text" className="form-control" placeholder="MM/YYYY" name="creditcardexpirydate" onChange={this.handleOnchange} value={data.creditcardexpirydate} />
                                                <div className="invalid-feedback" style={{ display: this.state.creditcardexpirydateError ? "block" : 'none' }}>Expiry date is required</div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">CVV<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="number" className="form-control" placeholder="Credit card cvv" name="creditcardcvv" onChange={this.handleOnchange} value={data.creditcardcvv} />
                                                <div className="invalid-feedback" style={{ display: this.state.creditcardcvvError ? "block" : 'none' }}>CVV is required</div>
                                            </div>
                                        </div>
                                    </>


                                    <>
                                        <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Amount to pay<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="number" className="form-control" placeholder="Amount Received" name="amountpaid" onChange={this.handleOnchange} value={data.amountpaid ? data.amountpaid : ''} />
                                                <div className="invalid-feedback" style={{ display: this.state.amountpaidError ? "block" : 'none' }}>Invoice Amount is required</div>
                                                <div className="invalid-feedback" style={{ display: this.state.amountpaidnumbermustError ? "block" : 'none' }}> Only accept  number </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Amount received Date <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <div className='site_pass'>
                                                    <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="amountpaiddate" onChange={this.handleOnchange} value={data.amountpaiddate ? moment.utc(data.amountpaiddate).format('YYYY-MM-DD') : ''} />
                                                    <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                    <div className="invalid-feedback" style={{ display: this.state.amountpaiddateError ? "block" : 'none' }}>Date required</div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </>

                                </div>
                            </div>
                            <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                <div className="text-right payrollgen_btn">
                                    {this.state.saving ?
                                        <button type="button" className="btn btn-primary"><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                        :
                                        <button type="button" className="btn btn-primary" onClick={this.submitattachment}><i className="fe fe-save mr-2"></i>Save</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* =============================== */}

                <div >
                    {/* <div style={{ visibility: 'hidden', overflow: "hidden", height: 0 }}> */}
                    {invoiceData && invoiceData.company ?

                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel"></div>
                                <div className='card' id='print_pagepdf' style={{ fontFamily: 'Arial' }}>

                                    <div className="card-header">
                                        <div className='rounded_icon'><i className="fa fa-file mr-2"></i></div>
                                        <h3 className="card-title">Invoice</h3>
                                        <div class="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <ul className="nav nav-tabs page-header-tab"></ul>
                                                <div className="header-action">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='card-body invoice_generate'>
                                        <div className='row print_design' >
                                            <div className='payslip_content'>
                                                <p className='payslip_heading'>
                                                    INVOICE FOR THE MONTH OF  {moment.utc(d).format('MMMM').toUpperCase() + " " + year}
                                                </p>
                                            </div>
                                            <div className='col-lg-8'>
                                                <div className='invoice_address' style={{ fontSize: '20px' }}>
                                                    <h4 style={{ fontSize: '35px' }}>{invoiceData.companyname}</h4>
                                                    <p>{invoiceData.companyaddress}</p>
                                                    <p>{invoiceData.companycity}</p>
                                                    <p>{invoiceData.companystate + " " + invoiceData.code}</p>
                                                    <p>
                                                        <a href='#' style={{ color: '#0096FF' }}>{invoiceData.invoiceurl}</a>
                                                    </p>
                                                    <a href={`mailto:${invoiceData.email}`} style={{ color: '#0096FF' }}>{invoiceData.company.email}</a>
                                                    <p className='pt-1'>
                                                        {/* <a href={'tel:' + invoiceData.company.phonenumber}>{invoiceData.company.phonenumber}</a> */}
                                                        {this.phonenumberformat(invoiceData.company.phonenumber)}
                                                    </p>

                                                </div>
                                            </div>
                                            <div className='col-lg-4 pt-4'>
                                                <div className='payslip_content pt-4'>
                                                    <div className='payslip_company_intro'>
                                                        <div className='company_logo'>
                                                            <img src={ImportedURL.LIVEURL + invoiceData.companyimage} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='invoice_detail_billto print_design mt-3'>
                                            <div className='row'>
                                                <div className='col-lg-6 ' style={{ fontSize: '20px', lineHeight: '1.2' }}>
                                                    <h4 className='invoice_detail_billto_heading'>BILL TO</h4>
                                                    <p className='dynaic_tex_invoice mb-1'>{invoiceData.tocompany}</p>
                                                    <p className='dynaic_tex_invoice'>{invoiceData.toaddress}</p>
                                                </div>
                                                <div className='col-lg-6 ' style={{ fontSize: '20px', lineHeight: '1.2' }}>
                                                    <h4 className='invoice_detail_billto_heading inv_det'>INVOICE DETAILS</h4>
                                                    <div className='detslin'>
                                                        <p className='invoice_heading_tex'>Invoice #</p>
                                                        <p className='dynaic_tex_invoice'>{invoiceData.invoiceId}</p>
                                                    </div>
                                                    <div className='detslin mt-2'>
                                                        <p className='invoice_heading_tex'>Invoice Date</p>
                                                        <p className='dynaic_tex_invoice'>{moment.utc(invoiceData.invoicedate).format('MM/DD/YYYY')}</p>
                                                    </div>
                                                    <div className='detslin mt-2'>
                                                        <p className='invoice_heading_tex'>Due Date</p>
                                                        <p className='dynaic_tex_invoice_due'>{moment.utc(invoiceData.duedate).format('MM/DD/YYYY')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='invoice_detail print_design mt-5' style={{ fontSize: '20px' }}>
                                            <div className='table_invoice'>
                                                <div className='row tab_inv'>
                                                    <div className='col-lg-5 col_border_right'>
                                                        <h6 className='desc_h6'>Description</h6>
                                                        {invoiceData.category && invoiceData.category.map((item) => {
                                                            return (
                                                                <>
                                                                    <h5 className='desc_h5'>{item.name}</h5>
                                                                    <>
                                                                        {
                                                                            (invoiceData.subcategory)
                                                                            &&
                                                                            invoiceData.subcategory.map((item1) => {
                                                                                if (item._id == item1.category)
                                                                                    return (
                                                                                        <>
                                                                                            <p className='desc_p'>{item1.subcategory}</p>
                                                                                        </>
                                                                                    )
                                                                            })
                                                                        }
                                                                    </>

                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className='col-lg-2 col_border_right'>
                                                        <h6 className='desc_h6'>Month</h6>
                                                        <p className='next_tab_div'>{moment.utc(d).format('MMMM') + " - " + moment.utc(invoiceData.invoicedate).format('YYYY')}</p>
                                                    </div>
                                                    <div className='col-lg-3 col_border_right'>
                                                        <h6 className='desc_h6'>Url</h6>
                                                        <a href='#' className='next_tab_div' style={{ color: '#0096FF' }}>{invoiceData.url ? invoiceData.url : '-'}</a>
                                                    </div>
                                                    <div className='col-lg-2 col_border_right'>
                                                        <h6 className='desc_h6'>Total</h6>
                                                        <p className='next_tab_div'>{"$" + Currency((invoiceData.amount).toString())}</p>

                                                    </div>
                                                </div>
                                                {invoiceData.discount && Number(invoiceData.discount) ?
                                                    <div className='invoice_dis print_subtotal'>
                                                        <div className='row'>
                                                            <div className='col-lg-10'>
                                                                <p className='dis_Name'>
                                                                    {invoiceData.discountname ? invoiceData.discountname : 'Multiple Property Discount'} Applied
                                                                </p>

                                                            </div>
                                                            <div className='col-lg-2'>
                                                                {invoiceData.discounttype && invoiceData.discounttype === 'percentage' ? <p className='Disc_amount'>-{invoiceData.discount}%</p> : <p className='Disc_amount'>{invoiceData.discount ? " - " + Currency((invoiceData.discount).toString()) : 0}</p>}
                                                            </div>
                                                        </div>
                                                    </div> : ''}
                                                {invoiceData.previousbalance && Number(invoiceData.previousbalance) ?
                                                    <div className='invoice_dis print_subtotal'>
                                                        <div className='row'>
                                                            <div className='col-lg-10'>
                                                                <p className='dis_Name'>
                                                                    Outstanding balance
                                                                </p>

                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <p className='Disc_amount'>{Currency((invoiceData.previousbalance).toString())}</p>
                                                            </div>
                                                        </div>
                                                    </div> : ''}
                                                <div className='invoice_dis subtotal_invoice'>
                                                    <div className='row justify-content-end' style={{ border: "none" }}>
                                                        <div className='col-lg-2'>
                                                            <p className='dis_Name'>
                                                                SUBTOTAL
                                                            </p>

                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <p className='Disc_amount'>{discountAmount ? Currency((discountAmount).toString()) : 0}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='invoice_dis subtotal_invoice'>
                                                    <div className='row justify-content-end' style={{ border: "none" }}>
                                                        {!invoiceData.amountpaidstatus ? <div className='col-lg-2' style={{ border: 'none' }}>
                                                            <p className='balance_name_invoice pt-2 pr-4'>
                                                                <button type="submit" className="btn btn-primary" data-toggle="modal" data-target="#amountpaidupdate">Pay now</button>
                                                            </p>

                                                        </div> : ''}
                                                        <div className='col-lg-2'>
                                                            <p className='balance_name_invoice'>
                                                                BALANCE DUE
                                                            </p>

                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <p className='Disc_amount current_amount_invoice'><i class="fa fa-usd" aria-hidden="true"></i>{discountAmount ? Currency((discountAmount).toString()) : 0}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {spinner ?
                                            <div className='common_loader_ag_grid'>
                                                <img className='loader_img_style_common_ag_grid' src='../../../assets/images/banner-logo.png' />
                                                <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                </Spinner>
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>

                        : ''
                    }
                </div>

            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    invoiceState: state.invoice,
    invoicedetailState: state.invoicedetail,
    invoicesubcategoryState: state.invoicesubcategory,
    invoicecategoryState: state.invoicecategory,
    AccountState: state.account,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);


