import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import { AC_HANDLE_HOLIDAY_CHANGE, AC_LIST_HOLIDAY, AC_VIEW_HOLIDAY, AC_RESET_HOLIDAY } from '../../../actions/holidayAction';
import moment from 'moment';


const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    viewFlag: false,
    flagError: false,
    pushValue: '',
    columnDefs: [
        { headerName: 'Holiday', field: 'name', width: 130, floatingFilter: true },
        {
            headerName: 'Date', field: 'date', width: 130, floatingFilter: true,
            cellRenderer: function (params) {
                return moment(params.data.date).format('DD/MM/YYYY')
            }
        },
        {
            headerName: 'Day', field: 'date', width: 130, floatingFilter: true,
            cellRenderer: function (params) {
                return moment(params.data.date).format('dddd ')
            }
        },
        {
            headerName: 'Status', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class Listholidays extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.props.resetholiday();
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.viewholiday(event.data._id);
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.deleteHoliday + "/" + event.data._id)
                        .then((data) => {
                            this.props.listholiday();
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'holidays' })
                        .then((data) => {
                            this.props.listholiday();
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'date',],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { holidayState } = this.props;
        const exportData = holidayState.listholiday
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Employee Details";
        const headers = [['#', 'Holiday name', 'Holiday Date',]];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.name,
            elt.date,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Holidays.pdf")
    }


    componentDidMount() {
        this.props.listholiday();
        localStorage.setItem('invoicetoken', "");
    }
    onChange = (e) => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        this.props.AC_HANDLE_HOLIDAY_CHANGE(name, value);
        if (name === "name") {
            if (value) {
                this.setState({ [Error]: false })
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name === "date") {
            if (value) {
                this.setState({ [Error]: false })
            } else {
                this.setState({ [Error]: true })
            }
        }
    }
    submit = () => {
        const data = this.props.holidayState.holiday;
        if (!data.name) {
            this.setState({ nameError: true });
        }
        if (!data.date) {
            this.setState({ dateError: true });
        }
        var formdata = {
            name: data.name,
            date: data.date,
        }
        if (formdata.name && formdata.date) {
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addHoliday, formdata)
                    .then((formdata) => {
                        Success(formdata.statusText);
                        this.setState({ save: false });
                        this.props.listholiday();
                        this.props.resetholiday();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateHoliday + '/' + this.state.id, formdata)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.listholiday();
                        this.props.resetholiday();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }
    render() {
        const { holidayState } = this.props;
        const rowData = holidayState.listholiday;
        const data = holidayState.holiday;


        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listholiday"><h6> Holiday List</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">List Holiday</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>

                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Modal */}
                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel"><i className="fa fa-flag mr-2"></i>{this.state.modalType} Holiday</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                        <img src='../../assets/images/cancel.png' />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Holiday Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onChange} value={data.name} placeholder="Holiday Name" />
                                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Holiday Name is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group'>
                                                            <label className="form-label">Holiday Date<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <div className='site_pass'>
                                                                <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="date" value={moment(data.date).format('YYYY-MM-DD')} onChange={this.onChange} />
                                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}>Date is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    holidayState: state.holiday
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { AC_HANDLE_HOLIDAY_CHANGE, listholiday: AC_LIST_HOLIDAY, viewholiday: AC_VIEW_HOLIDAY, resetholiday: AC_RESET_HOLIDAY }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Listholidays);

