import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';
import { Link, Redirect } from 'react-router-dom';


const ADD_ASSIGNINVENTORY = 'ADD_ASSIGNINVENTORY';
const LIST_ASSIGNINVENTORY = 'LIST_ASSIGNINVENTORY';
const VIEW_ASSIGNINVENTORY = 'VIEW_ASSIGNINVENTORY';


export function AC_LIST_ASSIGNINVENTORY() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listAssignInventory)
            .then(({ data }) => {
                dispatch({ type: LIST_ASSIGNINVENTORY, payload: data });
            })
    }
}

export function AC_VIEW_ASSIGNINVENTORY(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewAssignInventory + "/" + id)
            .then(({ data }) => { 
                dispatch({ type: VIEW_ASSIGNINVENTORY, payload: data });
            });
    }
}

export function AC_RESET_ASSIGNINVENTORY() {
	return function (dispatch) {
		dispatch({ type: "RESET_ASSIGNINVENTORY" })
	};
}

export function AC_HANDLE_ASSIGNINVENTORY_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_ASSIGNINVENTORY_CHANGE", name: name, value: value })
    };
}

// ---------stock
export function AC_LIST_STOCK() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listStock)
            .then(({ data }) => {
                dispatch({ type: "LIST_STOCK", payload: data });
            })
    }
}

// ---------service
export function AC_LIST_SERVICE() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listService)
            .then(({ data }) => {
                dispatch({ type: "LIST_SERVICE", payload: data });
            })
    }
}

export function AC_VIEW_SERVICE(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewService + "/" + id)
            .then(({ data }) => { 
                dispatch({ type: 'VIEW_SERVICE', payload: data });
            });
    }
}

// ---------damage
export function AC_LIST_DAMAGE() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listDamage)
            .then(({ data }) => {
                dispatch({ type: "LIST_DAMAGE", payload: data });
            })
    }
}

