import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_LEAVE_TEMPLATE_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_LEAVE_TEMPLATE_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_LEAVE_TEMPLATE() {
    return function (dispatch) {
        dispatch({ type: "RESET_LEAVE_TEMPLATE" })
    };
}


export function AC_LIST_LEAVE_TEMPLATE() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listLeaveTemplate)
                .then((res) => {
                    dispatch({ type: "LIST_LEAVE_TEMPLATE", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_LEAVE_TEMPLATE(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewLeaveTemplate + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_LEAVE_TEMPLATE", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


export function AC_EMPTY_LEAVE_TEMPLATE() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_JOBTEMPLATE" })
    }
}