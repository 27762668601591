import React, { Component } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_LIST_DEPARTMENT, AC_VIEW_DEPARTMENT, AC_HANDLE_DEPARTMENT_CHANGE, AC_RESET_DEPARTMENT } from '../../../actions/departmentAction';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import { Success, Error } from '../../../common/swal';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Select from 'react-select';
import moment from 'moment';


const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "Add",
    save: false,
    columnDefs: [
        { headerName: 'Policy Name', field: 'name', floatingFilter: true, width: 170, },
        { headerName: 'Version', width: 130, field: 'version', floatingFilter: true },
        {
            headerName: 'Prepared By', field: 'preparedby', floatingFilter: true, width: 150, hide: false,
            valueGetter: function (params) {
                if (params.data.preparedby) return `${params.data.preparedby} (${params.data.preparedbyid})`
                else return '-'
            }
        },
        {
            headerName: 'Recommended By', field: 'recommendedby', floatingFilter: true, width: 170,
            valueGetter: function (params) {
                if (params.data.recommendedby) return `${params.data.recommendedby} (${params.data.recommendedbyid})`
                else return '-'
            }
        },
        {
            headerName: 'Approver', field: 'approvedby', floatingFilter: true, width: 150, hide: true,
            valueGetter: function (params) {
                if (params.data.approvedby) return `${params.data.approvedby} (${params.data.approvedbyid})`
                else return '-'
            }
        },
        {
            headerName: 'Effective Date', field: 'effectivedate', filter: false, sortable: false, width: 150,
            valueGetter: function (params) {
                if (params.data.effectivedate) return moment(params.data.effectivedate).format('MM/DD/YYYY')
                else return '-'
            }
        },
        {
            headerName: 'Status', width: 130, field: 'status', cellStyle: { textAlign: 'center' }, filter: false, sortable: false,
            cellRenderer: function (params) {
                if (params.data.status == 'active') {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else if (params.data.status == 'inactive') {
                    return '<span type="button" style="margin-top: 5px" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                } else {
                    // } else if (params.data.status == 'hold') {
                    return '<span type="button" style="margin-top: 5px; color:#FABD09; border:2px solid #FABD09" class="tag tag-danger" data-action-type="Status">Hold</span>';
                }
            }
        },
        {
            headerName: 'Approval', field: 'approved', width: 110, headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' }, filter: false, sortable: false,
            cellRenderer: function (params) {
                if (params.data.approved) return '<span type="button" class="tag tag-success" data-action-type="Approval">Approved</span>'
                else return '<span type="button" class="tag tag-warning" data-action-type="Approval">Pending</span>'
            }
        },
        {
            headerName: 'Actions', field: 'actions', cellStyle: { textAlign: 'center' }, filter: false, sortable: false, width: 150,
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],

    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
    policyList: [],
    policyRequestsList: [],
    policydata: {},
    showapprove: true
}
class ListDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            const location = this.props.match.path;
            this.props.history.push(`edit-company-sop/${event.data._id}?type=${location == '/company-sop-requests' ? 'requests' : 'approval'}`)
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.softDeletePolicy + "/" + event.data._id)
                        .then((data) => {
                            Success('Deleted successfully');
                            this.getPolicyData()
                            this.getPolicyRequests();
                        })
                }
            })
        }
        if (value === 'Status') {
            let inputoptions = {
                active: 'Active',
                hold: 'Hold',
                inactive: 'Inactive',
            }
            delete inputoptions[event.data.status]
            Swal.fire({
                title: 'Are you sure to change the status?',
                input: 'select',
                inputOptions: inputoptions,
                inputLabel: 'Select a status',
                customClass: {
                    input: 'inline-flex',
                    inputLabel: 'inline-flex'
                },
                showCancelButton: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: result.value, model: 'sops' })
                            .then((data) => {
                                this.getPolicyData();
                                Success('Status updated successfully')
                                this.getPolicyRequests();

                            }).catch(({ response }) => { if (response) Error('Status update failed') });
                    }
                })

        }
        if (value === 'Approval' && this.state.showapprove) {
            Swal.fire({
                title: event.data.approved ? 'Are you sure to revert the approval?' : 'Are you sure to Approve this SOP?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.simpleChanges, { params: { id: event.data._id, model: 'sops' }, data: { approved: !event.data.approved, } })
                        .then((data) => {
                            this.getPolicyData()
                            Success('Approval updated successfully')
                        }).catch(({ response }) => {
                            if (response.status == 500) {
                                Error(response.status + ' Internal Server Error')
                            } else if (response.status == 502) {
                                Error(response.status + ' Bad Gateway')
                            } else if (response.status == 400) {
                                Error('Bad request')
                            } else {
                                Error('Approval update failed')
                            }
                        });
                }
            })

        }
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }


    componentDidMount() {
        const location = this.props.match.path;
        if (location == '/company-sop-requests')
            this.setState({ showapprove: false })

        this.getPolicyData();
        this.getPolicyRequests();
        localStorage.setItem('invoicetoken', "");
    }

    getPolicyData = () => {
        axios.get(ImportedURL.API.listSopsApproval)
            .then((res) => {
                this.setState({ policyList: res.data })
            }).catch((err) => {
                console.log(err);
            });
    }

    getPolicyRequests = () => {
        axios.get(ImportedURL.API.listSopsApproval, { params: { type: 'requests' } })
            .then((res) => {
                this.setState({ policyRequestsList: res.data })
            }).catch((err) => {
                console.log(err);
            });
    }

    componentDidUpdate(prevProps, prevState) {

        // To set data once grid api is updated
        if (prevState.showapprove != this.state.showapprove) {

            if (this.gridColumnApi)
                if (this.state.showapprove) {
                    this.gridColumnApi.setColumnVisible('approvedby', false)
                    this.gridColumnApi.setColumnVisible('preparedby', true)

                } else {
                    this.gridColumnApi.setColumnVisible('approvedby', true)
                    this.gridColumnApi.setColumnVisible('preparedby', false)

                }
        }

        const location = this.props.match.path;
        if (this.gridColumnApi && location == '/company-sop-requests' && !this.state.changed) {
            this.gridColumnApi.setColumnVisible('approvedby', true)
            this.gridColumnApi.setColumnVisible('preparedby', false)
            this.setState({ changed: true })
        }
    }

    render() {
        const { fixNavbar, departmentReducer, AccountState } = this.props;
        const rowData = this.state.policyList;
        const rowRequestData = this.state.policyRequestsList;
        const data = this.state.policydata;

        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "SOP" });
        const editOption = true ? '<button type="button"  class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>' : '';
        const deleteOption = true ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>' : '';

        rowData.forEach(object => {
            const preview = `<a href=${ImportedURL.LIVEURL + object.file} target=_blank><button type="button"  class="btn btn-icon" ><i class="fa fa-file-pdf" style="font-size:20px;color: #1DC9B7 !important" data-toggle="tooltip" title="Preview"></i></button></a>`;
            const template =
                '<div>'
                // '<button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                + preview
                + editOption
                + deleteOption
                + '</div>'
            object.actions = template;
        });
        rowRequestData.forEach(object => {
            const preview = `<a href=${ImportedURL.LIVEURL + object.file} target=_blank><button type="button"  class="btn btn-icon" ><i class="fa fa-file-pdf" style="font-size:20px;color: #1DC9B7 !important" data-toggle="tooltip" title="Preview"></i></button></a>`;
            const editOption = !object.approved ? '<button type="button"  class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>' : '';
            const template =
                '<div>'
                // '<button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                + preview
                + editOption
                + deleteOption
                + '</div>'
            object.actions = template;
        });
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/company-policy"><h6> Policy</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i class="fa-sharp fa-solid fa-file-text mr-2"></i>
                                            </div>
                                            <h3 className="card-title">{this.state.showapprove ? 'SOP Reviews' : 'MY Requests'} </h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="header-action">
                                                        <Link to={'/company-sop'}>
                                                            <button type="button" className="btn btn-primary" id='Add'><i className="fa fa-chevron-left mr-2 mt-2" id='Add' />Back</button>
                                                        </Link>
                                                        {AccountState.account.issopapprover && AccountState.account.issopcreator ? this.state.showapprove ?
                                                            <button type="button" className="btn button_auto_front_desk mr-2" onClick={() => { this.setState({ showapprove: false }) }} ><i className="fa fa-question-circle mr-2 mt-2" id='Add' />Requests</button>
                                                            :
                                                            <button type="button" className="btn button_auto_front_desk mr-2" onClick={() => { this.setState({ showapprove: true }) }} ><i className="fa fa-check-circle mr-2 mt-2" id='Add' />Approvals</button>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    {this.state.showapprove ? <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>

                                                    </AgGridReact> :
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={25}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={rowRequestData}
                                                            columnDefs={this.state.columnDefs}
                                                            onGridReady={this.onGridReady}>

                                                        </AgGridReact>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}



const mapStateToProps = (state) => ({
    departmentReducer: state.departmentReducer,
    AccountState: state.account,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AC_LIST_DEPARTMENT, AC_VIEW_DEPARTMENT, AC_HANDLE_DEPARTMENT_CHANGE, AC_RESET_DEPARTMENT
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListDepartment);