import React, { Component, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_HANDLE_EMPLOYEE_CHANGE, AC_RESET_EMPLOYEE, AC_LIST_EMPLOYEE, AC_LIST_EMPLOYEE_ROLE, AC_VIEW_EMPLOYEE, AC_HANDLE_EMPLOYEE_INFO_CHANGE, AC_HANDLE_EMPLOYEE_INFO_MULTIPLE_UPDATE, AC_HANDLE_EDUCATION_INFO_MULTIPLE_UPDATE, AC_HANDLE_EDUCATION_INFO_CHANGE, AC_HANDLE_EXPERIENCE_INFO_CHANGE, AC_HANDLE_EXPERIENCE_INFO_MULTIPLE_UPDATE } from '../../actions/employeeAction';
import ImportedURL from '../../common/api';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import { AC_LIST_KYC } from '../../actions/kycdocumentAction';
import Select from 'react-select';
import { Row } from 'react-bootstrap';
import { Success, Error } from '../../common/swal';
import { Emailvalidate, Phonenumber, Validpassword, ImageFilevalidation } from '../../common/validate';
import { AC_LIST_DEPARTMENT } from '../../actions/departmentAction';
import { AC_LIST_DESIGNATION } from '../../actions/designationAction';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { AC_LIST_DIVISION } from '../../actions/divisionAction';
import { AC_ACCOUNT_DETAILS, AC_SETTING_DETAIL } from '../../actions/accountAction';



// import { AC_ACCOUNT_DETAILS } from '../../actions/accountAction';
import Swal from 'sweetalert2';
import moment from 'moment';
import pdf from '../../images/pdf.png'
import doc from '../../images/doc.png'
class AddEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            pushValue: '',
            modalType: '',
            redirect: false,
            images: [],
            imageArray: [],
            existingImage: [],
            imagesArr: [],
            pushValue: '',
            image: '',
            ccLocal: true,
            save: false,
            passwordhide: false,
            roleOption: '',
            phoneNoPatternError: false,
            managerID: '',
            employeecategorylist: []
        }
        this._onChange = this._onChange
    }

    componentDidMount() {
        this.props.AC_LIST_EMPLOYEE_ROLE({ role: "Manager" });
        this.props.AC_RESET_EMPLOYEE();
        this.props.AC_LIST_COUNTRY();
        this.props.AC_LIST_DEPARTMENT();
        this.props.AC_LIST_DESIGNATION();
        this.props.AC_LIST_EMPLOYEE();
        this.props.AC_LIST_KYC();
        this.props.AC_LIST_CATEGORY();
        this.props.AC_LIST_DIVISION();
        this.props.AC_ACCOUNT_DETAILS();
        this.props.AC_SETTING_DETAIL();

        axios.get(ImportedURL.API.listEmployeeCategory)
            .then((res) => {
                this.setState({ employeecategorylist: res.data });

            }).catch(({ response }) => {
            });

        localStorage.setItem('invoicetoken', "");

        const account = this.props.AccountState.account;
        this.props.AC_HANDLE_EMPLOYEE_CHANGE("cc", account.phonecode);


        axios.get(ImportedURL.API.viewManager)
            .then((res) => {
                this.setState({ managerID: res.data._id })
            }).catch(({ response }) => { Error(response.statusText) });

        const { params, path } = this.props.match;

        if (path === "/editemployee/:id") {
            this.setState({ modalType: "Edit", id: params.id })
        } else {
            this.setState({ modalType: "Add" });
            this.props.AC_RESET_EMPLOYEE();
        }
        this.props.AC_VIEW_EMPLOYEE(params.id);
    }

    AddSelectHandleChange = e => {
        const { name, label, value } = e;
        const Error = name + "Error";
        this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        if (name === "role") {
            this.setState({ roleOption: value })
        }
        if (name === 'department') {
            this.setState({ pushValue: value })
            if (value !== 'Select department') {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name === 'designation') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name == 'role') {
            if (value) {
                this.setState({ [Error]: false });
                this.setState({ managerError: false });
                this.props.AC_HANDLE_EMPLOYEE_CHANGE('manager', '')
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name == 'manager') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name === 'grade') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name === 'level') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name === 'date') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        // if (name === 'salary') {
        //     if (value) {
        //         this.setState({ [Error]: false });
        //     } else {
        //         this.setState({ [Error]: true })
        //     }
        // }

        // if (name === "role") {log
        //     this.props.AC_RESET_EMPLOYEE();

        //         this.setState({ [Error]: false });
        //     } else {
        //         this.setState({ [Error]: true })
        //     }


    }
    handlePhoneCode = e => {
        const { name, value, label } = e;
        this.setState({ ccLocal: false });
        this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        const Error = name + "Error";
        this.setState({ [Error]: false });
    }
    AddSelectHandleChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";


        if (name === 'department') {
            this.setState({ pushValue: value })
            if (value !== 'Select department') {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
            this.props.AC_HANDLE_EMPLOYEE_CHANGE('designation', '');
            this.props.AC_HANDLE_EMPLOYEE_CHANGE('division', '');

        } else if (name === 'division') {
            this.setState({ pushValue: value })
            if (value !== 'Select department') {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
            this.props.AC_HANDLE_EMPLOYEE_CHANGE('designation', '');
        } else if (name === 'designation') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        } else if (name == 'role') {
            if (value) {
                this.setState({ [Error]: false });
                this.setState({ managerError: false });
                this.props.AC_HANDLE_EMPLOYEE_CHANGE('manager', '')
            } else {
                this.setState({ [Error]: true })
            }
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        } else if (name == 'manager') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        } else if (name === 'grade') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        } else if (name === 'level') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        } else if (name === 'date') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        } else {
            this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);
        }
        // if (name === 'salary') {
        //     if (value) {
        //         this.setState({ [Error]: false });
        //     } else {
        //         this.setState({ [Error]: true })
        //     }
        // }
        // if (name === 'grade') {

        // }
    }
    AddHandleChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, value);

        if (name === 'employeeName', 'image', 'username') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name === 'email') {
            if (value) {
                this.setState({ [Error]: false });
                if (Emailvalidate(value)) {
                    this.setState({ emailpatternError: false })
                } else {
                    this.setState({ emailpatternError: true })
                }
            } else {
                this.setState({ [Error]: true, emailpatternError: false })
            }
        }
        // if (name === 'phoneNo') {
        //     if (value) {
        //         this.setState({ [Error]: false });
        //         if (Phonenumber(value)) {
        //             this.setState({ phoneNoPatternError: false })
        //         } else {
        //             this.setState({ phoneNoPatternError: true })
        //         }
        //     } else {
        //         this.setState({ [Error]: true, phoneNoPatternError: false })
        //     }
        // }
        if (name == 'phoneNo') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                this.setState({ [Error]: false });
                if (Phonenumber(val)) {
                    this.setState({ phoneNoPatternError: false })
                } else {
                    this.setState({ phoneNoPatternError: true })
                }
                this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, val);
            } else {
                this.setState({ phoneNoPatternError: false })
                this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, val);
            }
        }
        if (name == 'primaryPhone') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                if (Phonenumber(val)) {
                    this.setState({ primaryPhoneValidError: false })
                } else {
                    this.setState({ primaryPhoneValidError: true })
                }
                this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, val);
            } else {
                this.setState({ primaryPhoneValidError: false })
                this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, val);
            }
        }
        if (name == 'secondaryPhone') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                if (Phonenumber(val)) {
                    this.setState({ secondaryPhoneValidError: false })
                } else {
                    this.setState({ secondaryPhoneValidError: true })
                }
                this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, val);
            } else {
                this.setState({ secondaryPhoneValidError: false })
                this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, val);
            }
        }

        if (name === 'password') {
            if (value) {
                this.setState({ [Error]: false });
                if (Validpassword(value)) {
                    this.setState({ passwordPatternError: false })
                } else {
                    this.setState({ passwordPatternError: true })
                }
            } else {
                this.setState({ [Error]: true, passwordPatternError: false })
            }
        }

    }

    back = e => {
        this.setState({ redirect: true });
    }

    uploadhandleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'image') {
            this.setState({ image: event.target.files[0], imageError: false })
        }
        this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, event.target.files[0]);


    }
    // componentWillUpdate(nextProps) {
    //     const newaccount = nextProps.AccountState.account.phonecode;
    //     if (this.state.initial) {
    //         if (newaccount) {
    //             this.props.AC_HANDLE_EMPLOYEE_CHANGE('cc', newaccount);
    //         }
    //         this.setState({ initial: false })
    //     }
    // }

    onSubmit = e => {
        const data = this.props.employeeReducer.employee;
        var fields = this.props.AccountState.fields;
        // var countryCode = localStorage.getItem("countryCode");
        let valid = 1
        if (!data.employeeName) {
            this.setState({ employeeNameError: true });
            valid = 0
        }
        if (!data.employeeId) {
            this.setState({ employeeIdError: true });
            valid = 0
        }
        if (!data.employeecategory) {
            this.setState({ employeecategoryError: true });
            valid = 0
        }
        if (!data.role) {
            this.setState({ roleError: true });
            valid = 0
        }
        if (!data.grade) {
            this.setState({ gradeError: true });
            valid = 0
        }

        if (!data.level) {
            this.setState({ levelError: true });
            valid = 0
        }
        if (!data.email) {
            this.setState({ emailError: true });
            valid = 0
        }
        if (!data.department) {
            this.setState({ departmentError: true });
            valid = 0
        }
        if (!data.division) {
            this.setState({ divisionError: true });
            valid = 0
        }
        if (!data.designation) {
            this.setState({ designationError: true });
            valid = 0
        }
        if (this.state.primaryPhoneValidError) {
            valid = 0
        }
        if (this.state.secondaryPhoneValidError) {
            valid = 0
        }
        if (this.state.famPhoneValidError) {
            valid = 0
        }
        // if (!countryCode) {
        //     this.setState({ ccError: true });
        //     valid = 0
        // }
        // else if(!data.cc) {
        //     this.setState({ ccError: true });
        // }

        if (!data.phoneNo) {
            this.setState({ phoneNoError: true });
            valid = 0
        }
        if (!data.username) {
            this.setState({ usernameError: true });
            valid = 0
        }
        if (!data.password) {
            this.setState({ passwordError: true });
            valid = 0
        }
        if (data.role == this.state.managerID) {
            if (!data.manager) {
                this.setState({ managerError: true });
                valid = 0
            }
            else {
                this.setState({ managerError: false });
            }
        }
        if (!data.image) {
            this.setState({ imageError: true });
            valid = 0
        }
        if (!data.date) {
            this.setState({ dateError: true });
            valid = 0
        }
        // if (!data.salary) {
        //     this.setState({ salaryError: true });
        //     valid = 0
        // }
        if (valid) {
            this.setState({ save: true })
            var formData = new FormData();
            formData.append("employeecategory", data.employeecategory);
            formData.append("employeeName", data.employeeName);
            formData.append("image", data.image);
            formData.append("employeeId", data.employeeId);
            formData.append("email", data.email);
            formData.append("department", data.department);
            formData.append("designation", data.designation);
            formData.append("division", data.division);
            // { this.state.ccLocal ? formData.append("cc", countryCode) : formData.append("cc", data.cc) }
            formData.append("cc", data.cc);
            formData.append("phoneNo", data.phoneNo);
            formData.append("username", data.username);
            formData.append("password", data.password);
            formData.append("role", data.role);
            formData.append("manager", data.manager);
            formData.append("grade", data.grade);
            formData.append("level", data.level);
            formData.append("date", data.date);
            // formData.append("salary", data.salary);
            formData.append("dob", data.dob);
            formData.append("bloodgroup", data.bloodgroup);

            formData.append("kycname", data.kycname);
            formData.append("kycnumber", data.kycnumber);
            if (data.kyc)
                formData.append("kyc", JSON.stringify(data.kyc));
            if (this.state.imagesArr.length) {
                if (this.state.existingImage.length) {
                    var sendData = this.state.imagesArr.concat(this.state.existingImage)
                    for (const file of sendData) {
                        formData.append('kycimage', file);
                    };
                }
                else {
                    for (const file of this.state.imagesArr) {
                        formData.append('kycimage', file);
                    };
                }
            } else {
                if (data.kycimage) {
                    formData.append('kycimage', JSON.stringify(data.kycimage));
                }
            }
            formData.append("nationality", data.nationality);
            formData.append("religion", data.religion);
            formData.append("maritalstatus", data.maritalstatus);
            formData.append("spouse", data.spouse);
            formData.append("location", data.location);

            formData.append("noofchildren", data.noofchildren);

            formData.append("primaryName", data.primaryName);
            formData.append("primaryRelationship", data.primaryRelationship);
            formData.append("primaryPhone", data.primaryPhone);
            formData.append("secondaryName", data.secondaryName);
            formData.append("secondaryRelationship", data.secondaryRelationship);
            formData.append("secondaryPhone", data.secondaryPhone);

            formData.append("bankInfo", data.bankInfo);
            formData.append("bankaccountNo", data.bankaccountNo);
            formData.append("ifscCode", data.ifscCode);
            formData.append("bankasperName", data.bankasperName);

            formData.append("family", JSON.stringify(data.family));
            formData.append("education", JSON.stringify(data.education));
            formData.append("experience", JSON.stringify(data.experience));



            // for (let key in data) {
            //     if (key === 'family') formData.append(key, JSON.stringify(data[key]));
            //     else formData.append(key, data[key])
            // }

            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addEmployee, formData)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ redirect: true, save: false });
                        this.props.AC_RESET_EMPLOYEE();
                    }).catch(({ response }) => { Error(response.statusText); this.setState({ save: false }); });
            }
            else {
                axios.post(ImportedURL.API.updateEmployee + "/" + data._id, formData)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ redirect: true, save: false });
                        this.props.AC_RESET_EMPLOYEE();
                    }).catch(({ response }) => { Error(response.statusText); this.setState({ save: false }); });
            }

        } else {
            window.scrollTo(150, 150);
        }
    }
    passwordHide = () => {
        this.setState({ passwordhide: !this.state.passwordhide })
    }
    onChangeItem = (i, e) => {
        this.setState({ spinner: false, itemsError: false })
        const { name, value } = e.target;
        if (name == 'famPhone') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                if (Phonenumber(val)) {
                    // this.setState({ famPhoneValidError: false })
                } else {
                    // this.setState({ famPhoneValidError: true })
                }
                this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, val);
            } else {
                // this.setState({ famPhoneValidError: false })
                this.props.AC_HANDLE_EMPLOYEE_CHANGE(name, val);
            }
        }
        this.props.AC_HANDLE_EMPLOYEE_INFO_CHANGE(name, value, i);
    }
    addFormFields(e) {
        this.setState({ spinner: false })
        this.props.AC_HANDLE_EMPLOYEE_INFO_MULTIPLE_UPDATE('family', { 'famName': '', 'famRelationship': '', 'famdateofbirth': '', 'famPhone': '' }, '');
    }
    removeFormFields(i) {
        this.setState({ spinner: false })
        const DataValue = this.props.employeeReducer.employee.family;
        if (DataValue.length === 1 && i === 0) {
            this.props.AC_HANDLE_EMPLOYEE_INFO_MULTIPLE_UPDATE(true, { 'famName': '', 'famRelationship': '', 'famdateofbirth': '', 'famPhone': '' }, i);
        } else {
            this.props.AC_HANDLE_EMPLOYEE_INFO_MULTIPLE_UPDATE('family', '', i);
        }
    }
    // =========
    onChangeEducation = (i, e) => {
        this.setState({ spinner: false, itemsError: false })
        const { name, value } = e.target;
        this.props.AC_HANDLE_EDUCATION_INFO_CHANGE(name, value, i);
    }
    addEducationFields(e) {
        this.setState({ spinner: false })
        this.props.AC_HANDLE_EDUCATION_INFO_MULTIPLE_UPDATE('education', { 'institution': '', 'degree': '', 'educationstartdate': '', 'subject': '', 'educationcompletedate': '', 'grade': '' }, '');
    }
    removeEducationFields(i) {
        this.setState({ spinner: false })
        const DataValue = this.props.employeeReducer.employee.education;
        if (DataValue.length === 1 && i === 0) {
            this.props.AC_HANDLE_EDUCATION_INFO_MULTIPLE_UPDATE(true, { 'institution': '', 'degree': '', 'educationstartdate': '', 'subject': '', 'educationcompletedate': '', 'grade': '' }, i);
        } else {
            this.props.AC_HANDLE_EDUCATION_INFO_MULTIPLE_UPDATE('education', '', i);
        }
    }

    // =================
    onChangeExperience = (i, e) => {
        this.setState({ spinner: false, itemsError: false })
        const { name, value } = e.target;
        this.props.AC_HANDLE_EXPERIENCE_INFO_CHANGE(name, value, i);
    }
    addExperienceFields(e) {
        this.setState({ spinner: false })
        this.props.AC_HANDLE_EXPERIENCE_INFO_MULTIPLE_UPDATE('experience', { 'expcompanyname': '', 'expjobposition': '', 'expperiodfrom': '', 'explocation': '', 'expperiodto': '' }, '');
    }
    removeExperienceFields(i) {
        this.setState({ spinner: false })
        const DataValue = this.props.employeeReducer.employee.experience;
        if (DataValue.length === 1 && i === 0) {
            this.props.AC_HANDLE_EXPERIENCE_INFO_MULTIPLE_UPDATE(true, { 'expcompanyname': '', 'expjobposition': '', 'expperiodfrom': '', 'explocation': '', 'expperiodto': '' }, i);
        } else {
            this.props.AC_HANDLE_EXPERIENCE_INFO_MULTIPLE_UPDATE('experience', '', i);
        }
    }
    onChangeAddImages = e => {
        const { name, value } = e.target;
        const fieldError = name + "Error";
        if (name == 'kycimage') {
            this.setState({ imagesArr: [...this.state.imagesArr, ...e.target.files] });
            const files = Array.from(e.target.files);
            const newfiles = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                const imgvalidate = ImageFilevalidation(file);
                if (imgvalidate) {
                    newfiles.push(file)
                } else {
                    Error('Invalid file is discarded');
                }
            }
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    if (images.length > 0) {
                        images.map((item) => {
                            this.setState({ imageArray: [...this.state.imageArray, item] })
                        })
                    }
                }, error => {
                    console.error(error);
                });

        }
    }
    onChangeEdit = e => {
        this.setState({ spinner: false })
        const data = this.props.employeeReducer.employee.kycimage;
        this.setState({ existingImage: data });

        this.setState({ imagesArr: [...this.state.imagesArr, ...e.target.files] });
        const files = Array.from(e.target.files);

        const newfiles = [];

        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            const imgvalidate = ImageFilevalidation(file);
            if (imgvalidate) {
                newfiles.push(file)
            } else {
                Error('Invalid file is discarded');
            }
        }
        Promise.all(newfiles.map(file => {
            return (new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', (ev) => {
                    resolve(ev.target.result);
                });
                reader.addEventListener('error', reject);
                reader.readAsDataURL(file);
            }));
        }))
            .then(images => {
                if (images.length > 0) {
                    images.map((item) => {
                        this.setState({ imageArray: [...this.state.imageArray, item] })
                    })
                }
            }, error => {
                console.error(error);
            });
    }
    handleRemove = (i, value) => {
        this.setState({ spinner: false })
        const file = this.state.imageArray.filter((item, index) => index !== i);
        const Store = this.state.images.filter((item, index) => index !== i);
        this.setState({ imageArray: file, images: Store })
        if (value == 'editFirst') {
            const data = this.props.employeeReducer.employee.kycimage;
            const file = data.filter((item, index) => index !== i);
            this.props.AC_HANDLE_EMPLOYEE_CHANGE('kycimage', file);
        }
    }
    handleRemoveExisting = (i, value) => {
        this.setState({ spinner: false })
        const file = this.state.existingImage.filter((item, index) => index !== i);
        this.setState({ existingImage: file })
    }

    addKyc = () => {
        var data = this.props.employeeReducer.employee;
        let valid = 1;
        if (!this.state.kycname) {
            valid = 0
            this.setState({ kycnameError: true })
        }
        if (!this.state.kycnumber) {
            valid = 0
            this.setState({ kycnumberError: true })
        }
        if (!this.state.kycimage) {
            valid = 0
            this.setState({ kycimageError: true })
        }
        if (valid) {
            var formData = new FormData();
            const { params, } = this.props.match;
            formData.append("kycname", this.state.kycname);
            formData.append("kycnumber", this.state.kycnumber);
            formData.append("kycimage", this.state.kycimage);
            formData.append("id", params.id);
            axios.post(ImportedURL.API.addKyc, formData)
                .then((res) => {

                    this.setState({ kycimage: '', kycname: '', kycnumber: '', kycoption: '' })
                    this.props.AC_VIEW_EMPLOYEE(params.id);

                    Success('Kyc Added');
                    // this.setState({ redirect: true, save: false });
                }).catch(({ response }) => { Error(response.statusText); this.setState({ save: false }); });


        }
    }

    kycoptionchange = e => {
        this.setState({ kycoption: e, kycname: e.label, kycnameError: false })
    }

    changeKYC = e => {
        if (e.target.name == 'kycimage') {
            this.setState({ kycimage: e.target.files[0], kycimageError: false })
        } else
            this.setState({ [e.target.name]: e.target.value, [e.target.name + 'Error']: false })
    }

    removeKYC = (i) => {
        var data = this.props.employeeReducer.employee;
        let arr = [...data.kyc]
        arr.splice(i, 1)
        this.props.AC_HANDLE_EMPLOYEE_CHANGE('kyc', arr);

        // this.setState({ verificationdata: { ...this.state.verificationdata, questions: arr } })
    }

    render() {
        const { divisionReducer } = this.props;
        const listDivision = divisionReducer.listDivision;
        if (this.state.redirect) {
            return <Redirect to={'/listemployee'} />
        }
        const { employeeReducer, kycdocumentState, AccountState, CategoryState } = this.props;
        // const account = AccountState?.account;
        var fields = AccountState.fields;
        const listcategory = CategoryState.listcategories;
        const listemployee = employeeReducer.listEmployee;
        const listkycdocument = kycdocumentState.listkycdocument;
        const listemployeerole = employeeReducer.listemployeerole;
        var data = this.props.employeeReducer.employee;
        var phoneData = this.props.countryReducer.listCountry;
        var departmentData = this.props.departmentReducer.listDepartment;
        var designationData = this.props.designationReducer.listDesignation;
        // =============================
        // let designationArr = []
        // let designationArray = '';
        // if (data.department != undefined && data.department) {
        //     if (designationData.length > 0) {
        //         designationData.map((item) => {
        //             if (item.departmentId == data.department) {
        //                 designationArr.push(item)
        //             }
        //             if (item._id == data.designation) {
        //                 designationArray = item.name
        //             }

        //         })
        //     }
        // }

        let departmentEditArray = '';
        departmentData.length && departmentData.map(item => {
            if (item._id == data.department) {
                departmentEditArray = item.name
            }
        })

        const divisionOptions = [];

        let divisionEditArray = '';
        listDivision.filter(filterItem => filterItem.status === true).map(item => {
            if (item.department._id == data.department) {
                divisionOptions.push({ label: item.division, value: item._id, name: 'division' });
            }
            if (item._id == data.division) {
                divisionEditArray = { label: item.division, value: item._id, name: 'division' }
            }
        })

        const designationOptions = [];
        let designationArray = '';
        designationData.filter(filterItem => filterItem.status === true).map(item => {
            if (item.divisionId._id == data.division) {
                designationOptions.push({ label: item.designation, value: item._id, name: 'designation' });
            }
            if (item._id == data.designation) {
                designationArray = { label: item.designation, value: item._id, name: 'designation' }
            }
        })
        let roleEditArray = '';
        listcategory.length && listcategory.map(item => {
            if (item._id == data.role) {
                roleEditArray = item.name
            }
        })

        let employeecategorysel = ''
        let employeecategoryoptions = []

        this.state.employeecategorylist.filter(filterItem => filterItem.status === true).map(item => {
            if (item._id == data.employeecategory) employeecategorysel = {
                label: item.name, value: item._id, name: 'employeecategory', _id: item._id
            }
            employeecategoryoptions.push({
                label: item.name, value: item._id, name: 'employeecategory', _id: item._id
            })
        })
        let kycdocumentdata = '';
        listkycdocument.length && listkycdocument.map(item => {
            if (item._id == data.kycname) {
                kycdocumentdata = item.name
            }
        })




        // designationData.length && designationData.map(item => {
        //     if (item.departmentId == data.department) {
        //         designationArray = item.name
        //     }
        // })

        if (data !== undefined) {
            var imageData = data.image;
        }
        // ================================
        var ccData = '';
        const account = AccountState.account;
        if (phoneData) {
            phoneData.map(item => {
                if (data.cc) {
                    if (item._id == data.cc) {
                        ccData = { label: item.countryCode, value: item._id, flag: item.flag }
                    }
                }
            })
        }
        const level = [
            { value: 'I', label: 'I', name: 'level' },
            { value: 'II', label: 'II', name: 'level' },
            { value: 'III', label: 'III', name: 'level' },
            { value: 'IV', label: 'IV', name: 'level' },
            { value: 'V', label: 'V', name: 'level' },
        ]
        const grade = [
            { value: 'I', label: 'I', name: 'grade' },
            { value: 'II', label: 'II', name: 'grade' },
            { value: 'III', label: 'III', name: 'grade' },
        ]
        const maritalstatus = [
            { value: 'Single', label: 'Single', name: 'maritalstatus' },
            { value: 'Married', label: 'Married', name: 'maritalstatus' },
        ]
        let managerlist = '';
        listemployeerole.length && listemployeerole.map(item => {
            if (item._id === data.manager) {
                managerlist = item.employeeName
            }
        })



        var familyArray = [];
        if (data.family && data.family.length > 0) {
            data.family.map((item, i) => {
                familyArray.push(
                    <div className='row' key={i}>
                        <div className='col-lg-5'>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Name</label>
                                    <input type="text" className="form-control" placeholder="Name" name="famName" value={item.famName} onChange={e => this.onChangeItem(i, e)} />
                                    <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Relationship</label>
                                    <input type="text" className="form-control" placeholder="Relationship" name="famRelationship" value={item.famRelationship} onChange={e => this.onChangeItem(i, e)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Date of Birth</label>
                                    <div className='site_pass'>
                                        <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="famdateofbirth" value={moment(item.famdateofbirth).format('YYYY-MM-DD')} onChange={e => this.onChangeItem(i, e)} />
                                        <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Phone</label>
                                    <input type="text" className="form-control" placeholder="Phone" name="famPhone" value={item.famPhone} onChange={e => this.onChangeItem(i, e)} maxLength={10} />
                                    <div className="invalid-feedback" style={{ display: this.state.famPhoneValidError ? "block" : 'none' }}>Phone Number is required</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='todo_increment mt-5'>
                                < span className='plus_minus' > <i className="fa fa-minus-circle" aria-hidden="true" onClick={() => this.removeFormFields(i)}></i></span>
                                <span className='plus_minus'><i className="fa fa-plus-circle" aria-hidden="true" onClick={() => this.addFormFields(item, i)}></i></span>
                            </div>
                        </div>
                    </div>
                )
            })
        }


        var educationArray = [];
        if (data.education && data.education.length > 0) {
            data.education.map((item, i) => {
                educationArray.push(
                    <div className='row' key={i}>
                        <div className='col-lg-5'>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Institution</label>
                                    <input type="text" className="form-control" placeholder="Name" name="institution" value={item.institution} onChange={e => this.onChangeEducation(i, e)} />
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Starting Date</label>
                                    <div className='site_pass'>
                                        <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="educationstartdate" value={moment(item.educationstartdate).format('YYYY-MM-DD')} onChange={e => this.onChangeEducation(i, e)} />
                                        <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Degree</label>
                                    <input type="text" className="form-control" placeholder="Degree" name="degree" value={item.degree} onChange={e => this.onChangeEducation(i, e)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Subject</label>
                                    <input type="text" className="form-control" placeholder="Subject" name="subject" value={item.subject} onChange={e => this.onChangeEducation(i, e)} />
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Complete Date</label>
                                    <div className='site_pass'>
                                        <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="educationcompletedate" value={moment(item.educationcompletedate).format('YYYY-MM-DD')} onChange={e => this.onChangeEducation(i, e)} />
                                        <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Grade</label>
                                    <input type="text" className="form-control" placeholder="Grade" name="educationgrade" value={item.educationgrade} onChange={e => this.onChangeEducation(i, e)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='todo_increment mt-5 pt-5'>
                                < span className='plus_minus' > <i className="fa fa-minus-circle" aria-hidden="true" onClick={() => this.removeEducationFields(i)}></i></span>
                                <span className='plus_minus'><i className="fa fa-plus-circle" aria-hidden="true" onClick={() => this.addEducationFields(item, i)}></i></span>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        var experienceArray = [];
        if (data.experience && data.experience.length > 0) {
            data.experience.map((item, i) => {
                experienceArray.push(
                    <div className='row' key={i}>
                        <div className='col-lg-5'>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Company Name</label>
                                    <input type="text" className="form-control" placeholder="Company Name" name="expcompanyname" value={item.expcompanyname} onChange={e => this.onChangeExperience(i, e)} />
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Designation</label>
                                    <input type="text" className="form-control" placeholder="Designation" name="expjobposition" value={item.expjobposition} onChange={e => this.onChangeExperience(i, e)} />
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Period From</label>
                                    <div className='site_pass'>
                                        <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="expperiodfrom" value={moment(item.expperiodfrom).format('YYYY-MM-DD')} onChange={e => this.onChangeExperience(i, e)} />
                                        <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Location</label>
                                    <input type="text" className="form-control" placeholder="Location" name="explocation" value={item.explocation} onChange={e => this.onChangeExperience(i, e)} />
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group">
                                    <label className="form-label">Period To</label>
                                    <div className='site_pass'>
                                        <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="expperiodto" value={moment(item.expperiodto).format('YYYY-MM-DD')} onChange={e => this.onChangeExperience(i, e)} />
                                        <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className='todo_increment mt-5 pt-5'>
                                < span className='plus_minus' > <i className="fa fa-minus-circle" aria-hidden="true" onClick={() => this.removeExperienceFields(i)}></i></span>
                                <span className='plus_minus'><i className="fa fa-plus-circle" aria-hidden="true" onClick={() => this.addExperienceFields(item, i)}></i></span>
                            </div>
                        </div>
                    </div>
                )
            })
        }


        const photos = [];
        if (this.state.imageArray.length > 0 && this.state.existingImage.length > 0) {
            this.state.existingImage.map((image, i) => {
                let isImgtype = false;
                isImgtype = image.includes(".pdf")
                photos.push(
                    <div key={i} style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                        <div style={{ position: 'relative' }}>
                            <img src={isImgtype ? pdf : (image ? ImportedURL.LIVEURL + image : '')} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemoveExisting(i, 'edit')} ></i>
                        </div>
                    </div>)
            })
            this.state.imageArray.map((image, i) => {
                var isImgtype = false;
                isImgtype = image.includes(".pdf")
                photos.push(
                    <div key={i} style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                        <div style={{ position: 'relative' }}>
                            <img src={isImgtype ? pdf : image} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} />
                        </div>
                        <i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                    </div>
                )
            })
        } else {
            if (this.state.imageArray.length) {
                this.state.imageArray.map((image, i) => {
                    let firstSet = image.split("/")[1];
                    let secoundSet = firstSet.split(";")[0];
                    if (secoundSet == ('pdf' || 'PDF')) {
                        photos.push(
                            <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={pdf} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                                </div>
                            </div>
                        )
                    } else if (secoundSet == ('DOC' || 'doc' || 'docx' || 'DOCX' || 'msword')) {
                        photos.push(

                            <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={doc} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                                </div>
                            </div>
                        )

                    } else {
                        photos.push(
                            <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={image} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                                </div>
                            </div>
                        )
                    }

                })
            } else if (data.kycimage && data.kycimage.length) {
                data.kycimage.map((kycimage, i) => {
                    let isImgtype = false;
                    let name = kycimage && kycimage.split(".")[1];
                    isImgtype = name == 'pdf'
                    photos.push(
                        <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                            <div style={{ position: 'relative' }}>
                                <img src={isImgtype ? pdf : (kycimage ? ImportedURL.LIVEURL + kycimage : '')} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'editFirst')} ></i>
                            </div>
                        </div >)
                })
            }
        }



        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listemployee"><h6>List Employee</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> {this.state.modalType == "Add" ? <Link to="/addemployee"><h6>Add Employee</h6></Link> : <h6>Edit Employee</h6>}  </p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'>
                                            <i className="fa-solid fa-user mr-2"></i>
                                        </div>
                                        <h3 className="card-title">{this.state.modalType} Employee / Manager</h3>
                                        <div class="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <ul className="nav nav-tabs page-header-tab"></ul>
                                                <div className="header-action">
                                                    <button type="button" className="btn btn-primary" onClick={this.back}><i className="fa fa-solid fa-chevron-left mr-2"></i>Back</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form id='clear_form' autocomplete="off">
                                            <div className="row clearfix">
                                                <div className="form-group col-lg-6 col-md-12">
                                                    <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" value={data.employeeName} placeholder="Name" name="employeeName" onChange={this.AddHandleChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.employeeNameError ? "block" : 'none' }}>Employee Name is required</div>
                                                </div>
                                                {/* <div className="form-group col-lg-6 col-md-12">
                                                    <label className="form-label">Code<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Code" name="employeeCode" onChange={this.AddHandleChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                </div> */}
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Employee Category <span className="ml-1" style={{ color: 'red' }}>*</span> </label>
                                                        <Select
                                                            value={employeecategorysel}
                                                            onChange={this.AddSelectHandleChange}
                                                            options={employeecategoryoptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.employeecategoryError ? "block" : 'none' }}>Employee category is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Employee Role <span className="ml-1" style={{ color: 'red' }}>*</span> </label>
                                                        <Select
                                                            value={roleEditArray ? { label: roleEditArray } : ''}
                                                            onChange={this.AddSelectHandleChange}
                                                            options={listcategory ? listcategory.filter(filterItem => filterItem.status === true).map(item => {
                                                                return {
                                                                    label: item.name, value: item._id, name: 'role', _id: item._id
                                                                }
                                                            }) : ''}

                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.roleError ? "block" : 'none' }}>Employee role is required</div>
                                                    </div>
                                                </div>
                                                {/* {this.state.roleOption === "Employee" ?  */}
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Assign Manager{this.state.roleOption === "Employee" ? <span className="ml-1" style={{ color: 'red' }}>*</span> : ""}</label>
                                                        <Select
                                                            value={managerlist ? { label: managerlist } : ''}
                                                            onChange={this.AddSelectHandleChange}
                                                            options={listemployeerole ? listemployeerole.filter(filterItem => filterItem.status === true).map(item => {
                                                                return {
                                                                    label: item.employeeName, value: item._id, name: 'manager', _id: item._id
                                                                }
                                                            }) : ''}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.managerError ? "block" : 'none' }}>Assign Manager is required</div>
                                                    </div>
                                                </div>
                                                {/* : ''} */}

                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Grade<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={data.grade ? { label: data.grade, value: data.grade, name: 'grade' } : ''}
                                                            onChange={this.AddSelectHandleChange}
                                                            options={grade}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.gradeError ? "block" : 'none' }}>Grade is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Level<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={data.level ? { label: data.level, value: data.level, name: 'level' } : ''}
                                                            onChange={this.AddSelectHandleChange}
                                                            options={level}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.levelError ? "block" : 'none' }}>Level is required</div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12">
                                                    <label className="form-label">Employee ID<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <div className='site_pass'>
                                                        <input type="text" disabled={this.state.modalType == "View"} className="form-control" name="employeeId" value={data.employeeId} onChange={this.AddHandleChange} placeholder='Employee ID' />
                                                        <div className="invalid-feedback" style={{ display: this.state.employeeIdError ? "block" : 'none' }}>ID required</div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12">
                                                    <label className="form-label">Join Date<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <div className='site_pass'>
                                                        <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="date" value={moment(data.date).format('YYYY-MM-DD')} onChange={this.AddHandleChange} />
                                                        <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}>Date required</div>
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-6 col-md-12">
                                                    <label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Email" name="email" value={data.email} onChange={this.AddHandleChange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.emailpatternError ? "block" : 'none' }}>Enter valid email</div>
                                                </div>
                                                <div className='form-group col-lg-6'>
                                                    <Row>
                                                        <label className="form-label">Phone Number<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <div className="form-group col-lg-3 pr-0">
                                                            <Select
                                                                isDisabled={this.state.modalType == "View"}
                                                                value={ccData ? { label: <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + ccData.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + ccData.label }}></span> } : ''}
                                                                placeholder='+'
                                                                onChange={this.handlePhoneCode}
                                                                options={phoneData && phoneData.filter(filterItem => filterItem.status === true).map(item => {
                                                                    return {
                                                                        label: <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.countryCode }} />
                                                                        , value: item._id, name: 'cc'
                                                                    }
                                                                })} />
                                                            <div className="invalid-feedback" style={{ display: this.state.ccError ? "block" : 'none' }}>CC required</div>
                                                        </div>

                                                        <div className="form-group col-lg-9 pl-0">
                                                            <input type="text" className="form-control" placeholder="Phone Number" name="phoneNo" value={data.phoneNo} onChange={this.AddHandleChange} maxLength={10} />
                                                            <div className="invalid-feedback" style={{ display: this.state.phoneNoError ? "block" : 'none' }}>Phone Number is required</div>
                                                            <div className="invalid-feedback" style={{ display: this.state.phoneNoPatternError ? "block" : 'none' }}>Phone Number should contain 10 digits</div>
                                                        </div>
                                                    </Row>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12">
                                                    <label className="form-label">Username<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Username" name="username" onChange={this.AddHandleChange} value={data.username} />
                                                    <div className="invalid-feedback" style={{ display: this.state.usernameError ? "block" : 'none' }}>Username is required</div>
                                                </div>
                                                {this.state.modalType == "Edit" ? ' ' :
                                                    <div className="form-group col-lg-6 col-md-12" style={{ position: "relative" }}>
                                                        <label className="form-label">Password<span className="ml-1" style={{ color: 'red' }}>*</span></label><input class="form-check-input password_check" type="checkbox" id="autoSizingCheck" style={{ borderRadius: "0px !important" }} onClick={this.passwordHide} />
                                                        <input type={this.state.passwordhide ? "text" : "password"} className="form-control" placeholder="Password" name="password" onChange={this.AddHandleChange} value={data.password} />
                                                        <div className="invalid-feedback" style={{ display: this.state.passwordError ? "block" : 'none' }}>Password is required</div>
                                                        <div className="invalid-feedback" style={{ display: this.state.passwordPatternError ? "block" : 'none' }}>Password should contain 8 digit with 1 Special character<br></br>Uppercase,Lowercase,Number</div>
                                                    </div>
                                                }

                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Department<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={departmentEditArray ? { label: departmentEditArray } : ''}
                                                            onChange={this.AddSelectHandleChange}
                                                            options={departmentData ? departmentData.filter(filterItem => filterItem.status === true).map(item => {
                                                                return {
                                                                    label: item.name, value: item._id, name: 'department', _id: item._id
                                                                }
                                                            }) : ''}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.departmentError ? "block" : 'none' }}>Department is required</div>
                                                    </div>
                                                </div>
                                                {/* === */}
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Division<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={divisionEditArray}
                                                            onChange={this.AddSelectHandleChange}
                                                            options={divisionOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.divisionError ? "block" : 'none' }}>Division is required</div>
                                                    </div>
                                                </div>
                                                {/* === */}
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Designation<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={designationArray}
                                                            onChange={this.AddSelectHandleChange}
                                                            options={designationOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.designationError ? "block" : 'none' }}>Designation is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Image<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <input name="image" type="file" accept="image/png,image/jpg,image/jpeg" className="form-control" onChange={this.uploadhandleChange} />
                                                        <div className="invalid-feedback" style={{ display: this.state.imageError ? "block" : 'none' }}>Please upload image</div>
                                                    </div>
                                                    <div className="form-group">
                                                        {
                                                            this.state.modalType == "Add"
                                                            &&
                                                            (this.state.image
                                                                ?
                                                                <div style={{ width: '200px', height: '100px' }}>
                                                                    <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : null} alt='12' />
                                                                </div>
                                                                : '')
                                                        }
                                                        {
                                                            this.state.modalType == "Edit"
                                                                ?
                                                                <div className="form-group">
                                                                    <div style={{ width: '200px', height: '100px' }}>
                                                                        <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : ImportedURL.LIVEURL + imageData} alt="" />
                                                                    </div>
                                                                </div>
                                                                : ''
                                                        }
                                                    </div>
                                                </div>



                                            </div>
                                            {this.state.modalType == 'Edit' ?

                                                <div className='employee_info'>
                                                    <h3 className='py-3'>Employee Information</h3>
                                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                        <li class="nav-item mt-2">
                                                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Personal Informations</a>
                                                        </li>
                                                        <li class="nav-item mt-2">
                                                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">KYC</a>
                                                        </li>
                                                        <li class="nav-item mt-2">
                                                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Bank information</a>
                                                        </li>
                                                        <li class="nav-item mt-2">
                                                            <a class="nav-link" id="pills-contact-tab1" data-toggle="pill" href="#pills-contact1" role="tab" aria-controls="pills-contact1" aria-selected="false">Family Informations</a>
                                                        </li>
                                                        <li class="nav-item mt-2">
                                                            <a class="nav-link" id="pills-contact-tab2" data-toggle="pill" href="#pills-contact2" role="tab" aria-controls="pills-contact2" aria-selected="false">Education Informations</a>
                                                        </li>
                                                        <li class="nav-item mt-2">
                                                            <a class="nav-link" id="pills-contact-tab3" data-toggle="pill" href="#pills-contact3" role="tab" aria-controls="pills-contact3" aria-selected="false">Experience</a>
                                                        </li>
                                                    </ul>
                                                    <div class="tab-content" id="pills-tabContent">
                                                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                            <h4>Personal Informations</h4>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">D.O.B</label>
                                                                            <div className='site_pass'>
                                                                                <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="dob" value={data.dob ? moment(data.dob).format('YYYY-MM-DD') : null} onChange={this.AddHandleChange} />
                                                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Religion</label>
                                                                            <input type="text" className="form-control" placeholder="Religion" name="religion" value={data.religion} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Nationality</label>
                                                                            <input type="text" className="form-control" placeholder="Nationality" name="nationality" value={data.nationality} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Location</label>
                                                                            <input type="text" className="form-control" placeholder="Location" name="location" value={data.location} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Blood Group</label>
                                                                            <input type="text" className="form-control" placeholder="Blood Group" name="bloodgroup" value={data.bloodgroup} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Marital status</label>
                                                                            <Select
                                                                                value={data.maritalstatus ? { label: data.maritalstatus, value: data.maritalstatus, name: 'maritalstatus' } : ''}
                                                                                onChange={this.AddSelectHandleChange}
                                                                                options={maritalstatus}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {data.maritalstatus && data.maritalstatus == "Married" ?
                                                                        <>
                                                                            <div className='col-lg-12'>
                                                                                <div className="form-group">
                                                                                    <label className="form-label">Employment of spouse</label>
                                                                                    <input type="text" className="form-control" placeholder="Employment of spouse" name="spouse" value={data.spouse} onChange={this.AddHandleChange} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-12'>
                                                                                <div className="form-group">
                                                                                    <label className="form-label">No. of children</label>
                                                                                    <input type="text" className="form-control" placeholder="No. of children" name="noofchildren" value={data.noofchildren} onChange={this.AddHandleChange} />
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* ====== */}
                                                            <h4>Emergency Contact</h4>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <h5>Primary</h5>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Name</label>
                                                                            <input type="text" className="form-control" placeholder="Name" name="primaryName" value={data.primaryName} onChange={this.AddHandleChange} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Relationship</label>
                                                                            <input type="text" className="form-control" placeholder="Relationship" name="primaryRelationship" value={data.primaryRelationship} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Phone</label>
                                                                            <input type="text" className="form-control" placeholder="Phone" name="primaryPhone" value={data.primaryPhone} onChange={this.AddHandleChange} maxLength={10} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.primaryPhoneValidError ? "block" : 'none' }}>Phone number is invalid</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <h5>Secondary</h5>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Name</label>
                                                                            <input type="text" className="form-control" placeholder="Name" name="secondaryName" value={data.secondaryName} onChange={this.AddHandleChange} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Relationship</label>
                                                                            <input type="text" className="form-control" placeholder="Relationship" name="secondaryRelationship" value={data.secondaryRelationship} onChange={this.AddHandleChange} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Phone</label>
                                                                            <input type="text" className="form-control" placeholder="Phone" name="secondaryPhone" value={data.secondaryPhone} onChange={this.AddHandleChange} maxLength={10} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.secondaryPhoneValidError ? "block" : 'none' }}>Phone number is invalid</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                            <h4>KYC</h4>
                                                            <div className='row'>
                                                                <div className='col-lg-3'>
                                                                    <div className="form-group">
                                                                        <label className="form-label">KYC Document</label>
                                                                        <Select
                                                                            value={this.state.kycoption}
                                                                            onChange={this.kycoptionchange}
                                                                            options={listkycdocument ? listkycdocument.filter(filterItem => filterItem.status === true && (!data.kyc || !data.kyc.some(el => el.kycname == filterItem.name))).map(item => {
                                                                                return {
                                                                                    label: item.name, value: item._id, name: 'kycname', _id: item._id
                                                                                }
                                                                            }) : ''}
                                                                        />
                                                                        <div className="invalid-feedback" style={{ display: this.state.kycnameError ? "block" : 'none' }}>Name is required</div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-4'>
                                                                    <div className="form-group">
                                                                        <label className="form-label">KYC Number</label>
                                                                        <input type="text" className="form-control" placeholder="KYC Number" name="kycnumber" value={this.state.kycnumber} onChange={this.changeKYC} />
                                                                        <div className="invalid-feedback" style={{ display: this.state.kycnumberError ? "block" : 'none' }}>Name is required</div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3'>
                                                                    <label className="form-label">KYC Images</label>
                                                                    <input type="file" className="form-control" name="kycimage" onChange={this.changeKYC} />
                                                                    <div className="invalid-feedback" style={{ display: this.state.kycimageError ? "block" : 'none' }}>KYC image is required</div>
                                                                    {this.state.kycimage ? <div style={{ width: '200px', height: '100px' }}>
                                                                        <img src={this.state.kycimage ? URL.createObjectURL(this.state.kycimage) : null} alt='12' />
                                                                    </div> : ''}
                                                                </div>
                                                                <div className='col-lg-2'>
                                                                    <div class="form-group float-left "><label class="form-label mb-0">&nbsp;</label>
                                                                        <button type="button" class="btn commor_save pl-4 pr-4" onClick={this.addKyc}><i class="fe fe-plus " ></i></button>
                                                                    </div>
                                                                </div>
                                                                {/* {photos.length > 0 ?
                                                                    <div className="form-group mt-3" >
                                                                        <div className="photos_wid">
                                                                            {photos}
                                                                        </div>
                                                                    </div>
                                                                    : ''} */}
                                                            </div>
                                                            {
                                                                data.kyc ? data.kyc.map((item, i) => {
                                                                    return <>
                                                                        <hr />
                                                                        <div className='row'>
                                                                            <div className='col-lg-3'>
                                                                                <div className="form-group">
                                                                                    <p> {item.kycname}</p>
                                                                                    {/* <label className="form-label">KYC Documents</label> */}
                                                                                    {/* <Select
                                                                                value={kycdocumentdata ? { label: kycdocumentdata } : ''}
                                                                                onChange={this.AddSelectHandleChange}
                                                                                options={listkycdocument ? listkycdocument.filter(filterItem => filterItem.status === true).map(item => {
                                                                                    return {
                                                                                        label: item.name, value: item._id, name: 'kycname', _id: item._id
                                                                                    }
                                                                                }) : ''}
                                                                            /> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-4'>
                                                                                <div className="form-group">
                                                                                    <p> {item.kycnumber}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-3'>
                                                                                <div style={{ width: '200px', height: '100px' }}>
                                                                                    <img src={ImportedURL.LIVEURL + item.kycimage} alt='12' />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-2'>
                                                                                <div class="form-group float-left ">
                                                                                    <button type="button" className="btn commor_save  pl-4 pr-4" onClick={() => this.removeKYC(i)}><i className="fe fe-minus "></i></button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </>

                                                                })
                                                                    : ''
                                                            }
                                                            {/* <div className='employess_prev_next_btn'>
                                                            <li class="nav-item">
                                                                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">next</a>
                                                            </li>
                                                        </div> */}
                                                        </div>
                                                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                                            <h4>Bank information</h4>
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Name as per Bank </label>
                                                                            <input type="text" className="form-control" placeholder="Name as per Bank" name="bankasperName" value={data.bankasperName} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Bank name</label>
                                                                            <input type="text" className="form-control" placeholder="Bank name" name="bankInfo" value={data.bankInfo} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Branch Name</label>
                                                                            <input type="text" className="form-control" placeholder="Branch Name" name="branchname" value={data.branchname} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">IFSC Code</label>
                                                                            <input type="text" className="form-control" placeholder="IFSC Code" name="ifscCode" value={data.ifscCode} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Bank account No.</label>
                                                                            <input type="text" className="form-control" placeholder="Bank account No." name="bankaccountNo" value={data.bankaccountNo} onChange={this.AddHandleChange} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact-tab1">
                                                            <h4>Family Informations</h4>
                                                            {familyArray}
                                                        </div>
                                                        <div class="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact-tab2">
                                                            <h4>Education Informations</h4>
                                                            {educationArray}
                                                        </div>
                                                        <div class="tab-pane fade" id="pills-contact3" role="tabpanel" aria-labelledby="pills-contact-tab3">
                                                            <h4>Experience</h4>
                                                            {experienceArray}
                                                        </div>

                                                    </div>
                                                </div>

                                                : ''}


                                            <div className="footer-action" >
                                                {this.state.save ?
                                                    <button type="button" className="btn btn-primary"><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn btn-primary" onClick={this.onSubmit}><i className="fe fe-save mr-2"></i>Save</button>
                                                }
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        )
    }
}
const mapStateToProps = state => ({
    departmentReducer: state.departmentReducer,
    designationReducer: state.designationReducer,
    employeeReducer: state.employeeReducer,
    countryReducer: state.countryReducer,
    kycdocumentState: state.kycdocument,
    CategoryState: state.category,
    AccountState: state.account,
    divisionReducer: state.divisionReducer
})
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        AC_LIST_COUNTRY,
        AC_HANDLE_EMPLOYEE_CHANGE,
        AC_RESET_EMPLOYEE,
        AC_LIST_DEPARTMENT,
        AC_LIST_DESIGNATION,
        AC_LIST_EMPLOYEE,
        AC_LIST_EMPLOYEE_ROLE,
        AC_VIEW_EMPLOYEE,
        AC_HANDLE_EMPLOYEE_INFO_CHANGE,
        AC_HANDLE_EMPLOYEE_INFO_MULTIPLE_UPDATE,
        AC_HANDLE_EDUCATION_INFO_CHANGE,
        AC_HANDLE_EDUCATION_INFO_MULTIPLE_UPDATE,
        AC_HANDLE_EXPERIENCE_INFO_CHANGE,
        AC_HANDLE_EXPERIENCE_INFO_MULTIPLE_UPDATE,
        AC_LIST_KYC,
        AC_LIST_CATEGORY,
        AC_LIST_DIVISION,
        AC_ACCOUNT_DETAILS,
        AC_SETTING_DETAIL

    }, dispatch)

}
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);