import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { AC_ADD_USER, AC_DELETE_USER, AC_HANDLE_USER_CHANGE, AC_LIST_USER, AC_RESET_USER, AC_UPDATE_USER, AC_VIEW_USER, } from '../../actions/userAction';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
// import Emailvalidate from '../../common/validate';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        { headerName: 'Hotel', field: 'hotel', width: 130, rowDrag: true },
        { headerName: 'category', field: 'category', width: 130 },
        { headerName: 'Name', field: 'name', width: 130 },
        { headerName: 'Email', field: 'email', width: 160 },
        {
            headerName: 'Status', width: 100, field: 'status',
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Send OTP', width: 150, field: 'sendotp',
            cellRenderer: function (params) {
                return `<button title="Send OTP" type="button" class="btn btn-icon"><span class="tag tag-blue" type="button" data-action-type="otp">Send OTP</span></button>`;
            }
        },
        {
            headerName: 'Actions', width: 150, field: 'actions',
            cellRenderer: function (params) {
                return params.value;
            }
        },

    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    hotelError: false,
    categoryError: false,
    nameError: false,
    emailError: false,
    emailValidError: false,
    phonenumberError: false,
    id: '',
    cc: '+1',
    orignalData: [],
}
class ListUser extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.props.ResetUser();
        this.setState({ modalType: e.target.id });
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.props.ResetUser();
            this.setState({ modalType: "View", nameError: false, emailError: false, hotelError: false });
            this.props.ViewUser(event.data._id);
        }
        if (value === 'Edit') {
            this.setState({ email: event.data.email, modalType: "Edit", id: event.data._id, nameError: false, emailError: false, hotelError: false });
            this.props.ViewUser(event.data._id);
        }
        if (value === 'otp') {
            axios.post(ImportedURL.API.sendOtp + "/" + event.data._id)
                .then((res) => {
                    Success(res.statusText);
                }).catch(({ response }) => { Error(response.statusText) });
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.props.DeleteUser(event.data._id)
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'users' })
                        .then((data) => {
                            this.props.ListUser();
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }

    onChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        const ValidError = name + "ValidError";


        if (name === 'email') {
            this.setState({ email: value });
            var email = value;
            if (email) {
                this.setState({ [Error]: false });
                // if (Emailvalidate(email)) {
                //     this.setState({ [ValidError]: false })
                // } else {
                //     this.setState({ [ValidError]: true })
                // }
            } else {
                this.setState({ [Error]: true, [ValidError]: false })
            }
        }
        if (name === 'name') {
            this.setState({ userName: value });
            var userName = value;
            if (userName) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        if (name === 'cc') {
            this.setState({ cc: value });
        }
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
    }
    handleChange = e => {
        const { name, value } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
    }
    submit = () => {
        const { CategoryState, UserState } = this.props;
        const listCategories = CategoryState.listcategories;
        const data = UserState.user;
        let valid = 1;
        if (!data.hotel) {
            this.setState({ hotelError: true });
            valid = 0;
        }
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (!data.email) {
            this.setState({ emailError: true });
            valid = 0;
        }
        if (!data.phonenumber) {
            valid = 0;
            this.setState({ phonenumberError: true });
        }
        if (!data.category) {
            valid = 0;
            this.setState({ categoryError: true });
        }
        if (data.email && data.hotel && data.name && data.phonenumber) {
            if (this.state.modalType === "Add") {
                let category = data.category ? listCategories.find(x => x._id === data.category) : { previleges: [] };
                axios.post(ImportedURL.API.addUser, data)
                    .then((res) => {
                        Success(res.statusText);
                        this.props.ListUser();
                        this.props.ResetUser();
                        axios.post(ImportedURL.API.previleges, { email: data.email, previleges: JSON.stringify(category.previleges), update: 1 })
                            .then((res) => { });
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => { Error(response.statusText) });
            } else {
                this.props.UpdateUser(data, this.state.id);
                let btn = document.getElementById("closeModal");
                btn.click();
            }
        }
    }

    componentDidMount() {
        this.props.ListUser();
        this.props.ListCategory();
        localStorage.setItem('invoicetoken', "");
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    render() {
        if (this.state.modalType === 'Edit') {
            return <Redirect to={'edit-user/' + this.state.email} />
        }
        const { fixNavbar, UserState, AccountState, CategoryState } = this.props;
        const listCategories = CategoryState.listcategories;
        const rowData = UserState.listusers;
        const data = UserState.user;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name === "User" });
        const editOption = Previlege.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" ><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const deleteOption = Previlege.delete ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
        const template = '<div class="userlist_view"><button  title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
            + editOption
            + deleteOption
            + '</div>';
        if (rowData) {
            rowData.forEach(object => {
                object.actions = template;
            });
        }


        let selectedCategory = ''
        if (data.category) {
            selectedCategory = listCategories.find(e => e._id === data.category)
        }
        // let hotelsList = listHotels.length > 0
        //     && listHotels.map((item, i) => {
        //         return (
        //             <option key={i} value={item._id} selected={data.hotel == item._id}>{item.name}</option>
        //         )
        //     }, this);
        // let categoriesList = listCategories.length > 0
        //     && listCategories.map((item, i) => {
        //         return (
        //             <option key={i} value={item._id} selected={data.category == item._id}>{item.name}</option>
        //         )
        //     }, this);

        return (
            <>
                <div>
                    <div>
                        <div className={`section-body pt-3 ${fixNavbar ? "marginTop" : ""} `}>
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab">
                                    </ul>
                                    <div className="header-action" style={{ marginTop: '5px' }}>
                                        {Previlege.add ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content pt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title"><i className="icon-user mr-2"></i>Users</h3>
                                                {/* <button onClick={(e) => this.onBtnExport(e)}>Export</button> */}
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <div className="ag-theme-balham">
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={10}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={rowData}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            animateRows={true}
                                                            onGridReady={this.onGridReady}>
                                                        </AgGridReact>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{this.state.modalType} User</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>x</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                {/* <select className="form-control" disabled={this.state.modalType == "View"} name='category' onChange={this.onChange}>
                                                    <option value="" >Select Category</option>
                                                    {categoriesList}
                                                </select> */}
                                                <Select
                                                    value={selectedCategory ? { label: selectedCategory.name, value: selectedCategory._id, name: 'category' } : ''}
                                                    onChange={this.handleChange}
                                                    options={listCategories.map(item => {
                                                        return { label: item.name, value: item._id, name: 'category' }
                                                    })}
                                                    isDisabled={this.state.modalType === "View"}
                                                />
                                                <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Category is required</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" className="form-control" disabled={this.state.modalType === "View"} name='name' onChange={this.onChange} value={data.name} placeholder="User name*" />
                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>User name is required</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="email" className="form-control" disabled={this.state.modalType === "View"} name='email' onChange={this.onChange} value={data.email} placeholder="Email*" />
                                                <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email name is required</div>
                                                <div className="invalid-feedback" style={{ display: this.state.emailValidError ? "block" : 'none' }}>Enter valid email</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <select className="form-control" value={data.cc} disabled={this.state.modalType === "View"} onChange={this.onChange} name="cc">
                                                    <option value="+1">+1</option>
                                                    <option value="+91">+91</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <input type="number" className="form-control" disabled={this.state.modalType === "View"} name='phonenumber' onChange={this.onChange} value={data.phonenumber} placeholder="Phone number*" />
                                                <div className="invalid-feedback" style={{ display: this.state.phonenumberError ? "block" : 'none' }}>Phone number is required</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group">
                                                <select className="form-control" disabled={this.state.modalType === "View"} name='status' onChange={this.onChange}>
                                                    <option value={1} selected={data.status == 1}>Active</option>
                                                    <option value={0} selected={data.status == 0}>Inactive</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {this.state.modalType !== "View" ? <div className="modal-footer" >
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                </div> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    UserState: state.user,
    CategoryState: state.category,
    AccountState: state.account,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ HandleInputChange: AC_HANDLE_USER_CHANGE, ListCategory: AC_LIST_CATEGORY, AddUser: AC_ADD_USER, ListUser: AC_LIST_USER, ViewUser: AC_VIEW_USER, ResetUser: AC_RESET_USER, UpdateUser: AC_UPDATE_USER, DeleteUser: AC_DELETE_USER }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListUser);