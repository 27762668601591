import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_INVOICE_NUMBER_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INVOICE_NUMBER_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_INVOICE_NUMBER() {
    return function (dispatch) {
        dispatch({ type: "RESET_INVOICE_NUMBER" })
    };
}


export function AC_LIST_INVOICE_NUMBER() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listInvoicenumber)
                .then((res) => {
                    dispatch({ type: "LIST_INVOICE_NUMBER", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_INVOICE_NUMBER(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewInvoicenumber + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_INVOICE_NUMBER", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


export function AC_EMPTY_INVOICE_NUMBER() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_INVOICE_NUMBER" })
    }
}