
const initialState = {
    addAssignInventory: [],
    listAssignInventory: [],

    listStock: [],
    listService: [],
    viewService: [],
    listDamage:[],
    
    deleteAssignInventory: [],
    assignInventory: {
        propertyId: '',
        employeeName: '',
        assignedDate: '',
        reason: '',

        invoiceDate: '',
        deliveryDate: '',
        invoiceNo: '',
        amount: '',
        remarks: '',
        billUpload: '',

        dateDamage: '',
        reasonDamage: '',
        
        reasonService:'',
        dateofservice:''
    },
}
function assignInventoryReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_ASSIGNINVENTORY':
            return {
                ...state,
                assignInventory: action.payload
            }
        case 'LIST_ASSIGNINVENTORY':
            return {
                ...state,
                listAssignInventory: action.payload
            }





        case 'LIST_STOCK':
            return {
                ...state,
                listStock: action.payload
            }
        case 'LIST_SERVICE':
            return {
                ...state,
                listService: action.payload
            }
        case 'VIEW_SERVICE':
            return {
                    ...state,
                viewService: action.payload
            }
        case 'LIST_DAMAGE':
            return {
                ...state,
                listDamage: action.payload
            }
            






        case 'VIEW_ASSIGNINVENTORY':
            return {
                ...state,
                assignInventory: action.payload
            }
        case 'RESET_ASSIGNINVENTORY':
            return Object.assign({}, state, {
                assignInventory: initialState.assignInventory
            })
        case 'DELETE_ASSIGNINVENTORY':
            return {
                ...state,
                deleteAssignInventory: action.payload
            }

        case 'HANDLE_ASSIGNINVENTORY_CHANGE':
            return Object.assign({}, state, {
                assignInventory: {
                    ...state.assignInventory,
                    [action.name]: action.value
                }
            })
        default:
            return state;

    }
}

export default assignInventoryReducer;