
import React, { Component, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_HANDLE_ASSIGNINVENTORY_CHANGE, AC_RESET_ASSIGNINVENTORY, AC_LIST_ASSIGNINVENTORY } from '../../actions/assignInventoryAction';
import ImportedURL from '../../common/api';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { Row } from 'react-bootstrap';
import { Success, Error } from '../../common/swal';
import { Emailvalidate, Phonenumber, Validpassword } from '../../common/validate';
import { AC_LIST_INVENTORYASSET, AC_VIEW_INVENTORYASSET } from '../../actions/inventoryAssetAction';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';
import Swal from 'sweetalert2';
import moment from 'moment';


class AssignInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pushValue: '',
            pagetype: 'add',
            redirect: false,
            // mainImage: [],
            // mainImageArray: [],
            pushValue: '',
            image: '',

            all: true,
            save: false,
            categorylist: [],
            subcategorylist: [],
            assetdata: { warrantyexist: false },
            required: [
                'assetname',
                'cost',
                'dateofpurchase',
                'category',
                'subcategory',
            ]
        }

    }

    componentDidMount() {
        this.GetCategories();
        const { params, path } = this.props.match;
        if (path === "/edit-asset/:id") {
            this.setState({ pagetype: "Edit", id: params.id })
            this.getAssetData();
        }
        // this.props.AC_LIST_EMPLOYEE();
    }
    getAssetData = () => {
        const { params } = this.props.match;
        axios.get(`${ImportedURL.API.viewAsset}/${params.id}`)
            .then((res) => {
                this.setState({ assetdata: res.data })
            }).catch((err) => {
                console.log(err);
            });
    }
    GetCategories = () => {
        axios.get(ImportedURL.API.listAssetCategory, { params: { type: 'subcategory' } })
            .then((res) => {
                this.setState({ subcategorylist: res.data });

            }).catch(({ response }) => {
            });
        axios.get(ImportedURL.API.listAssetCategory, { params: { type: 'category' } })
            .then((res) => {
                this.setState({ categorylist: res.data });

            }).catch(({ response }) => {
            });
    }

    SelectHandleChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false, assetdata: { ...this.state.assetdata, [name]: value } })

    }

    onChangeHandle = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false, assetdata: { ...this.state.assetdata, [name]: value } })

    }



    onSave = () => {
        const data = this.state.assetdata;
        let valid = 1;
        this.state.required.map((item) => {
            if (!data[item]) {
                valid = 0
                this.setState({ [item + 'Error']: true });
            }
        })

        if (valid) {
            this.setState({ save: true });
            if (this.state.pagetype == 'add') {
                axios.post(ImportedURL.API.addAsset, data)
                    .then((res) => {
                        this.setState({ save: false });
                        Success("Asset added Successfully");
                        this.props.history.push('/list-asset')
                    }).catch(({ response }) => {
                        Error('Asset addition failed')
                    });
            } else {
                axios.post(ImportedURL.API.updateAsset, data)
                    .then((res) => {
                        this.setState({ save: false });
                        Success("Asset updated Successfully");
                        this.props.history.push('/list-asset')
                    }).catch(({ response }) => {
                        Error('Asset update failed')
                    });
            }

        }


    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={'/listassignedinventory'} />
        }

        // const propertyId = this.props.inventoryAssetReducer.listInventoryAsset;
        const employee = this.props.employeeReducer.listEmployee;
        // const listAssignInventory = this.props.assignInventoryReducer.listAssignInventory;

        // var Array1 = [];
        // var Array2 = [];
        // listAssignInventory.map((data) => {
        //     Array1.push(data.propertyId)
        // })

        // propertyId.map((data) => {
        //     Array2.push(data.propertyId)
        // })

        const subcategorylist = this.state.subcategorylist;
        const categorylist = this.state.categorylist;

        const data = this.state.assetdata;

        let selectedCategory = '';
        const categoryOptions = [];
        categorylist.filter(filterItem => filterItem.status === true).map(item => {
            if (data.category == item._id) selectedCategory = { label: item.name, value: item._id, name: 'category' }
            categoryOptions.push({ label: item.name, value: item._id, name: 'category' });
        })

        let selectedSubCategory = '';
        const subcategoryOptions = [];
        if (data.category)
            subcategorylist.filter(filterItem => filterItem.status === true).map(item => {
                if (data.category == item.category) {
                    if (data.subcategory == item._id) selectedSubCategory = { label: item.name, value: item._id, name: 'subcategory' }
                    subcategoryOptions.push({ label: item.name, value: item._id, name: 'subcategory' });
                }
            })
        return (
            <>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'>
                                            <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                                        </div>
                                        <h3 className="card-title">{this.state.pagetype == 'add' ? 'Add' : 'Edit'} Asset</h3>
                                        <div class="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <ul className="nav nav-tabs page-header-tab"></ul>

                                                <div className="header-action">
                                                    <Link to='/list-asset'>
                                                        <button type="button" className="btn btn-primary" ><i class="fa fa-solid fa-chevron-left mr-2"></i>Back</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form id='clear_form'>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='form-group '>
                                                        <label className="form-label">Category<span style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            placeholder='Select Category'
                                                            value={selectedCategory}
                                                            onChange={this.SelectHandleChange}
                                                            options={categoryOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Category is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='form-group '>
                                                        <label className="form-label">Sub Category<span style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            placeholder='Select Sub Category'
                                                            value={selectedSubCategory}
                                                            onChange={this.SelectHandleChange}
                                                            options={subcategoryOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.subcategoryError ? "block" : 'none' }}>Sub Category is required</div>
                                                    </div>
                                                </div>
                                                {/* <div className='col-lg-6'>
                                                    <div className="form-group ">
                                                        <label className="form-label">Employee Name<span style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            placeholder='Employee Name'
                                                            onChange={this.AddSelectHandleChange}
                                                            options={employee && employee.filter(filterItem => filterItem.status === true).map((item, i) => {
                                                                return {
                                                                    label: item.employeeName, value: item.employeeName, name: 'employeeName',
                                                                }
                                                            })} />
                                                        <div className="invalid-feedback" style={{ display: this.state.employeeNameError ? "block" : 'none' }}>Employee Name is required</div>
                                                    </div>
                                                </div> */}
                                                <div className='col-lg-6'>
                                                    <div className="form-group ">
                                                        <label className="form-label">Asset Name<span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" placeholder='Name' name="assetname" onChange={this.onChangeHandle} value={data.assetname} />
                                                        <div className="invalid-feedback" style={{ display: this.state.assetnameError ? "block" : 'none' }}>Name is Required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group ">
                                                        <label className="form-label">Purchase Cost<span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number" className="form-control" placeholder='Cost' name="cost" onChange={this.onChangeHandle} value={data.cost} />
                                                        <div className="invalid-feedback" style={{ display: this.state.costError ? "block" : 'none' }}>Cost is Required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group ">
                                                        <label className="form-label">Date of Purchase<span style={{ color: 'red' }}>*</span></label>
                                                        <input type="date" className="form-control" name="dateofpurchase" onChange={this.onChangeHandle} value={data.dateofpurchase ? moment(data.dateofpurchase).format('YYYY-MM-DD') : ''} />
                                                        <div className="invalid-feedback" style={{ display: this.state.dateofpurchaseError ? "block" : 'none' }}>Please Select Date</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group ">
                                                        <label className="form-label">Warranty Tenure</label>
                                                        <div className='row'>
                                                            <div className='col-lg-4'>
                                                                <div class="selectgroup w-100">
                                                                    <label class="selectgroup-item">
                                                                        <input type="radio" name="value" class="selectgroup-input" onChange={() => { this.setState({ [Error]: false, assetdata: { ...this.state.assetdata, warrantyexist: true }, required: [...this.state.required, 'warrantyexpiry'] }) }} checked={data.warrantyexist} />
                                                                        <span class="selectgroup-button">Yes</span>
                                                                    </label>
                                                                    <label class="selectgroup-item">
                                                                        <input type="radio" name="value" class="selectgroup-input" onChange={() => { this.setState({ [Error]: false, assetdata: { ...this.state.assetdata, warrantyexist: false } }) }} checked={!data.warrantyexist} />
                                                                        <span class="selectgroup-button">No</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {
                                                                data.warrantyexist ?
                                                                    <div className='col-lg-8'>
                                                                        <input type="date" className="form-control" name="warrantyexpiry" onChange={this.onChangeHandle} value={data.warrantyexpiry ? moment(data.warrantyexpiry).format('YYYY-MM-DD') : ''} />
                                                                        <div className="invalid-feedback" style={{ display: this.state.warrantyexpiryError ? "block" : 'none' }}>Please Select Date</div>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </div>
                                                        {/* <div className="invalid-feedback" style={{ display: this.state.dateofpurchaseError ? "block" : 'none' }}>Please Select Date</div> */}
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group ">
                                                        <label className="form-label">Features</label>
                                                        <textarea type="text" className="form-control" name="features" placeholder='Features' onChange={this.onChangeHandle} value={data.features} />
                                                        {/* <div className="invalid-feedback" style={{ display: this.state.dateofpurchaseError ? "block" : 'none' }}>Please Select Date</div> */}
                                                    </div>
                                                </div>
                                                <div className="form-group ">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary" style={{ position: 'relative' }}><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" style={{ position: 'relative' }} onClick={this.onSave}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        )
    }
}
const mapStateToProps = state => ({
    assignInventoryReducer: state.assignInventoryReducer,
    employeeReducer: state.employeeReducer,
    countryReducer: state.countryReducer,
    inventoryReducer: state.inventoryReducer,
    assetReducer: state.assetReducer,
    inventoryAssetReducer: state.inventoryAssetReducer,
})
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        AC_LIST_INVENTORYASSET, AC_VIEW_INVENTORYASSET,
        AC_HANDLE_ASSIGNINVENTORY_CHANGE, AC_RESET_ASSIGNINVENTORY,
        AC_LIST_ASSIGNINVENTORY, AC_LIST_EMPLOYEE
        // AC_LIST_COUNTRY,AC_LIST_INVENTORY,AC_LIST_ASSET

        // AC_HANDLE_ASSIGNINVENTORY_CHANGE, AC_RESET_ASSIGNINVENTORY, AC_LIST_DEPARTMENT, AC_LIST_DESIGNATION
    }, dispatch)

}
export default connect(mapStateToProps, mapDispatchToProps)(AssignInventory);
