const initialState = {
    listinvoice: [],
    total: '',
    invoice: {
        company: '',
        tocompany: '',
        address: '',
        toaddress: '',
        tocompanyimage: '',
        amount: '',
        invoicedate: '',
        duedate: '',
        url: '',
        discount: '',
        category: [],
        subcategory: [],
        discountname: 'Multiple Property Discount',
        discounttype: 'flat'
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INVOICE_CHANGE':
            return Object.assign({}, state, {
                invoice: {
                    ...state.invoice,
                    [action.name]: action.value
                }
            })
        case 'RESET_INVOICE':
            return Object.assign({}, state, {
                invoice: initialState.invoice,
            })
        case 'LIST_INVOICE':
            return {
                ...state,
                listinvoice: action.payload,
                total: action.total,
                spinner: action.spinner
            }
        case 'VIEW_INVOICE':
            return Object.assign({}, state, {
                invoice: action.payload,
                spinner: false,
            })
        case 'SPINNER_INVOICE_ALL':
            return Object.assign({}, state, {
                spinner: true,
            })
        case 'EMPTY_INVOICE':
            return Object.assign({}, state, {
                invoice: {
                    company: '',
                    tocompany: '',
                    address: '',
                    toaddress: '',
                    tocompanyimage: '',
                    amount: '',
                    invoicedate: '',
                    duedate: '',
                    url: '',
                    discount: '',
                    category: [],
                    subcategory: []
                },
                spinner: true
            })
        default:
            return state;
    }
}