import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';
import { Link, Redirect } from 'react-router-dom';


const ADD_EARNING = 'ADD_EARNING';
const LIST_EARNING = 'LIST_EARNING';
const VIEW_EARNING = 'VIEW_EARNING';

// export function AC_ADD_DEPARTMENT(data) {
//     return function (dispatch) {
//         axios.post(ImportedURL.API.addDepartment,data)
//             .then((data) => {
//                 Success('Added successfully');
//                 dispatch({ type: ADD_DEPARTMENT, payload: data});
// 				// window.location='/listinventory'
//             }).catch(function (error) {
//                 if (error) {
//                     Error('Data already exists')
//                 }
//             });
//     }
// }

export function AC_LIST_EARNING() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listEarning)
            .then(({ data }) => {
                dispatch({ type: LIST_EARNING, payload: data });
            })
    }
}

export function AC_VIEW_EARNING(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewEarning + "/" + id)
            .then(({ data }) => { 
                dispatch({ type: VIEW_EARNING, payload: data });
            });
    }
}

export function AC_RESET_EARNING() {
	return function (dispatch) {
		dispatch({ type: "RESET_EARNING" })
	};
}

export function AC_HANDLE_EARNING_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EARNING_CHANGE", name: name, value: value })
    };
}



