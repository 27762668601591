import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';
import { Link, Redirect } from 'react-router-dom';


const ADD_INVENTORY = 'ADD_INVENTORY';
const LIST_INVENTORY = 'LIST_INVENTORY';
const VIEW_INVENTORY = 'VIEW_INVENTORY';
const UPDATE_INVENTORY = 'UPDATE_INVENTORY';
const DELETE_INVENTORY = 'DELETE_INVENTORY';
const RESET_INVENTORY = 'RESET_INVENTORY';

export function AC_ADD_INVENTORY(FormData) {
    return function (dispatch) {
        axios.post(ImportedURL.API.addInventory, FormData)
            .then((data) => {
                Success('Added successfully');
                // Swal.fire('Inventory added successfully')
                dispatch({ type: ADD_INVENTORY, payload: data });
				window.location='/listinventory'
            }).catch(function (error) {
                if (error) {
                    Error('Data already exists')
                }
            });
    }
}

export function AC_LIST_INVENTORY() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listInventory)
            .then(({ data }) => {
                dispatch({ type: LIST_INVENTORY, payload: data });
            })
    }
}

export function AC_VIEW_INVENTORY(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewInventory + "/" + id)
            .then(({ data }) => { 
                dispatch({ type: VIEW_INVENTORY, payload: data });
            });
    }
}

export function AC_RESET_INVENTORY() {
	return function (dispatch) {
		dispatch({ type: "RESET_INVENTORY" })
	};
}

export function AC_UPDATE_INVENTORY(formData) {

    return function (dispatch) {
        axios.post(ImportedURL.API.updateInventory, formData)
            .then((data) => {
                Success(' updated successfully');
                dispatch({ type: UPDATE_INVENTORY, payload: data })
				// window.location='/listinventory'
            }).catch(function (error) {
                if (error) {
                    Error('Data already exists')
                }
            });
    }
}

export function AC_DELETE_INVENTORY(id) {
    return function (dispatch) {
        axios.post(ImportedURL.API.deleteInventory + "/" + id)
            .then((data) => {
                dispatch({ type: LIST_INVENTORY, payload: data.data })
            })
    }
}

export function AC_HANDLE_INVENTORY_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INVENTORY_CHANGE", name: name, value: value })
    };
}



