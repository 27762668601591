import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import { AC_HANDLE_PAYROLL_ITEM_CHANGE, AC_LIST_PAYROLL_ITEM, AC_VIEW_PAYROLL_ITEM, AC_RESET_PAYROLL_ITEM } from '../../../actions/payrollitemAction';
import moment from 'moment';


const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    viewFlag: false,
    flagError: false,
    setChecked: '',
    pushValue: '',
    columnDefs: [
        { headerName: 'Name', field: 'name', width: 130, floatingFilter: true },
        {
            headerName: 'Amount', field: 'amount', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Status', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class PayrollItem extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.props.resetPayrollitem();
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.viewPayrollitem(event.data._id);
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeletePayrollitem + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            this.props.listParollitem();
                        })
                }
            })
        }
        if (value === 'Status') {

            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'payrollitems' })
                        .then((data) => {
                            this.props.listParollitem();
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'amount'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { payrollitemState } = this.props;
        const exportData = payrollitemState.listpayrollitem
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Employee Details";
        const headers = [['#', 'Name', 'Amount']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.name,
            elt.amount,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Payrollitems.pdf")
    }


    componentDidMount() {
        this.props.listParollitem();
        localStorage.setItem('invoicetoken', "");
    }
    onChange = (e) => {
        const { name, value, checked } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        if (name === "name") {
            if (value) {
                this.setState({ [Error]: false })
            } else {
                this.setState({ [Error]: true })
            }
            this.props.handleChange(name, value);

        }
        if (name === "amount") {
            if (value) {
                this.setState({ [Error]: false })
            } else {
                this.setState({ [Error]: true })
            }
            this.props.handleChange(name, value);

        }

    }
    submit = () => {
        const data = this.props.payrollitemState.payrollitem;
        if (!data.name) {
            this.setState({ nameError: true });
        }
        if (!data.amount) {
            this.setState({ amountError: true });
        }
        if (!data.salarystructure) {
            this.setState({ salarystructureError: true });
        }
        var formdata = {
            name: data.name,
            amount: data.amount,
        }
        if (formdata.name && formdata.amount) {
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addPayrollitem, formdata)
                    .then((formdata) => {
                        Success(formdata.statusText);
                        this.setState({ save: false });
                        this.props.listParollitem();
                        this.props.resetPayrollitem();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updatePayrollitem + '/' + this.state.id, formdata)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.listParollitem();
                        this.props.resetPayrollitem();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }
    render() {
        const { payrollitemState } = this.props;
        const rowData = payrollitemState.listpayrollitem;
        const data = payrollitemState.payrollitem;




        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listpayrollitem"><h6> Payroll Item List</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">List Payroll Item</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Modal */}
                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>{this.state.modalType} Payroll Item</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                        <img src='../../assets/images/cancel.png' />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label"> Allowance<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onChange} placeholder="Name" value={data.name} />
                                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label className="form-label">Amount<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='amount' onChange={this.onChange} placeholder="Amount" value={data.amount} />
                                                            <div className="invalid-feedback" style={{ display: this.state.amountError ? "block" : 'none' }}>Amount  is required</div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-md-12'>
                                                        <label class="form-label" for="flexSwitchCheckChecked">Need to include salary structure?</label>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input payroll_toggle" type="checkbox" name="salarystructure" onChange={this.onChange} checked={data.salarystructure} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    payrollitemState: state.payrollitem
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { handleChange: AC_HANDLE_PAYROLL_ITEM_CHANGE, listParollitem: AC_LIST_PAYROLL_ITEM, viewPayrollitem: AC_VIEW_PAYROLL_ITEM, resetPayrollitem: AC_RESET_PAYROLL_ITEM }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PayrollItem);

