const initialState = {
	user: {
		name: '',
		status: 1,
		email: '',
		category: '',
		phonenumber: '',
		cc: '+1',
	},
	listusers: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_USER_CHANGE':
			return Object.assign({}, state, {
				user: {
					...state.user,
					[action.name]: action.value
				}
			})
		case 'RESET_USER':
			return Object.assign({}, state, {
				user: initialState.user
			})
		case 'LIST_USER':
			return Object.assign({}, state, {
				listusers: action.payload
			})
		case 'VIEW_USER':
			return Object.assign({}, state, {
				user: action.payload
			})
		default:
			return state;
	}
}