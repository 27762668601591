import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from "react-multi-date-picker";
import { AC_EMPTY_LEAVES, AC_HANDLE_LEAVES_CHANGE, AC_LIST_LEAVES, AC_RESET_LEAVES, AC_VIEW_LEAVES } from '../../actions/leavesAction';
import { AC_LIST_LEAVE_TYPE } from '../../actions/leavetypeAction';
import { AC_LIST_EMPLOYEE_LEAVE } from '../../actions/employeeLeaveAction';
import { AC_LIST_EMPLOYEE, AC_LIST_HR } from '../../actions/employeeAction';
import { AC_LIST_EMAIL, AC_RESET_EMAIL } from '../../actions/emailAction';

const initialState = {
    modalType: "Add",
    columnDefs: [
        {
            headerName: 'Employee', field: 'employeeImage', width: 200, filter: false, cellStyle: { textAlign: 'center' },
            cellRendererFramework: function (params) {
                return <div>
                    <img style={{ width: '40px', height: '40px', borderRadius: '0px', objectFit: 'cover', marginRight: '10px' }} src={ImportedURL.LIVEURL + params.data.employeeImage} alt="" />
                    <span>{params.data.employeeName}</span>
                </div>
            }
        },
        {
            headerName: 'Leave Type', field: 'leavetypename', width: 200, floatingFilter: true, headerClass: 'ag-center-header',
            cellRenderer: function (params) {
                return params.value ? params.value : params.data.islop ? 'Loss of pay' : '';
            }
        },
        {
            headerName: 'Date', field: 'from', width: 200, floatingFilter: true, headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                if (params.data.from && params.data.to) {
                    return `<span >` + moment(params.data.from).format("DD-MM-YYYY") + ' - ' + moment(params.data.to).format("DD-MM-YYYY") + `</span>`
                }
            }
        },
        {
            headerName: 'Number of days', field: 'numberofdays', width: 100, floatingFilter: false, headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                if (params.data.numberofdays == 1) {
                    return `<span >` + params.data.numberofdays + `</span>`
                } else {
                    return `<span >` + params.data.numberofdays + ' days' + `</span>`

                }
            }
        },
        // { headerName: 'Leave Reason ', field: 'reason', width: 200, floatingFilter: true, headerClass: 'ag-center-header', },
        // {
        //     headerName: 'Status', width: 150, field: 'status', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
        //     cellRenderer: function (params) {
        //         if (params.data.trackingStatus == 200) {
        //             return '<button type="button" class="tag tag-approval"  data-action-type="Approval"  data-toggle="modal" data-target="#statusChange"><i class="fa fa-check-circle-o mr-2 leave_up_icon" aria-hidden="true"></i>Approved</button>';
        //         } else if (params.data.trackingStatus == 540) {
        //             return '<button type="button" class="tag tag-decline"  data-action-type="Approval"  data-toggle="modal" data-target="#statusChange"><i class="fa fa-times-circle-o mr-2 leave_up_icon" aria-hidden="true"></i>Declined</button>';
        //         } else {
        //             return `<button type="button" class="tag tag-info" data-action-type="Approval"  data-toggle="modal" data-target="#statusChange"><i class="fa fa-plus-circle mr-2 leave_up_icon" aria-hidden="true"></i>Pending</button>`;
        //         }
        //     }
        // },
        {
            headerName: 'Status', field: 'createdAt', width: 130, headerClass: 'ag-center-header', filter: false, cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                if (params.data.status == 'approved') return '<span class="tag tag-success">Approved</span>'
                else if (params.data.status == 'declined') return '<span class="tag tag-warning" style="background-color:#EE4B2B">Declined</span>'
                else if (params.data.status == 'hold') return '<span class="tag tag-warning" style="background-color:#2CBAEC">Hold</span>'
                else if (params.data.status == 'pending') return '<span class="tag tag-warning">Pending</span>'
                else if (params.data.status == 'systemapproved') return `<span class="tag tag-default">System approved</span>`
                else return `<span class="tag tag-default">${params.data.status}</span>`
            }
        },

        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
    },
    id: '',
    editSpi: false,
    leavedata: {},
    leavelist: []
}
class Leaves extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {

        this.getLeaveList();
        localStorage.setItem('invoicetoken', "");
    }

    getLeaveList = (query = {}) => {
        axios.get(ImportedURL.API.listLeaveApproval, { params: query })
            .then((res) => {
                this.setState({ leavelist: res.data });

            }).catch((err) => {
                console.log(err);
            });
    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.props.EmptyLeaves();
            this.setState({ modalType: "View", id: event.data._id, leavedata: event.data });
        }
    }


    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    setStatus = (status) => {
        if (this.state.leavedata.status != status)
            Swal.fire({
                title: status == 'approved' ? 'Approve Request?' : status == 'declined' ? 'Decline Request?' : 'Hold Request',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                imageUrl: 'assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.leavestatuschange, { status: status, id: this.state.leavedata._id, email: this.state.leavedata.employeeEmail })
                        .then((res) => {
                            Success(status == 'approved' ? 'Request approved' : status == 'declined' ? 'Request declined' : 'Request on hold')
                            this.getLeaveList()
                            let btn = document.getElementById("leaveModel");
                            btn.click();
                        }).catch((err) => {
                            Error('Status update failed again')
                            console.log(err);
                        });
                }
            })

    }


    setDateValue = e => {
        this.setState({ timesheetdate: e.year })
        this.getLeaveList({ year: e.year })

    }

    render() {
        const { LeaveState, AccountState, LeaveTypeState, EmployeeLeaveState, employeeState } = this.props;
        const account = AccountState?.account;
        const rowData = this.state.leavelist;
        const data = this.state.leavedata;
        const listLeavetype = LeaveTypeState.listLeavetype;


        const editOption = account.role == 'admin' || account.role == 'EMPLOYEE' ? '<button type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>' : ''
        const deleteOption = account.role == 'admin' || account.role == 'EMPLOYEE' ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>' : ''
        const template = '<div><button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModal" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View" data-toggle="tooltip" title="View"></i></button>'
            + editOption
            + deleteOption
            + '</div>'

        rowData.forEach(object => {
            object.actions = template;
        });

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        return (
            <>

                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/leaves"><h6 className="highlights_breadcrump"> Leaves</h6></Link></p>
                        </div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-briefcase mr-2 "></i></div>
                                                <h3 className="card-title"> Leaves</h3>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">Limit</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">Year</label>
                                                        <div className="form-group">
                                                            <DatePicker
                                                                onlyYearPicker
                                                                value={new Date()}
                                                                onChange={this.setDateValue}
                                                                maxDate={new Date()}
                                                                className='limit_size'
                                                                style={{ height: '35px', paddingLeft: '13px', width: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <div className="ag-theme-alpine">
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={25}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={rowData}
                                                            rowDragManaged={true}
                                                            animateRows={true}
                                                            columnDefs={this.state.columnDefs}
                                                            onGridReady={this.onGridReady}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {/* <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div> */}
                                                    {/* {spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ""} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* status change model  */}
                    {account.role == "HR" ?
                        <div className="modal fade" id="statusChange" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className='rounded_icon'><i className="fa fa-home mr-2 "></i></div>
                                        <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>Approval</h5>
                                        <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" style={{ fontSize: "23px" }} id="closeModal" onClick={this.clear}>
                                                <img src='../../assets/images/cancel.png' />
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-body leave_approval">
                                        <div className='row'>
                                            <div className='col-7'>
                                                <div className='img_name'>
                                                    <div className='img_size'>
                                                        <img src={ImportedURL.LIVEURL + data.employeeImage} />
                                                    </div>
                                                    <div>
                                                        <p>{data.employeeName}</p>
                                                        <span class="tag tag-blue">{data.employeeRole}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-5'>
                                                <div className='mb-2'>
                                                    <span style={{ fontWeight: '500' }}>Date : </span>
                                                    <span>{data.from + '  to '}</span>
                                                    <span>{data.to}</span>
                                                </div>
                                                <div>
                                                    <span style={{ display: 'inline-block', border: '1px solid #ccc', background: '#f5f5f5', color: '#555', padding: '4px 15px', borderRadius: '10px', fontWeight: '500', fontSize: '14px' }}>
                                                        {data.numberofdays == 1 ? data.numberofdays : data.numberofdays + ' days'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <h6>Leave Reason</h6>
                                        <p>{data.reason}</p>
                                        <div className='ap_re_button mt-5'>
                                            <button type='button' className='btn approval_save   commor_save' onClick={this.approval}><i class="fa fa-check-circle-o mr-2 " aria-hidden="true"></i>Approve</button>
                                            <button type='button' className='btn reject_save mr-3   commor_save' onClick={this.rejected} ><i class="fa fa-times-circle-o mr-2 " aria-hidden="true"></i>Decline</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""}
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" autoComplete="off" style={{ zIndex: 1030 }} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog " role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-home mr-2 "></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} Leave</h5>
                                    <button type="button" id="leaveModel" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }} id="leaveModel" onClick={this.clear}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='view_lew row'>
                                        <div className='mb-2 col-6'>
                                            <label className="form-label" >Leave Type</label>
                                            <div className=''>
                                                <div>{data.leavetypename ? data.leavetypename : data.islop ? 'Loss of pay' : ''}</div>
                                            </div>
                                        </div>
                                        <div className='mb-2 col-6'>
                                            <label className="form-label" >From</label>
                                            <div className=''>
                                                <div>{moment(data.from).format("DD-MM-YYYY")}</div>
                                            </div>
                                        </div>
                                        <div className='mb-2 col-6' >
                                            <label className="form-label" >To</label>
                                            <div className=''>
                                                <div>{moment(data.to).format("DD-MM-YYYY")}</div>
                                            </div>
                                        </div>
                                        <div className='mb-2 col-6'>
                                            <label className="form-label" >Number of days</label>
                                            <div className=''>
                                                <div>{data.numberofdays}</div>
                                            </div>
                                        </div>
                                        {/* <div className='mb-2'>
                                                <label className="form-label" >Remaining Leaves</label>
                                                <div className=''>
                                                    <div>{data.remainingleaves}</div>
                                                </div>
                                            </div> */}
                                        <div className='mb-2'>
                                            <label className="form-label" >Leave Reason</label>
                                            <div className=''>
                                                <div>{data.reason}</div>
                                            </div>
                                        </div>

                                        {data.status != 'systemapproved' ? <div className="col-md-12">
                                            <div className="form-group">
                                                <label class="selectgroup-item mr-2">
                                                    <span class="selectgroup-button selectgroup-button-icon" onClick={() => { this.setStatus('approved') }} style={data.status == 'approved' ? { color: '#2CBAEC', borderColor: '#2CBAEC' } : {}}>
                                                        <i class="fa fa-thumbs-up"></i>
                                                    </span>

                                                </label>
                                                {data.status == 'approved' ? 'Approved' : 'Approve'}
                                                <label class="selectgroup-item ml-2 mr-2">
                                                    <span class="selectgroup-button selectgroup-button-icon" onClick={() => { this.setStatus('declined') }} style={data.status == 'declined' ? { color: '#2CBAEC', borderColor: '#2CBAEC' } : {}}>
                                                        <i class="fa fa-thumbs-down"></i>
                                                    </span>

                                                </label>
                                                {data.status == 'declined' ? 'Declined' : 'Decline'}
                                                <label class="selectgroup-item ml-2 mr-2">
                                                    <span class="selectgroup-button selectgroup-button-icon" onClick={() => { this.setStatus('hold') }} style={data.status == 'hold' ? { color: '#2CBAEC', borderColor: '#2CBAEC' } : {}}>
                                                        <i class="fa fa-hand-stop-o" style={{ fontWeight: 1000 }}></i>
                                                    </span>

                                                </label>
                                                Hold
                                            </div>
                                        </div> : ''}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    LeaveState: state.leaves,
    LeaveTypeState: state.leavetype,
    EmployeeLeaveState: state.employeeleave,
    employeeState: state.employeeReducer,
    EmailState: state.email,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            HandleInputChange: AC_HANDLE_LEAVES_CHANGE,
            ListLeaves: AC_LIST_LEAVES,
            ViewLeaves: AC_VIEW_LEAVES,
            ResetLeaves: AC_RESET_LEAVES,
            EmptyLeaves: AC_EMPTY_LEAVES,
            ListLeaveType: AC_LIST_LEAVE_TYPE,
            ListEmployeeLeave: AC_LIST_EMPLOYEE_LEAVE,
            ListEmployee: AC_LIST_EMPLOYEE,
            ResetEmail: AC_RESET_EMAIL,
            ListEmail: AC_LIST_EMAIL,
            ListHr: AC_LIST_HR,

        }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaves);