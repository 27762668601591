import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_VIEW_MONTHLY_PAYSLIP } from '../../../actions/payrollAction';
import ImportedURL from '../../../common/api';
import moment from 'moment';
import { numToWords } from '../../../common/validate';


class Payslip extends Component {
    printFun = () => {
        window.print();
    }
    componentDidMount() {
        this.props.ViewMonthlyPayslip(this.props.match.params.id);
        localStorage.setItem('invoicetoken', "");
    }


    render() {
        const { employeesalaryState, payrollState } = this.props;
        const data = payrollState.payslip;
        var grossA = data.salary;
        var grossB = Math.round(data.pf) + Math.round(data.esi) + Math.round(data.professionaltax);
        var grossC = Math.round(data.pfemployer) + Math.round(data.esiepmloyer) + Math.round(data.gratuity);
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6>Payslip</h6></p>
                </div>

                <div className='download_options'>
                    <div className="header-action" style={{ marginTop: '5px' }}>
                        <Link to='/monthlypayroll'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                    </div>
                    <nav aria-label="Page navigation example" >
                        <ul class="pagination" style={{ marginRight: "150px", marginTop: "10px" }}>
                            <li class="page-item"><a class="page-link" href="#">CSV</a></li>
                            <li class="page-item"><a class="page-link" href="#">PDF</a></li>
                            <li class="page-item"><a class="page-link" href="#" onClick={this.printFun}> <span><i class="fa fa-print" aria-hidden="true"></i></span> Print</a></li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">Payslip</h3>
                                            {/* <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                    <a className="dropdown-item" type='button' onClick={this.printFun}><i className="dropdown-icon fa fa-file-pdf-o"></i> Print</a>
                                                </div>

                                            </div> */}
                                        </div>
                                        <div className='card-body' id='print_page'>
                                            <div className='payslip_content'>
                                                <p className='payslip_heading'>
                                                    PAYSLIP FOR THE MONTH OF FEB 2019
                                                </p>
                                                {/* <h4 className='payslip_Id'>PAYSLIP #49029</h4>
                                                <p className='payslip_date'>
                                                    <span>Salary Month</span> : <span>March , 2019</span>
                                                </p> */}
                                                <div className='payslip_company_intro'>
                                                    <div className='company_logo'>
                                                        <img src='../../assets/images/banner-logo.png' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='payslip_tables pt-5'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <table class="table table-bordered">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Employee ID</td>
                                                                    <td>{data.employeeId}</td>
                                                                    <td>PAN</td>
                                                                    <td>{data.kycnumber}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Employee Name</td>
                                                                    <td>{data.employeeName}</td>
                                                                    <td>Bank Name</td>
                                                                    <td>{data.bankname}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Date of Joining</td>
                                                                    <td>{moment(data.joindate).format('DD/MM/YYYY')}</td>
                                                                    <td>Bank Account No</td>
                                                                    <td>{data.bankaccountno}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Department</td>
                                                                    <td>{data.departmentName}</td>
                                                                    <td>IFSC code</td>
                                                                    <td>{data.ifsccode}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Division</td>
                                                                    <td>{data.divisionName}</td>
                                                                    <td>UAN</td>
                                                                    <td>-</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Designation</td>
                                                                    <td>{data.designationName}</td>
                                                                    <td>ESIC No</td>
                                                                    <td>-</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Grade</td>
                                                                    <td>{data.grade}</td>
                                                                    <td>EPF No</td>
                                                                    <td>-</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Level</td>
                                                                    <td>{data.level}</td>
                                                                    <td>Location</td>
                                                                    <td>{data.location}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>


                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Earnings</th>
                                                                <th>Scale (₹)</th>
                                                                <th>Amount (₹)</th>
                                                                <th>Deductions</th>
                                                                <th>Amount (₹)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Basic + DA </td>
                                                                <td>{data.basicsalary ? data.basicsalary : 0}</td>
                                                                <td>{data.currentBasicsalary ? data.currentBasicsalary : 0}</td>
                                                                <td>Professional Tax</td>
                                                                <td>{data.currentProfessionaltax ? data.currentProfessionaltax : 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>House Rent Allowance</td>
                                                                <td>{data.hra ? data.hra : 0}</td>
                                                                <td>{data.currentHra ? data.currentHra : 0}</td>
                                                                <td>EPF</td>
                                                                <td>{data.currentEpf ? data.currentEpf : 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Conveyance Allowance</td>
                                                                <td>{data.conveyance ? data.conveyance : 0}</td>
                                                                <td>{data.Currentallowanceconveyance ? data.Currentallowanceconveyance : 0}</td>
                                                                <td>TDS</td>
                                                                <td>{data.payrollempDedamount}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Medical Allowance</td>
                                                                <td>{data.medical ? data.medical : 0}</td>
                                                                <td>{data.Currentallowancemedical ? data.Currentallowancemedical : 0}</td>
                                                                <td>ESI</td>
                                                                <td>{data.currentEsi ? data.currentEsi : 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Internet Allowance</td>
                                                                <td>{data.internet ? data.internet : 0}</td>
                                                                <td>{data.Currentallowanceinternet ? data.Currentallowanceinternet : 0}</td>
                                                                <td>TNLWF</td>
                                                                <td>{data.mainsalary}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Telephone Allowance</td>
                                                                <td>{data.telephone ? data.telephone : 0}</td>
                                                                <td>{data.Currentallowancetelephone ? data.Currentallowancetelephone : 0}</td>
                                                                <td>Other deductions</td>
                                                                <td>{data.currenttotaldeduction ? data.currenttotaldeduction  : 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Food Allowance</td>
                                                                <td>{data.food ? data.food : 0}</td>
                                                                <td>{data.Currentallowancefood ? data.Currentallowancefood : 0}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Fuel Allowance</td>
                                                                <td>{data.fuel ? data.fuel : 0}</td>
                                                                <td>{data.Currentallowancefuel ? data.Currentallowancefuel : 0}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Children Hostel Allowance</td>
                                                                <td>{data.childhostel ? data.childhostel : 0}</td>
                                                                <td>{data.Currentallowancechildhostel ? data.Currentallowancechildhostel : 0}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Children Education Allowance</td>
                                                                <td>{data.childeducation ? data.childeducation : 0}</td>
                                                                <td>{data.Currentallowancechildeducation ? data.Currentallowancechildeducation : 0}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Driver Allowance</td>
                                                                <td>{data.driver ? data.driver : 0}</td>
                                                                <td>{data.Currentallowancedriver ? data.Currentallowancedriver : 0}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Uniform Allowance</td>
                                                                <td>{data.uniform ? data.uniform : 0}</td>
                                                                <td>{data.Currentallowanceuniform ? data.Currentallowanceuniform : 0}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Statutory Bonus***</td>
                                                                <td>{data.statutorybonus}</td>
                                                                <td>{data.currentStatutory}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Special Allowance</td>
                                                                <td>{data.specialallowance}</td>
                                                                <td>{data.currentSpecialallowance}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Other Earnings</td>
                                                                <td>0</td>
                                                                <td>{data.payrollempEaramount}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>ESI</td>
                                                                <td>{data.esiepmloyer}</td>
                                                                <td>0</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>EPF</td>
                                                                <td>{data.pfemployer}</td>
                                                                <td>0</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Gratutity</td>
                                                                <td>{data.gratuity}</td>
                                                                <td>0</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Earnings:</td>
                                                                <td>{data.totalbenifits ? data.totalbenifits : 0}</td>
                                                                <td>0</td>
                                                                <td>Total Deductions:</td>
                                                                <td>{data.currenttotaldeduction ? data.currenttotaldeduction : 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Net Pays:
                                                                </td>
                                                                <td>{data.nettakehome}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>In words:</td>
                                                                <td>{data.nettakehome ? numToWords(data.nettakehome) : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Working Days:
                                                                    <tr>
                                                                        {data.workingdays}
                                                                    </tr>
                                                                </td>
                                                                <td>Net Payable Days
                                                                    <tr>
                                                                        {data.paiddays}
                                                                    </tr>
                                                                </td>
                                                                <td>LOP Days
                                                                    <tr>
                                                                        {data.lopdays}
                                                                    </tr>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/* <div className='net_salary pt-5 pb-3'>
                                                <p><strong>Net Salary <span className='px-2'>:</span></strong> <span style={{ fontWeight: "bolder" }}>{ } <i class="fa-solid fa-indian-rupee-sign ml-2"></i></span></p>
                                            </div> */}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    employeeReducer: state.employeeReducer,
    payrollitemState: state.payrollitem,
    employeesalaryState: state.employeesalary,
    payrollState: state.payroll
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ViewMonthlyPayslip: AC_VIEW_MONTHLY_PAYSLIP,
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Payslip);