
const initialState = {
    addDesignation: [],
    listDesignation: [],
    deleteDesignation: [],
    designation: {
        designation: '',
        department: '',
        division: '',
    },
}
function designationReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_DESIGNATION':
            return {
                ...state,
                designation: action.payload
            }
            break;

        case 'LIST_DESIGNATION':
            return {
                ...state,
                listDesignation: action.payload
            }
            break;
        case 'VIEW_DESIGNATION':
            return {
                ...state,
                designation: action.payload
            }
        case 'RESET_DESIGNATION':
            return Object.assign({}, state, {
                designation: initialState.designation
            })
        case 'DELETE_DESIGNATION':
            return {
                ...state,
                deleteDesignation: action.payload
            }
            break;
        case 'HANDLE_DESIGNATION_CHANGE':
            return Object.assign({}, state, {
                designation: {
                    ...state.designation,
                    [action.name]: action.value
                }
            })
        default:
            return state;
            break;
    }
}

export default designationReducer;