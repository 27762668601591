import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_LIST_DEPARTMENT, AC_VIEW_DEPARTMENT, AC_HANDLE_DEPARTMENT_CHANGE, AC_RESET_DEPARTMENT } from '../../../actions/departmentAction';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import { Success, Error } from '../../../common/swal';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Select from 'react-select';


const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "Add",
    save: false,
    columnDefs: [
        { headerName: 'Department Name', field: 'name', width: 400, floatingFilter: true },
        {
            headerName: 'Status', width: 400, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" style="margin-top: 5px" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 400, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class ListDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.changeModal = this.changeModal.bind(this);
    }
    changeModal = (e) => {
        this.props.AC_RESET_DEPARTMENT();
        this.setState({ modalType: e.target.id });
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id, nameError: false });
            this.props.AC_VIEW_DEPARTMENT(event.data._id);
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.deleteDepartment + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            this.props.AC_LIST_DEPARTMENT();
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'departments' })
                        .then((data) => {
                            this.props.AC_LIST_DEPARTMENT();
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };
    searchUser = e => {
        const { name, value } = e;
        this.setState({
            [name]: value,
        });
    }

    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { departmentReducer } = this.props;
        const exportData = departmentReducer.listDepartment;
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Department";
        const headers = [['#', 'Department Name']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.name
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Department.pdf")
    }


    onChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        this.props.AC_HANDLE_DEPARTMENT_CHANGE(name, value);
        if (name === "name") {
            if (value) {
                this.setState({ [Error]: false })
            } else {
                this.setState({ [Error]: true })
            }
        }
    }

    submit = () => {
        const data = this.props.departmentReducer.department;
        if (!data.name) {
            this.setState({ nameError: true })
        }
        if (data.name) {
            if (this.state.modalType === "Add") {
                this.setState({ save: true });
                axios.post(ImportedURL.API.addDepartment, data)
                    .then((data) => {
                        Success(data.statusText);
                        this.setState({ save: false });
                        this.props.AC_LIST_DEPARTMENT();
                        this.props.AC_RESET_DEPARTMENT();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateDepartment, data)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });

                        this.props.AC_LIST_DEPARTMENT();
                        this.props.AC_RESET_DEPARTMENT();

                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }

    componentWillMount() {
        this.props.AC_LIST_DEPARTMENT();
        localStorage.setItem('invoicetoken', "");
    }
    render() {
        const { fixNavbar, departmentReducer, AccountState } = this.props;
        const rowData = departmentReducer.listDepartment;
        const data = departmentReducer.department;

        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            // '<div><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listdepartment"><h6> Department List</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i class="fa-sharp fa-solid fa-building-circle-arrow-right mr-2"></i>
                                            </div>
                                            <h3 className="card-title">List Department</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>

                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>

                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Modal */}
                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel"><i class="fa-sharp fa-solid fa-building-circle-arrow-right mr-2"></i>{this.state.modalType} Department</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }} >x</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Department Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onChange} value={data.name} placeholder="Department Name" />
                                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Department Name is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}



const mapStateToProps = (state) => ({
    departmentReducer: state.departmentReducer
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AC_LIST_DEPARTMENT, AC_VIEW_DEPARTMENT, AC_HANDLE_DEPARTMENT_CHANGE, AC_RESET_DEPARTMENT
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListDepartment);