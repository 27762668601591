import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import CKEditor from "react-ckeditor-component";
import { capitalizeFirstLetter, convertToSlug, ImageFilevalidation } from '../../common/validate';
import { AC_EMPTY_PROJECT, AC_HANDLE_PROJECT_CHANGE, AC_RESET_PROJECT, AC_VIEW_PROJECT } from '../../actions/projectAction';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';
import pdf from '../../images/pdf.png'
import doc from '../../images/doc.png'
import Swal from 'sweetalert2';
// import RLDD from 'react-list-drag-and-drop/lib/RLDD';

class AddProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            id: "",
            saving: false,
            images: [],
            imageArray: [],
            existingImage: [],
            nameError: false,
            dateError: false,
            leaderError: false,
            teamError: false,
            imageError: false,
            imagesArr: [],
            listRedirect: false,
            moduledata: {},
            submoduledata: {},
        }
    }

    componentDidMount() {
        const { params, path } = this.props.match;

        if (path === "/edit-project/:id") {
            this.setState({ modalType: "Edit", id: params.id })
        } else {
            this.setState({ modalType: "Add" });
            this.props.ResetProject();
        }

        var today = new Date()
        var todayDate = moment(today).format("YYYY-MM-DD");
        this.props.HandleInputChange('date', todayDate);
        this.props.ListEmployee();
        this.props.ViewProject(params.id);
        localStorage.setItem('invoicetoken', "");
    }

    onChangeAdd = e => {
        this.setState({ spinner: false })
        const { name, value } = e.target;
        const fieldError = name + "Error";
        if (name == 'image') {
            this.setState({ imagesArr: [...this.state.images, ...e.target.files] });
            const files = Array.from(e.target.files);
            const newfiles = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                const imgvalidate = ImageFilevalidation(file);
                if (imgvalidate) {
                    newfiles.push(file)
                } else {
                    Error('Invalid file is discarded');
                }
            }
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    if (images.length > 0) {
                        images.map((item) => {
                            this.setState({ imageArray: [...this.state.imageArray, item] })
                        })
                    }
                }, error => {
                    console.error(error);
                });

        }


    }
    onChangeEdit = e => {
        this.setState({ spinner: false })
        const data = this.props.ProjectState.project.image;
        this.setState({ existingImage: data });

        this.setState({ imagesArr: [...this.state.images, ...e.target.files] });
        const files = Array.from(e.target.files);

        const newfiles = [];

        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            const imgvalidate = ImageFilevalidation(file);
            if (imgvalidate) {
                newfiles.push(file)
            } else {
                Error('Invalid file is discarded');
            }
        }
        Promise.all(newfiles.map(file => {
            return (new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', (ev) => {
                    resolve(ev.target.result);
                });
                reader.addEventListener('error', reject);
                reader.readAsDataURL(file);
            }));
        }))
            .then(images => {
                if (images.length > 0) {
                    images.map((item) => {
                        this.setState({ imageArray: [...this.state.imageArray, item] })
                    })
                }
            }, error => {
                console.error(error);
            });
    }
    handleRemove = (i, value) => {
        this.setState({ spinner: false })
        const file = this.state.imageArray.filter((item, index) => index !== i);
        const Store = this.state.images.filter((item, index) => index !== i);
        this.setState({ imageArray: file, images: Store })
        if (value == 'editFirst') {
            const data = this.props.ProjectState.project.image;
            const file = data.filter((item, index) => index !== i);
            this.props.HandleInputChange('image', file);
        }
    }
    handleRemoveExisting = (i, value) => {
        this.setState({ spinner: false })
        const file = this.state.existingImage.filter((item, index) => index !== i);
        this.setState({ existingImage: file })
    }

    handleChangeLeader = (e) => {
        const { name, value, label } = e;
        if (name == 'leader') {
            if (value) {
                this.props.HandleInputChange('leader', value);
                this.setState({ leaderError: false })
            } else {
                this.setState({ departmentError: true })
            }
        }
    }

    handleChangeTeam = (e) => {
        const { name, value, label } = e;
        let valueArray = [];
        e && e.map((val) => {
            valueArray.push(val.value)
        })
        this.props.HandleInputChange('team', valueArray);
    };


    handleChange = (e) => {
        const { name, value } = e.target;
        const Error = name + "Error";
        if (name == 'name') {
            if (value) {
                this.setState({ nameError: false })
                this.props.HandleInputChange('name', value)
            } else {
                this.setState({ nameError: true })
                this.props.HandleInputChange('name', value)
            }
        }
        if (name == 'date') {
            if (value) {
                this.props.HandleInputChange('date', value)
            }
        }
    }

    onChangeEditor = e => {
        this.props.HandleInputChange("description", e.editor.getData());
    }

    submit = () => {
        const data = this.props.ProjectState.project;
        let valid = 1
        if (!data.name) {
            this.setState({ nameError: true })
            valid = 0;
        };
        if (!data.leader) {
            this.setState({ leaderError: true })
            valid = 0;
        };
        if (!data.team) {
            this.setState({ teamError: true })
            valid = 0;
        };
        // if (!data.image) {
        //     valid = 0;
        //     this.setState({ imageError: true })
        // };

        if (valid) {
            const formData = new FormData();
            formData.append("name", data.name)
            formData.append("date", data.date)
            formData.append("leader", data.leader)
            formData.append("description", data.description)
            // if (this.state.imagesArr.length) {
            //     for (const file of this.state.imagesArr) {
            //         formData.append('image', file);
            //     };
            // }
            if (data.team.length) {
                for (const team of data.team) {
                    formData.append('team', team);
                };
            }

            if (this.state.imagesArr.length) {
                if (this.state.existingImage.length) {
                    var sendData = this.state.imagesArr.concat(this.state.existingImage)
                    for (const file of sendData) {
                        formData.append('image', file);
                    };
                }
                else {
                    for (const file of this.state.imagesArr) {
                        formData.append('image', file);
                    };
                }
            } else {
                if (data.image) {
                    formData.append('image', JSON.stringify(data.image));
                }
            }
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addProject, formData)
                    .then((res) => {
                        this.props.ResetProject();
                        this.setState({ saving: false, closed: true })
                        Success('Project created successfully');
                        this.setState({ listRedirect: true })
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        // if (response.status == 500) {
                        //     Error(response.status + ' Internal Server Error')
                        // } else
                        if (response) {
                            if (response.status == 502) {
                                Error(response.status + ' Bad Gateway')
                            } else if (response.status == 409) {
                                Error('Project already exist')
                            } else if (response.status == 400) {
                                Error('Bad request')
                            } else {
                                Error(response.statusMessage)
                            }
                        }
                    });
            } else {
                axios.post(ImportedURL.API.updateProject + "/" + this.state.id, formData)
                    .then((res) => {
                        this.props.ResetProject();
                        this.setState({ saving: false, closed: true })
                        Success('Project updated successfully');
                        this.setState({ listRedirect: true })
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        // Error(response.statusText)
                        if (response) {
                            if (response.status == 500) {
                                Error(response.status + ' Internal Server Error')
                            } else if (response.status == 502) {
                                Error(response.status + ' Bad Gateway')
                            } else if (response.status == 409) {
                                Error('Project already exist')
                            } else if (response.status == 400) {
                                Error('Bad request')
                            } else {
                                Error(response.statusMessage)
                            }
                        }
                    });
                // this.props.UpdateJobTitle(data, this.state.id);
            }
        }
    }

    ResetModules = () => {
        this.setState({
            moduledata: { module: '' },
            moduleindex: '', submoduledata: { submodule: '', module: '' },
            submoduleindex: '', submoduleError: false,
            moduleError: false
        })
    }

    submitModule = () => {
        const data = this.state.moduledata;
        const { ProjectState } = this.props;
        let valid = 1
        if (!data.module) {
            this.setState({ moduleError: true })
            valid = 0;
        };

        if (valid) {
            let modules = [...ProjectState.modules]

            if (this.state.moduleindex !== '') {
                modules[this.state.moduleindex] = data
            } else {
                let obj = modules.find(o => o.module.toUpperCase() == data.module.toUpperCase())
                if (obj) return Error('Module already exist')
                modules.push(data)
            }
            this.setState({ modulesaving: true })

            axios.post(ImportedURL.API.updateProjectModule + "/" + this.state.id, { modules: JSON.stringify(modules) })
                .then((res) => {
                    this.ResetModules();
                    this.setState({ modulesaving: false })
                    Success('Project updated successfully');
                    const { params } = this.props.match;
                    this.props.ViewProject(params.id);
                    let btn = document.getElementById("leaveModel");
                    btn.click();
                }).catch(({ response }) => {
                    this.setState({ modulesaving: false })
                    if (response) {
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Project already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    }
                });
        }
    }

    deleteModule = (index, type = 'module') => {
        Swal.fire({
            title: 'Are you sure want to delete?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            imageUrl: 'assets/images/delete.png',
            customClass: {
                popup: 'swal_pop',
                title: 'swal_title',
                image: 'swal_image',
                actions: 'swal_action',
                confirmButton: 'swal_confirm',
                cancelButton: 'swal_close',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                let modules = type == 'module' ? [...this.props.ProjectState.modules] : [...this.props.ProjectState.submodules]
                modules.splice(index, 1);

                let formData = type == 'module' ? { modules: JSON.stringify(modules) } : { submodules: JSON.stringify(modules) }
                axios.post(ImportedURL.API.updateProjectModule + "/" + this.state.id, formData)
                    .then((res) => {
                        this.ResetModules();
                        Success('Project updated successfully');
                        const { params } = this.props.match;
                        this.props.ViewProject(params.id);
                    }).catch(({ response }) => {
                        if (response) {
                            if (response.status == 500) {
                                Error(response.status + ' Internal Server Error')
                            } else if (response.status == 502) {
                                Error(response.status + ' Bad Gateway')
                            } else if (response.status == 409) {
                                Error('Project already exist')
                            } else if (response.status == 400) {
                                Error('Bad request')
                            } else {
                                Error(response.statusMessage)
                            }
                        }
                    });
            }
        })

    }
    handleChangeModule = (e) => {
        let { name, value } = e.target;
        if (name == 'module')
            this.setState({ moduledata: { ...this.state.moduledata, [name]: capitalizeFirstLetter(value), slug: convertToSlug(value) }, [name + 'Error']: false })
        else
            this.setState({ submoduledata: { ...this.state.submoduledata, [name]: capitalizeFirstLetter(value), slug: convertToSlug(value) }, [name + 'Error']: false })
    }
    changeModule = (e) => {
        this.setState({ submoduledata: { ...this.state.submoduledata, module: e.value }, moduleError: false })
    }
    submitSubModule = () => {
        const data = this.state.submoduledata;
        const { ProjectState } = this.props;
        let valid = 1
        if (!data.module) {
            this.setState({ moduleError: true })
            valid = 0;
        };
        if (!data.submodule) {
            this.setState({ submoduleError: true })
            valid = 0;
        };
        if (valid) {
            let modules = [...ProjectState.submodules]

            if (this.state.moduleindex !== '') {
                modules[this.state.moduleindex] = data
            } else {
                let obj = modules.find(o => o.submodule.toUpperCase() == data.submodule.toUpperCase() && o.module.toUpperCase() == data.module.toUpperCase())
                if (obj) return Error('Submodule already exist')
                modules.push(data)
            }
            this.setState({ modulesaving: true })
            axios.post(ImportedURL.API.updateProjectModule + "/" + this.state.id, { submodules: JSON.stringify(modules) })
                .then((res) => {
                    this.ResetModules();
                    this.setState({ modulesaving: false })
                    Success('Project updated successfully');
                    const { params } = this.props.match;
                    this.props.ViewProject(params.id);
                    let btn = document.getElementById("leaveModelSub");
                    btn.click();
                }).catch(({ response }) => {
                    this.setState({ modulesaving: false })
                    if (response) {
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Project already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    }
                });
        }
    }
    render() {
        if (this.state.listRedirect) {
            return <Redirect to={'/list-project'} />
        }


        const { fixNavbar, ProjectState, AccountState, employeeState, DesignationState } = this.props;
        const account = AccountState?.account;
        const data = ProjectState.project;
        const employeeData = employeeState.listEmployee;

        let selectedEmployeeData = '';
        if (data.leader) {
            selectedEmployeeData = employeeData.find(e => e._id === data.leader)
        }
        const employeeOptions = [];
        employeeData.filter(filterItem => filterItem.status === true).map(item => {
            employeeOptions.push({ label: item.employeeName, value: item._id, name: 'leader' });
        })



        const employeeTeamOptions = [];
        employeeData.filter(filterItem => filterItem.status === true).map(item => {
            employeeTeamOptions.push({ label: item.employeeName, value: item._id, name: 'team' });
        });

        const selectedTeamArray = [];
        employeeData.filter(filterItem => filterItem.status === true).map(item => {
            if (data.team != []) {
                (data.team.length != '') && (data.team).map((teamValue) => {
                    if (teamValue == item._id) {
                        selectedTeamArray.push({ label: item.employeeName, value: item._id, name: 'team' });
                    }
                })
            }
        })

        const photos = [];
        if (this.state.imageArray.length > 0 && this.state.existingImage.length > 0) {
            this.state.existingImage.map((image, i) => {
                let isImgtype = false;
                isImgtype = image.includes(".pdf")
                photos.push(
                    <div key={i} style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                        <div style={{ position: 'relative' }}>
                            <img src={isImgtype ? pdf : (image ? ImportedURL.LIVEURL + image : '')} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemoveExisting(i, 'edit')} ></i>
                        </div>
                    </div>)
            })
            this.state.imageArray.map((image, i) => {
                var isImgtype = false;
                isImgtype = image.includes(".pdf")
                photos.push(
                    <div key={i} style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                        <div style={{ position: 'relative' }}>
                            <img src={isImgtype ? pdf : image} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} />
                        </div>
                        <i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                    </div>
                )
            })
        } else {
            if (this.state.imageArray.length) {
                this.state.imageArray.map((image, i) => {
                    let firstSet = image.split("/")[1];
                    let secoundSet = firstSet.split(";")[0];
                    if (secoundSet == ('pdf' || 'PDF')) {
                        photos.push(
                            <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={pdf} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                                </div>
                            </div>
                        )
                    } else if (secoundSet == ('DOC' || 'doc' || 'docx' || 'DOCX' || 'msword')) {
                        photos.push(

                            <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={doc} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                                </div>
                            </div>
                        )

                    } else {
                        photos.push(
                            <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={image} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                                </div>
                            </div>
                        )
                    }

                })
            } else if (data.image && data.image.length) {
                data.image.map((image, i) => {
                    let isImgtype = false;
                    let name = image && image.split(".")[1];
                    isImgtype = name == 'pdf'
                    photos.push(
                        <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                            <div style={{ position: 'relative' }}>
                                <img src={isImgtype ? pdf : (image ? ImportedURL.LIVEURL + image : '')} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'editFirst')} ></i>
                            </div>
                        </div >)
                })
            }
        }

        let moduledata = this.state.moduledata;
        let submoduledata = this.state.submoduledata;
        let modules = ProjectState.modules
        let submodules = ProjectState.submodules

        const moduleOptions = [];
        let moduleval = ''
        modules.map(item => {
            if (item.module == submoduledata.module) moduleval = { label: item.module, value: item.module }
            moduleOptions.push({ label: item.module, value: item.module });
        })
        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/add-project"><h6 className="highlights_breadcrump"> Add Project</h6></Link>  {this.state.modalType == "Edit" ? <><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6 className="highlights_breadcrump"> Edit Project</h6></> : ''}  </p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'><i className="fa fa-project-diagram mr-2 "></i></div>
                                        <h3 className="card-title">{this.state.modalType} Project</h3>
                                        <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                            <Link to='/list-project'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-sm-6 col-md-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Project Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" name='name' onChange={this.handleChange} value={data.name} placeholder="First Name" />
                                                    <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}> Name is required</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Start Date<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <div className='site_pass'>
                                                        <input type="date" className="form-control" name="date" value={data.date} onChange={this.handleChange} />
                                                        <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}> Date is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <label className="form-label">Add Project Manager<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        value={selectedEmployeeData ? { label: selectedEmployeeData.employeeName, value: selectedEmployeeData._id, name: "leader" } : ''}
                                                        onChange={this.handleChangeLeader}
                                                        options={employeeOptions}

                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.leaderError ? "block" : 'none' }}>Add Project Leader is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <label className="form-label">Add Team<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        isMulti
                                                        value={selectedTeamArray}
                                                        onChange={this.handleChangeTeam}
                                                        options={employeeTeamOptions}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.teamError ? "block" : 'none' }}>Add Team is required</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label" >Description</label>
                                                    <CKEditor
                                                        activeclassName="p10"
                                                        content={data.description}
                                                        events={{
                                                            "change": this.onChangeEditor
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group ">
                                                    <label className="form-label">
                                                        Upload Files
                                                    </label>
                                                    <input type="file" className="form-control" multiple disabled={this.state.modalType === "View"} placeholder="Staff Image" name='image' onChange={this.state.modalType === "Edit" ? this.onChangeEdit : this.onChangeAdd} id="imageSet" />
                                                </div>
                                                <div className="invalid-feedback" style={{ display: this.state.imageError ? "block" : 'none' }}>Image is required</div>
                                                {photos.length > 0 ?
                                                    <div className="form-group " >
                                                        <div className="photos_wid">
                                                            {photos}
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.modalType == 'Edit' ?
                                        <div className="card-footer text-right mandatory">
                                            <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                            <div className="text-right">
                                                {
                                                    this.state.saving ?
                                                        <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                        :
                                                        <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                            <i className="fe fe-save mr-2"></i>Update
                                                        </button>
                                                }
                                            </div>
                                        </div> :
                                        <div className="card-footer text-right mandatory">
                                            <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                            <div className="text-right">
                                                {
                                                    this.state.saving ?
                                                        <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                        :
                                                        <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                            <i className="fe fe-save mr-2"></i>Save
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                {this.state.modalType == 'Edit' ? <div className="row">
                                    <div className='col-sm-6 col-md-6'>
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-cubes mr-2 "></i></div>
                                                <h3 className="card-title">Modules</h3>
                                                <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                                    <button type="button" className="btn btn-primary" id='Add' data-toggle="modal" data-target="#exampleModal" onClick={this.ResetModules}><i className="fa fa-plus mr-2" ></i>Add</button>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-responsive">
                                                    <table class="table table-striped table-hover table-vcenter text-nowrap mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th class="w60">Name</th>
                                                                {/* <th>Status</th> */}
                                                                <th class="w100 text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {modules.length ? <tbody>

                                                            {modules.map((item, i) => {
                                                                return <tr>

                                                                    <td>
                                                                        <h6 class="mb-0">{item.module}</h6>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button type="button" class="btn btn-icon p-0 m-3" title="Edit" data-toggle="modal" data-target="#exampleModal" onClick={() => { this.setState({ moduleindex: i, moduledata: item }) }}>
                                                                            <i class="fa fa-edit"></i>
                                                                        </button>
                                                                        <button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-type="confirm" onClick={() => { this.deleteModule(i, 'module') }}>
                                                                            <i class="fa fa-trash-o" style={{ color: 'red' }} ></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            })}

                                                        </tbody> :
                                                            <tr >
                                                                <td colspan="12" className='text-center'>
                                                                    <h6 class="mb-0 p-4"> No data found</h6>


                                                                </td>
                                                            </tr>}
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-md-6'>
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-cubes mr-2 "></i></div>
                                                <h3 className="card-title">Sub Modules</h3>
                                                <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                                    <button type="button" className="btn btn-primary" id='Add' data-toggle="modal" data-target="#exampleModalSub" onClick={this.ResetModules}><i className="fa fa-plus mr-2" ></i>Add</button>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-responsive">
                                                    <table class="table table-striped table-hover table-vcenter text-nowrap mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th class="w60">Module</th>
                                                                <th class="w60">Name</th>
                                                                {/* <th>Status</th> */}
                                                                <th class="w100 text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {submodules.length ? <tbody>

                                                            {submodules.map((item, i) => {
                                                                return <tr>

                                                                    <td>
                                                                        <h6 class="mb-0">{item.module}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 class="mb-0">{item.submodule}</h6>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button type="button" class="btn btn-icon p-0 m-3" title="Edit" data-toggle="modal" data-target="#exampleModalSub" onClick={() => { this.setState({ moduleindex: i, submoduledata: item }) }}>
                                                                            <i class="fa fa-edit"></i>
                                                                        </button>
                                                                        <button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-type="confirm" onClick={() => { this.deleteModule(i, 'submodule') }}>
                                                                            <i class="fa fa-trash-o" style={{ color: 'red' }} ></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            })}

                                                        </tbody> :
                                                            <tr >
                                                                <td colspan="12" className='text-center'>
                                                                    <h6 class="mb-0 p-4"> No data found</h6>


                                                                </td>
                                                            </tr>}
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> : ''}
                            </div>
                            <div className="modal fade" id="exampleModal" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2 "></i></div>
                                            <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.moduleindex !== '' ? 'Edit' : 'Add'} Module</h5>
                                            <button type="button" id="leaveModel" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true" style={{ fontSize: "23px" }} id="leaveModel" onClick={this.clear}>
                                                    <img src='../../assets/images/cancel.png' />
                                                </span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row clearfix">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='module' onChange={this.handleChangeModule} placeholder="Module name" value={moduledata.module} />
                                                        <div className="invalid-feedback" style={{ display: this.state.moduleError ? "block" : 'none' }}>Module name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.moduleindex !== '' ?
                                                <div className="card-footer text-right mandatory">
                                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                    <div className="" >
                                                        {
                                                            this.state.modulesaving ?
                                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                :
                                                                <button type="button" className="btn commor_save" onClick={this.submitModule}><i className="fe fe-save mr-2"></i>Update</button>
                                                        }
                                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                                    </div>
                                                </div>
                                                : <div className="card-footer text-right mandatory">
                                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                    <div className="" >
                                                        {
                                                            this.state.modulesaving ?
                                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                                :
                                                                <button type="button" className="btn commor_save" onClick={this.submitModule}><i className="fe fe-save mr-2"></i>Save</button>
                                                        }
                                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                                    </div>
                                                </div>
                                        }


                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="exampleModalSub" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2 "></i></div>
                                            <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.moduleindex !== '' ? 'Edit' : 'Add'} Sub Module</h5>
                                            <button type="button" id="leaveModelSub" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true" style={{ fontSize: "23px" }} id="leaveModelSub" onClick={this.clear}>
                                                    <img src='../../assets/images/cancel.png' />
                                                </span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row clearfix">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Module<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={moduleval}
                                                            onChange={this.changeModule}
                                                            options={moduleOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.moduleError ? "block" : 'none' }}>Module is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='submodule' onChange={this.handleChangeModule} placeholder="Module name" value={submoduledata.submodule} />
                                                        <div className="invalid-feedback" style={{ display: this.state.submoduleError ? "block" : 'none' }}>Submodule name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.state.moduleindex !== '' ?
                                                <div className="card-footer text-right mandatory">
                                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                    <div className="" >
                                                        {
                                                            this.state.modulesaving ?
                                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                :
                                                                <button type="button" className="btn commor_save" onClick={this.submitSubModule}><i className="fe fe-save mr-2"></i>Update</button>
                                                        }
                                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                                    </div>
                                                </div>
                                                : <div className="card-footer text-right mandatory">
                                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                    <div className="" >
                                                        {
                                                            this.state.modulesaving ?
                                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                                :
                                                                <button type="button" className="btn commor_save" onClick={this.submitSubModule}><i className="fe fe-save mr-2"></i>Save</button>
                                                        }
                                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                                    </div>
                                                </div>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    ProjectState: state.project,
    employeeState: state.employeeReducer,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            EmptyProject: AC_EMPTY_PROJECT,
            HandleInputChange: AC_HANDLE_PROJECT_CHANGE,
            ViewProject: AC_VIEW_PROJECT,
            ResetProject: AC_RESET_PROJECT,
            ListEmployee: AC_LIST_EMPLOYEE,
            // HandleModuleInputChange: AC_HANDLE_MODULE_CHANGE
        }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);