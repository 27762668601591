import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_EMPLOYEE, AC_RESET_EMPLOYEE } from '../../actions/employeeAction';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import { AC_LIST_DEPARTMENT } from '../../actions/departmentAction';
import { AC_LIST_DESIGNATION } from '../../actions/designationAction';
import ImportedURL from '../../common/api';
import moment from 'moment';


class ViewEmployee extends Component {



    handleclear = e => {
        this.props.AC_RESET_EMPLOYEE();
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.AC_VIEW_EMPLOYEE(id)
        this.props.AC_LIST_COUNTRY();
        this.props.AC_LIST_DEPARTMENT();
        this.props.AC_LIST_DESIGNATION();
        localStorage.setItem('invoicetoken', "");
    }

    render() {
        var data = this.props.employeeReducer.employee;
        var phoneData = this.props.countryReducer.listCountry;

        var editdepartmentData = this.props.departmentReducer.listDepartment;
        var editdesignationData = this.props.designationReducer.listDesignation;
        var editdesignationData = this.props.designationReducer.listDesignation;


        var ccData = '';
        if (data.cc) {
            ccData = phoneData.find(e => e._id === data.cc)
        }

        var departmentData = '';
        if (data.department) {
            departmentData = editdepartmentData.find(e => e._id === data.department)
        }

        var designationData = '';
        if (data.designation) {
            designationData = editdesignationData.find(e => e._id === data.designation)
        }
        var divisionData = '';
        if (data.division) {
            divisionData = editdesignationData.find(e => e._id === data.division)
        }
        let kycImages = []
        {
            data.kycimage && data.kycimage.map((item) => {
                kycImages.push(<div className='kyc_image_view'><img src={ImportedURL.LIVEURL + item} alt="" /></div>)
            })
        }
        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listemployee"><h6>List Employee</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6>View Employee</h6></p>
                </div>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Project-OnGoing" role="tabpanel">
                                <div className='row'>
                                    <div className="col-lg-12   col-md-12">
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className="card ">
                                                    <div className="card-header">
                                                        <div className='rounded_icon'>
                                                            <i className="fa-solid fa-user mr-2"></i>
                                                        </div>
                                                        <h3 className="card-title">View Employee</h3>
                                                        <div class="card-options">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <ul className="nav nav-tabs page-header-tab"></ul>

                                                                <div className="header-action">
                                                                    <Link to='/listemployee'>
                                                                        <button type="button" onClick={this.handleclear} className="btn btn-round btn-primary">
                                                                            <i className="fa fa-solid fa-chevron-left mr-2"></i>Back
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4 py-1"><strong>Name</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.employeeName}</div>

                                                            <div className="col-4 py-1"><strong>Image</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{<img style={{ width: '40px', height: '40px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + data.image} alt="" />}</div>

                                                            <div className="col-4 py-1"><strong>ID</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.employeeId}</div>

                                                            <div className="col-4 py-1"><strong>Category</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.employeecategoryName}</div>

                                                            <div className="col-4 py-1"><strong>Role</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.roleName}</div>
                                                            {/* 
                                                            <div className="col-4 py-1"><strong>Salary</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.salary}</div> */}

                                                            <div className="col-4 py-1"><strong>Join Date</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{moment(data.date).format('DD/MM/YYYY')}</div>

                                                            <div className="col-4 py-1"><strong>Assign Manager</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.managerName}</div>

                                                            <div className="col-4 py-1"><strong>Email</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.email}</div>

                                                            {/* <div className="col-4 py-1"><strong>Flag:</strong></div>
                                                <div className="col-7 py-1"></div> */}

                                                            <div className="col-4 py-1"><strong>Phone Number</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{<span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + ccData?.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` }} />} {data.ccName} {data.phoneNo}</div>

                                                            <div className="col-4 py-1"><strong>Username</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.username}</div>

                                                            {/* <div className="col-4 py-1"><strong>Password:</strong></div>
                                                <div className="col-7 py-1">{data.password}</div> */}

                                                            <div className="col-4 py-1"><strong>Department</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.departmentName}</div>

                                                            <div className="col-4 py-1"><strong>Division</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.divisionName}</div>

                                                            <div className="col-4 py-1"><strong>Designation</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.designationName}</div>

                                                            {/* <div className="col-5 py-1"><strong>Status:</strong></div>
                                                <div className="col-7 py-1">
                                                    <button type='button' disabled className="tag btn px-0 py-0">
                                                        {data.status ? <span style={{ marginTop: '1px', backgroundColor: 'green', color: 'white' }} className='tag tag-success'>Active</span> : <span style={{ cursor: 'pointer', marginTop: '1px', backgroundColor: 'red', color: 'white' }} className='tag tag-danger'>Inactive</span>}
                                                    </button>
                                                </div> */}



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {data.passportNo || data.passportDate || data.tel || data.nationality || data.religion || data.maritalstatus || data.spouse || data.noofchildren ? */}
                                            <div className='col-lg-6'>
                                                <div className="card  p-2">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Personal Informations</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4 py-1"><strong>D.O.B</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.dob ? moment(data.dob).format('DD/MM/YYYY') : ""}</div>

                                                            {/* <div className="col-4 py-1"><strong>Passport Exp Date</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.passportDate ? data.passportDate : ''}</div>

                                                            <div className="col-4 py-1"><strong>Tel</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.tel ? data.tel : ''}</div> */}

                                                            <div className="col-4 py-1"><strong>Nationality</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.nationality ? data.nationality : ''}</div>

                                                            <div className="col-4 py-1"><strong>Location</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.location ? data.location : ''}</div>

                                                            <div className="col-4 py-1"><strong>Religion</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.religion ? data.religion : ''}</div>

                                                            <div className="col-4 py-1"><strong>Blood Group</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.bloodgroup ? data.bloodgroup : ''}</div>

                                                            <div className="col-4 py-1"><strong>Marital status</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.maritalstatus ? data.maritalstatus : ''}</div>

                                                            {/* <div className="col-4 py-1"><strong>Employment of spouse</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.spouse ? data.spouse : ''}</div>

                                                            <div className="col-4 py-1"><strong>No. of children</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.noofchildren ? data.noofchildren : ''}</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* //  : ''} */}
                                            <div className='col-lg-6'>
                                                <div className="card  p-2">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Emergency Contact</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <h5 className='pb-3 underline_double_color'>Primary</h5>
                                                            <div className="col-4 py-1"><strong>Name</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.primaryName ? data.primaryName : ''}</div>

                                                            <div className="col-4 py-1"><strong>Relationship</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.primaryRelationship ? data.primaryRelationship : ''}</div>

                                                            <div className="col-4 py-1"><strong>Phone</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.primaryPhone ? data.primaryPhone : ''}</div>
                                                            <h5 className='pt-3 pb-2 underline_double_color'>Secondary</h5>
                                                            <div className="col-4 py-1"><strong>Name</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.secondaryName ? data.secondaryName : ''}</div>

                                                            <div className="col-4 py-1"><strong>Relationship</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.secondaryRelationship ? data.secondaryRelationship : ''}</div>

                                                            <div className="col-4 py-1"><strong>Phone</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.secondaryPhone ? data.secondaryPhone : ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className="card  p-2">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Bank information</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4 py-1"><strong>Bank as Per Name</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.bankasperName ? data.bankasperName : ''}</div>

                                                            <div className="col-4 py-1"><strong>Bank name</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.bankInfo ? data.bankInfo : ''}</div>

                                                            <div className="col-4 py-1"><strong>Bank account No</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.bankaccountNo ? data.bankaccountNo : ''}</div>

                                                            <div className="col-4 py-1"><strong>IFSC Code</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.ifscCode ? data.ifscCode : ''}</div>

                                                            <div className="col-4 py-1"><strong>Branch</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.branchname ? data.branchname : ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="card  p-2">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Family Informations</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4 py-1"><strong>Name</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.famName ? data.famName : ''}</div>

                                                            <div className="col-4 py-1"><strong>Relationship</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.famRelationship ? data.famRelationship : ''}</div>

                                                            <div className="col-4 py-1"><strong>Date of Birth</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.famdateofbirth ? moment(data.famdateofbirth).format('DD/MM/YYYY') : ""}</div>

                                                            <div className="col-4 py-1"><strong>Phone</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.famPhone ? data.famPhone : ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="card  p-2">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Education Informations</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4 py-1"><strong>Institution</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.institution ? data.institution : ''}</div>

                                                            <div className="col-4 py-1"><strong>Degree</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.degree ? data.degree : ''}</div>

                                                            <div className="col-4 py-1"><strong>Start Date</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.educationstartdate ? moment(data.educationstartdate).format('YYYY') : ''}</div>

                                                            <div className="col-4 py-1"><strong>Subject</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.subject ? data.subject : ''}</div>

                                                            <div className="col-4 py-1"><strong>Completed Date</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.educationcompletedate ? moment(data.educationcompletedate).format('YYYY') : ""}</div>

                                                            <div className="col-4 py-1"><strong>Grade</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.educationgrade ? data.educationgrade : ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className="card  p-2">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Experience</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4 py-1"><strong>Company Name</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.expcompanyname ? data.expcompanyname : ''}</div>

                                                            <div className="col-4 py-1"><strong>job Position</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.expjobposition ? data.expjobposition : ''}</div>

                                                            <div className="col-4 py-1"><strong>period From</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.expperiodfrom ? moment(data.expperiodfrom).format('YYYY') : ''}</div>

                                                            <div className="col-4 py-1"><strong>Location</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.explocation ? data.explocation : ''}</div>

                                                            <div className="col-4 py-1"><strong>Period To</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.expperiodto ? moment(data.expperiodto).format('YYYY') : ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-9'>
                                                <div className="card  p-2">
                                                    <div className="card-header">
                                                        <h3 className="card-title">KYC</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className='row'>
                                                            <div className='col-lg-4'>
                                                                <div className="form-group">
                                                                    <label className="form-label">KYC Document</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <div className="form-group">
                                                                    <label className="form-label">KYC Number</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <label className="form-label">KYC Images</label>
                                                            </div>
                                                        </div>
                                                        {
                                                            data.kyc ? data.kyc.map((item, i) => {
                                                                return <>
                                                                    <hr />
                                                                    <div className='row'>
                                                                        <div className='col-lg-4'>
                                                                            <div className="form-group">
                                                                                <p> {item.kycname}</p>
                                                                                {/* <label className="form-label">KYC Documents</label> */}
                                                                                {/* <Select
                                                                                value={kycdocumentdata ? { label: kycdocumentdata } : ''}
                                                                                onChange={this.AddSelectHandleChange}
                                                                                options={listkycdocument ? listkycdocument.filter(filterItem => filterItem.status === true).map(item => {
                                                                                    return {
                                                                                        label: item.name, value: item._id, name: 'kycname', _id: item._id
                                                                                    }
                                                                                }) : ''}
                                                                            /> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <div className="form-group">
                                                                                <p> {item.kycnumber}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <div style={{ width: '200px', height: '100px' }}>
                                                                                <img src={ImportedURL.LIVEURL + item.kycimage} alt='12' />
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </>

                                                            })
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    employeeReducer: state.employeeReducer,
    countryReducer: state.countryReducer,
    departmentReducer: state.departmentReducer,
    designationReducer: state.designationReducer
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ AC_VIEW_EMPLOYEE, AC_RESET_EMPLOYEE, AC_LIST_COUNTRY, AC_LIST_DEPARTMENT, AC_LIST_DESIGNATION }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEmployee);