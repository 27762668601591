import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_USER_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_USER_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_USER() {
	return function (dispatch) {
		dispatch({ type: "RESET_USER" })
	};
}

export function AC_ADD_USER(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.addUser, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_USER", payload: res.data })
				dispatch({ type: "RESET_USER" })
			}).catch(({ response }) => { Error(response.statusText) });
	};
}

export function AC_LIST_USER() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listUser)
			.then((res) => {
				dispatch({ type: "LIST_USER", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_USER(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewUser + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_USER", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_USER(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateUser + "/" + id, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_USER", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_DELETE_USER(id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.deleteUser + "/" + id)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_USER", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}
