import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_TASK_BOARD_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TASK_BOARD_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_TASK_BOARD() {
    return function (dispatch) {
        dispatch({ type: "RESET_TASK_BOARD" })
    };
}

export function AC_LIST_TASK_BOARD(params = {}) {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listTaskBoard, { params: params })
                .then((res) => {
                    dispatch({ type: "LIST_TASK_BOARD", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_TASK_BOARD(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTaskBoard + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_TASK_BOARD", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


// ==============================   Task  =====================================

export function AC_HANDLE_TASK_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TASK_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_TASK() {
    return function (dispatch) {
        dispatch({ type: "RESET_TASK" })
    };
}

export function AC_LIST_TASK(params = {}) {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listTask, { params: params })
                .then((res) => {
                    dispatch({ type: "LIST_TASK", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_TASK(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTask + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_TASK", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}



