import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { AC_EMPTY_PROJECT, AC_LIST_PROJECT, AC_VIEW_PROJECT } from '../../actions/projectAction';
import { Calendar } from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
let todaydate = new Date();

const initialState = {
    modalType: "Add",
    columnDefs: [
        // { headerName: '#', valueGetter: "node.rowIndex+1", hide: true, width: 50, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', hide: 'true' },
        { headerName: 'Project', field: 'projectname', width: 200, headerClass: 'ag-center-header', },
        { headerName: 'Project Manager', field: 'managername', width: 200, headerClass: 'ag-center-header', },
        { headerName: 'Module', field: 'module', width: 180, headerClass: 'ag-center-header', },
        {
            headerName: 'Rating', width: 140, field: 'rating', cellStyle: { textAlign: 'center' },
            valueGetter: function (params) {
                if (params.data.rating) return params.data.rating
                else return '-'
            }
        },
        {
            headerName: 'Status', width: 120, field: 'approved', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.approved) return '<span class="tag tag-success">Approved</span>'
                else return '<span class="tag tag-warning">Pending</span>'
                // if (params.data.approved) return '<i class="fa fa-thumbs-up" style="color:#2CBAEC;font-size: 20px"></i>'
                // else return '<i class="fa fa-thumbs-up" style="color:grey;font-size: 20px"></i>'
            }
        },
        {
            headerName: 'Actions', width: 130, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return params.value
                // return '<div><button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModalView" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View" data-toggle="tooltip" title="View"></i></button><button type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button><button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>'
            }
        },
    ],
    columnDefs1: [
        {
            headerName: 'Date', field: 'date', width: 150, headerClass: 'ag-center-header',
            valueGetter: function (params) {
                if (params.data.date) return moment(params.data.date).format('MM/DD/YYYY')
                else return '-'
            }
        },
        { headerName: 'Project', field: 'projectname', width: 150, headerClass: 'ag-center-header', },
        { headerName: 'Module', field: 'module', width: 150, headerClass: 'ag-center-header', },
        {
            headerName: 'Sub Module', field: 'submodule', width: 170, headerClass: 'ag-center-header',
            valueGetter: function (params) {
                if (params.data.submodule) return params.data.submodule
                else return '-'
            }
        },
        {
            headerName: 'Rating', width: 70, field: 'rating', cellStyle: { textAlign: 'center' },
            valueGetter: function (params) {
                if (params.data.rating) return params.data.rating
                else return '-'
            }
        },
        {
            headerName: 'Status', width: 150, field: 'approved', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.approved) return '<span class="tag tag-success">Approved</span>'
                else return '<span class="tag tag-warning">Pending</span>'
            }
        },
    ],
    defaultColumDef: {
        editable: false,
        // sortable: true,
        resizable: true,
        // filter: true,
        flex: 1,
    },
    nameError: false,
    id: '',
    hotelError: false,
    saving: false,
    searchhoteloption: '',
    searchhotel: '',
    viewRedirect: false,
    editRedirect: false,
    viewRedirectId: '',
    closed: false,
    editSpi: false,
    viewSpin: false,
    hotelName: '',
    employeeprojects: [],
    taskdata: { approved: false },
    taskList: [],
    timesheetdate: new Date(),
    modules: [],
    submodules: [],
    historylist: [],
    hidehistory: true,
    fromdate: moment(new Date(todaydate.getFullYear(), todaydate.getMonth() - 0, 1)).format('YYYY-MM-DD'),
    myleaves: [],
    myholidays: [],
    pendingleaves: [],
}
class ListProject extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.ResetTask();
            this.setState({ modalType: "View", taskdata: event.data });
        }
        if (value === 'Edit') {
            this.ResetTask();
            let project = this.state.employeeprojects.find(item => item._id == event.data.project)
            this.setState({ modalType: "Edit", taskdata: event.data, rowIndex: event.rowIndex, modules: project.modules, submodules: project.submodules });

        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    let tasks = [...this.state.taskList]
                    tasks.splice(event.rowIndex, 1);
                    axios.post(ImportedURL.API.updateTimesheetentry, { tasks: JSON.stringify(tasks), id: this.state.entryid })
                        .then((res) => {
                            let btn = document.getElementById("leaveModel");
                            btn.click();
                            if (res.data)
                                this.getEmployeeEntry()

                        }).catch((err) => {
                            console.log(err);
                        });
                }
            })
        }

    }

    componentDidMount() {
        axios.get(ImportedURL.API.listEmployeeProject)
            .then((res) => {
                this.setState({ employeeprojects: res.data })
            }).catch((err) => {
                console.log(err);
            });
        this.getEmployeeEntry()
        this.gethistory()
        this.getLeaves(moment(new Date()).format('YYYY-MM-DD'));
        localStorage.setItem('invoicetoken', "");
    }

    getEmployeeEntry = (date = this.state.timesheetdate ? moment(this.state.timesheetdate).format('YYYY-MM-DD') : '') => {
        axios.post(ImportedURL.API.getemployeeEntry, { date: date })
            .then((res) => {
                if (res.data && res.data._id)
                    this.setState({ entryexist: true, entryid: res.data._id, taskList: res.data.tasks, requeststatus: res.data.requeststatus ? res.data.requeststatus : '' })
                else
                    this.setState({ entryexist: false, entryid: '', taskList: [], requeststatus: res.data ? res.data.requeststatus : '' })

            }).catch((err) => {
                console.log(err);
            });
    }

    getLeaves = (date) => {
        axios.post(ImportedURL.API.getLeavesHolidays, { date: date })
            .then((res) => {
                if (res.data)
                    this.setState({
                        myleaves: res.data.leaves ? res.data.leaves : [],
                        myholidays: res.data.holidays ? res.data.holidays : [],
                        pendingleaves: res.data.pendingleaves ? res.data.pendingleaves : [],

                    })

            }).catch((err) => {
                console.log(err);
            });
    }

    gethistory = (fromdate = this.state.fromdate, todate = this.state.todate) => {
        axios.post(ImportedURL.API.gettimesheethistory, { fromdate: fromdate, todate: todate })
            .then((res) => {
                if (res.data)
                    this.setState({ historylist: res.data })

            }).catch((err) => {
                console.log(err);
            });
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    setDateValue = e => {
        this.setState({ timesheetdate: e.toDate() })
        this.getEmployeeEntry(moment(e.toDate()).format('YYYY-MM-DD'))
    }


    monthChangeDatepicker = e => {
        this.getLeaves(moment(e.toDate()).format('YYYY-MM-DD'));
        // this.setState({ timesheetdate: e.year })
        // this.props.ListLeaves({ year: e.year });
    }

    projectSelectChange = e => {
        this.setState({
            taskdata:
            {
                ...this.state.taskdata,
                projectname: e.label,
                project: e.value,
                projectmanager: e.item.leader,
                managername: e.item.leadername,
                managerId: e.item.leaderid
            },
            modules: e.item.modules,
            submodules: e.item.submodules,
        })
    }

    handleChange = e => {
        let { name, value } = e.target;
        this.setState({ taskdata: { ...this.state.taskdata, [name]: value, } })
        this.setState({ [name + 'Error']: false })
    }

    moduleSelectChange = e => {
        let { name, value } = e;
        if (name == 'module')
            this.setState({ taskdata: { ...this.state.taskdata, [name]: value, submodule: '' } })
        else
            this.setState({ taskdata: { ...this.state.taskdata, [name]: value, } })
    }

    submit = e => {
        let { taskdata } = this.state;
        let valid = 1;
        if (!taskdata.project) {
            valid = 0;
            this.setState({ projectError: true })
        }
        if (!taskdata.module) {
            valid = 0;
            this.setState({ moduleError: true })
        }
        if (!taskdata.projectmanager) {
            valid = 0;
            this.setState({ projectmanagerError: true })
        }

        this.setState({ saving: true })

        if (this.state.entryid) {
            let tasks = [...this.state.taskList]
            if (this.state.modalType == 'Add')
                tasks.push(this.state.taskdata)
            else tasks[this.state.rowIndex] = this.state.taskdata
            axios.post(ImportedURL.API.updateTimesheetentry, { tasks: JSON.stringify(tasks), id: this.state.entryid })
                .then((res) => {
                    Success('Task updated sucessfully')
                    this.setState({ saving: false })
                    let btn = document.getElementById("leaveModel");
                    btn.click();
                    if (res.data)
                        this.getEmployeeEntry()

                }).catch((err) => {
                    Error('Task update failed')
                    this.setState({ saving: false })
                    console.log(err);
                });
        } else {
            axios.post(ImportedURL.API.addTimesheetentry, { tasks: JSON.stringify([taskdata]), date: moment(this.state.timesheetdate).format('YYYY-MM-DD') })
                .then((res) => {
                    Success('Task updated sucessfully')
                    this.setState({ saving: false })
                    let btn = document.getElementById("leaveModel");
                    btn.click();
                    if (res.data) {
                        this.getEmployeeEntry()
                    }

                }).catch((err) => {
                    Error('Task update failed')
                    this.setState({ saving: false })
                    console.log(err);
                });
        }

    }

    ResetTask = () => {
        this.setState({
            taskdata: { project: '', projectmanager: '', module: '', submodule: '', managername: '', approved: false },
            projectError: false, projectmanagerError: false, moduleError: false, modalType: 'Add', rowIndex: '', modules: [], submodules: [], reason: ''
        })
    }

    getoptions = () => {
        let options = []
        if (this.state.employeeprojects) {
            for (let i = 0; i < this.state.employeeprojects.length; i++) {
                let item1 = this.state.employeeprojects[i],
                    found = false;

                for (let j = 0; j < this.state.taskList.length; j++) {
                    if (this.state.modalType == 'Add') {
                        if (item1._id == this.state.taskList[j].project) found = true
                    }
                    else {
                        if (item1._id == this.state.taskList[j].project && item1._id != this.state.taskdata.project) found = true
                    }
                }

                // if (!found) { // isUnion is coerced to boolean
                options.push({
                    label: item1.name, value: item1._id, item: item1
                });
                // }
            }
        }

        return options

    }
    submitRequest = () => {
        if (!this.state.reason)
            this.setState({ reasonError: true })
        else {
            Swal.fire({
                title: 'Are you sure to submit request',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                imageUrl: 'assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    let formdata = {
                        reason: this.state.reason,
                        date: moment(this.state.timesheetdate).format('YYYY-MM-DD')
                    }
                    axios.post(ImportedURL.API.timesheetrequest, formdata)
                        .then((res) => {
                            Success('Request submitted')
                            this.setState({ saving: false })
                            let btn = document.getElementById("leaveModelRequest");
                            btn.click();
                            // if (res.data) {
                            this.getEmployeeEntry()
                            // }

                        }).catch((err) => {
                            Error('Request not submitted try again')
                            this.setState({ saving: false })
                            console.log(err);
                        });
                }
            })


        }
    }

    datechange = (e) => {
        if (e.target.name == 'fromdate') {

            this.setState({ fromdate: e.target.value ? e.target.value : initialState.fromdate })
            this.gethistory(e.target.value ? e.target.value : initialState.fromdate)
        } else {
            this.setState({ todate: e.target.value })
            this.gethistory(this.state.fromdate, e.target.value)
        }
    }

    render() {
        // if (this.state.viewRedirect) {
        //     return <Redirect to={'/view-project/' + this.state.id} />
        // }
        // if (this.state.editRedirect) {
        //     return <Redirect to={'/edit-project/' + this.state.id} />
        // }
        const { ProjectState, AccountState } = this.props;
        const account = AccountState?.account;
        const rowData = this.state.taskList;
        const editOption = '<button type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';


        rowData.forEach(object => {
            const template = '<div><button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModalView" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View" data-toggle="tooltip" title="View"></i></button>'
                + (object.approved ? '' : editOption)
                + (object.approved ? '' : deleteOption)
                + '</div>'
            object.actions = template;
        });
        const taskdata = this.state.taskdata;
        // const spinner = LeaveTemplateState.spinner;
        // const previleges = AccountState.previleges;

        // let projectoptions = this.getoptions
        const moduleOptions = [];
        let moduleval = ''
        this.state.modules.map(item => {
            if (item.module == taskdata.module) moduleval = { label: item.module, value: item.module }
            moduleOptions.push({ label: item.module, value: item.module, name: 'module' });
        })

        const submoduleOptions = [];
        let submoduleval = ''
        if (taskdata.module)
            this.state.submodules.map(item => {
                if (item.module == taskdata.module) {
                    if (item.submodule == taskdata.submodule) submoduleval = { label: item.submodule, value: item.submodule }
                    submoduleOptions.push({ label: item.submodule, value: item.submodule, name: 'submodule' });
                }
            })

        let now = new Date()
        let check = new Date(this.state.timesheetdate)

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-department"><h6 className="highlights_breadcrump">Project List</h6></Link></p>
                </div>
                <div className="section-body user_sec">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='card-title d-flex'>
                                            <div className='rounded_icon'><i className="fa fa-project-diagram mr-2"></i></div>
                                            <h3 className="card-title " style={{ marginTop: '10px' }}> Timesheet {this.state.hidehistory ? 'Entry' : 'History'}</h3>
                                        </div>
                                        <div className="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="header-action" >
                                                    {this.state.hidehistory ?
                                                        <button type="button" className="btn btn-primary" onClick={() => { this.setState({ hidehistory: false }) }} ><i className="fe fe-clock mr-2" id='Add' />History</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={() => { this.setState({ hidehistory: true }) }} ><i className="fe fe-calendar mr-2" id='Add' />Entry</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {this.state.hidehistory ?
                                    <div className="row clearfix row-cards">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className='col-lg-4 col-md-12 col-sm-12 col-xl-4'>
                                                        <Calendar
                                                            value={this.state.timesheetdate}
                                                            onChange={this.setDateValue}
                                                            maxDate={new Date()}
                                                            // plugins={[weekends([5, 6])]}
                                                            onMonthChange={this.monthChangeDatepicker}
                                                            onYearChange={this.monthChangeDatepicker}
                                                            mapDays={({ date }) => {
                                                                let props = {}
                                                                let isleave = this.state.myleaves.includes(date.day)
                                                                let isholiday = this.state.myholidays.includes(date.day)
                                                                let ispending = this.state.pendingleaves.includes(date.day)
                                                                let isWeekend = [0].includes(date.weekDay.index)
                                                                if (isWeekend) props.className = "calendar-weekend"
                                                                if (ispending) {
                                                                    props.style = { backgroundColor: "rgb(250,216,23)", color: 'white' }
                                                                }
                                                                if (isleave) {
                                                                    props.disabled = true
                                                                    props.style = { backgroundColor: "green", color: 'white' }
                                                                    // props.className = "calendar-leave"
                                                                    props.onClick = () => Swal.fire('You cannot enter timesheet for leave days')
                                                                }
                                                                if (isholiday) props.className = "calendar-weekend"
                                                                return props
                                                            }}
                                                        />
                                                        <div>
                                                            <p style={{ marginTop: '21px', fontSize: '12px', display: 'inline-block' }}>
                                                                <span style={{
                                                                    background: 'salmon', height: '15px', width: '15px', display: 'inline-block', borderRadius: '50px',
                                                                    position: 'relative', top: '4px', right: '2px'
                                                                }}></span>Holiday</p>
                                                            <p style={{ marginTop: '21px', fontSize: '12px', display: 'inline-block', paddingLeft: '10px' }}>
                                                                <span style={{
                                                                    background: 'green', height: '15px', width: '15px', display: 'inline-block', borderRadius: '50px',
                                                                    position: 'relative', top: '4px', right: '2px'
                                                                }}></span>Leave</p>
                                                            <p style={{ marginTop: '21px', fontSize: '12px', display: 'inline-block', paddingLeft: '10px' }}>
                                                                <span style={{
                                                                    background: 'rgb(250,216,23)', height: '15px', width: '15px', display: 'inline-block', borderRadius: '50px',
                                                                    position: 'relative', top: '4px', right: '2px'
                                                                }}></span>Pending Leave</p>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-8 col-md-12 col-sm-12 col-xl-8'>
                                                        <div className="row">
                                                            <div className='col-6'>
                                                                <div className="h6">Employee ID</div>
                                                                <p>{account.employeeId}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="h6">Name</div>
                                                                <p>{account.name}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="h6">Department</div>
                                                                <p>{account.department}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="h6">Division</div>
                                                                <p>{account.division}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="h6">Designation</div>
                                                                <p>{account.designation}</p>
                                                            </div>
                                                            {account.managerID ? <div className="col-6">
                                                                <div className="h6">Reporting Manager</div>
                                                                <p>{account.manager} ({account.managerID})</p>
                                                            </div> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <div className="card-header">
                                                <div className='card-title d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                                    <h3 className="card-title " style={{ marginTop: '10px' }}> Task </h3>
                                                </div>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {moment(this.state.timesheetdate).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD') || account.managernotexist ?
                                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={this.ResetTask} ><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                                :
                                                                !this.state.entryexist && !this.state.requeststatus && (check.getFullYear() == now.getFullYear()) && (check.getMonth() == now.getMonth()) && <button type="button" className="btn button_auto_front_desk" data-toggle="modal" data-target="#exampleModalRequest" onClick={this.ResetTask} ><i className="fe fe-send mr-2" id='Add' />Request</button>
                                                            }
                                                            {this.state.requeststatus == 'approved' && (check.getFullYear() == now.getFullYear()) && (check.getMonth() == now.getMonth()) ?
                                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={this.ResetTask} ><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                {this.state.requeststatus == 'denied' && (check.getFullYear() == now.getFullYear()) && (check.getMonth() == now.getMonth()) ? <div class="card-alert alert alert-danger mb-0">Request Denied</div> : ''}
                                                {this.state.requeststatus == 'pending' && (check.getFullYear() == now.getFullYear()) && (check.getMonth() == now.getMonth()) ? <div class="card-alert alert alert-warning mb-0">Request Pending</div> : ''}
                                                {this.state.requeststatus == 'approved' && (check.getFullYear() == now.getFullYear()) && (check.getMonth() == now.getMonth()) ? <div class="card-alert alert alert-success mb-0">Request Approved</div> : ''}

                                                <div className="table-responsive">
                                                    <div className="ag-theme-alpine">
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={25}
                                                            pagination={false}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={rowData}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            animateRows={true}
                                                            onGridReady={this.onGridReady}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                <label className="form-label">Limit</label>
                                                <div className="form-group">
                                                    <Select
                                                        onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                        options={limitOptions}
                                                        defaultValue={limitOptions[0]}
                                                    />
                                                </div>
                                            </div> */}
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                                                    <label className="form-label">Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='fromdate' onChange={this.datechange} placeholder="Module" value={this.state.fromdate} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                                                    <label className="form-label">To Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='todate' onChange={this.datechange} placeholder="Module" value={this.state.todate} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={this.state.historylist}
                                                        columnDefs={this.state.columnDefs1}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        onGridReady={this.onGridReady}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModal" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-calendar mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} Task</h5>
                                <button type="button" id="leaveModel" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }} id="leaveModel" onClick={this.clear}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="row clearfix">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Project<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <Select
                                                value={taskdata.project ? { label: taskdata.projectname, value: taskdata.project } : ''}
                                                onChange={this.projectSelectChange}
                                                options={this.getoptions()}

                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.projectError ? "block" : 'none' }}>Project is required</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Project Manager<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='Manager' onChange={this.handleChange} placeholder="Project Manager" value={taskdata.managername} />
                                            <div className="invalid-feedback" style={{ display: this.state.projectmanagerError ? "block" : 'none' }}>Project manager is required</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Module<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <Select
                                                value={moduleval}
                                                onChange={this.moduleSelectChange}
                                                options={moduleOptions}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.moduleError ? "block" : 'none' }}>Module is required</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Sub Module</label>
                                            <Select
                                                value={submoduleval}
                                                onChange={this.moduleSelectChange}
                                                options={submoduleOptions}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Module<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='module' onChange={this.handleChange} placeholder="Module" value={taskdata.module} />
                                            <div className="invalid-feedback" style={{ display: this.state.moduleError ? "block" : 'none' }}>Module is required</div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Remarks</label>
                                            <textarea type="text" className="form-control" disabled={this.state.modalType == "View"} name='remarks' onChange={this.handleChange} placeholder="Remarks" value={taskdata.remarks} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.modalType == "View" ?
                                    '' :
                                    <>
                                        {
                                            this.state.modalType == "Edit" ?
                                                <div className="card-footer text-right mandatory">
                                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                    <div className="" >
                                                        {
                                                            this.state.saving ?
                                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                :
                                                                <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                                        }
                                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                                    </div>
                                                </div>
                                                : <div className="card-footer text-right mandatory">
                                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                    <div className="" >
                                                        {
                                                            this.state.saving ?
                                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                                :
                                                                <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                        }
                                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                                    </div>
                                                </div>
                                        }
                                    </>
                            }


                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModalView" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-calendar mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalcls" style={{ marginTop: '5px' }}>View Task</h5>
                                <button type="button" id="leaveModel" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }} id="leaveModel" onClick={this.clear}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row clearfix">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Project</label>
                                            <p>{taskdata.projectname}</p>
                                            {/* <div className="invalid-feedback" style={{ display: this.state.projectError ? "block" : 'none' }}>Project is required</div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Project Manager</label>
                                            <p>{taskdata.managername}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Module</label>
                                            <p>{taskdata.module}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Sub module</label>
                                            <p>{taskdata.submodule ? taskdata.submodule : '-'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Remarks</label>
                                            <p>{taskdata.remarks ? taskdata.remarks : '-'}</p>
                                            {/* <div className="invalid-feedback" style={{ display: this.state.submoduleError ? "block" : 'none' }}>Leave is required</div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Rating</label>
                                            <p>{taskdata.rating ? taskdata.rating : '-'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Status</label>
                                            <p>{taskdata.approved ? 'Approved' : 'Pending'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Comments</label>
                                            <p>{taskdata.comments ? taskdata.comments : '-'}</p>
                                            {/* <div className="invalid-feedback" style={{ display: this.state.submoduleError ? "block" : 'none' }}>Leave is required</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModalRequest" style={{ zIndex: 1030 }} autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-calendar mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>Request for entry</h5>
                                <button type="button" id="leaveModelRequest" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }} id="leaveModelRequest" onClick={this.clear}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row clearfix">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Reason<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <textarea type="text" className="form-control" disabled={this.state.modalType == "View"} name='reason' onChange={(e) => { this.setState({ reason: e.target.value, reasonError: false }) }} placeholder="Reason for missing entry" value={this.state.reason} />

                                            <div className="invalid-feedback" style={{ display: this.state.reasonError ? "block" : 'none' }}>Reason is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                <div className="" >
                                    {
                                        this.state.saving ?
                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Sending</button>
                                            :
                                            <button type="button" className="btn commor_save" onClick={this.submitRequest}><i className="fe fe-save mr-2"></i>Send</button>
                                    }
                                    <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    ProjectState: state.project,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ViewProject: AC_VIEW_PROJECT,
            EmptyProject: AC_EMPTY_PROJECT,
            ListProject: AC_LIST_PROJECT
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListProject);