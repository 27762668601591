import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Swal from 'sweetalert2';
import { AC_LIST_EMAIL, AC_VIEW_EMAIL } from '../../actions/emailAction';

class ViewEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,

        }
    }
    componentDidMount() {
        this.props.ViewEmail(this.props.match.params.id)
        localStorage.setItem('invoicetoken', "");
    }
    deleteMail = (id) => {
        Swal.fire({
            title: 'Are you sure want to delete?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            imageUrl: 'assets/images/delete.png',
            customClass: {
                popup: 'swal_pop',
                title: 'swal_title',
                image: 'swal_image',
                actions: 'swal_action',
                confirmButton: 'swal_confirm',
                cancelButton: 'swal_close',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                axios.get(ImportedURL.API.softDeleteEmail + "/" + this.props.match.params.id)
                    .then((res) => {
                        Success("Your mail is deleted successfully");
                        this.setState({ redirect: true })
                    }).catch((err) => {
                        console.log(err);
                    });
            }
        })
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={'/sent'} />
        }
        const { AccountState, EmailState } = this.props;
        const account = AccountState?.account;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Email" });
        const listEmail = EmailState.listEmail;
        const data = EmailState.emailData;
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/sent"><h6 className="highlights_breadcrump">Sent</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/view-email"><h6 className="highlights_breadcrump">View Message</h6></Link></p>
                </div>
                <div className="section-body user_sec email_sec">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='card-title d-flex'>
                                            <div className='rounded_icon'><i className="fa fa-commenting mr-2"></i></div>
                                            <h3 className="card-title " style={{ marginTop: '10px' }}> View Message </h3>
                                        </div>
                                        <div className="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="header-action" >
                                                    {Previlege?.add ?
                                                        <Link to='/compose'><button type="button" className="btn compose_btn" ><i className="fe fe-plus mr-2" id='Add' /> Compose</button></Link>
                                                        : ''}
                                                    <Link to='/sent'><button type="button" className="btn compose_btn mr-3" ><i className="fa fa-arrow-left mr-2" /> Back</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body email_list">
                                        <div className='vie_email_templete'>
                                            <div className='top_title'>
                                                <div className='left_content'>
                                                    <h4>{data.subject}</h4>
                                                    <p style={{ width: '330px', fontSize: '15px', marginBottom: '0px' }}><span style={{ fontWeight: '500' }}>To : </span>{data.toValue}</p>
                                                    <p style={{ width: '330px', fontSize: '15px' }}><span style={{ fontWeight: '500' }}>Cc : </span>{data.ccValue}</p>
                                                </div>
                                                <div className='icons_replay'>
                                                    {/* <div className='mb-2 d-flex justify-content-end'>
                                                        <i class="fa fa-trash-o" aria-hidden="true" onClick={this.deleteMail}></i>
                                                        <i class="fa fa-reply" aria-hidden="true"></i>
                                                        <i class="fa fa-share" aria-hidden="true"></i>
                                                        <i class="fa fa-print" aria-hidden="true"></i>
                                                    </div> */}
                                                    <span>{data.date}</span>
                                                </div>
                                            </div>
                                            <div className='content'>
                                                <p>{data.description && data.description.replace(/<[^>]+>/g, '')}</p>
                                            </div>
                                            <div className='bottom_title'>
                                                <div className='bot_left_btn'>
                                                    {/* <button type='button' className='btn commor_save'><i class="fa fa-share" aria-hidden="true"></i> Forward</button> */}
                                                    {/* <button type='button' className='btn commor_save mr-3'><i class="fa fa-reply" aria-hidden="true"></i> Reply</button> */}
                                                </div>
                                                <div className='bot_right_btn'>
                                                    <button type='button' className='btn commor_save'><i class="fa fa-check" aria-hidden="true"></i> Accept</button>
                                                    {/* <button type='button' className='btn commor_save mr-3' onClick={this.deleteMail}><i class="fa fa-trash-o" aria-hidden="true"></i> Delete</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    EmailState: state.email
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ViewEmail: AC_VIEW_EMAIL,
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEmail);