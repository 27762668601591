import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Select from 'react-select';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';
import { AC_LIST_ASSIGNINVENTORY, AC_VIEW_ASSIGNINVENTORY, AC_HANDLE_ASSIGNINVENTORY_CHANGE, AC_RESET_ASSIGNINVENTORY } from '../../actions/assignInventoryAction';
import jsPDF from "jspdf";
import moment from 'moment';
import { capitalizeFirstLetter } from '../../common/validate';



const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    viewFlag: false,
    flagError: false,
    pushValue: '',
    activeClick: false,
    inactiveClick: false,
    page: '',

    columnDefs: [
        { headerName: 'Category', field: 'category', width: 200, floatingFilter: true },
        { headerName: 'Subcategory', field: 'subcategory', width: 200, floatingFilter: true },
        { headerName: 'Asset name', field: 'assetname', width: 200, floatingFilter: true },
        {
            headerName: 'Date assigned', width: 270, field: 'assignedDate',
            cellRenderer: function (params) {
                if (params.data.assignedDate) {
                    return moment(params.data.assignedDate).format("MM/DD/YYYY");
                }
            }
        },
        {
            headerName: 'Accepted', field: 'accepted', width: 270, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.accepted)
                    return '<i class="fa fa-check-square" style="font-size: 30px;color: #AED582; cursor:pointer" data-toggle="tooltip" title="fa fa-check-square"></i>'
                else
                    return '<i class="fa fa-square-o" style="font-size: 30px;color: grey; cursor:pointer" data-toggle="tooltip" title="fa fa-check-square" data-action-type="Acceptance"></i>'
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },

    assigndata: {},
    servicedata: {},
    damagedata: {},
    status: ''
}

class ListAssignedInventory extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Acceptance') {
            Swal.fire({
                title: 'Confirm that you received the asset?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.simpleChanges, { params: { id: event.data._id, model: 'histories' }, data: { accepted: true } })
                        .then((data) => {
                            this.GetAssets();
                            Success('Asset accepted')
                        }).catch(({ response }) => { if (response) Error('Asset acceptance failed') });
                }
            })
        }
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    componentDidMount() {
        this.GetAssets();
    }

    GetAssets = () => {
        axios.get(ImportedURL.API.myAssets)
            .then((res) => {
                this.setState({ assetslist: res.data });
            }).catch(({ response }) => {
            });
    }

    render() {
        const rowData = this.state.assetslist;
        const employee = this.props.employeeReducer.listEmployee;

        const employeeOptions = [];
        employee.filter(filterItem => filterItem.status === true).map(item => {
            employeeOptions.push({ label: `${item.employeeName} (${item.employeeId})`, value: item._id, name: 'employee' });
        })

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                                            </div>
                                            <h3 className="card-title">My Assets</h3>
                                        </div>


                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine" id="content">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={10}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}



const mapStateToProps = (state) => ({
    inventoryReducer: state.inventoryReducer,
    assignInventoryReducer: state.assignInventoryReducer,
    assetReducer: state.assetReducer,
    countryReducer: state.countryReducer,
    employeeReducer: state.employeeReducer,
    AccountState: state.account,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AC_LIST_ASSIGNINVENTORY, AC_VIEW_ASSIGNINVENTORY, AC_HANDLE_ASSIGNINVENTORY_CHANGE,
            AC_LIST_EMPLOYEE,
            AC_RESET_ASSIGNINVENTORY
            // AC_LIST_COUNTRY, AC_LIST_INVENTORY, AC_LIST_ASSET, AC_VIEW_MANAGEASSIGNINVENTORY
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListAssignedInventory);

