import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import CKEditor from "react-ckeditor-component";
import { ImageFilevalidation } from '../../common/validate';
import { AC_EMPTY_PROJECT, AC_HANDLE_PROJECT_CHANGE, AC_RESET_PROJECT, AC_VIEW_PROJECT } from '../../actions/projectAction';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';
import pdf from '../../images/pdf.png'
import doc from '../../images/doc.png'
import { AC_HANDLE_EMPLOYEE_LEAVE_CHANGE, AC_HANDLE_EMPLOYEE_LEAVE_MULTI_CHANGE, AC_HANDLE_EMPLOYEE_LEAVE_PLUS, AC_LIST_EMPLOYEE_LEAVE, AC_RESET_EMPLOYEE_LEAVE, AC_VIEW_EMPLOYEE_LEAVE } from '../../actions/employeeLeaveAction';
import { AC_LIST_LEAVE_TYPE } from '../../actions/leavetypeAction';

class AddEmployeeLeave extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            id: "",
            saving: false,
            employeeError: false,
            listRedirect: false
        }
    }
    componentDidMount() {
        const { params, path } = this.props.match;
        if (path === "/edit-employee-leave/:id") {
            this.setState({ modalType: "Edit", id: params.id })
        } else {
            this.setState({ modalType: "Add" });
            this.props.ResetEmployeeLeave();
        }
        this.props.ListEmployee();
        this.props.ListLeaveType();
        this.props.ListEmployeeLeave();
        this.props.ViewEmployeeLeave(params.id);
        localStorage.setItem('invoicetoken', "");
    }
    handleChangeEmployee = (e) => {
        const { name, value, label } = e;
        if (name == 'employee') {
            if (value) {
                this.props.HandleInputChange('employee', value);
                this.setState({ employeeError: false })
            } else {
                this.setState({ departmentError: true })
            }
        }
    }
    // handleChangeLeaveTypeChange = (i, e) => {
    //     const leaveTypeData = this.props.LeaveTypeState.listLeavetype;
    //     const { name, value } = e;
    //     this.props.HandleInputMultiChange("leavetype", value, i);
    //     {
    //         leaveTypeData && leaveTypeData.map((data) => {
    //             if (data._id == value) {
    //                 this.props.HandleInputMultiChange("max", data.maxdays, i);
    //             }
    //         })
    //     }
    // }
    handleOnchange = (e) => {
        const { name, value } = e.target;
        this.props.HandleInputChange("leave", value);

    }
    // onChangeItem(i, e) {
    //     this.setState({ spinner: false, itemsError: false })
    //     const { name, value } = e.target;
    //     this.props.HandleInputMultiChange("max", value, i);
    // }
    // addFormFields(e) {
    //     this.props.EmployeeLeavePlus('items', { 'leavetype': '', 'max': '' }, '');
    // }
    // removeFormFields(i) {
    //     const DataValue = this.props.EmployeeLeaveState.employeeLeave;
    //     if (DataValue.length === 1 && i === 0) {
    //         this.props.EmployeeLeavePlus(true, { 'leavetype': '', 'max': '' }, i);
    //     } else {
    //         this.props.EmployeeLeavePlus('items', '', i);
    //     }
    // }
    submit = () => {
        const data = this.props.EmployeeLeaveState.employeeLeave;
        let valid = 1;
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addEmployeeLeave, data)
                    .then((res) => {
                        this.setState({ saving: false, closed: true, listRedirect: true })
                        Success('Employee Leave created successfully');
                        // Success(res.statusText);
                        this.props.ResetEmployeeLeave();
                        this.props.ListLeaves();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Employee Leave already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            } else {
                axios.post(ImportedURL.API.updateEmployeeLeave + "/" + this.state.id, data)
                    .then((res) => {
                        this.setState({ saving: false, closed: true, listRedirect: true })
                        Success('Employee Leave updated successfully');
                        // Success(res.statusText);
                        this.props.ListLeaves();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        // Error(response.statusText)
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Employee Leave already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
                // this.props.UpdateJobTitle(data, this.state.id);
            }
        }
    }

    render() {
        if (this.state.listRedirect) {
            return <Redirect to={'/employee-leave'} />
        }
        const { LeaveTypeState, employeeState, AccountState, EmployeeLeaveState } = this.props;
        const account = AccountState?.account;
        const data = EmployeeLeaveState.employeeLeave;
        const employeeData = employeeState.listEmployee;
        const leaveTypeData = LeaveTypeState.listLeavetype;
        const employeeLeavedata = EmployeeLeaveState.listEmployeeLeave;


        let selectedEmployeeData = '';
        if (data.employee) {
            selectedEmployeeData = employeeData.find(e => e._id === data.employee)
        }
        const employeeOptions = [];
        employeeData.filter(filterItem => filterItem.status === true).map(item => {
            let status = (employeeLeavedata && employeeLeavedata.length > 0) ? employeeLeavedata.find((e) => e.employeeId == item._id) : undefined
            if (!status) {
                employeeOptions.push({ label: item.employeeName, value: item._id, name: 'employee' });
            }
        })
        // var leaveTypeArray = [];
        // if (data.leave && data.leave.length > 0) {
        //     data.leave.map((item, i) => {
        //         let valueSelected = ''
        //         const LeaveTypeOptions = [];
        //         leaveTypeData.filter(filterItem => filterItem.status === true).map((leave) => {
        //             if (item.leavetype._id != undefined) {
        //                 if (item.leavetype._id == leave._id) {
        //                     valueSelected = { label: leave.leavetype, value: leave._id, name: 'leavetype', }
        //                 }
        //                 let status = (data.leave) ? (data.leave).find((e) => e.leavetype._id == leave._id) : undefined
        //                 if (!status) {
        //                     LeaveTypeOptions.push({ label: leave.leavetype, value: leave._id, name: 'leavetype' });
        //                 }
        //             }
        //             else {
        //                 if (item.leavetype == leave._id) {
        //                     valueSelected = { label: leave.leavetype, value: leave._id, name: 'leavetype', }
        //                 }
        //                 let status = (data.leave) ? (data.leave).find((e) => e.leavetype == leave._id) : undefined
        //                 if (!status) {
        //                     LeaveTypeOptions.push({ label: leave.leavetype, value: leave._id, name: 'leavetype' });
        //                 }
        //             }
        //         })
        //         leaveTypeArray.push(
        //             <div className='row' key={i}>
        //                 <div className="col-4">
        //                     <div className="form-group">
        //                         <Select
        //                             value={valueSelected ? valueSelected : ''}
        //                             onChange={(e) => this.handleChangeLeaveTypeChange(i, e)}
        //                             options={LeaveTypeOptions}
        //                         />
        //                         <div className="invalid-feedback" style={{ display: this.state.employeeError ? "block" : 'none' }}>Add Project Leader is required</div>
        //                     </div>
        //                 </div>
        //                 <div className="col-4">
        //                     <div className="form-group">
        //                         <p>
        //                             <input type="text" className='form-control' name="item" value={item.max} onChange={e => this.onChangeItem(i, e)} placeholder={"Max days"} />
        //                             <div className="invalid-feedback" style={{ display: this.state.itemsError ? 'block' : 'none' }}>Amenity is required</div>
        //                         </p>
        //                     </div>
        //                 </div>
        //                 <div className="col-sm-6 col-md-3">
        //                     <div className='areaamenity_increment'>
        //                         {data.leave.length > 1 ?
        //                             < span className='plus_minus' > <i className="fa fa-minus-circle" aria-hidden="true" onClick={() => this.removeFormFields(i)} style={{ color: '#2cbaec', fontSize: '35px', cursor: 'pointer' }}></i></span>
        //                             : ''}
        //                         <span className='plus_minus ml-3'><i className="fa fa-plus-circle" aria-hidden="true" onClick={() => this.addFormFields(item, i)} style={{ color: '#2cbaec', fontSize: '35px', cursor: 'pointer' }}></i></span>
        //                     </div>
        //                 </div>

        //             </div >
        //         )
        //     })
        // }
        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/employee-leave"><h6 className="highlights_breadcrump">Employee Leave List</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> {this.state.modalType == "Edit" ? <h6 className="highlights_breadcrump">Edit Employee Leave </h6> : ''}</p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'><i className="fa fa-project-diagram mr-2 "></i></div>
                                        <h3 className="card-title">{this.state.modalType} Employee Leave</h3>
                                        <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                            <Link to='/employee-leave'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <label className="form-label">Add Employee<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        value={selectedEmployeeData ? { label: selectedEmployeeData.employeeName, value: selectedEmployeeData._id, name: "leader" } : ''}
                                                        onChange={this.handleChangeEmployee}
                                                        options={employeeOptions}

                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.employeeError ? "block" : 'none' }}>Add Project Leader is required</div>
                                                </div>
                                            </div>
                                            {/* <div className=" col-12 col-sm-12 col-md-12">
                                                <label className="form-label">Leave Type<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                {leaveTypeArray.reverse()}
                                            </div> */}
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Total Leaves</label>
                                                    <input type="text" className="form-control" placeholder="Leaves" name="leaves" onChange={this.handleOnchange} value={data.leave} />
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.modalType == 'Edit' ?
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right">
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                <i className="fe fe-save mr-2"></i>Update
                                                            </button>
                                                    }
                                                </div>
                                            </div> :
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right">
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                <i className="fe fe-save mr-2"></i>Save
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    EmployeeLeaveState: state.employeeleave,
    employeeState: state.employeeReducer,
    LeaveTypeState: state.leavetype,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            HandleInputChange: AC_HANDLE_EMPLOYEE_LEAVE_CHANGE,
            ViewProject: AC_VIEW_PROJECT,
            EmptyProject: AC_EMPTY_PROJECT,
            EmployeeLeavePlus: AC_HANDLE_EMPLOYEE_LEAVE_PLUS,
            ListLeaveType: AC_LIST_LEAVE_TYPE,
            ListEmployee: AC_LIST_EMPLOYEE,
            HandleInputMultiChange: AC_HANDLE_EMPLOYEE_LEAVE_MULTI_CHANGE,
            ResetEmployeeLeave: AC_RESET_EMPLOYEE_LEAVE,
            ListEmployeeLeave: AC_LIST_EMPLOYEE_LEAVE,
            ViewEmployeeLeave: AC_VIEW_EMPLOYEE_LEAVE,
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeLeave);