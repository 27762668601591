
const initialState = {
    addInventoryAsset: [],
    listInventoryAsset: [],
    deleteInventoryAsset: [],
    inventoryAsset: {
		inventory:'',
        name:'',
        asset:'',
        serialNo:'',
        invoiceNo:'',
        supplier:'',
        cc:'',
        phoneNo:'',
        amount:'',
        gmNo:'',
        licenceKey:'',
        invoiceDate:'',
        deliveryDate:'',
        gmDate:'',
        remarks:'',
        billUpload:[],
        warentyYear:'',
        propertyId:''
	},
}
function inventoryAssetReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_INVENTORYASSET':
            return {
                ...state,
                inventoryAsset: action.payload
            }
            break;

        case 'LIST_INVENTORYASSET':
            return {
                ...state,
                listInventoryAsset: action.payload
            }
            break;
        case 'VIEW_INVENTORYASSET':
            return {
                ...state,
                inventoryAsset: action.payload
            }
        case 'RESET_INVENTORYASSET':
			return Object.assign({}, state, {
				inventoryAsset: initialState.inventoryAsset
			})
        case 'DELETE_INVENTORYASSET':
            return {
                ...state,
                deleteInventoryAsset: action.payload
            }
            break;
        case 'HANDLE_INVENTORYASSET_CHANGE': 
            return Object.assign({}, state, {
                inventoryAsset: {
                    ...state.inventoryAsset,
                    [action.name]: action.value
                }
            })
        default:
            return state;
            break;
    }
}

export default inventoryAssetReducer;