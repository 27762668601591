import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import { AC_LIST_EMPLOYEE, AC_VIEW_EMPLOYEE } from '../../actions/employeeAction';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import { AC_LIST_DEPARTMENT } from '../../actions/departmentAction';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    viewFlag: false,
    flagError: false,
    pushValue: '',
    columnDefs: [
        {
            headerName: 'Image', field: 'image', width: 100, filter: false, cellStyle: { textAlign: 'center' },
            cellRendererFramework: function (params) {
                return <div>
                    <img style={{ width: '40px', height: '40px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + params.data.image} alt="" />
                </div>
            }
        },
        { headerName: 'Name', field: 'employeeName', width: 130, floatingFilter: true },         //pinned: 'left'
        { headerName: 'Email', field: 'email', width: 200, floatingFilter: true },
        { headerName: 'Role', field: 'role', width: 200, floatingFilter: true },
        // { headerName: 'Manager', field: 'manager', width: 200, floatingFilter: true },
        { headerName: 'Department', field: 'department', width: 180, floatingFilter: true },
        { headerName: 'Division', field: 'divisionName', width: 140, floatingFilter: true },
        { headerName: 'Designation', field: 'designation', width: 140, floatingFilter: true },
        {
            headerName: 'Phone Number', field: 'phoneNo', width: 160, floatingFilter: true, cellStyle: { textAlign: 'center' },
        },
        {
            headerName: 'Status', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger" style="margin-top: 5px" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class ListEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.setState({ viewRedirect: true, id: event.data._id });
            this.props.AC_VIEW_EMPLOYEE(event.data._id);
        }
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.AC_VIEW_EMPLOYEE(event.data._id);
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteEmployee + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            this.props.AC_LIST_EMPLOYEE();
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'employees' })
                        .then((data) => {
                            Success('Status updated successfully')
                            this.props.AC_LIST_EMPLOYEE();
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    searchUser = e => {
        const { name, value } = e;
        this.setState({
            [name]: value,
        });
    }

    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['employeeName', 'email', 'department.name', 'designation.name'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { employeeReducer } = this.props;
        const exportData = employeeReducer.listEmployee
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Employee Details";
        const headers = [['#', 'Name', 'Email', 'Department', 'Designation']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.employeeName,
            elt.email,
            elt.department.name,
            elt.designation.name,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Employee.pdf")
    }


    componentDidMount() {
        this.props.AC_LIST_EMPLOYEE({ type: 'listadmin' });
        // this.props.AC_LIST_INVENTORY();
        // this.props.AC_LIST_DEPARTMENT();
        this.props.AC_LIST_COUNTRY();
        localStorage.setItem('invoicetoken', "");
    }
    render() {
        const { fixNavbar, employeeReducer } = this.props;
        const rowData = employeeReducer.listEmployee;

        var phoneData = this.props.countryReducer.listCountry;

        let ccData = '';
        if (rowData.cc) {
            ccData = phoneData.find(phoneData._id === rowData.cc)
        }


        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            '<div><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View" data-toggle="tooltip" title="View"></i></button>'
            + editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        // if (this.state.modalType === "View") return <Redirect to={'viewemployee/' + this.state.id} />
        if (this.state.modalType === "Add") return <Redirect to={'/addemployee'} />
        if (this.state.viewRedirect) return <Redirect to={'/viewemployee/' + this.state.id} />
        if (this.state.modalType === "Edit") return <Redirect to={'/editemployee/' + this.state.id} />

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listemployee"><h6>List Employee</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i className="fa-solid fa-user mr-2"></i>
                                            </div>
                                            <h3 className="card-title">List Employee</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>

                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine" id="content">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    employeeReducer: state.employeeReducer,
    countryReducer: state.countryReducer,
    departmentReducer: state.departmentReducer,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { AC_LIST_EMPLOYEE, AC_VIEW_EMPLOYEE, AC_LIST_COUNTRY, AC_LIST_DEPARTMENT }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListEmployee);