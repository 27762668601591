const initialState = {
    listEmail: [],
    listTrashEmail: [],
    emailData: {
        from: '',
        to: [],
        cc: [],
        subject: '',
        description: '',
        date: '',
        deletedBy: '',
    },
    replayMail: {
        reFrom: '',
        reTo: '',
        reSubject: '',
        reDescription: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_EMAIL_CHANGE':
            return Object.assign({}, state, {
                emailData: {
                    ...state.emailData,
                    [action.name]: action.value
                }
            })
        // ========= rrrrrrr
        case 'HANDLE_REPLAYMAIL_CHANGE':
            return Object.assign({}, state, {
                replayMail: {
                    ...state.replayMail,
                    [action.name]: action.value
                }
            })
        case 'RESET_EMAIL':
            return Object.assign({}, state, {
                emailData: initialState.emailData,
            })
        case 'LIST_EMAIL':
            return {
                ...state,
                listEmail: action.payload,
                spinner: action.spinner
            }
        case 'LIST_TRASH_EMAIL':
            return {
                ...state,
                listTrashEmail: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_EMAIL':
            return Object.assign({}, state, {
                emailData: action.payload,
                spinner: false,
            })

        case 'AC_EMPTY_EMAIL':
            return Object.assign({}, state, {
                emailData: {
                    title: '',
                    description: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}