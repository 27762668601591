const initialState = {
    listinvoicenumber: [],
    invoicenumber: {
        invoicenumber: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INVOICE_NUMBER_CHANGE':
            return Object.assign({}, state, {
                invoicenumber: {
                    ...state.invoicenumber,
                    [action.name]: action.value
                }
            })
        case 'RESET_INVOICE_NUMBER':
            return Object.assign({}, state, {
                invoicenumber: initialState.invoicenumber,
            })
        case 'LIST_INVOICE_NUMBER':
            return {
                ...state,
                listinvoicenumber: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_INVOICE_NUMBER':
            return Object.assign({}, state, {
                invoicenumber: action.payload,
                spinner: false,
            })

        case 'EMPTY_INVOICE_NUMBER':
            return Object.assign({}, state, {
                invoicenumber: {
                    invoicenumber: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}