import React, { Component, } from 'react'
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';
import { AC_LIST_INVENTORYASSET } from '../../actions/inventoryAssetAction';
import { AC_LIST_ASSIGNINVENTORY } from '../../actions/assignInventoryAction';
import { AC_EMPTY_PROJECT, AC_LIST_PROJECT, AC_VIEW_PROJECT } from '../../actions/projectAction';

import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';




class ListHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        this.props.AC_LIST_EMPLOYEE();
        this.props.AC_LIST_INVENTORYASSET();
        this.props.AC_LIST_ASSIGNINVENTORY();
        localStorage.setItem('invoicetoken', "");


        // this.props.AC_LIST_DEPARTMENT();
        // this.props.AC_LIST_COUNTRY();
    }
    render() {
        const employee = this.props.employeeReducer.listEmployee;
        const inventoryAsset = this.props.inventoryAssetReducer.listInventoryAsset;
        const assignInventory = this.props.assignInventoryReducer.listAssignInventory;


        return (
            <>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            {/* <div className="col-12 col-md-12 col-xl-4">
                                <div className="card">
                                    <div className="card-body ribbon" >
                                        <div className="ribbon-box orange">{employee.length}</div>
                                        <Link to='/listemployee'>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.employee}>
                                                <span className='dashbord_icons'>
                                                    <i className="fa-solid fa-user dashboard"></i>
                                                </span>
                                                <span className="dashboard">Employee</span>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-12 col-xl-4" >
                                <div className="card">
                                    <div className="card-body ribbon" >
                                        <div className="ribbon-box orange">{inventoryAsset.length}</div>
                                        <Link to='/listinventoryasset'>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.employee}>
                                                <span className='dashbord_icons'>
                                                    <i className="fa-solid fa-circle-nodes dashboard"></i>
                                                </span>
                                                <span className="dashboard">Manage Inventory</span>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-12 col-xl-4" >
                                <div className="card">
                                    <div className="card-body ribbon" >
                                        <div className="ribbon-box orange">{assignInventory.length}</div>
                                        <Link to='/listassignedinventory'>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.assignInventory}>
                                                <span className='dashbord_icons'>
                                                    <i className="fa-sharp fa-solid fa-circle-check dashboard"></i>
                                                </span>
                                                <span className="dashboard">Assign Inventory</span>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="section-body mt-3 dashboardText">
                    <h2>Welcome to My Techai</h2>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    employeeReducer: state.employeeReducer,
    inventoryAssetReducer: state.inventoryAssetReducer,
    assignInventoryReducer: state.assignInventoryReducer,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { AC_LIST_EMPLOYEE, AC_LIST_INVENTORYASSET, AC_LIST_ASSIGNINVENTORY }, dispatch
    );
} export default connect(mapStateToProps, mapDispatchToProps)(ListHistory);