import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';
import { Link, Redirect } from 'react-router-dom';


const ADD_INVENTORYASSET = 'ADD_INVENTORYASSET';
const LIST_INVENTORYASSET = 'LIST_INVENTORYASSET';
const VIEW_INVENTORYASSET = 'VIEW_INVENTORYASSET';
const UPDATE_INVENTORYASSET = 'UPDATE_INVENTORYASSET';
const DELETE_INVENTORYASSET = 'DELETE_INVENTORYASSET';
const RESET_INVENTORYASSET = 'RESET_INVENTORYASSET';

export function AC_ADD_INVENTORYASSET(data) {
    return function (dispatch) {
        axios.post(ImportedURL.API.addInventoryAsset, data)
            .then((data) => {
                Success('Added successfully');
                dispatch({ type: ADD_INVENTORYASSET, payload: data });
                // window.location='/listinventory'
            }).catch(function (error) {
                if (error) {
                    Error('Data already exists')
                }
            });
    }
}

export function AC_LIST_INVENTORYASSET(params = {}) {
    return function (dispatch) {
        axios.get(ImportedURL.API.listInventoryAsset, { params: params })
            .then(({ data }) => {
                dispatch({ type: LIST_INVENTORYASSET, payload: data });
            })
    }
}

export function AC_VIEW_INVENTORYASSET(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewInventoryAsset + "/" + id)
            .then(({ data }) => {
                dispatch({ type: VIEW_INVENTORYASSET, payload: data });
            });
    }
}

export function AC_RESET_INVENTORYASSET() {
    return function (dispatch) {
        dispatch({ type: "RESET_INVENTORYASSET" })
    };
}

export function AC_HANDLE_INVENTORYASSET_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INVENTORYASSET_CHANGE", name: name, value: value })
    };
}



