import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_LIST_DEPARTMENT, AC_VIEW_DEPARTMENT, AC_HANDLE_DEPARTMENT_CHANGE, AC_RESET_DEPARTMENT } from '../../../actions/departmentAction';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import { Success, Error } from '../../../common/swal';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Select from 'react-select';
import moment from 'moment';


const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "Add",
    save: false,
    columnDefs: [
        { headerName: 'Policy Name', field: 'name', floatingFilter: true },
        { headerName: 'Version', field: 'version', floatingFilter: true },
        {
            headerName: 'Effective Date', field: 'effectivedate',
            valueGetter: function (params) {
                if (params.data.effectivedate) return moment(params.data.effectivedate).format('MM/DD/YYYY')
                else return '-'
            }
        },
        {
            headerName: 'Approval', field: 'approved', headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' }, filter: false, sortable: false,
            cellRenderer: function (params) {
                if (params.data.approved) return '<span type="button" class="tag tag-success" data-action-type="Approval">Approved</span>'
                else return '<span type="button" class="tag tag-warning" data-action-type="Approval">Pending</span>'
            }
        },
        {
            headerName: 'Status', field: 'status', cellStyle: { textAlign: 'center' }, filter: false, sortable: false,
            cellRenderer: function (params) {
                if (params.data.status == 'active') {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else if (params.data.status == 'inactive') {
                    return '<span type="button" style="margin-top: 5px" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                } else {
                    // } else if (params.data.status == 'hold') {
                    return '<span type="button" style="margin-top: 5px; color:#FABD09; border:2px solid #FABD09" class="tag tag-danger" data-action-type="Status">Hold</span>';
                }
            }
        },
        {
            headerName: 'Actions', field: 'actions', cellStyle: { textAlign: 'center' }, filter: false, sortable: false,
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 283,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
    policyList: [],
    policydata: {}
}
class ListDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.props.history.push(`edit-company-policy/${event.data._id}`)
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.softDeletePolicy + "/" + event.data._id)
                        .then((data) => {
                            Success('Deleted successfully');
                            this.getPolicyData()
                        })
                }
            })
        }
        if (value === 'Status') {
            let inputoptions = {
                active: 'Active',
                hold: 'Hold',
                inactive: 'Inactive',
            }
            delete inputoptions[event.data.status]
            Swal.fire({
                title: 'Are you sure to change the status?',
                input: 'select',
                inputOptions: inputoptions,
                inputLabel: 'Select a status',
                customClass: {
                    input: 'inline-flex',
                    inputLabel: 'inline-flex'
                },
                showCancelButton: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: result.value, model: 'policies' })
                            .then((data) => {
                                this.getPolicyData();
                                Success('Status updated successfully')
                            }).catch(({ response }) => { if (response) Error('Status update failed') });
                    }
                })

        }
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'version', 'effectivedate'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const exportData = this.state.policyList;
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Department";
        const headers = [['#', 'Policy Name', 'Version', 'Effective Date']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.name,
            elt.version,
            moment(elt.effectivedate).format('MM/DD/YYYY'),
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Policy.pdf")
    }

    componentDidMount() {
        this.getPolicyData();
        localStorage.setItem('invoicetoken', "");
    }

    getPolicyData = () => {
        axios.get(ImportedURL.API.listPolicy)
            .then((res) => {
                this.setState({ policyList: res.data })
            }).catch((err) => {
                console.log(err);
            });
    }


    componentDidUpdate(prevProps, prevState) {

        // To set data once grid api is updated
        if (!this.state.changed && this.gridApi) {
            if (this.props.AccountState.account.role) {
                // const previleges = this.props.AccountState.previleges;
                // const Previlege = previleges.find(obj => { return obj.name == "Policy" });
                if (this.props.AccountState.account.role != 'admin') {
                    let coldefs = [...initialState.columnDefs]
                    coldefs.splice(3, 2);
                    this.gridApi.setColumnDefs(coldefs)
                }
                // this.gridColumnApi.setColumnVisible('status', false)
                this.setState({ changed: true })
            }
        }
    }

    render() {
        const { fixNavbar, departmentReducer, AccountState } = this.props;
        const rowData = this.state.policyList;
        const data = this.state.policydata;

        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Policy" });
        const editOption = AccountState.account.role == 'admin' ? '<button type="button"  class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>' : '';
        const deleteOption = AccountState.account.role == 'admin' ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>' : '';

        rowData.forEach(object => {
            const preview = `<a href=${ImportedURL.LIVEURL + object.file} target=_blank><button type="button"  class="btn btn-icon" ><i class="fa fa-file-pdf" style="font-size:20px;color: #1DC9B7 !important" data-toggle="tooltip" title="Preview"></i></button></a>`;
            const template =
                '<div>'
                // '<button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                + preview
                + editOption
                + deleteOption
                + '</div>'
            object.actions = template;
        });

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/company-policy"><h6> Policy</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i class="fa-sharp fa-solid fa-file-text mr-2"></i>
                                            </div>
                                            <h3 className="card-title">Company Policy</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>

                                                    {Previlege.add ? <div className="header-action">
                                                        <Link to='add-company-policy'>
                                                            <button type="button" className="btn btn-primary" id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                        </Link>
                                                    </div> : ''}
                                                    {AccountState.account.role != 'admin' && (AccountState.account.ispolicyapprover || AccountState.account.ispolicycreator) ? <div className="header-action">
                                                        <Link to={AccountState.account.ispolicyapprover ? '/approve-company-policy' : '/company-policy-requests'}>
                                                            <button type="button" className="btn button_auto_front_desk ml-2" id='Add'><i className="fe fe-link mr-2" id='Add' />Requests & Approvals</button>
                                                        </Link>
                                                    </div> : ''}
                                                </div>
                                                {AccountState.account.role == 'admin' ? <>
                                                    <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                        <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                    </div>
                                                </> : ''}

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>

                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}



const mapStateToProps = (state) => ({
    departmentReducer: state.departmentReducer,
    AccountState: state.account,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AC_LIST_DEPARTMENT, AC_VIEW_DEPARTMENT, AC_HANDLE_DEPARTMENT_CHANGE, AC_RESET_DEPARTMENT
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListDepartment);