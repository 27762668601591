import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../../common/api';
import { Error, Success } from '../../../common/swal';
import { Link, Redirect } from 'react-router-dom';
import { AC_EMPTY_PROJECT, AC_HANDLE_PROJECT_CHANGE, AC_RESET_PROJECT, AC_VIEW_PROJECT } from '../../../actions/projectAction';
import { AC_LIST_EMPLOYEE } from '../../../actions/employeeAction';

class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "Add",
      id: "",
      saving: false,
      images: [],
      imageArray: [],
      existingImage: [],
      nameError: false,
      dateError: false,
      leaderError: false,
      teamError: false,
      imageError: false,
      imagesArr: [],
      listRedirect: false,
      moduledata: {},
      submoduledata: {},
      verificationdata: {}
    }
  }

  componentDidMount() {
    const { params, path } = this.props.match;

    // if (path === "/edit-verification/:id") {
    //   this.setState({ modalType: "Edit", id: params.id })
    //   axios.get(`${ImportedURL.API.viewVerification}/${params.id}`)
    //     .then((res) => {
    //       this.setState({ verificationdata: res.data })
    //     }).catch(({ response }) => {
    //     });
    // } else {
    //   this.setState({ modalType: "Add", verificationdata: {} });
    // }
    axios.get(`${ImportedURL.API.viewEmployeeVerification}/${params.id}`)
      .then((res) => {
        this.setState({ verificationdata: res.data })
      }).catch(({ response }) => {
      });
    localStorage.setItem('invoicetoken', "");
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const Error = name + "Error";
    this.setState({ verificationdata: { ...this.state.verificationdata, [name]: value }, [Error]: false })
  }

  addQuestion = () => {
    if (this.state.question) {
      if (this.state.verificationdata.questions) {
        let arr = [...this.state.verificationdata.questions]
        arr.push({ question: this.state.question, type: 'yes/no' })
        this.setState({ verificationdata: { ...this.state.verificationdata, questions: arr }, question: '' })
      } else {
        this.setState({ verificationdata: { ...this.state.verificationdata, questions: [{ question: this.state.question, type: 'yes/no' }] }, question: '' })
      }
    } else {
      Error('Type question before adding')
    }
  }

  removeQuestion = (i) => {
    let arr = [...this.state.verificationdata.questions]
    arr.splice(i, 1)
    this.setState({ verificationdata: { ...this.state.verificationdata, questions: arr } })
  }

  submit = () => {
    const data = this.state.verificationdata;
    let valid = 1

    if (this.state.answerchanged) {
      const formData = {
        title: data.title,
        questions: data.questions ? JSON.stringify(data.questions) : JSON.stringify([]),
      }
      const { params } = this.props.match;

      this.setState({ saving: true })
      axios.post(`${ImportedURL.API.UserVerification}/${params.id}`, formData)
        .then((res) => {
          this.setState({ saving: false, closed: true })
          Success('Verification saved successfully');
          this.props.history.push('/my-verifications')
        }).catch(({ response }) => {
          this.setState({ saving: false })
          Error('Verification update failed')
        });
    } else {
      Error('Enter answers before submitting')
    }
  }

  onChangeVal = (e, i) => {
    let arr = [...this.state.verificationdata.questions]
    arr[i][e.target.name] = e.target.value;
    this.setState({ answerchanged: true, verificationdata: { ...this.state.verificationdata, questions: arr } })
  }
  render() {
    const data = this.state.verificationdata;
    return (
      <>
        <div className="breadcrump">
          <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump"> Employee Verification</h6>   </p>
        </div>
        <div className="section-body pt-3">
          <div className="container-fluid">
            <div className="tab-content pt-3">
              <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                <div className="card">
                  <div className="card-header">
                    <div className='rounded_icon'><i className="fa fa-project-diagram mr-2 "></i></div>
                    <h3 className="card-title">Verification</h3>
                    <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                      <Link to='/employee-verifications'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className='col-4'>
                        <div className='h6'>
                          Employee Photo
                        </div>
                        <p><img src={ImportedURL.LIVEURL + data.photo} alt='' height={'120px'} width={'120px'} /></p>
                      </div>
                      <div className='col-4'>
                        <div className='h6'>
                          Employee name
                        </div>
                        <p>{data.employee}</p>
                      </div>
                      <div className='col-4'>
                        <div className='h6'>
                          Employee ID
                        </div>
                        <p>{data.employeeID}</p>
                      </div>
                      {data.Aadhar ? <div className='col-4'>
                        <div className='h6'>
                          Employee Aadhar
                        </div>
                        <p><img src={ImportedURL.LIVEURL + data.Aadhar} alt='' height={'200px'} width={'250px'} /></p>
                      </div> : ''}
                      {data.Pan ? <div className='col-4'>
                        <div className='h6'>
                          Employee Pan
                        </div>
                        <p><img src={ImportedURL.LIVEURL + data.Pan} alt='' height={'200px'} width={'250px'} /></p>
                      </div>
                        : ''}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className='col-6'>
                        <div className="form-group">
                          <strong>{data.title}</strong>
                        </div>
                      </div>
                      {
                        data.questions && data.questions.length ?
                          data.questions.map(((item, i) => {
                            return <div className='col-12'>
                              <div className="row">
                                <div className='col-12'>
                                  <div className="form-group" dangerouslySetInnerHTML={{ __html: item.question }}>
                                    {/* {item.question} */}
                                  </div>
                                </div>
                                <div className='col-6'>
                                  <div className="form-group">
                                    <input className='form-control' value={item.answer} name='answer' placeholder='Answer' onChange={(e) => this.onChangeVal(e, i)} />
                                  </div>
                                </div>
                                <div className='col-6'>
                                  <div className="form-group">
                                    <textarea className='form-control' value={item.detail} name='detail' placeholder='Details' onChange={(e) => this.onChangeVal(e, i)} />
                                  </div>
                                </div>
                                {/* <div className='col-1'>
                                  <div className="form-group float-right">
                                    <button type="button" className="btn commor_save  pl-4 pr-4" onClick={() => this.removeQuestion(i)}><i className="fe fe-minus "></i></button>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          }))

                          : ''
                      }

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}


const mapStateToProps = state => ({
  fixNavbar: state.settings.isFixNavbar,
  AccountState: state.account,
  ProjectState: state.project,
  employeeState: state.employeeReducer,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      EmptyProject: AC_EMPTY_PROJECT,
      HandleInputChange: AC_HANDLE_PROJECT_CHANGE,
      ViewProject: AC_VIEW_PROJECT,
      ResetProject: AC_RESET_PROJECT,
      ListEmployee: AC_LIST_EMPLOYEE,
      // HandleModuleInputChange: AC_HANDLE_MODULE_CHANGE
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);