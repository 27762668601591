
const initialState = {
    addEmployee: [],
    listEmployee: [],
    deleteEmployee: [],
    listDepartmentParentId: [],
    listemployeerole: [],
    listHr: [],
    employee: {
        employeeName: '',
        employeeId: '',
        role: '',
        manager: '',
        grade: '',
        level: '',
        email: '',
        cc: '',
        phoneNo: '',
        username: '',
        password: '',
        department: '',
        designation: '',
        division: '',
        image: '',
        date: '',
        salary: '',
        dob: '',
        bloodgroup: '',
        // passportNo: '',
        // tel: '',
        // passportDate: '',
        nationality: '',
        religion: '',
        maritalstatus: '',
        spouse: '',
        location: '',
        noofchildren: '',
        primaryName: '',
        primaryRelationship: '',
        primaryPhone: '',
        secondaryName: '',
        secondaryRelationship: '',
        secondaryPhone: '',
        bankInfo: '',
        bankaccountNo: '',
        ifscCode: '',
        bankasperName: '',
        kycname: '',
        kycnumber: '',
        kycimage: [],
        branchname: '',
        family: [{
            famName: '',
            famRelationship: '',
            famdateofbirth: '',
            famPhone: '',

        }],
        education: [{
            institution: '',
            degree: '',
            educationstartdate: '',
            subject: '',
            educationcompletedate: '',
            grade: ''
        }],
        experience: [{
            expcompanyname: '',
            expjobposition: '',
            expperiodfrom: '',
            explocation: '',
            expperiodto: '',
        }]
    },
}
function employeeReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_EMPLOYEE':
            return {
                ...state,
                employee: action.payload
            }

        case 'LIST_EMPLOYEE_ROLE':
            return {
                ...state,
                listemployeerole: action.payload
            }
        case 'LIST_EMPLOYEE':
            return {
                ...state,
                listEmployee: action.payload,
            }
        case 'LIST_HR':
            return {
                ...state,
                listHr: action.payload
            }
        // case 'HANDLE_EMPLOYEE_SEARCH':
        //     return Object.assign({}, state, {
        //         listEmployee: action.value
        //     })
        case 'VIEW_EMPLOYEE':
            return {
                ...state,
                employee: action.payload
            }
        case 'RESET_EMPLOYEE':
            return Object.assign({}, state, {
                employee: initialState.employee
            })
        case 'DELETE_EMPLOYEE':
            return {
                ...state,
                deleteEmployee: action.payload
            }
        case 'HANDLE_EMPLOYEE_CHANGE':
            return Object.assign({}, state, {
                employee: {
                    ...state.employee,
                    [action.name]: action.value
                }
            })
        case 'LIST_DEPARTMENTPARENTID':
            return {
                ...state,
                listDepartmentParentId: action.payload
            }
        case 'HANDLE_EMPLOYEE_INFO_CHANGE':
            return {
                ...state,
                employee: {
                    ...state.employee,
                    family:
                        [
                            ...state.employee.family.slice(0, action.i),
                            { ...state.employee.family[action.i], [action.name]: action.value },
                            ...state.employee.family.slice(action.i + 1)
                        ]
                }
            }
        case 'HANDLE_EDUCATION_INFO_CHANGE':
            return {
                ...state,
                employee: {
                    ...state.employee,
                    education:
                        [
                            ...state.employee.education.slice(0, action.i),
                            { ...state.employee.education[action.i], [action.name]: action.value },
                            ...state.employee.education.slice(action.i + 1)
                        ]
                }
            }
        case 'HANDLE_EXPERIENCE_INFO_CHANGE':
            return {
                ...state,
                employee: {
                    ...state.employee,
                    experience:
                        [
                            ...state.employee.experience.slice(0, action.i),
                            { ...state.employee.experience[action.i], [action.name]: action.value },
                            ...state.employee.experience.slice(action.i + 1)
                        ]
                }
            }
        case 'HANDLE_EMPLOYEE_INFO_MULTIPLE_UPDATE':
            if (action.id === '') {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        family: [...state.employee.family, action.value]
                    }
                }
            }
            else if (action.name === true) {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        family: [action.value]
                    }
                }
            }
            else {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        family: [...state.employee.family.filter((event, i) => i != action.id)]
                    }
                }
            }

        case 'HANDLE_EDUCATION_INFO_MULTIPLE_UPDATE':
            if (action.id === '') {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        education: [...state.employee.education, action.value]
                    }
                }
            }
            else if (action.name === true) {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        education: [action.value]
                    }
                }
            }
            else {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        education: [...state.employee.education.filter((event, i) => i !== action.id)]
                    }
                }
            }

        case 'HANDLE_EXPERIENCE_INFO_MULTIPLE_UPDATE':
            if (action.id === '') {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        experience: [...state.employee.experience, action.value]
                    }
                }
            }
            else if (action.name === true) {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        experience: [action.value]
                    }
                }
            }
            else {
                return {
                    ...state,
                    employee: {
                        ...state.employee,
                        experience: [...state.employee.experience.filter((event, i) => i !== action.id)]
                    }
                }
            }
        default:
            return state;
            break;
    }
}

export default employeeReducer;