import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';
import { Link, Redirect } from 'react-router-dom';


const ADD_DESIGNATION = 'ADD_DESIGNATION';
const LIST_DESIGNATION = 'LIST_DESIGNATION';
const VIEW_DESIGNATION = 'VIEW_DESIGNATION';

export function AC_ADD_DESIGNATION(data) {
    return function (dispatch) {
        axios.post(ImportedURL.API.addDesignation, data)
            .then((data) => {
                Success('Added successfully');
                dispatch({ type: ADD_DESIGNATION, payload: data });
            }).catch(function (error) {
                if (error) {
                    Error('Data already exists')
                }
            });
    }
}

export function AC_LIST_DESIGNATION() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listDesignation)
            .then(({ data }) => {
                dispatch({ type: LIST_DESIGNATION, payload: data });
            })
    }
}

export function AC_VIEW_DESIGNATION(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewDesignation + "/" + id)
            .then(({ data }) => {
                dispatch({ type: VIEW_DESIGNATION, payload: data });
            });
    }
}

export function AC_RESET_DESIGNATION() {
    return function (dispatch) {
        dispatch({ type: "RESET_DESIGNATION" })
    };
}

export function AC_HANDLE_DESIGNATION_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_DESIGNATION_CHANGE", name: name, value: value })
    };
}



