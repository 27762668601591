import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import SignUp from './components/Authentication/signup';
import ForgotPassword from './components/Authentication/forgotpassword';
import NotFound from './components/Authentication/404';
import InternalServer from './components/Authentication/500';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_ACCOUNT_DETAILS, AC_HANDLE_CHANGE_INVOICE_STATUS } from './actions/accountAction';
import ImportedURL from './common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success } from './common/swal';
import invoicepayment from './components/Invoice/Invoicepayment/invoicepayment';

import { AC_LIST_COUNTRY, } from './actions/countryAction';


const token = localStorage.getItem("mytechaitoken");
class App extends Component {

	showpopup = () => {
		axios.get(`${ImportedURL.API.getPolicyaccepted}`)
			.then(async (res) => {
				if (res.data && !res.data.accepted) {
					const { value: accept } = await Swal.fire({
						title: res.data.policy,
						input: 'checkbox',
						inputValue: 0,
						inputPlaceholder:
							`I agree with the <a href=${ImportedURL.LIVEURL + res.data.file} style='text-decoration: underline' target=_blank>${res.data.policy}</a>`,
						confirmButtonText:
							'Submit',
						inputValidator: (result) => {
							return !result && 'You need to agree with the Policy'
						}
					})

					if (accept) {
						axios.post(`${ImportedURL.API.acceptPolicy}`, { policy: res.data.policyid })
							.then((res) => {
								Success('Policy accepted successfully')
								this.showpopup()
							}).catch((err) => {
								Success('Policy accept failed')
								console.log(err);
							});
					}

				}
				if (res.data && !res.data.sopaccepted) {
					const { value: accept } = await Swal.fire({
						title: res.data.sop,
						input: 'checkbox',
						inputValue: 0,
						inputPlaceholder:
							`I agree with the <a href=${ImportedURL.LIVEURL + res.data.sopfile} style='text-decoration: underline' target=_blank>${res.data.sop}</a> for ${res.data.department}`,
						confirmButtonText:
							'Submit',
						inputValidator: (result) => {
							return !result && 'You need to agree with the Policy'
						}
					})

					if (accept) {
						axios.post(`${ImportedURL.API.acceptPolicy}`, { sop: res.data.sopid })
							.then((res) => {
								Success('SOP accepted successfully')
							}).catch((err) => {
								Success('SOP accept failed')
								console.log(err);
							});
					}

				}
				// this.setState({ policydata: res.data })
			}).catch((err) => {
				console.log(err);
			});
	}
	componentDidMount() {
		if (token) {
			this.props.AC_LIST_COUNTRY();
			this.props.AccountDetails();
			this.showpopup()

		}
	}

	render() {
		const { darkMode, boxLayout, darkSidebar, iconColor, gradientColor, rtl, fontType } = this.props
		return (
			<div className={`${darkMode ? "dark-mode" : ""}${darkSidebar ? "" : "sidebar_dark"} ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""} ${rtl ? "rtl" : ""} ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""}`}>
				<Router>
					{token ?
						<Switch>
							<Route path="/signup" component={SignUp} />
							<Route path="/login" component={Login} />
							<Route path="/forgotpassword" component={ForgotPassword} />
							<Route path="/notfound" component={NotFound} />
							<Route path="/internalserver" component={InternalServer} />
							<Route path="/invoice/:id" component={invoicepayment} />
							<Route path="/" component={Layout} />
							{/* {invoicetoken ?
								<>
									<Route path="/listinvoice" component={Invoice} />
									<Route path="/listinvoicegenerate/:id" component={Invoicegenerate} />

								</> : ''
							} */}
						</Switch> :
						<Switch>
							<Route path="/invoice/:id" component={invoicepayment} />
							<Route path="/forgotpassword" component={ForgotPassword} />
							<Route path="/" component={Login} />
						</Switch>
					}

				</Router>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	darkMode: state.settings.isDarkMode,
	darkSidebar: state.settings.isDarkSidebar,
	iconColor: state.settings.isIconColor,
	gradientColor: state.settings.isGradientColor,
	rtl: state.settings.isRtl,
	fontType: state.settings.isFont,
	boxLayout: state.settings.isBoxLayout,
})

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		AccountDetails: AC_ACCOUNT_DETAILS,
		HandleChangeInvoice: AC_HANDLE_CHANGE_INVOICE_STATUS,
		AC_LIST_COUNTRY
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)