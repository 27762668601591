const LIVE = true;
const LIVEURL = LIVE ? "https://mytechai.com/" : "http://localhost:5020/"
const SITEURL = LIVE ? "https://mytechai.com/" : "http://localhost:3000/"
const ROOTURL = LIVEURL + 'api/v1/';
const FILEURL = "https://assets-myhotelai.s3.us-east-2.amazonaws.com/";

const API = {
  login: ROOTURL + 'account/login',
  accountDetails: ROOTURL + 'account/accountDetails',
  getPolicyaccepted: ROOTURL + 'account/getPolicyaccepted',
  acceptPolicy: ROOTURL + 'account/acceptPolicy',
  changePassword: ROOTURL + 'account/changePassword',
  addCategory: ROOTURL + 'category/addCategory',
  viewManager: ROOTURL + 'category/viewManager',
  listCategory: ROOTURL + 'category/listCategory',
  viewCategory: ROOTURL + 'category/viewCategory',
  updateCategory: ROOTURL + 'category/updateCategory',
  deleteCategory: ROOTURL + 'category/deleteCategory',
  addUser: ROOTURL + 'user/addUser',
  listUser: ROOTURL + 'user/listUser',
  viewUser: ROOTURL + 'user/viewUser',
  updateUser: ROOTURL + 'user/updateUser',
  deleteUser: ROOTURL + 'user/deleteUser',

  addInventory: ROOTURL + 'inventory/addInventory',
  listInventory: ROOTURL + 'inventory/listInventory',
  viewInventory: ROOTURL + 'inventory/viewInventory',
  updateInventory: ROOTURL + 'inventory/updateInventory',
  deleteInventory: ROOTURL + 'inventory/deleteInventory',
  statusChange: ROOTURL + "common/statusChange",
  softDelete: ROOTURL + "common/softDelete",
  simpleChanges: ROOTURL + "common/simpleChanges",
  deleteDocument: ROOTURL + "common/deleteDocument",
  downloadPdf: ROOTURL + "common/downloadPdf",

  addAsset: ROOTURL + 'asset/addAsset',
  listAsset: ROOTURL + 'asset/listAsset',
  deleteAsset: ROOTURL + 'asset/deleteAsset',
  viewAsset: ROOTURL + 'asset/viewAsset',
  updateAsset: ROOTURL + 'asset/updateAsset',
  assetStatusChange: ROOTURL + "common/assetstatusChange",
  assignasset: ROOTURL + "asset/assignasset",
  assetStatusUpdate: ROOTURL + "asset/assetStatusUpdate",
  updateService: ROOTURL + 'asset/updateService',
  viewAssetDetails: ROOTURL + 'asset/viewAssetDetails',
  myAssets: ROOTURL + 'asset/myAssets',

  updateSettings: ROOTURL + "settings/updateSettings",
  getSettings: ROOTURL + "settings/getSettings",

  addCountry: ROOTURL + 'country/addCountry',
  listCountry: ROOTURL + 'country/listCountry',
  viewCountry: ROOTURL + 'country/viewCountry',
  updateCountry: ROOTURL + 'country/updateCountry',
  deleteCountry: ROOTURL + 'country/deleteCountry',
  CountryStatusChange: ROOTURL + "common/CountryStatusChange",

  addTax: ROOTURL + 'tax/addTax',
  listTax: ROOTURL + 'tax/listTax',
  viewTax: ROOTURL + 'tax/viewTax',
  updateTax: ROOTURL + 'tax/updateTax',
  softTax: ROOTURL + 'tax/softTax',

  addInventoryAsset: ROOTURL + 'inventoryAsset/addInventoryAsset',
  listInventoryAsset: ROOTURL + 'inventoryAsset/listInventoryAsset',
  deleteInventoryAsset: ROOTURL + 'inventoryAsset/deleteInventoryAsset',
  viewInventoryAsset: ROOTURL + 'inventoryAsset/viewInventoryAsset',
  updateInventoryAsset: ROOTURL + 'inventoryAsset/updateInventoryAsset',
  inventoryAssetStatusChange: ROOTURL + "common/inventoryAssetStatusChange",

  addEmployee: ROOTURL + 'employee/addEmployee',
  listEmployee: ROOTURL + 'employee/listEmployee',
  softDeleteEmployee: ROOTURL + 'employee/softDeleteEmployee',
  viewEmployee: ROOTURL + 'employee/viewEmployee',
  updateEmployee: ROOTURL + 'employee/updateEmployee',
  listDepartmentParentId: ROOTURL + 'employee/listDepartmentParentId',
  listEmployeeManager: ROOTURL + 'employee/listEmployeeManager',
  listEmployeeHr: ROOTURL + 'employee/listEmployeeHr',
  addKyc: ROOTURL + 'employee/addKyc',
  addEmployeeCategory: ROOTURL + 'employee/addEmployeeCategory',
  listEmployeeCategory: ROOTURL + 'employee/listEmployeeCategory',



  addDepartment: ROOTURL + 'department/addDepartment',
  listDepartment: ROOTURL + 'department/listDepartment',
  deleteDepartment: ROOTURL + 'department/deleteDepartment',
  viewDepartment: ROOTURL + 'department/viewDepartment',
  updateDepartment: ROOTURL + 'department/updateDepartment',

  addDesignation: ROOTURL + 'designation/addDesignation',
  listDesignation: ROOTURL + 'designation/listDesignation',
  deleteDesignation: ROOTURL + 'designation/deleteDesignation',
  viewDesignation: ROOTURL + 'designation/viewDesignation',
  updateDesignation: ROOTURL + 'designation/updateDesignation',

  addAssignInventory: ROOTURL + 'assignInventory/addAssignInventory',
  listAssignInventory: ROOTURL + 'assignInventory/listAssignInventory',
  deleteAssignInventory: ROOTURL + 'assignInventory/deleteAssignInventory',
  viewAssignInventory: ROOTURL + 'assignInventory/viewAssignInventory',
  updateAssignInventory: ROOTURL + 'assignInventory/updateAssignInventory',


  addStock: ROOTURL + 'assignInventory/addStock',
  listStock: ROOTURL + 'assignInventory/listStock',
  addService: ROOTURL + 'assignInventory/addService',
  viewService: ROOTURL + 'assignInventory/viewService',
  listService: ROOTURL + 'assignInventory/listService',
  addDamage: ROOTURL + 'assignInventory/addDamage',
  listDamage: ROOTURL + 'assignInventory/listDamage',
  statusService: ROOTURL + "common/statusService",
  updateAdmin: ROOTURL + 'account/updateAdmin',



  addHoliday: ROOTURL + 'holiday/addHoliday',
  listHoliday: ROOTURL + 'holiday/listHoliday',
  viewHoliday: ROOTURL + 'holiday/viewHoliday',
  updateHoliday: ROOTURL + 'holiday/updateHoliday',
  deleteHoliday: ROOTURL + 'holiday/deleteHoliday',

  addKycdocument: ROOTURL + 'kycdocuments/addKycdocument',
  listKycdocument: ROOTURL + 'kycdocuments/listKycdocument',
  viewKycdocument: ROOTURL + 'kycdocuments/viewKycdocument',
  updateKycdocument: ROOTURL + 'kycdocuments/updateKycdocument',
  softDeleteKycdocument: ROOTURL + 'kycdocuments/softDeleteKycdocument',

  addPayrollitem: ROOTURL + 'payrollitem/addPayrollitem',
  listPayrollitem: ROOTURL + 'payrollitem/listPayrollitem',
  viewPayrollitem: ROOTURL + 'payrollitem/viewPayrollitem',
  updatePayrollitem: ROOTURL + 'payrollitem/updatePayrollitem',
  softDeletePayrollitem: ROOTURL + 'payrollitem/softDeletePayrollitem',

  addEmployeesalary: ROOTURL + 'employeesalary/addEmployeesalary',
  listEmployeesalary: ROOTURL + 'employeesalary/listEmployeesalary',
  viewEmployeesalary: ROOTURL + 'employeesalary/viewEmployeesalary',
  updateEmployeesalary: ROOTURL + 'employeesalary/updateEmployeesalary',
  softDeleteEmployeesalary: ROOTURL + 'employeesalary/softDeleteEmployeesalary',
  listEmployepayrollitem: ROOTURL + 'employeesalary/listEmployepayrollitem',


  addLeaveTemplate: ROOTURL + 'leavetemplate/addLeaveTemplate',
  listLeaveTemplate: ROOTURL + 'leavetemplate/listLeaveTemplate',
  viewLeaveTemplate: ROOTURL + 'leavetemplate/viewLeaveTemplate',
  updateLeaveTemplate: ROOTURL + 'leavetemplate/updateLeaveTemplate',
  softDeleteLeaveTemplate: ROOTURL + 'leavetemplate/softDeleteLeaveTemplate',

  addManageGrades: ROOTURL + 'managegrades/addManageGrades',
  listManageGrades: ROOTURL + 'managegrades/listManageGrades',
  viewManageGrades: ROOTURL + 'managegrades/viewManageGrades',
  updateManageGrades: ROOTURL + 'managegrades/updateManageGrades',
  softDeleteManageGrades: ROOTURL + 'managegrades/softDeleteManageGrades',

  addProject: ROOTURL + 'project/addProject',
  listProject: ROOTURL + 'project/listProject',
  viewProject: ROOTURL + 'project/viewProject',
  updateProject: ROOTURL + 'project/updateProject',
  softDeleteProject: ROOTURL + 'project/softDeleteProject',
  updateProjectModule: ROOTURL + 'project/updateProjectModule',

  addEmail: ROOTURL + 'email/addEmail',
  listEmail: ROOTURL + 'email/listEmail',
  viewEmail: ROOTURL + 'email/viewEmail',
  updateEmail: ROOTURL + 'email/updateEmail',
  softDeleteEmail: ROOTURL + 'email/softDeleteEmail',
  listTrashEmail: ROOTURL + 'email/listTrashEmail',
  updateDeletedByEmail: ROOTURL + 'email/updateDeletedByEmail',
  updateSeen: ROOTURL + 'email/updateSeen',
  hardEeleteEmail: ROOTURL + 'email/hardEeleteEmail',
  addReplayMail: ROOTURL + 'replaymail/addReplayMail',

  addTaskBoard: ROOTURL + 'taskboard/addTaskBoard',
  listTaskBoard: ROOTURL + 'taskboard/listTaskBoard',
  viewTaskBoard: ROOTURL + 'taskboard/viewTaskBoard',
  updateTaskBoard: ROOTURL + 'taskboard/updateTaskBoard',
  softDeleteTaskBoard: ROOTURL + 'taskboard/softDeleteTaskBoard',
  listTrashTaskBoard: ROOTURL + 'taskboard/listTrashTaskBoard',

  addTask: ROOTURL + 'task/addTask',
  listTask: ROOTURL + 'task/listTask',
  viewTask: ROOTURL + 'task/viewTask',
  updateTask: ROOTURL + 'task/updateTask',
  updateTypeChangeTask: ROOTURL + 'task/updateTypeChangeTask',
  softDeleteTask: ROOTURL + 'task/softDeleteTask',
  listTrashTask: ROOTURL + 'task/listTrashTask',

  addLeaves: ROOTURL + 'leaves/addLeaves',
  listLeaves: ROOTURL + 'leaves/listLeaves',
  viewLeaves: ROOTURL + 'leaves/viewLeaves',
  updateLeaves: ROOTURL + 'leaves/updateLeaves',
  softDeleteLeaves: ROOTURL + 'leaves/softDeleteLeaves',
  listLeaveApproval: ROOTURL + 'leaves/listLeaveApproval',
  leavestatuschange: ROOTURL + 'leaves/statuschange',
  leavebalance: ROOTURL + 'leaves/leavebalance',

  addLeaveType: ROOTURL + 'leavetype/addLeaveType',
  listLeaveType: ROOTURL + 'leavetype/listLeaveType',
  viewLeaveType: ROOTURL + 'leavetype/viewLeaveType',
  updateLeaveType: ROOTURL + 'leavetype/updateLeaveType',
  softDeleteLeaveType: ROOTURL + 'leavetype/softDeleteLeaveType',

  addEmployeeLeave: ROOTURL + 'employeeleave/addEmployeeLeave',
  listEmployeeLeave: ROOTURL + 'employeeleave/listEmployeeLeave',
  viewEmployeeLeave: ROOTURL + 'employeeleave/viewEmployeeLeave',
  updateEmployeeLeave: ROOTURL + 'employeeleave/updateEmployeeLeave',
  softDeleteEmployeeLeave: ROOTURL + 'employeeleave/softDeleteEmployeeLeave',

  addLeavetransection: ROOTURL + 'leavetransections/addLeavetransection',
  listLeavetransection: ROOTURL + 'leavetransections/listLeavetransection',
  viewLeavetransection: ROOTURL + 'leavetransections/viewLeavetransection',
  updateLeavetransection: ROOTURL + 'leavetransections/updateLeavetransection',
  softDeleteLeavetransection: ROOTURL + 'leavetransections/softDeleteLeavetransection',
  viewempsalary: ROOTURL + 'leavetransections/viewempsalary',


  addDivision: ROOTURL + 'division/addDivision',
  listDivision: ROOTURL + 'division/listDivision',
  softDivision: ROOTURL + 'division/softDivision',
  viewDivision: ROOTURL + 'division/viewDivision',
  updateDivision: ROOTURL + 'division/updateDivision',

  addEarning: ROOTURL + 'earning/addEarning',
  listEarning: ROOTURL + 'earning/listEarning',
  deleteEarning: ROOTURL + 'earning/deleteEarning',
  viewEarning: ROOTURL + 'earning/viewEarning',
  updateEarning: ROOTURL + 'earning/updateEarning',

  addDeduction: ROOTURL + 'deduction/addDeduction',
  listDeduction: ROOTURL + 'deduction/listDeduction',
  deleteDeduction: ROOTURL + 'deduction/deleteDeduction',
  viewDeduction: ROOTURL + 'deduction/viewDeduction',
  updateDeduction: ROOTURL + 'deduction/updateDeduction',


  addPayroll: ROOTURL + 'payroll/addPayroll',
  listPayroll: ROOTURL + 'payroll/listPayroll',
  softDeletePayroll: ROOTURL + 'payroll/softDeletePayroll',
  viewPayroll: ROOTURL + 'payroll/viewPayroll',
  updatePayroll: ROOTURL + 'payroll/updatePayroll',
  updatePayroll: ROOTURL + 'payroll/updatePayroll',
  viewleavetransecsalary: ROOTURL + 'payroll/viewleavetransecsalary',
  viewmonthlypayslip: ROOTURL + 'payroll/viewmonthlypayslip',


  addInvoicecategory: ROOTURL + 'invoicecategory/addInvoicecategory',
  listInvoicecategory: ROOTURL + 'invoicecategory/listInvoicecategory',
  deleteInvoicecategory: ROOTURL + 'invoicecategory/deleteInvoicecategory',
  viewInvoicecategory: ROOTURL + 'invoicecategory/viewInvoicecategory',
  updateInvoicecategory: ROOTURL + 'invoicecategory/updateInvoicecategory',

  addInvoicesubcategory: ROOTURL + 'invoicesubcategory/addInvoicesubcategory',
  listInvoicesubcategory: ROOTURL + 'invoicesubcategory/listInvoicesubcategory',
  softInvoicesubcategory: ROOTURL + 'invoicesubcategory/softInvoicesubcategory',
  viewInvoicesubcategory: ROOTURL + 'invoicesubcategory/viewInvoicesubcategory',
  updateInvoicesubcategory: ROOTURL + 'invoicesubcategory/updateInvoicesubcategory',

  addInvoicedetail: ROOTURL + 'inviocedetail/addInvoicedetail',
  listInvoicedetail: ROOTURL + 'inviocedetail/listInvoicedetail',
  softDeleteInvoicedetail: ROOTURL + 'inviocedetail/softDeleteInvoicedetail',
  viewInvoicedetail: ROOTURL + 'inviocedetail/viewInvoicedetail',
  updateInvoicedetail: ROOTURL + 'inviocedetail/updateInvoicedetail',
  setDefault: ROOTURL + 'inviocedetail/setDefault',


  addInvoice: ROOTURL + 'invioce/addInvoice',
  listInvoice: ROOTURL + 'invioce/listInvoice',
  softDeleteInvoice: ROOTURL + 'invioce/softDeleteInvoice',
  viewInvoice: ROOTURL + 'invioce/viewInvoice',
  updateInvoice: ROOTURL + 'invioce/updateInvoice',
  addmultipleinvoice: ROOTURL + 'invioce/addmultipleinvoice',
  sortInvoiceCategories: ROOTURL + 'invioce/sortInvoiceCategories',
  getInvoice: ROOTURL + 'invioce/getInvoice',

  addInvoicenumber: ROOTURL + 'inviocenumber/addInvoicenumber',
  listInvoicenumber: ROOTURL + 'inviocenumber/listInvoicenumber',
  deleteInvoicenumber: ROOTURL + 'inviocenumber/deleteInvoicenumber',
  viewInvoicenumber: ROOTURL + 'inviocenumber/viewInvoicenumber',
  updateInvoicenumber: ROOTURL + 'inviocenumber/updateInvoicenumber',

  // addInvoicepassword: ROOTURL + 'inviocepwd/addInvoicepassword',
  // listInvoicepassword: ROOTURL + 'inviocepwd/listInvoicepassword',
  // deleteInvoicepassword: ROOTURL + 'inviocepwd/deleteInvoicepassword',
  // viewInvoicepassword: ROOTURL + 'inviocepwd/viewInvoicepassword',
  // updateInvoicepassword: ROOTURL + 'inviocepwd/updateInvoicepassword',
  invoicelogin: ROOTURL + 'invioce/invoicelogin',
  invoicechangePassword: ROOTURL + 'invioce/invoicechangePassword',
  sendEmail: ROOTURL + 'invioce/sendEmail',
  getListFilter: ROOTURL + 'invioce/getListFilter',
  updateattachment: ROOTURL + 'invioce/updateattachment',
  getpreviousinvoice: ROOTURL + 'invioce/getpreviousinvoice',
  addPaymentInvoice: ROOTURL + 'invioce/addPaymentInvoice',
  getTransactions: ROOTURL + 'invioce/getTransactions',

  addClient: ROOTURL + 'client/addClient',
  listClient: ROOTURL + 'client/listClient',
  viewClient: ROOTURL + 'client/viewClient',
  updateClient: ROOTURL + 'client/updateClient',
  softDeleteClient: ROOTURL + 'client/softDeleteClient',
  clientsHistory: ROOTURL + 'client/clientsHistory',

  listEmployeeProject: ROOTURL + 'timesheet/listEmployeeProject',
  getemployeeEntry: ROOTURL + 'timesheet/getemployeeEntry',
  gettimesheethistory: ROOTURL + 'timesheet/gettimesheethistory',
  addTimesheetentry: ROOTURL + 'timesheet/addTimesheetentry',
  updateTimesheetentry: ROOTURL + 'timesheet/updateTimesheetentry',
  getemployeeEntryReview: ROOTURL + 'timesheet/getemployeeEntryReview',
  employeeEntryReview: ROOTURL + 'timesheet/employeeEntryReview',
  updateemployeeEntryReview: ROOTURL + 'timesheet/updateemployeeEntryReview',
  getemployeeEntryReviewadmin: ROOTURL + 'timesheet/getemployeeEntryReviewadmin',
  employeeEntryReviewadmin: ROOTURL + 'timesheet/employeeEntryReviewadmin',
  timesheetrequest: ROOTURL + 'timesheet/timesheetrequest',
  timesheetrequestlist: ROOTURL + 'timesheet/timesheetrequestlist',
  updateTimesheetRequest: ROOTURL + 'timesheet/updateTimesheetRequest',
  getLeavesHolidays: ROOTURL + 'timesheet/getLeavesHolidays',

  listPolicy: ROOTURL + 'policy/listPolicy',
  addPolicy: ROOTURL + 'policy/addPolicy',
  updatePolicy: ROOTURL + 'policy/updatePolicy',
  softDeletePolicy: ROOTURL + 'policy/softDeletePolicy',
  viewPolicy: ROOTURL + 'policy/viewPolicy',
  listPolicyApproval: ROOTURL + 'policy/listPolicyApproval',

  listSOP: ROOTURL + 'sop/listSOP',
  addSOP: ROOTURL + 'sop/addSOP',
  updateSOP: ROOTURL + 'sop/updateSOP',
  softDeleteSOP: ROOTURL + 'sop/softDeleteSOP',
  viewSOP: ROOTURL + 'sop/viewSOP',
  listSopsApproval: ROOTURL + 'sop/listSopsApproval',

  listAssetCategory: ROOTURL + 'asset/listAssetCategory',
  addAssetCategory: ROOTURL + 'asset/addAssetCategory',

  listExpenseCategory: ROOTURL + 'expense/listExpenseCategory',
  addExpenseCategory: ROOTURL + 'expense/addExpenseCategory',
  addEditExpense: ROOTURL + 'expense/addEditExpense',
  listExpense: ROOTURL + 'expense/listExpense',
  listExpensePayment: ROOTURL + 'expense/listExpensePayment',
  addEditExpensePayment: ROOTURL + 'expense/addEditExpensePayment',
  getExpenseBalance: ROOTURL + 'expense/getExpenseBalance',

  addEditVerification: ROOTURL + 'verification/addEditVerification',
  listVerification: ROOTURL + 'verification/listVerification',
  viewVerification: ROOTURL + 'verification/viewVerification',
  userVerificationList: ROOTURL + 'verification/userVerificationList',
  viewUserVerification: ROOTURL + 'verification/viewUserVerification',
  UserVerification: ROOTURL + 'verification/UserVerification',
  employeeVerifications: ROOTURL + 'verification/employeeVerifications',
  viewEmployeeVerification: ROOTURL + 'verification/viewEmployeeVerification',
}

const ImportedURL = {
  API: API,
  LIVEURL: LIVEURL,
  FILEURL: FILEURL,
  SITEURL: SITEURL
}
export default ImportedURL;
