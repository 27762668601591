const initialState = {
    listPayroll: [],
    listEmpfilter: [],
    payroll: {
        name: '',
        employeeId: '',
        month: '',
        year: '',
        earning: '',
        earningamount: '',
        deduction: '',
        deductionamount: '',
        status: '',
        isDeleted: ''
    },
    payslip: '',
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_PAYROLL_CHANGE':
            return Object.assign({}, state, {
                payroll: {
                    ...state.payroll,
                    [action.name]: action.value
                }
            })
        case 'RESET_PAYROLL':
            return Object.assign({}, state, {
                payroll: initialState.payroll,
            })
        case 'HANDLE_PAYROLL_SEARCH':
            return Object.assign({}, state, {
                listPayroll: action.value
            })
        case 'LIST_PAYROLL':
            return {
                ...state,
                listPayroll: action.payload,
                listEmpfilter: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_PAYROLL':
            return Object.assign({}, state, {
                payroll: action.payload,
                spinner: false,
            })
        case 'VIEW_MONTHLY_PAYSLIP':
            return Object.assign({}, state, {
                payslip: action.payload,
                spinner: false,
            })

        case 'AC_EMPTY_PAYROLL':
            return Object.assign({}, state, {
                payroll: {
                    name: '',
                    employeeId: '',
                    month: '',
                    year: '',
                    earning: '',
                    earningamount: '',
                    deduction: '',
                    deductionamount: '',
                    status: '',
                    isDeleted: ''
                },
                spinner: true
            })
        default:
            return state;
    }
}