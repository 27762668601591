import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_VIEW_PROJECT } from '../../actions/projectAction';
import ImportedURL from '../../common/api';
class ViewProject extends Component {
  componentDidMount() {
    this.props.ViewProject(this.props.match.params.id);
    localStorage.setItem('invoicetoken', "");
  }
  render() {
    const { fixNavbar, ProjectState, AccountState } = this.props;
    const account = AccountState?.account;
    const data = ProjectState.project;
    var testArray = [];
    for (let i = 0; i < data?.image?.length; i++) {
      let imgType = data.image[i].split('.')[1]
      testArray.push(<a href={ImportedURL.FILEURL + data.image[i]} className="btn btn-icon" target='_blank' download><i className={imgType == ("pdf" || "PDF") ? "fa fa-file-pdf-o" : "fa fa-picture-o"} style={{ fontSize: '40px' }} data-action-type="View"></i></a>)
    }
    return (
      <div>
        <div className="breadcrump">
          <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <Link to="/list-project"><h6 className="highlights_breadcrump"> Project List</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6 className="highlights_breadcrump">View Project</h6></p>
        </div>
        <div className="section-body pt-3">
          <div className="container-fluid">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title d-flex "> <div className='rounded_icon'><i className="fa fa-project-diagram mr-2"></i></div><span className='pt-2'>  VIEW PROJECT</span> </h3>

                    <div className="card-options">
                      <div className="d-flex justify-content-between align-items-center">
                        <ul className="nav nav-tabs page-header-tab">
                        </ul>
                        <div className="header-action">
                          <Link to='/list-project'><button type="button" className="btn btn-primary mr-3" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <div class="card ">
                          <div class="card-header">
                            <h3 class="card-title">{data.name}</h3>
                          </div>
                          <div class="card-body">
                            {/* <span class="tag tag-blue mb-3">Web Design</span> */}
                            <div class="row">
                              <div class="col-2 py-1">
                                <strong>Start Date:</strong>
                              </div>
                              <div class="col-10 py-1">{data.date}</div>
                              <div class="col-2 py-1">
                                <strong> Project Leader:</strong>
                              </div>
                              <div class="col-10 py-1">{data.leaderName}</div>
                              <div class="col-2 py-1">
                                <strong> Team Members:</strong>
                              </div>
                              <div class="col-10 py-1">{data.teamList}</div>
                              <div class="col-2 py-1">
                                <strong>Description:</strong>
                              </div>
                              <div class="col-10 py-1">
                                {data.description ?
                                  <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
                                  : '-'}
                              </div>
                              <div class="col-2 py-1">
                                <strong>Upload Files:</strong>
                              </div>
                              <div class="col-10 py-1">
                                {
                                  testArray && testArray.length ? testArray : '-'
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
  AccountState: state.account,
  ProjectState: state.project,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ViewProject: AC_VIEW_PROJECT,
    }, dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewProject);