import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { AC_LIST_EMAIL } from '../../actions/emailAction';
import nodata from "../../assets/images/no-data.jpg"
class ListEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewRedirect: false,
      viewRedirectId: '',

    }
  }
  componentDidMount() {
    this.props.ListEmail();
    localStorage.setItem('invoicetoken', "");
  }
  viewClick = async (id, item) => {
    this.setState({ viewRedirect: true, viewRedirectId: id })
    const { AccountState } = this.props;
    const account = AccountState?.account;
    const userId = account._id;
    const emailId = id;
    const seen = !item.seenBy.find((seenBy) => seenBy.userId === userId).seen;
    if (seen) {
      await axios.post(`${ImportedURL.API.updateEmail}/${userId}/${emailId}/${seen}`)
        .then((res) => {
          this.props.ListEmail();
        })
    }
  }

  render() {
    if (this.state.viewRedirect) {
      return <Redirect to={'/view-inbox/' + this.state.viewRedirectId} />
    }
    const { AccountState, EmailState } = this.props;
    const account = AccountState?.account;
    const previleges = AccountState.previleges;
    const Previlege = previleges.find(obj => { return obj.name == "Email" });
    const listEmail = EmailState.listEmail;
    const userId = account._id; // Replace with actual user ID

    const inboxData = []
    if (listEmail && listEmail.length > 0) {
      listEmail.map((mailData) => {
        let reciveToMail = mailData.toValue && mailData.toValue.includes(account.email);
        let reciveCcMail = mailData.ccValue && mailData.ccValue.includes(account.email);
        if (reciveToMail || reciveCcMail) {
          inboxData.push(mailData)
        }
      })
    }


    return (
      <div>
        <div className="breadcrump">
          <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-email"><h6 className="highlights_breadcrump">Email List</h6></Link></p>
        </div>
        <div className="section-body user_sec email_sec">
          <div className="container-fluid">
            <div className="tab-content pt-3">
              <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                <div className="card">
                  <div className="card-header">
                    <div className='card-title d-flex'>
                      <div className='rounded_icon'><i className="fa fa-inbox mr-2"></i></div>
                      <h3 className="card-title " style={{ marginTop: '10px' }}> Inbox </h3>
                    </div>
                    <div className="card-options">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="header-action">
                          {Previlege?.add ?
                            <Link to='/compose'><button type="button" className="btn compose_btn" ><i className="fe fe-plus mr-2" id='Add' /> Compose</button></Link>
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body email_list">
                    <div class="table-responsive">
                      <table class="table table-hover table-striped table-vcenter text-nowrap mb-0">
                        <tbody>
                          {inboxData.length > 0 ? inboxData.map((data, i) => {
                            const seen = data.seenBy.find((seenBy) => seenBy.userId === userId)?.seen;
                            return <tr key={i} style={{ cursor: 'pointer' }} className='table_row'>
                              <td class="w80" onClick={(e) => this.viewClick(data._id, data)}>
                                <label class="custom-control custom-checkbox" style={{ cursor: 'pointer' }}>
                                  <input type="checkbox" class="custom-control-input" name="example-checkbox1" value="option1" />
                                  <span class="custom-control-label">&nbsp;</span>
                                </label>
                              </td>
                              <td style={{ width: '50px' }} onClick={(e) => this.viewClick(data._id, data)}>
                                <span><i class={data.replyStatus ? "fa fa-commenting-o" : "fa fa-inbox"} aria-hidden="true"></i></span>
                              </td>
                              <td onClick={(e) => this.viewClick(data._id, data)}><p className='description' style={{ fontWeight: seen ? '400' : '500' }}>{data.subject} - {data.description && data.description.replace(/<[^>]+>/g, '')}</p></td>
                              <td className='maildate' style={{ fontWeight: seen ? '400' : '500', color: seen ? '#555' : '#000' }} onClick={(e) => this.viewClick(data._id)}>{data.date}</td>
                              <td className='maildeleteicon' onClick={(e) => this.deleteMail(data._id)}><span ><i class="fa fa-trash-o " aria-hidden="true"></i></span></td>
                            </tr>
                          }) :
                            <tr>
                              <td style={{ textAlign: 'center' }}><img src={nodata} style={{ width: '400px', height: '400px' }} /> </td>
                            </tr>
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
  AccountState: state.account,
  EmailState: state.email
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ListEmail: AC_LIST_EMAIL
    }, dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListEmail);