import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import URL from '../../common/api';
import { Success, Error } from '../../common/swal';
import Carousel from 'react-bootstrap/Carousel';


export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			emailError: false,
			emailValidError: false,
			passwordError: false,
		}
	}
	onChange = e => {
		const { name, value } = e.target;
		const Error = name + "Error";
		const ValidError = name + "ValidError";
		this.setState({ [name]: value })
		if (name === 'email') {
			this.setState({ email: value });
			var email = value;
			if (email) {
				this.setState({ [Error]: false });
				// if (Emailvalidate(email)) {
				// 	this.setState({ [ValidError]: false, [Error]: false })
				// } else {
				// 	this.setState({ [ValidError]: true })
				// }
			}
			else {
				this.setState({ emailError: true, [ValidError]: false });
			}
		}
	}
	submit = (e) => {
		e.preventDefault();
		const { email, password } = this.state;
		if (!email) this.setState({ emailError: true });
		if (!password) this.setState({ passwordError: true });
		if (email && password) {
			const formData = { email: email, password: password }
			axios.post(URL.API.login, formData)
				.then((res) => {
					Success('Logged in successfully');
					localStorage.setItem('mytechaitoken', res.data);
					window.location.href = "/";
				}).catch(({ response }) => { Error('Login failed! Try again') });
		}
	}
	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center mb-2">
							<Link className="header-brand login_log" to="/">
								<img className="avatar" src="../assets/images/banner-logo.png" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title">Login to your account</div>
							{/* <div className="form-group">
									<select className="custom-select">
										<option>HR Dashboard</option>
										<option>Project Dashboard</option>
										<option>Job Portal</option>
									</select>
								</div> */}
							<div className="form-group">
								<input
									type="email"
									className="form-control"
									name="email"
									aria-describedby="emailHelp"
									placeholder="Enter email"
									onChange={this.onChange}
								/>
								<div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
								<div className="invalid-feedback" style={{ display: this.state.emailValidError ? 'block' : 'none' }}>Enter valid email</div>
							</div>
							<div className="form-group">
								<input
									type="password"
									className="form-control"
									name="password"
									placeholder="Password"
									onChange={this.onChange}
								/>
								<div className="invalid-feedback" style={{ display: this.state.passwordError ? "block" : 'none' }}>Password is required</div>
								<label className="form-label">
									{/* Password */}
									<Link className="float-right small" to="/forgotpassword">
										I forgot password
									</Link>
								</label>
							</div>
							{/* <div className="form-group">
								<label className="custom-control custom-checkbox">
									<input type="checkbox" className="custom-control-input" />
									<span className="custom-control-label">Remember me</span>
								</label>
							</div> */}
							<div className="form-footer">
								<a className="btn btn-primary btn-block" href="/" onClick={this.submit}>
									login
								</a>
							</div>
						</div>
						{/* <div className="text-center text-muted">
							Don't have account yet? <Link to="/signup">Sign Up</Link>
						</div> */}
					</div>
				</div>
				<div className="auth_right">
					<Carousel>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider1.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Effective Property Maintenance simplified with myhotelai.com</h5>
								<p>Manage the day-to-day operations of your hotels with ease using AI-supported software.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider2.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Managing multiple hotels? myhotelai.com is here to assist you.</h5>
								<p>Ensuring the flawless operation of multiple hotels at the same time could be challenging.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider3.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Track and monitor the performance of your hotels with real-time data.</h5>
								<p>Enhance the efficiency and profitability of your hotels by gauging their <br /> performance with accurate, auto-fetched data.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider4.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Fetch accurate data on the effectiveness of ticket resolution in your hotels</h5>
								<p>Catalyze the ticket resolution process with an automated workflow.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider5.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Assess the performance of your assets accurately with periodic reports.</h5>
								<p>Build a culture of data-based decision-making to give your customers a great stay.</p>
							</div>
						</Carousel.Item>
					</Carousel>
				</div>
			</div>
		);
	}
}
