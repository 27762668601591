const initialState = {
    listEmployeeLeave: [],
    employeeLeave: {
        employee: '',
        leave: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_EMPLOYEE_LEAVE_CHANGE':
            return Object.assign({}, state, {
                employeeLeave: {
                    ...state.employeeLeave,
                    [action.name]: action.value
                }
            })
        case 'RESET_EMPLOYEE_LEAVE':
            return Object.assign({}, state, {
                employeeLeave: initialState.employeeLeave,
            })
        case 'LIST_EMPLOYEE_LEAVE':
            return {
                ...state,
                listEmployeeLeave: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_EMPLOYEE_LEAVE':
            return Object.assign({}, state, {
                employeeLeave: action.payload,
                spinner: false,
            })

        case 'AC_EMPTY_EMPLOYEE_LEAVE':
            return Object.assign({}, state, {
                employeeLeave: {
                    employee: '',
                    leave: '',
                },
                spinner: true
            })
        // case 'HANDLE_EMPLOYEE_LEAVE_MULTI_CHANGE':
        //     return {
        //         ...state,
        //         employeeLeave: {
        //             ...state.employeeLeave,
        //             leave:
        //                 [
        //                     ...state.employeeLeave.leave.slice(0, action.i),
        //                     { ...state.employeeLeave.leave[action.i], [action.name]: action.value },
        //                     ...state.employeeLeave.leave.slice(action.i + 1)
        //                 ]
        //         }
        //     }
        // case 'HANDLE_EMPLOYEE_LEAVE_PLUS':
        //     if (action.id === '') {
        //         return {
        //             ...state,
        //             employeeLeave: {
        //                 ...state.employeeLeave,
        //                 leave: [...state.employeeLeave.leave, action.value]
        //             }
        //         }
        //     }
        //     else if (action.name === true) {
        //         return {
        //             ...state,
        //             employeeLeave: {
        //                 ...state.employeeLeave,
        //                 leave: [action.value]
        //             }
        //         }
        //     }
        //     else {
        //         return {
        //             ...state,
        //             employeeLeave: {
        //                 ...state.employeeLeave,
        //                 leave: [...state.employeeLeave.leave.filter((event, i) => i !== action.id)]
        //             }
        //         }
        //     }
        default:
            return state;
    }
}