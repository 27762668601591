
const initialState = {
    listDeduction: [],
    deduction: {
        name: '',
	},
}
function deductionReducer(state = initialState, action) {
    switch (action.type) {
        case 'LIST_DEDUCTION':
            return {
                ...state,
                listDeduction: action.payload
            }
            break;
        case 'VIEW_DEDUCTION':
            return {
                ...state,
                deduction: action.payload
            }
        case 'RESET_DEDUCTION':
			return Object.assign({}, state, {
				deduction: initialState.deduction
			})
        case 'HANDLE_DEDUCTION_CHANGE': 
            return Object.assign({}, state, {
                deduction: {
                    ...state.deduction,
                    [action.name]: action.value
                }
            })
        default:
            return state;
            break;
    }
}

export default deductionReducer;