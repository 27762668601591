import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_LIST_DESIGNATION, AC_VIEW_DESIGNATION, AC_HANDLE_DESIGNATION_CHANGE, AC_RESET_DESIGNATION } from '../../../actions/designationAction';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import { Success, Error } from '../../../common/swal';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AC_LIST_DEPARTMENT } from '../../../actions/departmentAction';
import { AC_LIST_DIVISION } from '../../../actions/divisionAction';

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Select from 'react-select';



const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "Add",
    save: false,
    columnDefs: [
        { headerName: 'Department Name', field: 'departmentName', width: 300, floatingFilter: true },
        { headerName: 'Division Name', field: 'divisionName', width: 300, floatingFilter: true },
        { headerName: 'Designation Name', field: 'designation', width: 300, floatingFilter: true },
        {
            headerName: 'Status', width: 200, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" style="margin-top: 5px" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 300, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class ListDesignation extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.changeModal = this.changeModal.bind(this);
    }
    changeModal = (e) => {
        this.props.AC_RESET_DESIGNATION();
        this.setState({ modalType: e.target.id });
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id, departmentError: false, nameError: false });
            this.props.AC_VIEW_DESIGNATION(event.data._id);
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.deleteDesignation + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            this.props.AC_LIST_DESIGNATION();
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'designations' })
                        .then((data) => {
                            this.props.AC_LIST_DESIGNATION();
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };
    searchUser = e => {
        const { name, value } = e;
        this.setState({
            [name]: value,
        });
    }

    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['department.name', 'name'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { designationReducer } = this.props;
        const exportData = designationReducer.listDesignation;
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Designation";
        const headers = [['#', 'Department Name', 'Designation Name']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.department?.name,
            elt.name,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Designation.pdf")
    }


    onChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.props.AC_HANDLE_DESIGNATION_CHANGE('designation', value);
        this.setState({ [Error]: false })

        // if (name === "department") {
        //     this.setState({ department: value })
        //     if (value !== 'Select Department') {
        //         this.setState({ departmentError: false })
        //     } else {
        //         this.setState({ departmentError: true })
        //     }
        // }
        if (name === "designation") {
            if (value) {
                this.setState({ nameError: false })
            } else {
                this.setState({ nameError: true })
            }
        }
    }

    submit = () => {
        const data = this.props.designationReducer.designation;
        if (!data.designation) {
            this.setState({ nameError: true })
        }
        if (!data.department) {
            this.setState({ departmentError: true })
        }
        if (!data.division) {
            this.setState({ divisionError: true })
        }
        if (data.designation && data.department && data.division) {
            if (this.state.modalType === "Add") {
                this.setState({ save: true });
                axios.post(ImportedURL.API.addDesignation, data)
                    .then((data) => {
                        Success(data.statusText);
                        this.setState({ save: false });
                        this.props.AC_LIST_DESIGNATION();
                        this.props.AC_RESET_DESIGNATION();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateDesignation, data)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.AC_LIST_DESIGNATION();
                        this.props.AC_RESET_DESIGNATION();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }
    onChangeSelect = (e) => {
        const { name, value, label } = e;
        const Error = name + "Error";
        if (name == 'department') {
            if (value) {
                this.props.AC_HANDLE_DESIGNATION_CHANGE('department', value);
                this.setState({ departmentError: false })
            } else {
                this.setState({ departmentError: true })
            }
        }
        if (name == 'division') {
            if (value) {
                this.props.AC_HANDLE_DESIGNATION_CHANGE('division', value);
                this.setState({ divisionError: false })
            } else {
                this.setState({ divisionError: true })
            }
        }
    }

    componentWillMount() {
        this.props.AC_LIST_DESIGNATION();
        this.props.AC_LIST_DEPARTMENT();
        this.props.AC_LIST_DIVISION();
        localStorage.setItem('invoicetoken', "");
    }
    render() {
        const { fixNavbar, designationReducer, AccountState, divisionReducer } = this.props;
        const rowData = designationReducer.listDesignation;
        var departmentData = this.props.departmentReducer.listDepartment;
        const data = designationReducer.designation;
        const listDivision = divisionReducer.listDivision;

        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            // '<div><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const departmentOptions = [];
        departmentData.filter(filterItem => filterItem.status === true).map(item => {
            departmentOptions.push({ label: item.name, value: item._id, name: 'department' });
        })
        const divisionOptions = [];
        listDivision.filter(filterItem => filterItem.status === true).map(item => {
            if (item.department._id == data.department) {
                divisionOptions.push({ label: item.division, value: item._id, name: 'division' });
            }
        })
        let selectedDepartment = '';
        if (data.department) {
            selectedDepartment = departmentData.find(e => e._id === data.department)
        }

        let selectedDivision = '';
        if (data.division) {
            selectedDivision = listDivision.find(e => e._id === data.division)
        }
     

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listdesignation"><h6> Designation List</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'>
                                                <i class="fa-brands fa-unity mr-2"></i>
                                            </div>
                                            <h3 className="card-title">List Designation</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>

                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Modal */}
                                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"><i class="fa-brands fa-unity mr-2"></i>{this.state.modalType} Designation</h5>
                                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true" style={{ fontSize: "23px" }} >x</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row clearfix">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="form-label">Department Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    onChange={this.onChangeSelect}
                                                                    value={selectedDepartment ? { label: selectedDepartment.name, value: selectedDepartment._id, name: "department" } : ''}
                                                                    options={departmentOptions}
                                                                />
                                                                <div className="invalid-feedback" style={{ display: this.state.departmentError ? "block" : 'none' }}>Department Name is required</div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">Division Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    onChange={this.onChangeSelect}
                                                                    value={selectedDivision ? { label: selectedDivision.division, value: selectedDivision._id, name: "division" } : ''}
                                                                    options={divisionOptions}
                                                                />
                                                                <div className="invalid-feedback" style={{ display: this.state.divisionError ? "block" : 'none' }}>Division is required</div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">Designation Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onChange} value={data.designation} placeholder="Designation Name" />
                                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Designation Name is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-right mandatory">
                                                    <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                    <div className="text-right">
                                                        {this.state.save ?
                                                            <button type="button" className="btn btn-primary" onClick={this.submit}><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                            :
                                                            <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    departmentReducer: state.departmentReducer,
    designationReducer: state.designationReducer,
    divisionReducer: state.divisionReducer
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            AC_LIST_DESIGNATION, AC_VIEW_DESIGNATION, AC_HANDLE_DESIGNATION_CHANGE, AC_RESET_DESIGNATION, AC_LIST_DEPARTMENT, AC_LIST_DIVISION
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ListDesignation);