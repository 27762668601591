import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_EMPLOYEE_LEAVE_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMPLOYEE_LEAVE_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_EMPLOYEE_LEAVE() {
    return function (dispatch) {
        dispatch({ type: "RESET_EMPLOYEE_LEAVE" })
    };
}


export function AC_LIST_EMPLOYEE_LEAVE() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listEmployeeLeave)
                .then((res) => {
                    dispatch({ type: "LIST_EMPLOYEE_LEAVE", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_EMPLOYEE_LEAVE(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewEmployeeLeave + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_EMPLOYEE_LEAVE", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


export function AC_EMPTY_EMPLOYEE_LEAVE() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_EMPLOYEE_LEAVE" })
    }
}

export function AC_HANDLE_EMPLOYEE_LEAVE_MULTI_CHANGE(name, value, i) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMPLOYEE_LEAVE_MULTI_CHANGE", name: name, value: value, i: i })
    };
}

export function AC_HANDLE_EMPLOYEE_LEAVE_PLUS(name, value, id) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMPLOYEE_LEAVE_PLUS", name: name, value: value, id: id })
    };
}
