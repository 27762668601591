import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_PROJECT_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_PROJECT_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_PROJECT() {
    return function (dispatch) {
        dispatch({ type: "RESET_PROJECT" })
    };
}


export function AC_LIST_PROJECT() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listProject)
                .then((res) => {
                    dispatch({ type: "LIST_PROJECT", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_PROJECT(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewProject + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_PROJECT", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


export function AC_EMPTY_PROJECT() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_PROJECT" })
    }
}