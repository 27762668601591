import React, { Component, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_HANDLE_INVOICE_CHANGE, AC_EMPTY_INVOICE, AC_SPINNER_INVOICE_ALL } from '../../actions/invoiceAction';
import ImportedURL from '../../common/api';
import axios from 'axios';
import { Link, Redirect, } from 'react-router-dom';
import { Success, Error } from '../../common/swal';
import { AC_HANDLE_CHANGE_INVOICE_STATUS } from '../../actions/accountAction';


class Invoicepwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            eye: false,
            redirect: false,
        }
    }

    componentDidMount() {

    }

    viewClick = () => {
        this.setState({ eye: !this.state.eye })
    }

    onSubmit = e => {
        const data = this.props.employeeReducer.employee;

    }
    handleOnchange = (e) => {
        const { name, value } = e.target;
        const Error = name + "Error";
        if (name == 'invoicepwd') {
            if (value) {
                this.setState({ invoicepasswordError: false });
            } else {
                this.setState({ invoicepasswordError: true })
            }
        }
        this.props.handleChange(name, value)
    }
    handleclickPwd = () => {
        // e.preventDefault();

        const data = this.props.invoiceState.invoice;
        var formData = {
            password: data.invoicepwd
        }
        let valid = 1
        if (!data.invoicepwd) {
            this.setState({ invoicepasswordError: true });
            valid = 0
        }
        if (valid) {
            axios.post(ImportedURL.API.invoicelogin, formData)
                .then((res) => {
                    if (res) {
                        Success("Logged in successfully");
                        this.setState({ show: false })
                        this.setState({ redirect: true })
                        this.props.HandleChangeInvoice(true)
                        localStorage.setItem('invoicetoken', res.data);
                    }
                }).catch((res) => {
                    Error("Invalid password for Admin")
                });
        }

    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // Your function logic goes here
            console.log('Enter key pressed!');
            this.handleclickPwd();
        }
    };

    componentDidMount() {
        // Attach the event listener to the document
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        // Remove the event listener when the component is unmounted
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
        const { invoiceState } = this.props;
        const data = invoiceState.invoice;
        const myParam = window.location.pathname.split("/").pop();
        const queryParams = new URLSearchParams(this.props.location.search);
        const redirectParam = queryParams.get('redirect')
        if (this.state.redirect) {
            return <Redirect to={redirectParam ? '/' + redirectParam : '/listinvoice'} />
        }


        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/invoicepwd"><h6>Invoice password</h6></Link> </p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'>
                                            <i className="fa-solid fa-user mr-2"></i>
                                        </div>
                                        <h3 className="card-title">ENTER INVOICE PASSWORD</h3>
                                        <div class="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <ul className="nav nav-tabs page-header-tab"></ul>
                                                {/* <div className="header-action">
                                                    <button type="button" className="btn btn-primary" onClick={this.back}><i className="fa fa-solid fa-chevron-left mr-2"></i>Back</button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div id='clear_form' autocomplete="off" >
                                                    <div className="form-group ">
                                                        <label className="form-label">Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <div className='site_pass'>
                                                            <input type={this.state.eye ? 'text' : 'password'} className="form-control" placeholder="Password" name='invoicepwd' onChange={this.handleOnchange} />
                                                            <i class={this.state.eye ? "fa fa-eye" : "fa fa-eye-slash"} data-toggle="tooltip" title={this.state.eye ? "Hide" : "Unhide"} style={{ cursor: 'pointer', fontSize: '20px' }} onClick={this.viewClick}></i>
                                                        </div>
                                                        <div className="invalid-feedback" style={{ display: this.state.invoicepasswordError ? "block" : 'none' }}> Password is required</div>
                                                    </div>

                                                    <div className="footer-action invoice_pwd_btn " >
                                                        {this.state.save ?
                                                            <button type="button" className="btn btn-primary mt-3" onClick={this.handleclickPwd}><i class="fa fa-spinner fa-spin mr-2"></i>Submiting</button>
                                                            :
                                                            <button type="button" className="btn btn-primary mt-3" onClick={this.handleclickPwd}><i className="fe fe-save mr-2"></i>Submit</button>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </>

        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    invoiceState: state.invoice,
})
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        handleChange: AC_HANDLE_INVOICE_CHANGE,
        emptyinvoice: AC_EMPTY_INVOICE,
        HandleChangeInvoice: AC_HANDLE_CHANGE_INVOICE_STATUS,
        AC_SPINNER_INVOICE_ALL

    }, dispatch)

}
export default connect(mapStateToProps, mapDispatchToProps)(Invoicepwd);