import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ImportedURL from '../../common/api';
import moment from 'moment';
import { Currency } from '../../common/validate';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
export default class Invoicepdf extends Component {
  render() {
    let data = this.props.data;
    const d = new Date();
    let year = d.getFullYear();
    const Month = new Date();
    var discountAmount = 0;
    discountAmount = data.amount - data.discount;


    return (
      <>
        <div className='card-body invoice_generate' id='print_pagepdf'>
          <div className='row print_design' >
            <div className='payslip_content'>
              <p className='payslip_heading'>
                INVOICE FOR THE MONTH OF  {moment(Month).format('MMMM').toUpperCase() + " " + year}
              </p>
            </div>
            <div className='col-lg-8'>
              <div className='invoice_address'>
                <h4>{data.company.name}</h4>
                <p>{data.company.address}</p>
                <p>{data.company.city}</p>
                <p>{data.company.state + " " + data.company.code}</p>
                <a href=''>{data.company.email}</a>
                <p className='pt-1'>
                  <a href={'tel:' + data.company.phonenumber}>{data.company.phonenumber}</a>
                </p>

              </div>
            </div>
            <div className='col-lg-4'>
              <div className='payslip_content'>
                <div className='payslip_company_intro'>
                  <div className='company_logo'>
                    <img src={ImportedURL.LIVEURL + data.company.image} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='invoice_detail_billto print_design mt-3'>
            <div className='row'>
              <div className='col-lg-6 '>
                <h4 className='invoice_detail_billto_heading'>BILL TO</h4>
                <p className='tocompname'>{data.tocompany}</p>
                <p>{data.toaddress}</p>
              </div>
              <div className='col-lg-6 '>
                <h4 className='invoice_detail_billto_heading inv_det'>INVOICE DETAIL</h4>
                <div className='detslin'>
                  <p className='invoice_heading_tex'>Invoice #</p>
                  <p className='dynaic_tex_invoice'>{data.invoiceId}</p>
                </div>
                <div className='detslin mt-2'>
                  <p className='invoice_heading_tex'>Invoice Date</p>
                  <p className='dynaic_tex_invoice'>{moment(data.invoicedate).format('MM-DD-YYYY')}</p>
                </div>
                <div className='detslin mt-2'>
                  <p className='invoice_heading_tex'>Due Date</p>
                  <p className='dynaic_tex_invoice_due'>{moment(data.duedate).format('MM-DD-YYYY')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='invoice_detail print_design mt-5'>
            <div className='table_invoice'>
              <div className='row tab_inv'>
                <div className='col-lg-5 col_border_right'>
                  <h6 className='desc_h6'>DESCRIPTION</h6>
                  {data.category && data.category.map((item) => {
                    return (
                      <>
                        <h5 className='desc_h5'>{item.name}</h5>
                        <>
                          {
                            (data.subcategory)
                            &&
                            data.subcategory.map((item1) => {
                              if (item._id == item1.category)
                                return (
                                  <>
                                    <p className='desc_p'>{item1.subcategory}</p>
                                  </>
                                )
                            })
                          }
                        </>

                      </>
                    )
                  })}
                </div>
                <div className='col-lg-2 col_border_right'>
                  <h6 className='desc_h6'>MONTH</h6>
                  <p className='next_tab_div'>{moment(Month).format('MMMM') + " - " + moment(data.invoicedate).format('YYYY')}</p>
                </div>
                <div className='col-lg-3 col_border_right'>
                  <h6 className='desc_h6'>URL</h6>
                  <a href='#' className='next_tab_div'>{data.url}</a>
                </div>
                <div className='col-lg-2 col_border_right'>
                  <h6 className='desc_h6'>TOTAL</h6>
                  <p className='next_tab_div'>{"$" + Currency((data.amount).toString())}</p>

                </div>
              </div>
              <div className='invoice_dis print_subtotal'>
                <div className='row'>
                  <div className='col-lg-10'>
                    <p className='dis_Name'>
                      Multiple Property Discount Applied
                    </p>

                  </div>
                  <div className='col-lg-2'>
                    <p className='Disc_amount'>{data.discount ? " - " + Currency((data.discount).toString()) : 0}</p>
                  </div>
                </div>
              </div>
              <div className='invoice_dis subtotal_invoice'>
                <div className='row justify-content-end' style={{ border: "none" }}>
                  <div className='col-lg-2'>
                    <p className='dis_Name'>
                      SUBTOTAL
                    </p>

                  </div>
                  <div className='col-lg-2'>
                    <p className='Disc_amount'>{discountAmount ? Currency((discountAmount).toString()) : 0}</p>
                  </div>
                </div>
              </div>
              <div className='invoice_dis subtotal_invoice'>
                <div className='row justify-content-end' style={{ border: "none" }}>
                  <div className='col-lg-2'>
                    <p className='balance_name_invoice'>
                      BALANCE DUE
                    </p>

                  </div>
                  <div className='col-lg-2'>
                    <p className='Disc_amount current_amount_invoice'><i class="fa fa-usd" aria-hidden="true"></i>{discountAmount ? Currency((discountAmount).toString()) : 0}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    )
  }
}
