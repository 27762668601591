import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import moment from 'moment';
import { Currency, Emailvalidate } from '../../../common/validate';
import { AC_HANDLE_INVOICE_CHANGE, AC_LIST_INVOICE, AC_VIEW_INVOICE, AC_RESET_INVOICE, AC_EMPTY_INVOICE, AC_SPINNER_INVOICE_ALL } from '../../../actions/invoiceAction';
import { Urlvalidate, NumberOnly } from '../../../common/validate';
import { AC_LIST_INVOICE_DETAIL } from '../../../actions/invoicedetail';
import { AC_LIST_INVOICE_CATEGORY } from '../../../actions/invoicecategoryAction';
import { AC_LIST_INVOICE_SUB_CATEGORY } from '../../../actions/invoicesubcategoryAction';
import ImportedUrl from '../../../common/api';
import Spinner from 'react-bootstrap/Spinner';
import ReactApexChart from 'react-apexcharts';
import html2canvas from 'html2canvas';

let date = new Date();
let nextmonth = date.setMonth(date.getMonth() + 1);
const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    image: '',
    email: '',
    eye: false,
    changepwdshow: false,
    changepwdinvoice: false,
    password: '',
    newpassword: '',
    confirmpassword: '',
    perPage: 25,
    tocompanyValue: '',
    passwordError: false,
    newpasswordError: false,
    confirmpasswordError: false,
    nameError: false,
    categoryError: false,
    subcategoryError: false,
    addressError: false,
    toaddressError: false,
    amountError: false,
    invoicedateError: false,
    duedateError: false,
    urlError: false,
    discountError: false,
    companyError: false,
    tocompanyError: false,
    categoryError: false,
    subcategoryError: false,
    invalidurlError: false,
    communicationemailValidError: false,
    invoicepasswordError: false,
    companyData: [],
    companydropdown: [],
    listinvoiceData: [],
    companyDrop: false,
    editSpi: false,
    allOption: true,
    pdfList: [],
    columnDefs: [
        // {
        //     headerName: 'Image', field: 'tocompanyimage', width: 50, filter: false, cellStyle: { textAlign: 'center' },
        //     cellRendererFramework: function (params) {
        //         if (params.data) {
        //             return <div>
        //                 <img style={{ width: '40px', height: '40px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + params.data.tocompanyimage} alt="" />
        //             </div>
        //         }
        //     }
        // },
        { headerName: 'Invoice #', field: 'invoicenum', width: 100, filter: true, floatingFilter: true },
        { headerName: 'Company Name', field: 'company.name', width: 130, hide: true },
        {
            headerName: 'Address', field: 'company.address', width: 150, hide: true
        },
        // { headerName: 'Client', field: 'tocompany', width: 130, hide: true, floatingFilter: true },
        // {
        //     headerName: 'To Address', field: 'toaddress', width: 130, hide: true
        // },
        {
            headerName: 'Invoice Amount', field: 'amount', width: 100, floatingFilter: true
        },
        {
            headerName: 'Due Date', field: 'duedate', width: 130, hide: true
        },
        {
            headerName: 'Invoice Date', field: 'invoicedate', width: 100, filter: false,
            // filter: 'agDateColumnFilter', floatingFilter: true, filterParams: filterParams,
            // filter: 'agDateColumnFilter', floatingFilter: true, filterParams: {
            //     suppressAndOrCondition: true,// Optionally suppress AND/OR buttons
            //     resetButton: true,
            //     filterOptions: ['inRange'],
            // },
            valueGetter: function (params) {
                if (params.data && params.data.invoicedate) {
                    return moment.utc(params.data.invoicedate).format('MM-DD-YYYY');
                }
            }
        }, {
            headerName: 'Amount paid', field: 'amountpaid', width: 100,
        },
        {
            headerName: 'Company URL', field: 'url', width: 130, hide: true,
        },
        {
            headerName: 'Discount', field: 'discount', width: 70, filter: false,
            cellRenderer: function (params) {
                if (params.data && params.data.discount)
                    if (params.data.discounttype === 'percentage')
                        return params.data.discount + '%'
                    else return params.data.discount + '/-'

                else return '';
            }
        },
        {
            headerName: 'Category', field: 'categoryName', width: 130, hide: true,
        },
        {
            headerName: 'Sub Category', field: 'subcategoryName', width: 130, hide: true,
        },
        {
            headerName: 'Email', width: 50, cellStyle: { textAlign: 'center' }, filter: false, sorting: false,
            cellRenderer: function (params) {
                if (params.data)
                    //         return `<button type="button" class="btn payslip_btn" data-action-type="Invoice">
                    //     Genarate Invoice
                    //   </button>`;

                    if (params.data.communicationemail && params.data.communicationemail.length) {
                        return `<span  class="tag  tag-default" style="background-color: #63b38a">
                        <i class="fe fe-check p-1" data-toggle="tooltip" title="Email exist" style="font-size:15px;color:white"></i>
                           </span>`;
                    } else {
                        return `<span  class="tag  tag-default" style="background-color: #E8759F;
                        border: #E8759F;">
                        <i class="fe fe-x p-1" data-toggle="tooltip" title="Email is empty" style="font-size:15px;color:white"></i>
                           </span>`;
                    }
                else return '';
            }
        },
        {
            headerName: 'Status', width: 100, field: 'status', cellStyle: { textAlign: 'center' }, filter: false, sorting: false,
            cellRenderer: function (params) {
                if (params.data)
                    if (params.data.status) {
                        return `<span type="button" class="tag tag-green" data-action-type="Status">Valid</span>`;
                    } else {
                        return '<span type="button" class="tag tag-danger"  data-action-type="Status">Invalid</span>';
                    }
                else return ''
            }
        },
        {
            headerName: 'Actions', width: 90, field: 'actions', cellStyle: { textAlign: 'center' }, filter: false,
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": false,
        "resizable": true
    },
    allOptionCategory: true,
    allOptionSubCategory: true,
    pdfData: '',
    datefilter: [],
    companyfilter: [],
    datefilterdata: '',
    companyfilterdata: { name: 'company', label: "All", value: '' },
    client: {
        category: [],
        subcategory: [],
        discounttype: 'flat',
        invoicedate: moment(new Date()).format("YYYY-MM") + "-25",
        duedate: moment(nextmonth).format("YYYY-MM") + "-05"
    },
    options: {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    download: true // <== line to add
                }
            },
            events: {
                mouseLeave: function (event, chartContext, config) {
                    chartContext.resetSeries()
                }
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            },
        },
        labels: ['Total', 'Amount paid', 'Outstanding', 'Balance Overpaid'],
    },
    invoicedata: {}
    // series: [10000, 8000, -2000]

}
class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }


    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        this.setState({ pdfList: [event.data] })
        if (value === 'View') {
            this.setState({ modalType: "View", id: event.data._id, editSpi: true, invoicedata: event.data });
        }

        // if (value === 'Status') {
        //     Swal.fire({
        //         title: 'Are you sure to change the status?',
        //         showDenyButton: false,
        //         showCancelButton: true,
        //         confirmButtonText: 'Ok',
        //         imageUrl: '../../assets/images/status.png',
        //         customClass: {
        //             popup: 'swal_pop',
        //             title: 'swal_title',
        //             image: 'swal_image',
        //             actions: 'swal_action',
        //             confirmButton: 'swal_confirm',
        //             cancelButton: 'swal_close',
        //         }
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'invoices' })
        //                 .then((data) => {
        //                     // this.props.listinvioce();
        //                     const dataSource = this.getDataSource()
        //                     this.gridApi.setDatasource(dataSource);
        //                     Success('Status updated successfully')
        //                     this.setState({ datefilterdata: '', })

        //                 }).catch(({ response }) => { if (response) Error(response.statusText) });
        //         }
        //     })
        // }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['company.name', 'company.address', 'invoicenum', 'amount', 'duedate', 'invoicedate', 'discount', 'categoryName', 'subcategoryName'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = async () => {
        const { listinvoiceData } = this.state;
        const exportData = listinvoiceData;

        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Invoice";
        const headers = [['#', 'Invoice #', 'Company Name', 'Client URL',
            'Invoice date',
            'Due date',
            'Total amount',
            'Amount paid',
            'Category',
            'Subcategory',
        ]];
        const input1 = document.getElementById('printreport');

        var img = new Image();
        img.src = '../../../assets/images/logo.png';

        doc.addImage(img, 'png', 720, 5, 150, 100);
        doc.setTextColor(28, 48, 120);
        doc.text(this.state.client.name + " Report", marginLeft, 40);

        await html2canvas(input1)
            .then(((canvas, i) => {
                const imgData = canvas.toDataURL('image/png');
                const imgProps = doc.getImageProperties(imgData);
                const pdfWidth = doc.internal.pageSize.getWidth();
                const height = doc.internal.pageSize.getHeight();
                const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width);
                var position = 0;
                while (pdfHeight >= -position) {
                    // if (i != 0)
                    //     doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, 150, pdfWidth, pdfHeight);
                    position -= height;
                    // if (pdfHeight >= -position) {
                    doc.addPage();
                    // }
                }
            }))

        const data = exportData.map((elt, i) => {
            let discountAmount = 0;
            if (elt.amount)
                if (elt.discounttype && elt.discounttype === 'percentage')
                    discountAmount = elt.amount - ((elt.discount / 100) * elt.amount);
                else
                    discountAmount = elt.amount - elt.discount;

            if (elt.previousbalance)
                discountAmount += elt.previousbalance

            return [
                i + 1,
                elt.invoicenum,
                elt.company?.name,
                // elt.address + " " + elt.city + " " + elt.state + " " + elt.code,
                elt.url,
                moment.utc(elt.invoicedate).format('MM-DD-YYYY'),
                moment.utc(elt.duedate).format('MM-DD-YYYY'),
                discountAmount,
                elt.amountpaid,
                elt.categoryName,
                elt.subcategoryName,
            ]
        });

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Invioce.pdf")
    }

    componentDidMount() {
        // this.props.listinvoicesubcategory();
        // this.props.listinvoicecategory();
        this.setState({ chartspinner: true });

        axios.post(ImportedURL.API.clientsHistory + '/' + this.props.match.params.id,
            {
                // ...query,
                // perPage: this.state.perPage,
                // page: page, filter: filter,
                // sort: sortModel.length ? sortModel[0] : {},
                // invoicecode: invoiceCode
            }
        )
            .then((res) => {
                if (res.data && res.data.count) {
                    let totalAmountAfterDiscount = res.data.count.totalAmountAfterDiscount ? res.data.count.totalAmountAfterDiscount : 0
                    let totalAmountPaid = res.data.count.totalAmountPaid ? res.data.count.totalAmountPaid : 0
                    let diff = totalAmountAfterDiscount - totalAmountPaid
                    this.setState({
                        series: [
                            totalAmountAfterDiscount,
                            totalAmountPaid,
                            diff >= 0 ? diff : 0,
                            diff < 0 ? diff * -1 : 0,
                        ],
                    });

                }
                if (res.data && res.data.client) {
                    this.setState({
                        client: res.data.client
                    });
                }
                this.setState({ chartspinner: false });
            }).catch(({ response }) => {
                this.setState({ chartspinner: false });
            });
    }

    getCountdata = (query = {}) => {
        axios.post(ImportedURL.API.clientsHistory + '/' + this.props.match.params.id,
            {
                fromDate: this.state.fromDate,
                toDate: this.state.toDate,
                ...query,
            }
        )
            .then((res) => {
                if (res.data && res.data.count) {
                    let totalAmountAfterDiscount = res.data.count.totalAmountAfterDiscount ? res.data.count.totalAmountAfterDiscount : 0
                    let totalAmountPaid = res.data.count.totalAmountPaid ? res.data.count.totalAmountPaid : 0
                    let diff = totalAmountAfterDiscount - totalAmountPaid
                    this.setState({
                        series: [
                            totalAmountAfterDiscount,
                            totalAmountPaid,
                            diff >= 0 ? diff : 0,
                            diff < 0 ? diff : 0,
                        ],
                    });

                }
                // if (res.data && res.data.client) {
                //     this.setState({
                //         client: res.data.client
                //     });
                // }
                this.setState({ chartspinner: false });
            }).catch(({ response }) => {
                this.setState({ chartspinner: false });
            });
    }
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {
                this.setState({ spinner: true })

                const page = params.endRow / this.state.perPage;
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                var invoiceCode = localStorage.getItem("invoicetoken") ? localStorage.getItem("invoicetoken") : '';
                await axios.post(ImportedURL.API.listInvoice,
                    {
                        perPage: this.state.perPage,
                        page: page, filter: filter,
                        sort: sortModel.length ? sortModel[0] : {},
                        invoicecode: invoiceCode,
                        tocompany: [this.props.match.params.id],
                        fromDate: this.state.fromDate,
                        toDate: this.state.toDate,
                        ...query,

                    }
                )
                    .then((res) => {
                        const rowData = res.data.data;
                        this.setState({ listinvoiceData: rowData, spinner: false });
                        const total = res.data.total;
                        const previleges = this.props.AccountState.previleges;
                        const Previlege = previleges.find(obj => { return obj.name == "Invoice" });
                        const editOption = Previlege.edit ? '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View" data-toggle="tooltip" title="Edit"></i></button>' : '';
                        // const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';

                        const template =
                            editOption
                            // + deleteOption
                            + '</div>'

                        rowData.forEach(object => {
                            object.actions = template;
                        });
                        this.setState({ spinner: false });
                        params.successCallback(rowData, total);
                    }).catch(({ response }) => {
                        this.setState({ spinner: false });
                        params.successCallback([], 0);
                        if (response.status == 405) {
                            localStorage.setItem('invoicetoken', "");
                            window.location.href = "/invoicepwdClient";
                        }
                    });


            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                this.setState({ spinner: true })
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };


    ResetFilters = () => {
        this.gridApi.setFilterModel(null);
        this.setState({ resetshow: false, datefilterdata: '', companyfilterdata: { name: 'company', label: "All", value: '' } })
        const dataSource = this.getDataSource({})
        this.gridApi.setDatasource(dataSource);
    }

    datechange = (e) => {
        const dataSource = this.getDataSource({ [e.target.name]: e.target.value })
        this.gridApi.setDatasource(dataSource);
        this.getCountdata({ [e.target.name]: e.target.value })
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {

        const spinner = this.state.spinner;

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]


        let invoicedata = this.state.invoicedata;

        let discountAmount = 0;
        if (invoicedata.amount)
            if (invoicedata.discounttype && invoicedata.discounttype === 'percentage')
                discountAmount = invoicedata.amount - ((invoicedata.discount / 100) * invoicedata.amount);
            else
                discountAmount = invoicedata.amount - invoicedata.discount;

        // if (invoicedata.previousbalance)
        //     discountAmount += invoicedata.previousbalance

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6>Clients History</h6></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-history mr-2"></i></div>
                                            <h3 className="card-title">Client History</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                    <div className="header-action">
                                                        <Link to='/clients-list'><button type="button" className="btn btn-primary" id='Add'><i className="fe fe-arrow-left mr-2" id='Add' />Back</button></Link>
                                                    </div>
                                                </div>
                                                {this.state.listinvoiceData && this.state.listinvoiceData.length ?
                                                    <>
                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                            <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                        </div>
                                                    </> : ''}

                                            </div>
                                        </div>
                                        <div className="row" id="printreport">
                                            <div className="col-6">
                                                <div className="card-body">
                                                    <ReactApexChart options={this.state.options} series={this.state.series} type="pie" />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="card-body">
                                                    <div className='userView'>
                                                        <div className='row'>
                                                            {/* <div className='col-lg-5'>
                                                                <div className='User_part_left '>
                                                                    <div className='ribbon'>
                                                                    </div>
                                                                    <div className='user_hotel_logo'>
                                                                        <img src={ImportedURL.LIVEURL + this.state.client.image} alt="fake_url" />
                                                                    </div>
                                                                    <div className='User_part_right'>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className='col-lg-10 px-4'>
                                                                <div className='User_part_right'>
                                                                    {this.state.client.name ? <>
                                                                        <label >Client Name</label> <br />
                                                                        <span className='mb-4'>{this.state.client.name}</span></>
                                                                        : ''}
                                                                    {this.state.client.clientaccountnumber ?
                                                                        <p className='mt-4'><label>Account number </label><br /><span className='ml-1'>{this.state.client.clientaccountnumber}</span></p>
                                                                        : ''}
                                                                    {this.state.client.address ?
                                                                        <p className='mt-4'>
                                                                            <label>Address</label>  <br />
                                                                            <span>{this.state.client.address}, {this.state.client.city}, {this.state.client.state}, {this.state.client.code}</span>
                                                                        </p>
                                                                        : ''}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <label className="form-label">Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='fromDate' onChange={this.datechange} placeholder="Module" value={this.state.fromDate} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <label className="form-label">To Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='toDate' onChange={this.datechange} placeholder="Module" value={this.state.toDate} />
                                                    </div>
                                                </div>
                                                {this.state.resetshow &&
                                                    <button type="button" className="btn btn-primary change_btn_invoice mx-3 mt-4" onClick={() => this.ResetFilters()}>Reset</button>
                                                }

                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={this.state.perPage}
                                                        cacheBlockSize={this.state.perPage}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        onGridReady={this.onGridReady}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        wrapText={true}
                                                        animateRows={true}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        rowModelType={'infinite'}>
                                                    </AgGridReact>
                                                </div>
                                                {spinner || this.state.chartspinner ?
                                                    <div className='common_loader_ag_grid' style={{ position: 'fixed', left: '55%' }}>
                                                        <img className='loader_img_style_common_ag_grid' src='../../../assets/images/banner-logo.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* ==============detail============== */}

                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ zIndex: 1030 }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>View Invoice</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Invoice Raised By </label>
                                            <p>{invoicedata.company?.name}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Amount </label>
                                            {invoicedata.discount ? <p><strike className='pr-2 pl-2'>{invoicedata.amount}</strike>{discountAmount}</p> : <p>{invoicedata.amount}</p>}
                                        </div>
                                    </div>
                                    {invoicedata.discount ?
                                        <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Discount</label>
                                                <p>{invoicedata.discount} {invoicedata.discounttype === 'percentage' ? '%' : '/-'}</p>
                                            </div>
                                        </div>
                                        : ''}

                                    {invoicedata.previousbalance ?
                                        <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Previous balance </label>
                                                <p>{invoicedata.previousbalance}</p>
                                            </div>
                                        </div>
                                        : ''}

                                    {invoicedata.amountpaid ?
                                        <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Amount paid</label>
                                                <p>{invoicedata.amountpaid}</p>
                                            </div>
                                        </div>
                                        : ''}

                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Invoice date </label>
                                            <p>{moment.utc(invoicedata.invoicedate).format('MM-DD-YYYY')}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Due date </label>
                                            <p>{moment.utc(invoicedata.duedate).format('MM-DD-YYYY')}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className="form-group">
                                            <label className="form-label">Category </label>
                                            <p> {invoicedata.category && invoicedata.category.map((item, i, row) => {
                                                return (
                                                    <>
                                                        {' '}{item.name}{i + 1 === row.length ? '' : ','}
                                                    </>
                                                )
                                            })}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className="form-group">
                                            <label className="form-label">Sub Category </label>
                                            <p> {invoicedata.subcategory && invoicedata.subcategory.map((item, i, row) => {
                                                return (
                                                    <>
                                                        {' '}{item.subcategory}{i + 1 === row.length ? '' : ','}
                                                    </>
                                                )
                                            })}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>


            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    invoiceState: state.invoice,
    invoicedetailState: state.invoicedetail,
    invoicesubcategoryState: state.invoicesubcategory,
    invoicecategoryState: state.invoicecategory,
    AccountState: state.account,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            handleChange: AC_HANDLE_INVOICE_CHANGE,
            listinvioce: AC_LIST_INVOICE,
            viewinvioce: AC_VIEW_INVOICE,
            resetinvioce: AC_RESET_INVOICE,
            listinvoicedetail: AC_LIST_INVOICE_DETAIL,
            listinvoicecategory: AC_LIST_INVOICE_CATEGORY,
            listinvoicesubcategory: AC_LIST_INVOICE_SUB_CATEGORY,
            emptyinvoice: AC_EMPTY_INVOICE,
            AC_SPINNER_INVOICE_ALL
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);


