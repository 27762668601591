import axios from 'axios';
import ImportedURL from '../common/api';
import { Redirect } from 'react-router-dom';

export function AC_HANDLE_INVOICE_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_INVOICE_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_INVOICE() {
    return function (dispatch) {
        dispatch({ type: "RESET_INVOICE" })
    };
}


export function AC_LIST_INVOICE(params = {}) {
    return async function (dispatch) {
        return (
            await axios.post(ImportedURL.API.listInvoice, params)
                .then((res) => {
                    dispatch({ type: "LIST_INVOICE", payload: res.data.data, total: res.data.total, spinner: false })
                }).catch(({ response }) => {
                    if (response.status == 405) {
                        localStorage.setItem('invoicetoken', "");
                        window.location.href = "/invoicepwd";
                    } else {
                        console.log(response);
                    }
                })
        )
    };
}

export function AC_SET_LIST_INVOICE(params) {
    return async function (dispatch) {
        dispatch({ type: "LIST_INVOICE", payload: params.data, total: params.total, spinner: false })

    };
}

export function AC_VIEW_INVOICE(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewInvoice + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_INVOICE", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_SPINNER_INVOICE_ALL() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_INVOICE_ALL" })
    }
}
export function AC_EMPTY_INVOICE() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_INVOICE" })
    }
}