const initialState = {
    listProject: [],
    project: {
        name: '',
        date: '',
        leader: '',
        team: [],
        description: '',
        images: [],
    },
    modules: [],
    submodules: [],
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_PROJECT_CHANGE':
            return Object.assign({}, state, {
                project: {
                    ...state.project,
                    [action.name]: action.value
                }
            })
        case 'RESET_PROJECT':
            return Object.assign({}, state, {
                project: initialState.project,
            })
        case 'LIST_PROJECT':
            return {
                ...state,
                listProject: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_PROJECT':
            return Object.assign({}, state, {
                project: action.payload,
                modules: action.payload.modules ? action.payload.modules : [],
                submodules: action.payload.submodules ? action.payload.submodules : [],
                spinner: false,
            })

        case 'AC_EMPTY_PROJECT':
            return Object.assign({}, state, {
                project: {
                    grade: '',
                    level: '',
                    department: '',
                    designation: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}