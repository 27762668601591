import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';

export function AC_RESET_HOLIDAY() {
	return function (dispatch) {
		dispatch({ type: "RESET_HOLIDAY" })
	};
}

export function AC_LIST_HOLIDAY() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listHoliday)
            .then(({ data }) => {
                dispatch({ type: "LIST_HOLIDAY", payload: data });
            })
    }
}
export function AC_VIEW_HOLIDAY(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewHoliday + "/" + id)
            .then(({ data }) => { 
                dispatch({ type: "VIEW_HOLIDAY", payload: data });
            });
    }
}
export function AC_HANDLE_HOLIDAY_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_HOLIDAY_CHANGE", name: name, value: value })
    };
}