const initialState = {
    listinvoicesubcategory: [],
    invoicesubcategory: {
        category: '',
        subcategory: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INVOICE_SUB_CATEGORY_CHANGE':
            return Object.assign({}, state, {
                invoicesubcategory: {
                    ...state.invoicesubcategory,
                    [action.name]: action.value
                }
            })
        case 'RESET_INVOICE_SUB_CATEGORY':
            return Object.assign({}, state, {
                invoicesubcategory: initialState.invoicesubcategory,
            })
        case 'LIST_INVOICE_SUB_CATEGORY':
            return {
                ...state,
                listinvoicesubcategory: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_INVOICE_SUB_CATEGORY':
            return Object.assign({}, state, {
                invoicesubcategory: action.payload,
                spinner: false,
            })

        case 'EMPTY_INVOICE_SUB_CATEGORY':
            return Object.assign({}, state, {
                invoicesubcategory: {
                    category: '',
                    subcategory: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}