import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';
import { Link, Redirect } from 'react-router-dom';



export function AC_LIST_DEDUCTION() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listDeduction)
            .then(({ data }) => {
                dispatch({ type: "LIST_DEDUCTION", payload: data });
            })
    }
}

export function AC_VIEW_DEDUCTION(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewDeduction + "/" + id)
            .then(({ data }) => { 
                dispatch({ type: "VIEW_DEDUCTION", payload: data });
            });
    }
}

export function AC_RESET_DEDUCTION() {
	return function (dispatch) {
		dispatch({ type: "RESET_DEDUCTION" })
	};
}

export function AC_HANDLE_DEDUCTION_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_DEDUCTION_CHANGE", name: name, value: value })
    };
}



