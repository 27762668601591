import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import moment from 'moment';
import { Currency, Emailvalidate, capitalizeFirstLetter } from '../../../common/validate';
import { AC_HANDLE_INVOICE_CHANGE, AC_LIST_INVOICE, AC_VIEW_INVOICE, AC_RESET_INVOICE, AC_EMPTY_INVOICE, AC_SPINNER_INVOICE_ALL } from '../../../actions/invoiceAction';
import { Urlvalidate, NumberOnly } from '../../../common/validate';
import { AC_LIST_INVOICE_DETAIL } from '../../../actions/invoicedetail';
import { AC_LIST_INVOICE_CATEGORY } from '../../../actions/invoicecategoryAction';
import { AC_LIST_INVOICE_SUB_CATEGORY } from '../../../actions/invoicesubcategoryAction';
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_TAX } from '../../../actions/countryAction';

let date = new Date();
let nextmonth = date.setMonth(date.getMonth() + 1);
const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    image: '',
    email: '',
    eye: false,
    changepwdshow: false,
    changepwdinvoice: false,
    password: '',
    newpassword: '',
    confirmpassword: '',
    perPage: 25,
    tocompanyValue: '',
    passwordError: false,
    newpasswordError: false,
    confirmpasswordError: false,
    nameError: false,
    categoryError: false,
    subcategoryError: false,
    addressError: false,
    toaddressError: false,
    amountError: false,
    invoicedateError: false,
    duedateError: false,
    urlError: false,
    discountError: false,
    companyError: false,
    tocompanyError: false,
    categoryError: false,
    subcategoryError: false,
    invalidurlError: false,
    communicationemailValidError: false,
    invoicepasswordError: false,
    companyData: [],
    companydropdown: [],
    listinvoiceData: [],
    companyDrop: false,
    editSpi: false,
    allOption: true,
    pdfList: [],
    columnDefs: [
        {
            headerName: 'Image', field: 'tocompanyimage', width: 100, filter: false, cellStyle: { textAlign: 'center' },
            cellRendererFramework: function (params) {
                if (params.data) {
                    return <div>
                        <img style={{ borderRadius: '0px' }} width='65px' height='65px' src={ImportedURL.LIVEURL + params.data.image} alt="" />
                    </div>
                }
            }
        },
        { headerName: 'Account number', field: 'clientaccountnumber', width: 130, floatingFilter: true, },
        { headerName: 'Name', field: 'name', width: 130, floatingFilter: true, },
        {
            headerName: 'Address', field: 'address', width: 130, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data)
                    return params.data.address + " " + params.data.city + " " + params.data.state + " " + params.data.code;
                else return ''
            }
        },
        {
            headerName: 'Invoice Amount', field: 'amount', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Client URL', field: 'url', width: 130, floatingFilter: true, hide: true,
        },
        {
            headerName: 'Discount', field: 'discount', width: 130, floatingFilter: true, hide: true,
        },
        {
            headerName: 'Category', field: 'categoryName', width: 130, floatingFilter: true, hide: true,
        },
        {
            headerName: 'Sub Category', field: 'subcategoryName', width: 130, floatingFilter: true, hide: true,
        },
        {
            headerName: 'Status', width: 150, field: 'status', filter: false, sortable: false, cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data)
                    if (params.data.status) {
                        return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                    } else {
                        return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                    }
                else return ''
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', filter: false, sortable: false, cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
    allOptionCategory: true,
    allOptionSubCategory: true,
    pdfData: '',
    datefilter: [],
    companyfilter: [],
    datefilterdata: '',
    companyfilterdata: { name: 'company', label: "All", value: '' },
    client: {
        category: [],
        subcategory: [],
        discounttype: 'flat',
        invoicedate: moment(new Date()).format("YYYY-MM") + "-25",
        duedate: moment(nextmonth).format("YYYY-MM") + "-05"
    },

}
class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ taxamountlocal: '', taxtypelocal: '', taxamounttypelocal: "" })
        this.setState({
            modalType: e.target.id, editSpi: false, client: {
                category: [],
                subcategory: [],
                discounttype: 'flat',

                invoicedate: moment(new Date()).format("YYYY-MM") + "-25",
                duedate: moment(nextmonth).format("YYYY-MM") + "-05"
            }
        });
        this.setState({ nameError: false, cityError: false, stateError: false, codeError: false })
        this.setState({ categoryError: false })
        this.setState({ subcategoryError: false })
        this.setState({ addressError: false })
        this.setState({ amountError: false })
        this.setState({ urlError: false })
        this.setState({ discountError: false })
        this.setState({ companyError: false })
        this.setState({ categoryError: false })
        this.setState({ subcategoryError: false })
        this.setState({ invalidurlError: false })
        this.setState({ imageError: false })
        this.setState({ image: '' })
        document.getElementById('imageSet').value = '';
        this.setState({ newpassword: '' });
        this.setState({ confirmpassword: '' })
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ taxamountlocal: '', taxtypelocal: '', taxamounttypelocal: "" })
            this.setState({ modalType: "Edit", id: event.data._id, client: event.data, editSpi: true });
            this.setState({ nameError: false, cityError: false, stateError: false, codeError: false })
            this.setState({ categoryError: false })
            this.setState({ subcategoryError: false })
            this.setState({ addressError: false })
            this.setState({ amountError: false })
            this.setState({ urlError: false })
            this.setState({ discountError: false })
            this.setState({ companyError: false })
            this.setState({ categoryError: false })
            this.setState({ subcategoryError: false })
            this.setState({ invalidurlError: false })
            this.setState({ imageError: false })
            this.setState({ image: '' })
            document.getElementById('imageSet').value = '';
            this.setState({ newpasswordError: false })
            this.setState({ confirmpasswordError: false })
        }

        if (value === 'History') {
            this.props.history.push('/clients-history/' + event.data._id)
        }
        if (value === 'Transaction') {
            this.props.history.push('/transactions/' + event.data._id)
        }

        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteInvoice + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'clients' })
                        .then((data) => {
                            // this.props.listinvioce();
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'address', 'email', 'url'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { listinvoiceData } = this.state;
        const exportData = listinvoiceData;

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Invoice";
        const headers = [['#', 'Company Name', 'Address', 'Invoice Amount', 'Client URL', 'Discount',]];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.name,
            elt.address + " " + elt.city + " " + elt.state + " " + elt.code,
            elt.amount,
            elt.url,
            elt.discount,

        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Invioce.pdf")
    }

    handleMultiSelectChange = (e, name) => {
        const data = this.state.client;
        const listinvoicesubcategory = this.props.invoicesubcategoryState.listinvoicesubcategory;

        this.setState({ spinner: false })
        if (name == 'category') {
            const listinvoicecategory = this.props.invoicecategoryState.listinvoicecategory;
            let findAll = e.find((item) => item.value == "")
            let pushedArr = []
            if (findAll) {
                this.setState({ allOptionCategory: false })
                let category = (data.category && data.category.length > 0) ? [...data.category] : []
                let allData = []
                listinvoicecategory.map((e) => {
                    allData.push(e._id)
                })
                let conact = allData.concat(category)
                pushedArr = [...new Set(conact)]
            } else {
                this.setState({ allOptionCategory: true })
                if (e && e.length > 0) {
                    e.map((item) => {
                        const { value } = item;
                        pushedArr.push(value)
                    })
                }
            }
            if (pushedArr) {
                this.setState({ categoryError: false })
            }
            else {
                this.setState({ categoryError: true })
            }
            this.setState({ client: { ...this.state.client, category: pushedArr, "subcategory": [] } })
        } else {
            const listinvoicesubcategory = this.props.invoicesubcategoryState.listinvoicesubcategory;
            let findAll = e.find((item) => item.value == "")
            let pushedArr = []
            if (findAll) {
                this.setState({ allOptionSubCategory: false })
                let subcategory = (data.subcategory && data.subcategory.length > 0) ? [...data.subcategory] : []
                let allData = []
                listinvoicesubcategory.map((e) => {
                    let status = (data.category && data.category.length > 0) ? data.category.find((i) => i == e.category._id) : undefined
                    if (status) allData.push(e._id)
                })
                let conact = allData.concat(subcategory)
                pushedArr = [...new Set(conact)]
            } else {
                this.setState({ allOptionSubCategory: true })
                if (e && e.length > 0) {
                    e.map((item) => {
                        const { value } = item;
                        pushedArr.push(value)
                    })
                }
            }
            if (pushedArr) {
                this.setState({ subcategoryError: false })
            }
            else {
                this.setState({ subcategoryError: true })
            }
            this.setState({ client: { ...this.state.client, subcategory: pushedArr } })

        }
    }
    handleOnchange = (e) => {
        this.setState({ spinner: false })
        this.setState({ editSpi: false })
        const { name, value } = e.target;
        const Error = name + "Error";
        if (value) {
            this.setState({ [Error]: false });
        } else {
            this.setState({ [Error]: true })
        }
        if (name == 'amount') {
            if (!isNaN(value)) {
                this.setState({ client: { ...this.state.client, [name]: value } });

                this.setState({ numbermustError: false })
            }
        }
        else if (name == 'discount') {
            if (!isNaN(value)) {
                this.setState({ client: { ...this.state.client, [name]: value } });

                this.setState({ discountnumberError: false })
            }
        }
        else if (name == 'url') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ invalidurlError: true })
                } else {
                    this.setState({ invalidurlError: false })
                }
            } else {
                this.setState({ invalidurlError: false })
            }
            this.setState({ client: { ...this.state.client, [name]: value } });
        } else {
            this.setState({ client: { ...this.state.client, [name]: value } });

        }
    }

    onChangeSelect = e => {
        if (e.name == 'country') {
            this.setState({ taxamountlocal: '', taxtypelocal: '', taxamounttypelocal: "" })
            this.setState({ client: { ...this.state.client, taxcountry: e.value, taxdata: [] } });
        } else if (e.name == 'country1') {
            this.setState({ client: { ...this.state.client, country: e.value } });
        } else {
            this.setState({ taxtypelocal: e.value });
        }
    }
    addTaxData = () => {
        if (this.state.taxamountlocal && this.state.taxtypelocal) {
            const data = this.state.client;
            let taxdata = data.taxdata && data.taxdata ? [...data.taxdata] : [];
            if (taxdata.some(item => item.taxtype === this.state.taxtypelocal)) {
                Error(`Tax already exists.`);
            } else {
                taxdata.push({ taxamount: this.state.taxamountlocal, taxtype: this.state.taxtypelocal, taxamounttype: this.state.taxamounttypelocal ? this.state.taxamounttypelocal : 'percentage' });
                this.setState({ taxamountlocal: '', taxtypelocal: '', taxamounttypelocal: "" })
                this.setState({ client: { ...this.state.client, taxdata: taxdata } });
            }
        } else {
            Error('Enter Tax and Tax type to add');
        }
    }

    removeTax = (indexToRemove) => {
        const data = this.state.client;
        let taxdata = [...data.taxdata]
        taxdata.splice(indexToRemove, 1);
        this.setState({ client: { ...this.state.client, taxdata: taxdata } });
    }

    handleDragStartTax = (e, index) => {
        const data = this.state.client;
        let taxdata = [...data.taxdata]
        this.setState({
            draggedItem: taxdata[index],
            dragtype: 'tax'
        });
        e.dataTransfer.effectAllowed = 'move';
    };

    handleDragOverTax = (e, index) => {
        e.preventDefault();
        if (this.state.dragtype !== 'tax') return;
        const data = this.state.client;
        let taxdata = [...data.taxdata]
        const draggedOverItem = taxdata[index];

        // If the dragged item is the same as the dragged over item, return
        if (this.state.draggedItem === draggedOverItem) {
            return;
        }

        // Remove the dragged item from the list
        const items = taxdata.filter(item => item !== this.state.draggedItem);

        // Insert the dragged item at the new position
        items.splice(index, 0, this.state.draggedItem);

        this.setState({ client: { ...this.state.client, taxdata: items } });

    };

    handleDragEnd = () => {
        this.setState({
            draggedItem: null,
            dragtype: ''
        });
    }

    addCustomCategory = () => {
        if (this.state.customcategory && this.state.customcategory.trim()) {
            const data = this.state.client;
            let category = data.customcategory && data.customcategory ? [...data.customcategory] : [];
            if (category.includes(this.state.customcategory.trim())) {
                Error(`Category already exists.`);
            } else {
                category.push(this.state.customcategory.trim());
                this.setState({ customcategory: '', categoryError: false })
                this.setState({ client: { ...this.state.client, customcategory: category } });
            }
        } else {
            Error('Enter category to add');
        }
    }

    removeCutomCategory = (indexToRemove) => {
        const data = this.state.client;
        let category = [...data.customcategory];
        let customsubcategory = [...data.customsubcategory]
        let categoryname = category[indexToRemove]
        let newArray = customsubcategory.filter(obj => obj.customcategorysub !== categoryname);
        category.splice(indexToRemove, 1);
        this.setState({ client: { ...this.state.client, customcategory: category, customsubcategory: newArray } });
    }

    addCustomSubCategory = () => {
        if (this.state.customsubcategory && this.state.customcategorysub) {
            const data = this.state.client;
            let category = data.customsubcategory && data.customsubcategory ? [...data.customsubcategory] : [];
            if (category.some(item => item.customsubcategory === this.state.customsubcategory)) {
                Error(`Subcategory already exists.`);
            } else {

                category.push({ customcategorysub: this.state.customcategorysub, customsubcategory: this.state.customsubcategory });
                this.setState({ customsubcategory: '', customcategorysub: '', subcategoryError: false })
                this.setState({ client: { ...this.state.client, customsubcategory: category } });
            }
        } else {
            Error('Enter category and subcategory to add');
        }
    }

    removeCutomSubCategory = (indexToRemove) => {
        const data = this.state.client;
        let category = [...data.customsubcategory]
        category.splice(indexToRemove, 1);
        this.setState({ client: { ...this.state.client, customsubcategory: category } });
    }

    handleDragStart = (e, index) => {
        const data = this.state.client;
        let category = [...data.customcategory]
        this.setState({
            draggedItem: category[index],
            dragtype: 'category'
        });
        e.dataTransfer.effectAllowed = 'move';
    };

    handleDragOver = (e, index) => {
        e.preventDefault();
        if (this.state.dragtype !== 'category') return;
        const data = this.state.client;
        let category = [...data.customcategory]
        const draggedOverItem = category[index];

        // If the dragged item is the same as the dragged over item, return
        if (this.state.draggedItem === draggedOverItem) {
            return;
        }

        // Remove the dragged item from the list
        const items = category.filter(item => item !== this.state.draggedItem);

        // Insert the dragged item at the new position
        items.splice(index, 0, this.state.draggedItem);

        this.setState({ client: { ...this.state.client, customcategory: items } });

    };

    handleDragStartSubcategory = (e, index) => {
        const data = this.state.client;
        let category = [...data.customsubcategory]
        this.setState({
            draggedItem: category[index],
            dragtype: 'subcategory'
        });
        e.dataTransfer.effectAllowed = 'move';
    };

    handleDragOverSubcategory = (e, index) => {
        e.preventDefault();
        if (this.state.dragtype !== 'subcategory') return;
        const data = this.state.client;
        let category = [...data.customsubcategory]
        const draggedOverItem = category[index];

        // If the dragged item is the same as the dragged over item, return
        if (this.state.draggedItem === draggedOverItem) {
            return;
        }

        // Remove the dragged item from the list
        const items = category.filter(item => item !== this.state.draggedItem);

        // Insert the dragged item at the new position
        items.splice(index, 0, this.state.draggedItem);

        this.setState({ client: { ...this.state.client, customsubcategory: items } });
    };
    handleKeyDown = e => {
        this.setState({ spinner: false })
        const { value } = e.target;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = this.state.client;
        if (e.key === 'Enter' && this.state.communicationemail !== '' && !this.state.communicationemailValidError) {
            let newData = data.communicationemail ? [...data.communicationemail] : []
            if (newData.includes(value)) {
                Error((languageData && languageData.ALREADY_ADDED ? languageData.ALREADY_ADDED : " Email Already Added"))
            } else {
                newData.push(value);
                this.setState({ communicationemail: '', client: { ...this.state.client, communicationemail: newData } });
            }

        }
    }

    removeEmail = (i) => {
        this.setState({ spinner: false })
        const data = this.state.client.communicationemail;
        data.splice(i, 1);
        this.setState({ communicationemail: '', client: { ...this.state.client, communicationemail: data } });
    }
    onChange = e => {
        const { name, value } = e.target;

        if (name === 'communicationemail') {
            if (value) {
                if (Emailvalidate(value)) {
                    this.setState({ communicationemailValidError: false })
                } else {
                    this.setState({ communicationemailValidError: true })
                }
                this.setState({ communicationemail: value });
            } else {
                this.setState({ communicationemailValidError: false })
                this.setState({ communicationemail: value });
            }
        }
    }

    uploadhandleChange = (event) => {
        this.setState({ spinner: false })
        const { name, value } = event.target;
        if (name === 'image') {
            this.setState({ image: event.target.files[0], imageError: false, client: { ...this.state.client, image: event.target.files[0] } })
        }
    }
    componentDidMount() {
        this.props.listinvoicesubcategory();
        this.props.listinvoicecategory();
        this.props.AC_LIST_TAX();
    }

    submit = () => {
        // const { invoiceState } = this.props;
        // const rowData = invoiceState.listinvoice;
        const data = this.state.client;
        let valid = 1
        if (!data.address) {
            this.setState({ addressError: true });
            valid = 0
        }
        if (!data.image) {
            this.setState({ imageError: true });
            valid = 0
        }
        if (!data.amount) {
            this.setState({ amountError: true });
            valid = 0
        }
        // if (!data.url) {
        //     this.setState({ urlError: true });
        // }
        if (!data.discount) {
            this.setState({ discountError: true });
            valid = 0
        }
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0
        }
        if (!data.city) {
            this.setState({ cityError: true });
            valid = 0
        }
        if (!data.state) {
            this.setState({ stateError: true });
            valid = 0
        }
        if (!data.code) {
            this.setState({ codeError: true });
            valid = 0
        }
        if (data.usecustomcategory) {
            if (data.customcategory && data.customcategory.length) { }
            else {
                this.setState({ categoryError: true });
                valid = 0
            }
            if (data.customsubcategory && data.customsubcategory.length) { }
            else {
                this.setState({ subcategoryError: true });
                valid = 0
            }
        } else {
            if (data.category.length == 0) {
                this.setState({ categoryError: true });
                valid = 0
            } if (data.subcategory.length == 0) {
                this.setState({ subcategoryError: true });
                valid = 0
            }
        }
        if (this.state.numbermustError || this.state.discountnumberError)
            valid = 0

        if (valid) {
            var formData = new FormData();
            formData.append("name", data.name);
            formData.append("address", data.address);
            formData.append("city", data.city);
            formData.append("state", data.state);
            formData.append("code", data.code);
            formData.append("image", data.image);
            formData.append("amount", data.amount);
            formData.append("url", data.url ? data.url : '');
            formData.append("invoicedate", data.invoicedate ? data.invoicedate : '');
            formData.append("duedate", data.duedate ? data.duedate : '');
            formData.append("discount", data.discount);
            formData.append("discounttype", data.discounttype);
            formData.append("category", JSON.stringify(data.category));
            formData.append("subcategory", JSON.stringify(data.subcategory));
            formData.append("taxapplied", data.taxapplied ? data.taxapplied : false);
            if (data.country) formData.append("country", data.country);
            if (data.usecustomcategory) formData.append("usecustomcategory", data.usecustomcategory);
            if (data.customcategory) formData.append("customcategory", JSON.stringify(data.customcategory));
            if (data.customsubcategory) formData.append("customsubcategory", JSON.stringify(data.customsubcategory));

            if (data.paymentmethod) formData.append("paymentmethod", data.paymentmethod);
            if (data.notes) formData.append("notes", data.notes);
            if (data.communicationemail) formData.append("communicationemail", JSON.stringify(data.communicationemail));
            if (data.taxapplied) {
                if (data.taxcountry) formData.append("taxcountry", data.taxcountry);
                if (data.taxdata) formData.append("taxdata", JSON.stringify(data.taxdata));
            }

            if (this.state.modalType === "Add") {
                this.setState({ save: true });
                axios.post(ImportedURL.API.addClient, formData)
                    .then((formdata) => {
                        Success(formdata.statusText ? formdata.statusText : 'Client added successfully');
                        this.setState({ save: false });
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        let btn = document.getElementById("closeModal");
                        btn.click();

                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateClient + '/' + this.state.id, formData)
                    .then((res) => {
                        Success(res.statusText ? res.statusText : 'Client updated successfully');
                        this.setState({ save: false });
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }

    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {
                this.setState({ spinner: true })

                const page = params.endRow / this.state.perPage;
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                var invoiceCode = localStorage.getItem("invoicetoken") ? localStorage.getItem("invoicetoken") : '';
                await axios.post(ImportedURL.API.listClient,
                    {
                        ...query,
                        perPage: this.state.perPage,
                        page: page, filter: filter,
                        sort: sortModel.length ? sortModel[0] : {},
                        invoicecode: invoiceCode
                    }
                )
                    .then((res) => {
                        const rowData = res.data.data;
                        this.setState({ listinvoiceData: rowData, spinner: false });
                        const total = res.data.total;
                        const previleges = this.props.AccountState.previleges;
                        const Previlege = previleges.find(obj => { return obj.name == "Invoice" });
                        const editOption = Previlege.edit ? '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>' : '';
                        // const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';

                        const template =
                            editOption
                            // + deleteOption
                            + '<button type="button" class="btn btn-icon" data-action-type="History"><i class="fa fa-history" style="color: #2D3377 !important;font-size:20px;" data-action-type="History" data-toggle="tooltip" title="Client history"></i></button>'
                            + '<button type="button" class="btn btn-icon" data-action-type="Transaction"><i class="fa fa-exchange" style="color: #2D3377 !important;font-size:17px;" data-action-type="Transaction" data-toggle="tooltip" title="Client transactions"></i></button>'
                            + '</div>'

                        rowData.forEach(object => {
                            object.actions = template;
                        });
                        this.setState({ spinner: false });
                        params.successCallback(rowData, total);
                    }).catch(({ response }) => {
                        this.setState({ spinner: false });
                        params.successCallback([], 0);
                        if (response.status == 405) {
                            localStorage.setItem('invoicetoken', "");
                            window.location.href = "/invoicepwdClient";
                        }
                    });


            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                this.setState({ spinner: true })
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };


    ResetFilters = () => {
        this.gridApi.setFilterModel(null);
        this.setState({ resetshow: false, })
        const dataSource = this.getDataSource({})
        this.gridApi.setDatasource(dataSource);
    }
    render() {

        const { countryReducer, invoicesubcategoryState, invoicecategoryState } = this.props;
        const spinner = this.state.spinner;
        const listinvoicesubcategory = invoicesubcategoryState.listinvoicesubcategory;
        const listinvoicecategory = invoicecategoryState.listinvoicecategory;
        const data = this.state.client;
        if (data !== undefined) {
            var imageData = data.image;
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        const subcategoryValue = [];
        const subcategoryOption = [];
        if (data.category && data.category.length > 0) {
            if (this.state.allOptionSubCategory) subcategoryOption.push(({ label: "All", value: '', name: 'subcategory' }))
        }
        if (listinvoicesubcategory && listinvoicesubcategory.length > 0) {
            listinvoicesubcategory.filter(e => e.status === true).map((item) => {
                if (listinvoicesubcategory.length > 0) {

                    let status = data.category.find(e => e == item.category._id);
                    if (status) {
                        subcategoryOption.push({ label: item.subcategory, value: item._id, name: 'subcategory' })
                    }
                }

                if (data.subcategory && data.subcategory.length > 0) {
                    let statusCat = data.subcategory.find(e => e == item._id);
                    if (statusCat) {
                        subcategoryValue.push(
                            { label: item.subcategory, value: item._id, name: 'subcategory' }
                        )
                    }
                }
            })
        }

        let selectedCategory = [];
        if (data.category && data.category.length > 0) {
            listinvoicecategory.map((item) => {
                let status = data.category.find(e => e == item._id)
                if (status) {
                    selectedCategory.push(
                        { label: item.name, value: item._id, name: item.name }
                    )
                }
            })
        }

        let listoptionCategory = []
        if (listinvoicecategory.length > 0) {
            if (this.state.allOptionCategory) listoptionCategory.push(({ label: "All", value: '', name: 'category' }))
            listinvoicecategory.filter(filterstatus => filterstatus.status === true).map((item) => {
                listoptionCategory.push(({ label: item.name, value: item._id, name: 'category' }))
            })
        }

        let selectedCountry = '';
        let selectedCountry1 = '';
        let selectedTax = '';
        const countryOptions = [];
        const countryOptions1 = [];
        const TaxOptions = [];
        const listCountry = countryReducer.listCountry.filter(filterItem => filterItem.status === true);
        const listTax = countryReducer.listTax.filter(filterItem => filterItem.status === true);
        listCountry.map(item => {
            if (item._id == data.taxcountry) selectedCountry = { label: item.countryName, value: item._id, name: 'country' }
            if (item._id == data.country) selectedCountry1 = { label: item.countryName, value: item._id, name: 'country1' }
            countryOptions.push({ label: item.countryName, value: item._id, name: 'country' });
            countryOptions1.push({ label: item.countryName, value: item._id, name: 'country1' });
        })
        listTax.map(item => {
            if (data.taxcountry && data.taxcountry == item.country) {
                if (item.name == this.state.taxtypelocal) selectedTax = { label: item.name, value: item.name, name: 'tax' }
                TaxOptions.push({ label: item.name, value: item.name, name: 'tax' });
            }
        })

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/clients-list"><h6>Clients List</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">List Client</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.resetshow &&
                                                    <button type="button" className="btn btn-primary change_btn_invoice mx-3 mt-4" onClick={() => this.ResetFilters()}>Reset</button>
                                                }

                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={this.state.perPage}
                                                        cacheBlockSize={this.state.perPage}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        onGridReady={this.onGridReady}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        wrapText={true}
                                                        animateRows={true}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        onFilterChanged={(e) => { if (e.api.isAnyFilterPresent()) this.setState({ resetshow: true }) }}
                                                        rowModelType={'infinite'}>
                                                    </AgGridReact>
                                                </div>
                                                {spinner ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../../assets/images/banner-logo.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="exampleModal" tabIndex={-1} style={{ zIndex: 1030 }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>{this.state.modalType} Client</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                        <img src='../../../assets/images/cancel.png' />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Name" name="name" onChange={this.handleOnchange} value={data.name ? data.name : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Address<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Name" name="address" onChange={this.handleOnchange} value={data.address ? data.address : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.addressError ? "block" : 'none' }}>Address is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">City<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="City" name="city" onChange={this.handleOnchange} value={data.city ? data.city : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.cityError ? "block" : 'none' }}>City is required</div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">State<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="State" name="state" onChange={this.handleOnchange} value={data.state ? data.state : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.stateError ? "block" : 'none' }}>State is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Postal Code<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Postal Code" name="code" onChange={this.handleOnchange} value={data.code ? data.code : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.codeError ? "block" : 'none' }}>Code is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Country</label>
                                                            {/* <label className="form-label float-right">  <i class="fe fe-chevron-up"></i> </label> */}
                                                            <Select
                                                                onChange={this.onChangeSelect}
                                                                value={selectedCountry1}
                                                                options={countryOptions1}
                                                                placeholder="Select country"
                                                            />


                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Client URL </label>
                                                            <input type="text" className="form-control" placeholder="URL" name="url" onChange={this.handleOnchange} value={data.url ? data.url : ''} />
                                                            {/* <div className="invalid-feedback" style={{ display: this.state.urlError ? "block" : 'none' }}>Url is required</div> */}
                                                            <div className="invalid-feedback" style={{ display: this.state.invalidurlError ? "block" : 'none' }}>Invalid Url</div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Invoice Amount <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Invoice Amount" name="amount" onChange={this.handleOnchange} value={data.amount ? data.amount : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.amountError ? "block" : 'none' }}>Invoice Amount is required</div>
                                                            <div className="invalid-feedback" style={{ display: this.state.numbermustError ? "block" : 'none' }}> Only accept  number </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-2'>
                                                        <div className="form-group">
                                                            <label className="form-label">Discount Type</label>
                                                            <select type="text" className="form-control" placeholder="Discount" name="discounttype" onChange={this.handleOnchange} value={data.discounttype ? data.discounttype : 'flat'} >
                                                                {/* <option value='none'>Select Discount type</option> */}
                                                                <option value='flat'>Flat</option>
                                                                <option value='percentage'>Percentage</option>
                                                            </select>
                                                            {/* <div className="invalid-feedback" style={{ display: this.state.discountError ? "block" : 'none' }}>Discount is required</div>
                                                            <div className="invalid-feedback" style={{ display: this.state.discountnumberError ? "block" : 'none' }}>Only accept number</div> */}

                                                        </div>
                                                    </div>

                                                    <div className='col-lg-4'>
                                                        <div className="form-group">
                                                            <label className="form-label">Discount <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Discount" name="discount" onChange={this.handleOnchange} value={data.discount ? data.discount : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.discountError ? "block" : 'none' }}>Discount is required</div>
                                                            <div className="invalid-feedback" style={{ display: this.state.discountnumberError ? "block" : 'none' }}>Only accept number</div>

                                                        </div>
                                                    </div>

                                                    <div className='col-lg-3'>
                                                        <div className="form-group">
                                                            <label className="form-label">Sample Invoice Date </label>
                                                            <div className='site_pass'>
                                                                <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="invoicedate" onChange={this.handleOnchange} value={data.invoicedate ? moment.utc(data.invoicedate).format('YYYY-MM-DD') : ''} />
                                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                <div className="invalid-feedback" style={{ display: this.state.invoicedateError ? "block" : 'none' }}>Date required</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-3'>
                                                        <div className="form-group">
                                                            <label className="form-label">Sample Due Date </label>
                                                            <div className='site_pass'>
                                                                <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="duedate" onChange={this.handleOnchange} value={data.duedate ? moment.utc(data.duedate).format('YYYY-MM-DD') : ''} />
                                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                <div className="invalid-feedback" style={{ display: this.state.duedateError ? "block" : 'none' }}>Date required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Payment Method</label>
                                                            <select type="text" className="form-control" placeholder="Discount" name="paymentmethod" onChange={this.handleOnchange} value={data.paymentmethod ? data.paymentmethod : ''} >
                                                                <option value=''>Select Payment Method</option>
                                                                <option value='cash'>Cash</option>
                                                                <option value='cheque'>Cheque</option>
                                                                <option value='creditcard'>Credit Card</option>
                                                                <option value='ach'>ACH</option>
                                                            </select>
                                                            {/* <div className="invalid-feedback" style={{ display: this.state.discountError ? "block" : 'none' }}>Discount is required</div>
                                                            <div className="invalid-feedback" style={{ display: this.state.discountnumberError ? "block" : 'none' }}>Only accept number</div> */}

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Image <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input name="image" type="file" accept="image/png,image/jpg,image/jpeg" className="form-control" onChange={this.uploadhandleChange} id='imageSet' />
                                                            <div className="invalid-feedback" style={{ display: this.state.imageError ? "block" : 'none' }}>Please upload image</div>
                                                        </div>
                                                        <div className="form-group ml-2">
                                                            {
                                                                this.state.modalType == "Add"
                                                                &&
                                                                (this.state.image
                                                                    ?
                                                                    <div style={{ width: '200px', height: '100px' }}>
                                                                        <img style={{ width: '100px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : null} alt='12' />
                                                                    </div>
                                                                    : '')
                                                            }
                                                            {
                                                                this.state.modalType == "Edit"
                                                                    ?
                                                                    <div style={{ width: '200px', height: '100px' }}>
                                                                        <img style={{ width: '100px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : ImportedURL.LIVEURL + imageData} alt="" />
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12'>
                                                        <div className="form-group">
                                                            <label className="form-label "> Use custom category  {data.usecustomcategory ? <i class="fa fa-toggle-on pl-2 cursor-pointer" onClick={() => this.setState({ client: { ...this.state.client, usecustomcategory: false } })} style={{ fontSize: '20px', cursor: 'pointer' }}></i> : <i class="fa fa-toggle-off pl-2 " style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => this.setState({ client: { ...this.state.client, usecustomcategory: true } })} ></i>}</label>
                                                        </div>
                                                    </div>
                                                    {data.usecustomcategory ?
                                                        <>
                                                            <div className='col-lg-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label"> Custom Category </label>
                                                                    {/* <label className="form-label float-right">  <i class="fe fe-chevron-up"></i> </label> */}
                                                                    <div class="row gutters-xs">

                                                                        <div class="col-10">
                                                                            <input type="text" class="form-control" placeholder="Category" name='customcategory' value={this.state.customcategory} onChange={(e) => { this.setState({ customcategory: capitalizeFirstLetter(e.target.value) }) }} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Category is required</div>
                                                                        </div>
                                                                        <span class="col-2">
                                                                            <button class="btn btn-default" onClick={this.addCustomCategory} type="button" style={{ width: '60px', color: 'white', background: '#00ccff' }}>
                                                                                <i class="fe fe-plus"></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>


                                                                </div>
                                                                {data.customcategory && data.customcategory.length ?
                                                                    <table class="card-table table table-center table-md mt-4">
                                                                        <tbody>
                                                                            {data.customcategory.map((item, i) => {
                                                                                return <tr key={i} draggable onDragStart={(e) => this.handleDragStart(e, i)}
                                                                                    onDragOver={(e) => this.handleDragOver(e, i)}
                                                                                    onDragEnd={this.handleDragEnd}>
                                                                                    <td>{item}</td>
                                                                                    <td><button class="card-options-remove" onClick={() => this.removeCutomCategory(i)} style={{
                                                                                        width: '25px', height: '23px', color: 'white', background: '#00ccff', border: 'none', borderRadius: '5px'
                                                                                    }}><i class="fe fe-x"></i></button></td>
                                                                                </tr>
                                                                            }
                                                                            )}

                                                                        </tbody>
                                                                    </table> : ''}
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Custom Sub Category </label>

                                                                    <div class="row gutters-xs">
                                                                        <div class="col-5">
                                                                            <select type="text" name='customcategorysub' class="form-control" placeholder="Search for..." value={this.state.customcategorysub} onChange={(e) => { this.setState({ customcategorysub: e.target.value }) }}>
                                                                                <option value=''>Select a category</option>
                                                                                {
                                                                                    data.customcategory ? data.customcategory.map((item, i) => {
                                                                                        return <option value={item}>{item}</option>
                                                                                    })
                                                                                        : ''
                                                                                }

                                                                            </select>
                                                                            <div className="invalid-feedback" style={{ display: this.state.subcategoryError ? "block" : 'none' }}>Sub Category is required</div>
                                                                        </div>
                                                                        <div class="col-5">
                                                                            <input type="text" name='customsubcategory' value={this.state.customsubcategory} onChange={(e) => { this.setState({ customsubcategory: capitalizeFirstLetter(e.target.value) }) }} class="form-control" placeholder="Subcategory" />
                                                                        </div>
                                                                        <span class="col-2">
                                                                            <button class="btn btn-default" onClick={this.addCustomSubCategory} type="button" style={{ width: '60px', color: 'white', background: '#00ccff' }}>
                                                                                <i class="fe fe-plus"></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {data.customsubcategory && data.customsubcategory.length ? <table class="card-table table table-center table-md mt-4">
                                                                    <tbody>
                                                                        {data.customsubcategory.map((item, i) => {
                                                                            return <tr key={i} draggable onDragStart={(e) => this.handleDragStartSubcategory(e, i)}
                                                                                onDragOver={(e) => this.handleDragOverSubcategory(e, i)}
                                                                                onDragEnd={this.handleDragEnd}>
                                                                                <td>{item.customcategorysub}</td>
                                                                                <td>{item.customsubcategory}</td>
                                                                                <td><button class="card-options-remove" onClick={() => this.removeCutomSubCategory(i)} style={{
                                                                                    width: '25px', height: '23px', color: 'white', background: '#00ccff', border: 'none', borderRadius: '5px'
                                                                                }}><i class="fe fe-x"></i></button></td>
                                                                            </tr>
                                                                        }
                                                                        )}
                                                                    </tbody>
                                                                </table> : ''}
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='col-lg-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label"> Category <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                    <Select
                                                                        value={selectedCategory}
                                                                        onChange={e => this.handleMultiSelectChange(e, "category")}
                                                                        isMulti
                                                                        closeMenuOnSelect={false}
                                                                        options={listoptionCategory}
                                                                    />
                                                                    <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Category is required</div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Sub Category <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                    <Select
                                                                        onChange={e => this.handleMultiSelectChange(e, "subcategory")}
                                                                        isMulti
                                                                        closeMenuOnSelect={false}
                                                                        options={subcategoryOption}
                                                                        value={subcategoryValue}
                                                                    />
                                                                    <div className="invalid-feedback" style={{ display: this.state.subcategoryError ? "block" : 'none' }}>Sub Category is required</div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Communication Email List<span> (Type and Press Enter to add Email)</span>
                                                            </label>
                                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='communicationemail' onKeyPress={this.handleKeyDown} value={this.state.communicationemail} onChange={this.onChange} placeholder="Add More Email By Hitting Enter Button" />
                                                            <div className="invalid-feedback" style={{ display: this.state.communicationemailValidError ? "block" : 'none' }}>Enter valid email</div>


                                                        </div>
                                                        {data.communicationemail && data.communicationemail.length ? <div className="form-group">
                                                            <div className="selectgroup selectgroup-pills">
                                                                {
                                                                    data.communicationemail.map((data, i) => {
                                                                        return <label className="selectgroup-item">
                                                                            <input type="checkbox" name="value" className="selectgroup-input" value="CSS" checked={true} disabled />
                                                                            <span className="selectgroup-button">{data} {this.state.modalType !== 'View' && <button type="button" className="close" style={{ outline: 'none' }} onClick={() => this.removeEmail(i)}></button>}</span>
                                                                        </label>
                                                                    })
                                                                }
                                                            </div>
                                                        </div> : ''}
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Notes </label>
                                                            <textarea type="text" className="form-control" placeholder="Notes" name="notes" onChange={this.handleOnchange} value={data.notes ? data.notes : ''} />
                                                            {/* <div className="invalid-feedback" style={{ display: this.state.urlError ? "block" : 'none' }}>Url is required</div> */}
                                                            <div className="invalid-feedback" style={{ display: this.state.invalidurlError ? "block" : 'none' }}>Invalid Url</div>
                                                        </div>
                                                    </div>
                                                    {data.taxapplied ?
                                                        <>
                                                            <div className='col-lg-3'>
                                                                <div className="form-group">
                                                                    <label className="form-label"> Tax Applied <i class="fa fa-toggle-on pl-2 cursor-pointer" onClick={() => this.setState({ client: { ...this.state.client, taxapplied: false } })} style={{ fontSize: '20px', cursor: 'pointer' }}></i></label>
                                                                    {/* <label className="form-label float-right">  <i class="fe fe-chevron-up"></i> </label> */}
                                                                    <Select
                                                                        onChange={this.onChangeSelect}
                                                                        value={selectedCountry}
                                                                        options={countryOptions}
                                                                        placeholder="Select country"
                                                                    />


                                                                </div>
                                                            </div>
                                                            <div className='col-lg-3'>
                                                                <div className="form-group">
                                                                    <label className="form-label"> Tax Type</label>
                                                                    {/* <select name='taxtype' class="form-control" value={this.state.taxtypelocal} onChange={(e) => { this.setState({ taxtypelocal: e.target.value }) }}>
                                                                        <option>CGST </option>
                                                                        <option>SGST </option>
                                                                    </select> */}
                                                                    <Select
                                                                        onChange={this.onChangeSelect}
                                                                        value={selectedTax}
                                                                        options={TaxOptions}
                                                                        placeholder="Select Tax"
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6'>
                                                                <div className="form-group">
                                                                    <div class="row gutters-xs">
                                                                        <div class="col-5">
                                                                            <label className="form-label">Percentage / Flat</label>
                                                                            <select type="text" className="form-control" placeholder="Discount" name="taxamounttype" value={this.state.taxamounttypelocal} onChange={(e) => { this.setState({ taxamounttypelocal: e.target.value }) }} >
                                                                                <option value='percentage'>Percentage</option>
                                                                                <option value='flat'>Flat</option>
                                                                            </select>

                                                                        </div>
                                                                        <div class="col-5">
                                                                            <label className="form-label">Tax </label>
                                                                            <input type="number" class="form-control" placeholder="Tax" name='taxamount' value={this.state.taxamountlocal} onChange={(e) => { this.setState({ taxamountlocal: e.target.value }) }} />
                                                                        </div>
                                                                        <span class="col-2">
                                                                            <label className="form-label">&nbsp;</label>
                                                                            <button class="btn btn-default" onClick={this.addTaxData} type="button" style={{ width: '60px', color: 'white', background: '#00ccff' }}>
                                                                                <i class="fe fe-plus"></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='col-lg-3'>
                                                            </div>
                                                            <div className='col-lg-9'>
                                                                {data.taxdata && data.taxdata.length ?
                                                                    <table class="card-table table table-center table-md mb-4">
                                                                        <tbody>
                                                                            {data.taxdata.map((item, i) => {
                                                                                return <tr key={i} draggable onDragStart={(e) => this.handleDragStartTax(e, i)}
                                                                                    onDragOver={(e) => this.handleDragOverTax(e, i)}
                                                                                    onDragEnd={this.handleDragEnd}>
                                                                                    <td>{item.taxtype}</td>
                                                                                    <td>{item.taxamounttype}</td>
                                                                                    <td>{item.taxamount}</td>
                                                                                    <td><button class="card-options-remove" onClick={() => this.removeTax(i)} style={{
                                                                                        width: '25px', height: '23px', color: 'white', background: '#00ccff', border: 'none', borderRadius: '5px'
                                                                                    }}><i class="fe fe-x"></i></button></td>
                                                                                </tr>
                                                                            }
                                                                            )}
                                                                        </tbody>
                                                                    </table> : ''}
                                                            </div>

                                                        </>
                                                        :
                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label "> Tax Applied  <i class="fa fa-toggle-off pl-2 " style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => this.setState({ client: { ...this.state.client, taxapplied: true } })} ></i></label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right payrollgen_btn">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary" ><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div id='overlay-model' style={{ display: this.state.editSpi && Object.keys(data.company && data.address && data.amount).length <= 0 && (this.state.modalType === 'Edit') ? 'block' : 'none' }}></div>
                                            {this.state.editSpi && Object.keys(data.company && data.address && data.amount).length <= 0 ?
                                                <div className='model_loader_poss'>
                                                    <img className='loader_img_style_model' src='../../../assets/images/banner-logo.png' />
                                                    <Spinner className='spinner_load_model' animation="border" variant="info" >
                                                    </Spinner>
                                                </div>
                                                : ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ==============detail============== */}




            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    invoiceState: state.invoice,
    invoicedetailState: state.invoicedetail,
    invoicesubcategoryState: state.invoicesubcategory,
    invoicecategoryState: state.invoicecategory,
    AccountState: state.account,
    countryReducer: state.countryReducer
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            handleChange: AC_HANDLE_INVOICE_CHANGE,
            listinvioce: AC_LIST_INVOICE,
            viewinvioce: AC_VIEW_INVOICE,
            resetinvioce: AC_RESET_INVOICE,
            listinvoicedetail: AC_LIST_INVOICE_DETAIL,
            listinvoicecategory: AC_LIST_INVOICE_CATEGORY,
            listinvoicesubcategory: AC_LIST_INVOICE_SUB_CATEGORY,
            emptyinvoice: AC_EMPTY_INVOICE,
            AC_SPINNER_INVOICE_ALL,
            AC_LIST_TAX
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);


