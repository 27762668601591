
const initialState = {
    addAsset: [],
    listAsset: [],
    listAssetParentId: [],
    deleteAsset: [],
    viewAsset: {
        assetName: '',
        inventoryid:''

    },
}
function assetReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_ASSET':
            return {
                ...state,
                addAsset: action.payload
            }
            break;

        case 'LIST_ASSET':
            return {
                ...state,
                listAsset: action.payload
            }
            break;
        case 'VIEW_ASSET':
            return {
                ...state,
                viewAsset: action.payload
            }
        case 'DELETE_ASSET':
            return {
                ...state,
                deleteAsset: action.payload
            }
            break;
        case 'LIST_ASSETPARENTID':
            return {
                ...state,
                listAssetParentId: action.payload
            }
            break;
        case 'HANDLE_ASSET_CHANGE': 
            return Object.assign({}, state, {
                viewAsset: {
                    ...state.viewAsset,
                    [action.name]: action.value
                }
            })
        case 'RESET_ASSET':
                return Object.assign({}, state, {
                    viewAsset: initialState.viewAsset
                })
        default:
            return state;
            break;
    }
}

export default assetReducer;