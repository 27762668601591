import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Swal from 'sweetalert2';
import { AC_LIST_EMAIL } from '../../actions/emailAction';
import nodata from "../../assets/images/no-data.jpg"
class Sent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewRedirect: false,
            viewRedirectId: '',

        }
    }
    componentDidMount() {
        this.props.ListEmail();
        localStorage.setItem('invoicetoken', "");
    }
    deleteMail = (id) => {
        Swal.fire({
            title: 'Are you sure want to delete?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            imageUrl: 'assets/images/delete.png',
            customClass: {
                popup: 'swal_pop',
                title: 'swal_title',
                image: 'swal_image',
                actions: 'swal_action',
                confirmButton: 'swal_confirm',
                cancelButton: 'swal_close',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const { AccountState, EmailState } = this.props;
                const account = AccountState?.account;
                // const data = EmailState.emailData;
                // data["deletedBy"] = account.email;
                axios.post(ImportedURL.API.updateDeletedByEmail + "/" + id, { deletedBy: account.email })
                    .then((res) => {
                        // Success("Your mail is deleted successfully");
                        this.props.ListEmail();

                    }).catch((err) => {
                        console.log(err);
                    });
                axios.get(ImportedURL.API.softDeleteEmail + "/" + id)
                    .then((res) => {
                        Success(res.statusText);
                        Success("Your mail is deleted successfully");
                        this.props.ListEmail();
                    }).catch((err) => {
                        console.log(err);
                    });
            }
        })
    }
    viewClick = (id) => {
        this.setState({ viewRedirect: true, viewRedirectId: id })
    }

    render() {
        if (this.state.viewRedirect) {
            return <Redirect to={'/view-email/' + this.state.viewRedirectId} />
        }
        const { AccountState, EmailState } = this.props;
        const account = AccountState?.account;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Email" });
        const listEmail = EmailState.listEmail;

        const sentData = []
        if (listEmail && listEmail.length > 0) {
            listEmail.map((mailData) => {
                if (mailData.from == account.email) {
                    sentData.push(mailData)
                }
            })
        }
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/Sent"><h6 className="highlights_breadcrump">Sent</h6></Link></p>
                </div>
                <div className="section-body user_sec email_sec">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='card-title d-flex'>
                                            <div className='rounded_icon'><i className="fa fa-paper-plane mr-2"></i></div>
                                            <h3 className="card-title " style={{ marginTop: '10px' }}> Sent </h3>
                                        </div>
                                        <div className="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="header-action" >
                                                    {Previlege?.add ?
                                                        <Link to='/compose'><button type="button" className="btn compose_btn" ><i className="fe fe-plus mr-2" id='Add' /> Compose</button></Link>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body email_list">
                                        <div class="table-responsive">
                                            <table class="table table-hover table-striped table-vcenter text-nowrap mb-0">
                                                <tbody>
                                                    {sentData.length > 0 ? sentData.map((data, i) => {
                                                        return <tr key={i} style={{ cursor: 'pointer' }} className='table_row'>
                                                            <td class="w80" onClick={(e) => this.viewClick(data._id)}>
                                                                <label class="custom-control custom-checkbox" style={{ cursor: 'pointer' }}>
                                                                    <input type="checkbox" class="custom-control-input" name="example-checkbox1" value="option1" />
                                                                    <span class="custom-control-label">&nbsp;</span>
                                                                </label>
                                                            </td>
                                                            <td style={{ width: '50px' }} onClick={(e) => this.viewClick(data._id)}>
                                                                <span ><i class="fa fa-star-o" aria-hidden="true"></i></span>
                                                            </td>
                                                            <td onClick={(e) => this.viewClick(data._id)}>
                                                                <p className='mb-0 tomail'><span style={{ fontWeight: '500' }}>To : </span><span>{data.toValue + data.ccValue}</span></p>
                                                            </td>
                                                            <td onClick={(e) => this.viewClick(data._id)}><p className='description'>{data.subject} - {data.description && data.description.replace(/<[^>]+>/g, '')}</p></td>
                                                            <td className='maildate' onClick={(e) => this.viewClick(data._id)}>{data.date}</td>
                                                            <td className='maildeleteicon' onClick={(e) => this.deleteMail(data._id)}><span ><i class="fa fa-trash-o " aria-hidden="true"></i></span></td>
                                                        </tr>
                                                    })
                                                        :
                                                        <tr>
                                                            <td style={{ textAlign: 'center' }}><img src={nodata} style={{ width: '400px', height: '400px' }} /> </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    EmailState: state.email
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ListEmail: AC_LIST_EMAIL
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Sent);