import Login from '../Authentication/login';
import SignUp from '../Authentication/signup';
import ForgotPassword from '../Authentication/forgotpassword';
import NotFound from '../Authentication/404';
import InternalServer from '../Authentication/500';
import dashboard from '../Dashboard/dashboard';
import Profile from '../Profile/Profile';
import listcategory from '../Category/listcategory';
import addcategory from '../Category/addcategory';
import listuser from '../User/listuser';
import adduser from '../User/adduser';
import listinventory from '../Master/Inventory/listinventory';
import sitesettings from '../Settings/sitesettings';
import changepassword from '../Settings/changepassword';
import listcountry from '../Master/Country/listcountry';
import listemployee from '../Employees/listemployee';
import addemployee from '../Employees/addemployee';
import viewemployee from '../Employees/viewemployee';
import listdepartment from '../Master/Department/listdepartment';
import listdivision from '../Master/Division/listdivision';
import listdesignation from '../Master/Designation/listdesignation';
import service from '../Asset/service';
import ListleaveTemplate from '../Master/Leavetemplate/listleavetemplate';
import ListManageGrades from '../Master/Managegrades/listmanagegrades';
import ListProject from '../Project/listproject';
import AddProject from '../Project/addproject';
import ViewProject from '../Project/viewproject';
import listholiday from '../Master/Holidays/listholiday';
import listpayrollitem from '../Master/Payrollitem/payrollitem';
import employeesalary from '../Payroll/EmployeeSalary/employeesalary';
import payslip from '../Payroll/Payslip/payslip';

import ListEmail from '../Email/listemail';
import compose from '../Email/compose';
import TrashEmails from '../Email/trashemails';
import Sent from '../Email/sent';
import ViewEmail from '../Email/viewemail';
import TaskBoard from '../Taskboard/taskboard';
import projectcategory from '../Taskboard/projectcategory';
import ViewTrashMail from '../Email/viewtrashmail';
import ViewInbox from '../Email/viewinbox';
import leaves from '../Leaves/leaves';
import LeaveType from '../Master/Leavetype/leavetype';
import EmployeeLeave from '../EmployeeLeave/employeeleave';
import AddEmployeeLeave from '../EmployeeLeave/addemployeeleave';
import listkycdocument from '../Master/Kycdocument/kycdocument';
import leavetransection from '../Payroll/leavetransection';
import monthlypayroll from '../Payroll/monthlypayroll';
import payslipgenaration from '../Payroll/payslipgenaration';
import listEarning from '../Master/Earning/listearnings';
import listDeduction from '../Master/Deduction/listdeduction';
import monthlypayslip from '../Payroll/Payslip/monthlypayslip';
import listInvoicecategory from '../Master/InvioceCategory/listinvoicecategory';
import listInvoicesubcategory from '../Master/InvoicesubCategory/listinvoicesubcategory';
import invoicedetail from '../Master/Invoicedetail/invoicedetail';
import invoicenumber from '../Master/InvoiceNumber/invoicenumber';
import invoice from '../Invoice/invoice';
import invoicegenerate from '../Invoice/invoicegenerate';
import invoicepwd from '../Invoice/invoicepwd';
import Invoicepdf from '../Invoice/invoicepdf';
import client from '../Invoice/Client/client';
import timesheetentry from '../Timesheet/timesheetentry';
import timesheetreview from '../Timesheet/timesheetreview';
import employeetimesheetreview from '../Timesheet/employeetimesheetreview';
import admintimesheetreview from '../Timesheet/admin/admintimesheetreview';
import adminemployeetimesheetreview from '../Timesheet/admin/adminemployeetimesheetreview';
import timesheetrequest from '../Timesheet/timesheetrequest';
import companypolicy from '../Policy/policy/companypolicy';
import addcompanypolicy from '../Policy/policy/addcompanypolicy';
import addsop from '../Policy/sop/addsop';
import sop from '../Policy/sop/sop';
import companypolicyapproval from '../Policy/policy/companypolicyapproval';
import sopapproval from '../Policy/sop/sopapproval';
import assetcategory from '../Master/Assets/assetcategory';
import assetsubcategory from '../Master/Assets/assetsubcategory';
import listasset from '../Asset/listasset';
import addasset from '../Asset/addasset';
import viewasset from '../Asset/viewasset';
import myassets from '../Asset/myassets';
import leaveapproval from '../Leaves/leaveapproval';
import expensecategory from '../Master/Expenses/expensecategory';
import expense from '../Expense/expense';
import expensepayment from '../Expense/expensepayment';
import listverification from '../Verification/listverification';
import addverification from '../Verification/addverification';
import employeeverificationlist from '../Verification/employeeverifications/employeeverificationlist';
import myverificationlist from '../Verification/myverifications/myverificationlist';
import myverification from '../Verification/myverifications/myverification';
import employeeverification from '../Verification/employeeverifications/employeeverification';
import employeecategory from '../Master/EmployeeCategory/employeecategory';
import clienthistory from '../Invoice/Client/clienthistory';
import invoicepayment from '../Invoice/Invoicepayment/invoicepayment';
import transaction from '../Invoice/Transactions/transaction';
import listtax from '../Master/Tax/listtax';

// Set dynamic routes according to previleges
export function getRoutes(props) {
    const Routes = [
        {
            path: "/login",
            name: 'login',
            exact: true,
            pageTitle: "Tables",
            component: Login
        },
        {
            path: "/signup",
            name: 'signup',
            exact: true,
            pageTitle: "Tables",
            component: SignUp
        },
        {
            path: "/forgotpassword",
            name: 'forgotpassword',
            exact: true,
            pageTitle: "Tables",
            component: ForgotPassword
        },
        {
            path: "/notfound",
            name: 'notfound',
            exact: true,
            pageTitle: "Tables",
            component: NotFound
        },
        {
            path: "/internalserver",
            name: 'internalserver',
            exact: true,
            pageTitle: "Tables",
            component: InternalServer
        },
        {
            path: "/",
            name: 'Dashboard',
            exact: true,
            pageTitle: "Dashboard",
            component: dashboard
        },
        {
            path: "/profile",
            name: 'profile',
            exact: true,
            pageTitle: "My Profile",
            component: Profile
        },
        {
            path: "/list-user",
            name: 'listuser',
            exact: true,
            pageTitle: "User",
            component: listuser
        },
        {
            path: "/invoice/:id",
            name: 'listuser',
            exact: true,
            pageTitle: "User",
            component: invoicepayment
        },
        {
            path: "/add-user",
            name: 'adduser',
            exact: true,
            pageTitle: "Add User",
            component: adduser
        },
        // ------------

        {
            path: "/listinventory",
            name: 'listinventory',
            exact: true,
            pageTitle: "ListInventory ",
            component: listinventory
        },
        {
            path: "/accountsettings",
            name: 'accountsettings',
            exact: true,
            pageTitle: "SiteSettings",
            component: sitesettings
        },
        {
            path: "/changepassword",
            name: 'changepassword',
            exact: true,
            pageTitle: "ChangePassword",
            component: changepassword
        },
        {
            path: "/list-department",
            name: 'listdepartment',
            exact: true,
            pageTitle: "ListDepartment",
            component: listdepartment
        },
        {
            path: "/list-division",
            name: 'listdivision',
            exact: true,
            pageTitle: "ListDivision",
            component: listdivision
        },
        {
            path: "/list-designation",
            name: 'listdesignation',
            exact: true,
            pageTitle: "ListDesignation",
            component: listdesignation
        },
        {
            path: "/service/:id",
            name: 'service',
            exact: true,
            pageTitle: "Service",
            component: service
        },
        {
            path: "/list-leave-template",
            name: 'service',
            exact: true,
            pageTitle: "Leave Template",
            component: ListleaveTemplate
        },
        {
            path: "/leave-type",
            name: 'leavetype',
            exact: true,
            pageTitle: "Leave Type",
            component: LeaveType
        },
        {
            path: "/view-leave-template/:id",
            name: 'service',
            exact: true,
            pageTitle: "Leave Template",
            component: ListleaveTemplate
        },
        {
            path: "/list-mange-grades",
            name: 'mangegrades',
            exact: true,
            pageTitle: "Mange Grades",
            component: ListManageGrades
        },
        {
            path: "/listholiday",
            name: 'holidays',
            exact: true,
            pageTitle: "Holidays",
            component: listholiday
        },
        {
            path: "/listkycdocument",
            name: 'kycdocument',
            exact: true,
            pageTitle: "Kycdocument",
            component: listkycdocument
        },
        {
            path: "/listpayrollitem",
            name: 'payrollitem',
            exact: true,
            pageTitle: "Payroll Item",
            component: listpayrollitem
        },
        {
            path: "/listemployeesalary",
            name: 'employeesalary',
            exact: true,
            pageTitle: "Employee Salary",
            component: employeesalary
        },
        {
            path: "/viewpayslip/:id",
            name: 'payslip',
            exact: true,
            pageTitle: "Payslip",
            component: payslip
        },
        {
            path: "/leavetransection",
            name: 'Leave Transection',
            exact: true,
            pageTitle: "Leave Transection",
            component: leavetransection
        },
        {
            path: "/monthlypayroll",
            name: 'Monthly Payroll',
            exact: true,
            pageTitle: "Monthly Payroll",
            component: monthlypayroll
        },
        {
            path: "/payslipgenaration",
            name: 'payslip Genaration',
            exact: true,
            pageTitle: "Payslip Genaration",
            component: payslipgenaration
        },
        {
            path: "/listearnig",
            name: 'List Earning',
            exact: true,
            pageTitle: "List Earning",
            component: listEarning
        },
        {
            path: "/listdeduction",
            name: 'List Deduction',
            exact: true,
            pageTitle: "List Deduction",
            component: listDeduction
        },
        {
            path: "/monthlypayslip/:id",
            name: 'Monthly Payslip',
            exact: true,
            pageTitle: "Monthly Payslip",
            component: monthlypayslip
        },

        // {
        //     path: "/listinvoicenumber",
        //     name: 'Invoice Number',
        //     exact: true,
        //     pageTitle: "Invoice Number",
        //     component: invoicenumber
        // },

        {
            path: "/invoicepwd",
            name: 'Invoice Pwd',
            exact: true,
            pageTitle: "Invoice Pwd",
            component: invoicepwd
        },
        {
            path: "/invoicepwdClient",
            name: 'Invoice Pwd',
            exact: true,
            pageTitle: "Invoice Pwd",
            component: invoicepwd
        },
        {
            path: "/Invoicepdf",
            name: 'Invoice Pwd',
            exact: true,
            pageTitle: "Invoice Pwd",
            component: Invoicepdf
        },


    ];

    let invoicetoken = props.AccountState.invoicestatus
    var tokenStatus = localStorage.getItem("invoicetoken");
    if (invoicetoken || tokenStatus) {
        Routes.push(
            {
                path: "/listinvoice",
                name: 'Invoice',
                exact: true,
                pageTitle: "Invoice",
                component: invoice
            },
            {
                path: "/transactions",
                name: 'Invoice',
                exact: true,
                pageTitle: "Invoice",
                component: transaction
            },
            {
                path: "/transactions/:id",
                name: 'Invoice',
                exact: true,
                pageTitle: "Invoice",
                component: transaction
            },
            {
                path: "/listinvoicegenerate/:id",
                name: 'Invoice Generate',
                exact: true,
                pageTitle: "Invoice Generate",
                component: invoicegenerate
            },
            {
                path: "/clients-list",
                name: 'Client',
                exact: true,
                pageTitle: "Client",
                component: client
            },
            {
                path: "/clients-history/:id",
                name: 'Client',
                exact: true,
                pageTitle: "Client",
                component: clienthistory
            },
            {
                path: "/listinvoicecategory",
                name: 'Invoice Category',
                exact: true,
                pageTitle: "Invoice Category",
                component: listInvoicecategory
            },
            {
                path: "/listinvoicesubcategory",
                name: 'Invoice SubCategory',
                exact: true,
                pageTitle: "Invoice SubCategory",
                component: listInvoicesubcategory
            },
            {
                path: "/listinvoicedetail",
                name: 'Invoice Detail',
                exact: true,
                pageTitle: "Invoice Detail",
                component: invoicedetail
            },
        )
    }
    const { AccountState } = props;
    const role = AccountState.role;
    let Employee_Leave = getPrevilege('Employee_Leave', props);
    if (Employee_Leave.view) Routes.push(
        {
            path: "/employee-leave",
            name: 'employee leave',
            exact: true,
            pageTitle: "Employee Leave",
            component: EmployeeLeave
        },
        {
            path: "/add-employee-leave",
            name: 'addemployee leave',
            exact: true,
            pageTitle: "Add Employee Leave",
            component: AddEmployeeLeave
        },
        {
            path: "/edit-employee-leave/:id",
            name: 'editemployee leave',
            exact: true,
            pageTitle: "Edit Employee Leave",
            component: AddEmployeeLeave
        },
    )

    let Employee = getPrevilege('Employee', props);
    if (Employee.view) Routes.push(
        {
            path: "/listemployee",
            name: 'listemployee',
            exact: true,
            pageTitle: "ListEmployee",
            component: listemployee
        },
        {
            path: "/addemployee",
            name: 'addemployee',
            exact: true,
            pageTitle: "AddEmployee",
            component: addemployee
        },
        {
            path: "/viewemployee/:id",
            name: 'viewemployee',
            exact: true,
            pageTitle: "ViewEmployee",
            component: viewemployee
        },
        {
            path: "/editemployee/:id",
            name: 'editemployee',
            exact: true,
            pageTitle: "EditEmployee",
            component: addemployee
        },
    )
    let Project = getPrevilege('Project', props);
    if (Project.view) Routes.push(
        {
            path: "/list-project",
            name: 'listproject',
            exact: true,
            pageTitle: "Project",
            component: ListProject
        },
        {
            path: "/add-project",
            name: 'addproject',
            exact: true,
            pageTitle: "Project",
            component: AddProject
        },
        {
            path: "/view-project/:id",
            name: 'viewproject',
            exact: true,
            pageTitle: "Project",
            component: ViewProject
        },
        {
            path: "/edit-project/:id",
            name: 'editproject',
            exact: true,
            pageTitle: "Project",
            component: AddProject
        },
    )
    let Timesheet = getPrevilege('Timesheet', props);
    if (Timesheet.view) Routes.push(
        {
            path: "/timesheet-entry",
            name: 'timesheetentry',
            exact: true,
            pageTitle: "Timesheet",
            component: timesheetentry
        },
    )
    if (AccountState.account.role == 'admin') Routes.push(
        {
            path: "/timesheet-review",
            name: 'timesheetentry',
            exact: true,
            pageTitle: "Timesheet",
            component: admintimesheetreview
        },
        {
            path: "/employee-timesheet-review",
            name: 'timesheetentry',
            exact: true,
            pageTitle: "Timesheet",
            component: adminemployeetimesheetreview
        },
    )
    else {
        if (AccountState.account && AccountState.account.isprojectmanager) Routes.push(
            {
                path: "/timesheet-review",
                name: 'timesheetentry',
                exact: true,
                pageTitle: "Timesheet",
                component: timesheetreview
            },
            {
                path: "/employee-timesheet-review",
                name: 'timesheetentry',
                exact: true,
                pageTitle: "Timesheet",
                component: employeetimesheetreview
            },
        )
        if (AccountState.account && AccountState.account.isrm) Routes.push(
            {
                path: "/timesheet-requests",
                name: 'timesheetentry',
                exact: true,
                pageTitle: "Timesheet",
                component: timesheetrequest
            },
        )
    }
    // Expense
    if (getPrevilege('Expense', props).view) {
        Routes.push({
            path: "/company-expense",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: expense
        })
    }


    if (getPrevilege('Expense_Payment', props).view) {
        Routes.push({
            path: "/expense-payment",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: expensepayment
        })
    }

    if (getPrevilege('Verification', props).view) {
        Routes.push({
            path: "/verification-process",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: listverification
        })
    }
    if (getPrevilege('Verification', props).add) {
        Routes.push({
            path: "/add-verification",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: addverification
        })
    }
    if (getPrevilege('Verification', props).edit) {
        Routes.push({
            path: "/edit-verification/:id",
            name: 'verifications',
            exact: true,
            pageTitle: "Verification",
            component: addverification
        })
    }
    if (getPrevilege('Employee_Verifications', props).view) {
        Routes.push(
            {
                path: "/employee-verifications",
                name: 'verifications',
                exact: true,
                pageTitle: "Verification",
                component: employeeverificationlist
            },
            {
                path: "/employee-verification/:id",
                name: 'verifications',
                exact: true,
                pageTitle: "Verification",
                component: employeeverification
            })
    }

    if (role != 'admin') {
        Routes.push(
            {
                path: "/my-verifications",
                name: 'verifications',
                exact: true,
                pageTitle: "Verification",
                component: myverificationlist
            },
            {
                path: "/my-verification/:id",
                name: 'verifications',
                exact: true,
                pageTitle: "Verification",
                component: myverification
            })
    }
    //Policy Routes
    let Policy = getPrevilege('Policy', props);
    if (Policy.view) Routes.push(
        {
            path: "/company-policy",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: companypolicy
        },

    )
    if (Policy.add) Routes.push(
        {
            path: "/add-company-policy",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: addcompanypolicy
        },
    )
    if (Policy.edit || AccountState.account.ispolicyapprover || AccountState.account.ispolicycreator) Routes.push(
        {
            path: "/edit-company-policy/:id",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: addcompanypolicy
        },
    )
    if (AccountState.account.ispolicyapprover || AccountState.account.ispolicycreator) Routes.push(
        {
            path: "/approve-company-policy",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: companypolicyapproval
        },
        {
            path: "/company-policy-requests",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: companypolicyapproval
        },
    )
    //Sop Routes
    let SOP = getPrevilege('SOP', props);
    if (SOP.view) Routes.push(
        {
            path: "/company-sop",
            name: 'policies',
            exact: true,
            pageTitle: "SOP",
            component: sop
        },

    )
    if (SOP.add) Routes.push(
        {
            path: "/add-company-sop",
            name: 'policies',
            exact: true,
            pageTitle: "SOP",
            component: addsop
        },
    )
    if (SOP.edit || AccountState.account.issopapprover || AccountState.account.issopcreator) Routes.push(
        {
            path: "/edit-company-sop/:id",
            name: 'policies',
            exact: true,
            pageTitle: "SOP",
            component: addsop
        },
    )
    if (AccountState.account.issopapprover || AccountState.account.issopcreator) Routes.push(
        {
            path: "/approve-company-sop",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: sopapproval
        },
        {
            path: "/company-sop-requests",
            name: 'policies',
            exact: true,
            pageTitle: "Policy",
            component: sopapproval
        },
    )

    // Category routes
    let Asset = getPrevilege('Asset', props);
    if (Asset.view) Routes.push(
        {
            path: "/list-asset",
            name: 'listcategory',
            exact: true,
            pageTitle: "Asset",
            component: listasset
        },

        {
            path: "/view-asset/:id",
            name: 'viewasset',
            exact: true,
            pageTitle: "Asset",
            component: viewasset
        },

    )

    if (role != 'admin') {
        Routes.push(
            {
                path: "/my-assets",
                name: 'assets',
                exact: true,
                pageTitle: "Asset",
                component: myassets
            },
        )
    }
    if (Asset.add) Routes.push(
        {
            path: "/add-asset",
            name: 'addasset',
            exact: true,
            pageTitle: "Category",
            component: addasset
        },
    )
    if (Asset.edit) Routes.push(
        {
            path: "/edit-asset/:id",
            name: 'addasset',
            exact: true,
            pageTitle: "Category",
            component: addasset
        },
    )
    // Category routes
    let Category = getPrevilege('Category', props);
    if (Category.view) Routes.push(
        {
            path: "/list-category",
            name: 'listcategory',
            exact: true,
            pageTitle: "Category",
            component: listcategory
        },
        {
            path: "/add-category",
            name: 'addcategory',
            exact: true,
            pageTitle: "Category",
            component: addcategory
        },
        {
            path: "/view-category/:id",
            name: 'addcategory',
            exact: true,
            pageTitle: "Category",
            component: addcategory
        },
        {
            path: "/edit-category/:id",
            name: 'addcategory',
            exact: true,
            pageTitle: "Category",
            component: addcategory
        },
    )

    if (getPrevilege('Employee_Category', props).view) Routes.push(
        {
            path: "/employee-category",
            name: 'listcategory',
            exact: true,
            pageTitle: "Employee Category",
            component: employeecategory
        },
    )
    let Country = getPrevilege('Country', props);
    if (Country.view) Routes.push(
        {
            path: "/list-country",
            name: 'listcountry',
            exact: true,
            pageTitle: "ListCountry",
            component: listcountry
        },
    )
    if (getPrevilege('Tax', props)) Routes.push(
        {
            path: "/list-tax",
            name: 'listtax',
            exact: true,
            pageTitle: "Listtax",
            component: listtax
        },
    )
    let Email = getPrevilege('Email', props);
    if (Email.view) Routes.push(
        {
            path: "/list-email",
            name: 'listemail',
            exact: true,
            pageTitle: "ListEmail",
            component: ListEmail
        },
        {
            path: "/compose",
            name: 'compose',
            exact: true,
            pageTitle: "Compose",
            component: compose
        },
        {
            path: "/trsh-emails",
            name: 'trashemails',
            exact: true,
            pageTitle: "Trash Emails",
            component: TrashEmails
        },
        {
            path: "/sent",
            name: 'sent',
            exact: true,
            pageTitle: "Sent",
            component: Sent
        },
        {
            path: "/view-email/:id",
            name: 'view-email',
            exact: true,
            pageTitle: "View Email",
            component: ViewEmail
        },
        {
            path: "/view-trash-mail/:id",
            name: 'view-email',
            exact: true,
            pageTitle: "View Trash Email",
            component: ViewTrashMail
        },
        {
            path: "/view-inbox/:id",
            name: 'view inbox',
            exact: true,
            pageTitle: "View Inbox",
            component: ViewInbox
        },
    )

    let Task_Board = getPrevilege('Task_Board', props);
    if (Task_Board.view) Routes.push(
        {
            path: "/project-category",
            name: 'projectcategory',
            exact: true,
            pageTitle: "Project Category",
            component: projectcategory
        },
        {
            path: "/task-board/:id",
            name: 'taskboard',
            exact: true,
            pageTitle: "TaskBoard",
            component: TaskBoard
        },
    )
    let Leaves = getPrevilege('Leaves', props);
    if (Leaves.view) Routes.push(
        {
            path: "/leaves",
            name: 'leaves',
            exact: true,
            pageTitle: "Leaves",
            component: leaves
        },
    )

    if (AccountState.account.isrm) Routes.push(
        {
            path: "/leave-approval",
            name: 'leaves',
            exact: true,
            pageTitle: "Leaves",
            component: leaveapproval
        },
    )

    if (getPrevilege('Asset_category', props).view) {
        Routes.push(
            {
                path: "/listassetcategory",
                name: 'listassetcategory',
                exact: true,
                pageTitle: "Asset",
                component: assetcategory
            },
        )
    }
    if (getPrevilege('Asset_subcategory', props).view) {
        Routes.push(
            {
                path: "/listassetsubcategory",
                name: 'listassetsubcategory',
                exact: true,
                pageTitle: "Asset",
                component: assetsubcategory
            },
        )
    }

    if (getPrevilege('Expense_Category', props).view) {
        Routes.push(
            {
                path: "/expensecategory",
                name: 'expensecategory',
                exact: true,
                pageTitle: "Asset",
                component: expensecategory
            },
        )
    }
    return Routes;
}

// Set dynamic content for sidebar according to previleges
export function getContent(props) {

    const { AccountState, EmailState } = props;
    const account = AccountState?.account;
    const role = AccountState.role;

    const listEmail = EmailState.listEmail;
    let seenArr = [];
    for (let i = 0; i < listEmail.length; i++) {
        if (listEmail[i].seen == true) {
            seenArr.push(listEmail[i])
        }
    }
    let seenCount = seenArr.length;
    let inboxCount = <span className='badge badge-light'>{seenCount}</span>

    let uniqueid = 1;

    let content = [
        {
            "id": 'SiteManagement',
            "label": "Site Management"
        },
        {
            "id": uniqueid++,
            "label": "Dashboard",
            "icon": "fa fa-area-chart",
            "to": "/"
        },
        {
            "id": uniqueid++,
            "label": "Settings",
            "icon": "fa fa-gear",

            content: [
                {
                    "id": uniqueid++,
                    "label": "Account Settings",
                    "to": "/accountsettings"
                },
                {
                    "id": uniqueid++,
                    "label": "Change Password",
                    "to": "/changepassword"
                },
            ]
        },
    ];
    // ==========employee
    // if (getPrevilege("Employee").view) content.push(
    // 	{
    // 		"id": 80,
    // 		"icon": "fa fa-users",
    // 		"label": "Employees",
    // 		"to": "/listemployee"
    // 	},

    // )
    const employeeInnerContent = [];
    let linkToEmployee = '';
    let nameEmployee = '';
    let iconEmployee = '';

    if (getPrevilege("Employee", props).view) {
        employeeInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Add Employee",
                "to": "/listemployee"
            }
        )
        linkToEmployee = "/listemployee"
        nameEmployee = "Employee"
        iconEmployee = "fa fa-users"
    }
    if (getPrevilege('Employee_salary', props).view) {
        employeeInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Employee CTC",
                "to": "/listemployeesalary"
            }
        )
        linkToEmployee = "/listemployeesalary"
        nameEmployee = "CTC Setup"
        iconEmployee = "icon-bar-chart"
    }
    if (getPrevilege('Employee_Leave', props).view) {
        employeeInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Leave Eligiblity",
                "to": "/employee-leave"
            }
        )
        linkToEmployee = "/employee-leave"
        nameEmployee = "Leave Eligiblity"
        iconEmployee = "icon-bar-chart"
    }
    if (employeeInnerContent.length == 1) {
        content.push(
            {
                "id": uniqueid++,
                "icon": iconEmployee,
                "label": nameEmployee,
                'to': linkToEmployee,
            }
        )
    } else if (employeeInnerContent.length) {
        content.push(
            {
                "id": uniqueid++,
                "icon": "fa fa-users",
                "label": "Employee",
                content: employeeInnerContent,
            }
        )
    }

    // ==========employee end
    // if (getPrevilege("Leaves", props).view) content.push(
    //     {
    //         "id": uniqueid++,
    //         "icon": "fa fa-home",
    //         "label": "Leaves",
    //         "to": "/leaves"
    //     }
    // )

    const LeaveInnerContent = [];
    let linkToLeave = '';
    let nameLeave = '';
    let iconLeave = '';

    if (account.role == 'admin') {
        if (getPrevilege("Leaves", props).view) content.push(
            {
                "id": uniqueid++,
                "icon": "fa fa-home",
                "label": "Leave",
                "to": "/leaves"
            }
        )
    } else {
        if (getPrevilege('Leaves', props).view) {
            LeaveInnerContent.push(
                {
                    "id": uniqueid++,
                    "label": "Leave list",
                    "to": "/leaves"
                }
            )
            linkToLeave = "/leaves"
            nameLeave = "Leave"
            iconLeave = "fa fa-home"
        }

        if (account.isrm) {
            LeaveInnerContent.push(
                {
                    "id": uniqueid++,
                    "label": "Leave Approval",
                    "to": "/leave-approval"
                }
            )
            linkToLeave = "/leave-approval"
            nameLeave = "Leave"
            iconLeave = "fa fa-home"
        }

        if (LeaveInnerContent.length == 1) {
            content.push(
                {
                    "id": uniqueid++,
                    "icon": iconLeave,
                    "label": nameLeave,
                    'to': linkToLeave,
                }
            )
        } else if (LeaveInnerContent.length) {
            content.push(
                {
                    "id": uniqueid++,
                    "icon": "fa fa-home",
                    "label": "Leave",
                    content: LeaveInnerContent,
                }
            )
        }
    }

    if (getPrevilege("Project", props).view) content.push(
        {
            "id": uniqueid++,
            "icon": "fa fa-project-diagram",
            "label": "Projects",
            "to": "/list-project"
        }
    )

    const AssetInnerContent = [];
    let linkToAsset = '';
    let nameAsset = '';
    let iconAsset = '';

    if (account.role == 'admin') {
        if (getPrevilege("Asset", props).view) content.push(
            {
                "id": uniqueid++,
                "icon": "fa fa-cubes",
                "label": "Asset",
                "to": "/list-asset"
            }
        )
    } else {
        if (getPrevilege('Asset', props).view) {
            AssetInnerContent.push(
                {
                    "id": uniqueid++,
                    "label": "Asset List",
                    "to": "/list-asset"
                }
            )
            linkToAsset = "/list-asset"
            nameAsset = "Asset"
            iconAsset = "fa fa-cubes"
        }

        if (true) {
            AssetInnerContent.push(
                {
                    "id": uniqueid++,
                    "label": "My Assets",
                    "to": "/my-assets"
                }
            )
            linkToAsset = "/my-assets"
            nameAsset = "Asset"
            iconAsset = "fa fa-cubes"
        }

        if (AssetInnerContent.length == 1) {
            content.push(
                {
                    "id": uniqueid++,
                    "icon": iconAsset,
                    "label": nameAsset,
                    'to': linkToAsset,
                }
            )
        } else if (AssetInnerContent.length) {
            content.push(
                {
                    "id": uniqueid++,
                    "icon": "fa fa-cubes",
                    "label": "Asset",
                    content: AssetInnerContent,
                }
            )
        }
    }


    const TimesheetInnerContent = [];
    let linkToTimesheet = '';
    let nameTimesheet = '';
    let iconTimeSheet = '';
    if (account.role == 'admin') content.push(
        {
            "id": uniqueid++,
            "icon": "fa fa-calendar",
            "label": "Timesheet",
            "to": "/timesheet-review"
        }
    )
    else {
        if (getPrevilege('Timesheet', props).view) {
            TimesheetInnerContent.push(
                {
                    "id": uniqueid++,
                    "label": "Timesheet Entry",
                    "to": "/timesheet-entry"
                }
            )
            linkToTimesheet = "/timesheet-entry"
            nameTimesheet = "Timesheet"
            iconTimeSheet = "fa fa-calendar"
        }
        if (account.isprojectmanager) {
            TimesheetInnerContent.push(
                {
                    "id": uniqueid++,
                    "label": "Timesheet Review",
                    "to": "/timesheet-review"
                }
            )
            linkToTimesheet = "/timesheet-entry"
            nameTimesheet = "Timesheet"
            iconTimeSheet = "fa fa-calendar"
        }
        if (account.isrm) {
            TimesheetInnerContent.push(
                {
                    "id": uniqueid++,
                    "label": "Timesheet Requests",
                    "to": "/timesheet-requests"
                }
            )
            linkToTimesheet = "/timesheet-requests"
            nameTimesheet = "Timesheet"
            iconTimeSheet = "fa fa-calendar"
        }
        // if (getPrevilege("Timesheet", props).view) content.push(
        //     {
        //         "id": uniqueid++,
        //         "icon": "fa fa-calendar",
        //         "label": "Timesheet",
        //         "to": "/timesheet-entry"
        //     }
        // )
        if (TimesheetInnerContent.length == 1) {
            content.push(
                {
                    "id": uniqueid++,
                    "icon": iconTimeSheet,
                    "label": nameTimesheet,
                    'to': linkToTimesheet,
                }
            )
        } else if (TimesheetInnerContent.length) {
            content.push(
                {
                    "id": uniqueid++,
                    "icon": "fa fa-calendar",
                    "label": "Timesheet",
                    content: TimesheetInnerContent,
                }
            )
        }
    }

    const PolicyInnerContent = [];
    let linkToPolicy = '';
    let namePolicy = '';
    let iconPolicy = '';


    if (getPrevilege('Policy', props).view) {
        PolicyInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Policy",
                "to": "/company-policy"
            }
        )
        linkToPolicy = "/company-policy"
        namePolicy = "Policy"
        iconPolicy = "fa fa-file-text"
    }


    if (getPrevilege('SOP', props).view) {
        PolicyInnerContent.push(
            {
                "id": uniqueid++,
                "label": "SOP",
                "to": "/company-sop"
            }
        )
        linkToPolicy = "/company-sop"
        namePolicy = "SOP"
        iconPolicy = "fa fa-file-text"
    }

    if (PolicyInnerContent.length == 1) {
        content.push(
            {
                "id": uniqueid++,
                "icon": iconPolicy,
                "label": namePolicy,
                'to': linkToPolicy,
            }
        )
    } else if (PolicyInnerContent.length) {
        content.push(
            {
                "id": uniqueid++,
                "icon": "fa fa-file-text",
                "label": "Policy",
                content: PolicyInnerContent,
            }
        )
    }

    if (getPrevilege("Task_Board", props).view) content.push(
        {
            "id": uniqueid++,
            "icon": "fa fa-tasks",
            "label": "Tasks",
            "to": "/project-category"
        }
    )

    const ExpenseInnerContent = [];
    let linkToExpense = '';
    let nameExpense = '';
    let iconExpense = '';


    if (getPrevilege('Expense', props).view) {
        ExpenseInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Expense",
                "to": "/company-expense"
            }
        )
        linkToExpense = "/company-expense"
        nameExpense = "Expense"
        iconExpense = "fa fa-money"
    }


    if (getPrevilege('Expense_Payment', props).view) {
        ExpenseInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Expense Payment",
                "to": "/expense-payment"
            }
        )
        linkToExpense = "/expense-payment"
        nameExpense = "Expense Payment"
        iconExpense = "fa fa-money"
    }

    if (ExpenseInnerContent.length == 1) {
        content.push(
            {
                "id": uniqueid++,
                "icon": iconExpense,
                "label": nameExpense,
                'to': linkToExpense,
            }
        )
    } else if (ExpenseInnerContent.length) {
        content.push(
            {
                "id": uniqueid++,
                "icon": "fa fa-money",
                "label": "Expense",
                content: ExpenseInnerContent,
            }
        )
    }

    const VerificationInnerContent = [];
    let linkToVerification = '';
    let nameVerification = '';
    let iconVerification = '';


    if (getPrevilege('Verification', props).view) {
        VerificationInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Verification",
                "to": "/verification-process"
            }
        )
        linkToVerification = "/verification-process"
        nameVerification = "Verification"
        iconVerification = "fa fa-check-circle"
    }
    if (role != 'admin') {
        VerificationInnerContent.push(
            {
                "id": uniqueid++,
                "label": "My Verification",
                "to": "/my-verifications"
            }
        )
        linkToVerification = "/my-verifications"
        nameVerification = "My Verification"
        iconVerification = "fa fa-check-circle"
    }
    if (getPrevilege('Employee_Verifications', props).view) {
        VerificationInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Employee Verification",
                "to": "/employee-verifications"
            }
        )
        linkToVerification = "/employee-verifications"
        nameVerification = "Employee Verifications"
        iconVerification = "fa fa-check-circle"
    }
    if (VerificationInnerContent.length == 1) {
        content.push(
            {
                "id": uniqueid++,
                "icon": iconVerification,
                "label": nameVerification,
                'to': linkToVerification,
            }
        )
    } else if (VerificationInnerContent.length) {
        content.push(
            {
                "id": uniqueid++,
                "icon": "fa fa-check-circle",
                "label": "Verification",
                content: VerificationInnerContent,
            }
        )
    }

    // if (getPrevilege('Verification', props).view) {
    //     Routes.push({
    //         path: "/verification-process",
    //         name: 'policies',
    //         exact: true,
    //         pageTitle: "Policy",
    //         component: listverification
    //     })
    // }
    // if (getPrevilege('Verification', props).add) {
    //     Routes.push({
    //         path: "/add-verification",
    //         name: 'policies',
    //         exact: true,
    //         pageTitle: "Policy",
    //         component: addverification
    //     })
    // }

    const emailInnerContent = [];
    let linkToEmail = '';
    let nameEmail = '';
    let iconEmail = '';

    if (getPrevilege("Email", props).view) {
        emailInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Inbox",
                "to": "/list-email"
            }
        )
        linkToEmail = "/list-email"
        nameEmail = "Inbox"
        iconEmail = "fa fa-envelope"
    }
    emailInnerContent.push(
        {
            "id": uniqueid++,
            "label": "Sent",
            "to": "/sent"
        }
    )
    emailInnerContent.push(
        {
            "id": uniqueid++,
            "label": "Trash",
            "to": "/trsh-emails"
        }
    )

    if (emailInnerContent.length == 1) {
        content.push(
            {
                "id": uniqueid++,
                "icon": iconEmail,
                "label": nameEmail,
                'to': linkToEmail,
            }
        )
    } else if (emailInnerContent.length) {
        content.push(
            {
                "id": uniqueid++,
                "icon": "fa fa-diamond",
                "label": "Email",
                content: emailInnerContent,
            }
        )
    }


    const setupInnerContent = [];
    let linkToSetup = '';
    let nameSetup = '';
    let iconSetup = '';
    if (getPrevilege('Category', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Categories",
                "to": "/list-category"
            }
        )
        linkToSetup = "/list-category"
        nameSetup = "Categories"
        iconSetup = "icon-bar-chart"
    }
    if (getPrevilege('Country', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Countries",
                "to": "/list-country"
            }
        )
        linkToSetup = "/list-country"
        nameSetup = "Countries"
        iconSetup = "fa fa-flag"
    }
    if (role == 'admin') {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Department",
                "to": "/list-department"
            },
        )
    }
    if (role == 'admin') {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Division",
                "to": "/list-division"
            },
        )
    }
    if (role == 'admin') {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Designation",
                "to": "/list-designation"
            },
        )
    }
    if (role == 'admin') {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Leave Template",
                "to": "/list-leave-template"
            },
        )
    }
    if (role == 'admin') {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Leave Type",
                "to": "/leave-type"
            },
        )
    }
    if (role == 'admin') {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Manage Grades",
                "to": "/list-mange-grades"
            },
        )
    }
    if (getPrevilege('Holidays', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Holidays",
                "to": "/listholiday"
            }
        )
        linkToSetup = "/listholiday"
        nameSetup = "Holidays"
        iconSetup = "fa fa-flag"
    }
    if (getPrevilege('Kycdocument', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Kycdocument",
                "to": "/listkycdocument"
            }
        )
        linkToSetup = "/listkycdocument"
        nameSetup = "Kycdocument"
        iconSetup = "fa fa-flag"
    }
    if (getPrevilege('PayrollItem', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Payroll Item",
                "to": "/listpayrollitem"
            }
        )
        linkToSetup = "/listpayrollitem"
        nameSetup = "payroll Item"
        iconSetup = "fa fa-flag"
    }
    if (getPrevilege('Earning', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Earning",
                "to": "/listearnig"
            }
        )
        linkToSetup = "/listearnig"
        nameSetup = "earning"
        iconSetup = "fa fa-flag"
    }
    if (getPrevilege('Deduction', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Deduction",
                "to": "/listdeduction"
            }
        )
        linkToSetup = "/listdeduction"
        nameSetup = "Deduction"
        iconSetup = "fa fa-flag"
    }
    // if (getPrevilege('Invoice_category', props).view) {
    //     setupInnerContent.push(
    //         {
    //             "id": uniqueid++,
    //             "label": "Invoice Category",
    //             "to": "/listinvoicecategory"
    //         }
    //     )
    //     linkToSetup = "/listinvoicecategory"
    //     nameSetup = "Invoice Category"
    //     iconSetup = "fa fa-flag"
    // }
    // if (getPrevilege('Invoice_subcategory', props).view) {
    //     setupInnerContent.push(
    //         {
    //             "id": uniqueid++,
    //             "label": "Invoice Subcategory",
    //             "to": "/listinvoicesubcategory"
    //         }
    //     )
    //     linkToSetup = "/listinvoicesubcategory"
    //     nameSetup = "Invoice Subcategory"
    //     iconSetup = "fa fa-flag"
    // }
    // if (getPrevilege('Invoice_detail', props).view) {
    //     setupInnerContent.push(
    //         {
    //             "id": uniqueid++,
    //             "label": "Invoice Detail",
    //             "to": "/listinvoicedetail"
    //         }
    //     )
    //     linkToSetup = "/listinvoicedetail"
    //     nameSetup = "Invoice Detail"
    //     iconSetup = "fa fa-flag"
    // }
    // if (getPrevilege('Invoice_number', props).view) {
    //     setupInnerContent.push(
    //         {
    //             "id": uniqueid++,
    //             "label": "Invoice Number",
    //             "to": "/listinvoicenumber"
    //         }
    //     )
    //     linkToSetup = "/listinvoicenumber"
    //     nameSetup = "Invoice Number"
    //     iconSetup = "fa fa-flag"
    // }

    if (getPrevilege('Asset_category', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Asset Category",
                "to": "/listassetcategory"
            }
        )
        linkToSetup = "/listassetcategory"
        nameSetup = "Invoice Category"
        iconSetup = "fa fa-flag"
    }
    if (getPrevilege('Asset_subcategory', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Asset Subcategory",
                "to": "/listassetsubcategory"
            }
        )
        linkToSetup = "/listassetsubcategory"
        nameSetup = "Asset Subcategory"
        iconSetup = "fa fa-flag"
    }

    if (getPrevilege('Expense_Category', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Expense Category",
                "to": "/expensecategory"
            }
        )
        linkToSetup = "/expensecategory"
        nameSetup = "Expense Category"
        iconSetup = "fa fa-flag"
    }
    if (getPrevilege('Employee_Category', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Employee Category",
                "to": "/employee-category"
            }
        )
        linkToSetup = "/employee-category"
        nameSetup = "Employee Category"
        iconSetup = "fa fa-flag"
    }
    if (getPrevilege('Tax', props).view) {
        setupInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Tax",
                "to": "/list-tax"
            }
        )
        linkToSetup = "/list-tax"
        nameSetup = "Tax"
        iconSetup = "fa fa-flag"
    }

    if (setupInnerContent.length == 1) {
        content.push(
            {
                "id": uniqueid++,
                "icon": iconSetup,
                "label": nameSetup,
                'to': linkToSetup,
            }
        )
    } else if (setupInnerContent.length) {
        content.push(
            {
                "label": "Master",
                id: 'Master',
                to: '#'
            },
            {
                "id": uniqueid++,
                "icon": "fa fa-flag",
                "label": "Setup",
                content: setupInnerContent,
            }
        )
    }
    const payrollInnerContent = [];
    let linkToPayroll = '';
    let namePayroll = '';
    let iconPayroll = '';

    if (getPrevilege('Leave_Transection', props).view) {
        payrollInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Leave Transection",
                "to": "/leavetransection"
            }
        )
        linkToPayroll = "/leavetransection"
        namePayroll = "Leave Transection"
        iconPayroll = "icon-bar-chart"
    }
    if (getPrevilege('Monthly_payroll', props).view) {
        payrollInnerContent.push(

            {
                "id": uniqueid++,
                "label": "Monthly Payroll",
                "to": "/monthlypayroll"
            }
        )
        linkToPayroll = "/monthlypayroll"
        namePayroll = "Monthly Payroll"
        iconPayroll = "icon-bar-chart"
    }


    // ==============
    const invoiceInnerContent = [];
    let linkToInvoice = '';
    let nameInvoice = '';
    let iconInvoice = '';
    let tokenStatus = props.AccountState.invoicestatus
    var invoicetoken = localStorage.getItem("invoicetoken");
    if (getPrevilege('Invoice', props).view) {
        invoiceInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Invoice",
                "to": tokenStatus || invoicetoken ? '/listinvoice' : "/invoicepwd?redirect=listinvoice"
            },
            {
                "id": uniqueid++,
                "label": "Client",
                "to": tokenStatus || invoicetoken ? "/clients-list" : "/invoicepwdClient?redirect=clients-list"
            },

        )
        linkToInvoice = "/invoicepwd"
        nameInvoice = "Invoice"
        iconInvoice = "icon-bar-chart"
    }
    if (getPrevilege('Invoice_category', props).view) {
        invoiceInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Invoice Category",
                "to": tokenStatus || invoicetoken ? '/listinvoicecategory' : "/invoicepwd?redirect=listinvoicecategory"
            }
        )
        linkToInvoice = "/listinvoicecategory"
        nameInvoice = "Invoice Category"
        iconInvoice = "fa fa-flag"
    }
    if (getPrevilege('Invoice_subcategory', props).view) {
        invoiceInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Invoice Subcategory",
                "to": tokenStatus || invoicetoken ? '/listinvoicesubcategory' : "/invoicepwd?redirect=listinvoicesubcategory"
            }
        )
        linkToInvoice = "/listinvoicesubcategory"
        nameInvoice = "Invoice Subcategory"
        iconInvoice = "fa fa-flag"
    }
    if (getPrevilege('Invoice_detail', props).view) {
        invoiceInnerContent.push(
            {
                "id": uniqueid++,
                "label": "Invoice Detail",
                "to": tokenStatus || invoicetoken ? '/listinvoicedetail' : "/invoicepwd?redirect=listinvoicedetail"
            }
        )
        linkToInvoice = "/listinvoicedetail"
        nameInvoice = "Invoice Detail"
        iconInvoice = "fa fa-flag"
    }
    if (payrollInnerContent.length || invoiceInnerContent.length) {
        content.push(
            {
                "id": 'HRID',
                "label": "HR",
                to: '#'
            },
        )
    }

    if (payrollInnerContent.length == 1) {
        content.push(
            {
                "id": uniqueid++,
                "icon": iconPayroll,
                "label": namePayroll,
                'to': linkToPayroll,
            }
        )
    } else if (payrollInnerContent.length) {
        content.push(

            {
                "id": uniqueid++,
                "icon": "fa fa-diamond",
                "label": "Payroll",
                content: payrollInnerContent,
            }
        )
    }

    if (invoiceInnerContent.length == 1) {
        content.push(

            {
                "id": uniqueid++,
                "icon": iconInvoice,
                "label": nameInvoice,
                'to': linkToInvoice,
            }
        )
    } else if (invoiceInnerContent.length) {
        content.push(
            {
                "id": uniqueid++,
                "icon": "icon-bar-chart",
                "label": "Invoice",
                content: invoiceInnerContent,
            }
        )
    }

    return content;
}

function getPrevilege(name, props) {
    const { AccountState } = props;
    const previleges = AccountState.previleges;
    const Previlege = previleges ? previleges.find(obj => { return obj.name == name }) : {};
    return Previlege ? Previlege : {};
}