const initialState = {
    listholiday: [],
    holiday: {
        name: '',
        date: '',
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LIST_HOLIDAY':
            return {
                ...state,
                listholiday: action.payload,
            }
            break;
        case 'VIEW_HOLIDAY':
            return {
                ...state,
                holiday: action.payload,
            }
        case 'RESET_HOLIDAY':
            return Object.assign({}, state, {
                holiday: initialState.holiday
            })
        case 'HANDLE_HOLIDAY_CHANGE':
            return Object.assign({}, state, {
                holiday: {
                    ...state.holiday,
                    [action.name]: action.value
                }
            })
        default:
            return state;
    }
}