import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_VIEW_INVOICE, AC_SPINNER_INVOICE_ALL } from '../../actions/invoiceAction';
import ImportedURL from '../../common/api';
import moment from 'moment';
import { Currency } from '../../common/validate';
import Spinner from 'react-bootstrap/Spinner';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";

class Invoicegenerate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfGenerate: false
        }
    }
    printFun = () => {
        window.print();
    }
    componentDidMount() {
        this.props.ViewInvoice(this.props.match.params.id);
        this.props.AC_SPINNER_INVOICE_ALL();
    }
    generatePDF = async () => {
        this.setState({ pdfGenerate: true })
        // var doc = new jsPDF('p', 'pt', 'A4','mm','letter');
        var doc = new jsPDF('p', 'pt', 'letter');

        const content = document.getElementById('print_page')

        doc.html(content, {
            async callback(doc) {
                await doc.save('arkashya');
            },
            margin: [10, 0, 10, 12],
            html2canvas: { scale: 0.465 }
            // html2canvas: {
            //     allowTaint: true,
            //     dpi: 300,
            //     letterRendering: true,
            //     logging: false,
            //     scale: 0.580
            // }
        });

    }

    render() {
        const { invoiceState } = this.props;
        const data = invoiceState.invoice;
        const spinner = invoiceState.spinner;
        const d = new Date(data.invoicedate);
        let year = d.getFullYear();
        const Month = d;
        var discountAmount = 0;
        discountAmount = Number(data.amount) - Number(data.discount)
        let invoicepdfSave = []
        if (data.invoiceId) {
            for (let i = 0; i < data.invoiceId.length; i++) {
                invoicepdfSave.push(
                    <span>
                        {data.invoiceId[i]}
                    </span>
                )
            }
        }
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6>Invoice</h6></p>
                </div>

                <div className='download_options'>
                    <div className="header-action" style={{ marginTop: '5px' }}>
                        <Link to='/listinvoice'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                    </div>
                    <nav aria-label="Page navigation example" >
                        <ul class="pagination" style={{ marginRight: "150px", marginTop: "10px" }}>
                            <li class="page-item"><a class="page-link" href="#" onClick={this.generatePDF}>PDF</a></li>
                            <li class="page-item"><a class="page-link" href="#" onClick={this.printFun}> <span><i class="fa fa-print" aria-hidden="true"></i></span> Print</a></li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">Invoice generate</h3>
                                        </div>
                                        <div className='card-body invoice_generate' id='print_page'>
                                            <div className='row print_design' >
                                                <div className='payslip_content'>
                                                    <p className='payslip_heading'>
                                                        INVOICE FOR THE MONTH OF  {moment(Month).format('MMMM').toUpperCase() + " " + year}
                                                    </p>
                                                </div>
                                                <div className='col-lg-8'>
                                                    <div className='invoice_address'>
                                                        <h4>{data.companyname}</h4>
                                                        <p>{data.companyaddress}</p>
                                                        <p>{data.companycity}</p>
                                                        <p>{data.companystate + " " + data.code}</p>
                                                        <a href=''>{data.email}</a>
                                                        <p className='pt-1'>
                                                            <a href={'tel:' + data.companyphonenumber}>{data.companyphonenumber}</a>
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='payslip_content'>
                                                        <div className='payslip_company_intro'>
                                                            <div className='company_logo'>
                                                                <img src={ImportedURL.LIVEURL + data.companyimage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='invoice_detail_billto print_design mt-3'>
                                                <div className='row'>
                                                    <div className='col-lg-6 '>
                                                        <h4 className='invoice_detail_billto_heading'>BILL TO</h4>
                                                        <p className='tocompname'>{data.tocompany}</p>
                                                        <p>{data.toaddress}</p>
                                                    </div>
                                                    <div className='col-lg-6 '>
                                                        <h4 className='invoice_detail_billto_heading inv_det'>INVOICE DETAIL</h4>
                                                        <div className='detslin'>
                                                            <p className='invoice_heading_tex'>Invoice #</p>
                                                            <p className='dynaic_tex_invoice'>{data.invoiceId}</p>
                                                        </div>
                                                        <div className='detslin mt-2'>
                                                            <p className='invoice_heading_tex'>Invoice Date</p>
                                                            <p className='dynaic_tex_invoice'>{moment(data.invoicedate).format('MM-DD-YYYY')}</p>
                                                        </div>
                                                        <div className='detslin mt-2'>
                                                            <p className='invoice_heading_tex'>Due Date</p>
                                                            <p className='dynaic_tex_invoice_due'>{moment(data.duedate).format('MM-DD-YYYY')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='invoice_detail print_design mt-5'>
                                                <div className='table_invoice'>
                                                    <div className='row tab_inv'>
                                                        <div className='col-lg-5 col_border_right'>
                                                            <h6 className='desc_h6'>DESCRIPTION</h6>
                                                            {data.categoryData && data.categoryData.map((item) => {
                                                                return (
                                                                    <>
                                                                        <h5 className='desc_h5'>{item.name}</h5>
                                                                        <>
                                                                            {
                                                                                (data.subcategoryData)
                                                                                &&
                                                                                data.subcategoryData.map((item1) => {
                                                                                    if (item._id == item1.category)
                                                                                        return (
                                                                                            <>
                                                                                                <p className='desc_p'>{item1.subcategory}</p>
                                                                                            </>
                                                                                        )
                                                                                })
                                                                            }
                                                                        </>

                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className='col-lg-2 col_border_right'>
                                                            <h6 className='desc_h6'> MONTH</h6>
                                                            <p className='next_tab_div'>{moment(Month).format('MMMM') + " - " + moment(data.invoicedate).format('YYYY')}</p>
                                                        </div>
                                                        <div className='col-lg-3 col_border_right'>
                                                            <h6 className='desc_h6'>URL</h6>
                                                            <a href={data.url} className='next_tab_div' target='_blank'>{data.url}</a>
                                                        </div>
                                                        <div className='col-lg-2 col_border_right'>
                                                            <h6 className='desc_h6'>TOTAL</h6>
                                                            <p className='next_tab_div'>{Currency((data.amount).toString())}</p>

                                                        </div>
                                                    </div>
                                                    <div className='invoice_dis print_subtotal'>
                                                        <div className='row'>
                                                            <div className='col-lg-10'>
                                                                <p className='dis_Name'>
                                                                    Multiple  Property  Discount  Applied
                                                                </p>

                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <p className='Disc_amount'>{data.discount ? " - " + Currency((data.discount).toString()) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='invoice_dis subtotal_invoice'>
                                                        <div className='row justify-content-end' style={{ border: "none" }}>
                                                            <div className='col-lg-2'>
                                                                <p className='dis_Name'>
                                                                    SUB TOTAL
                                                                </p>

                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <p className='Disc_amount'>{discountAmount ? Currency((discountAmount).toString()) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='invoice_dis subtotal_invoice'>
                                                        <div className='row justify-content-end' style={{ border: "none" }}>
                                                            <div className='col-lg-2'>
                                                                <p className='balance_name_invoice'>
                                                                    BALANCE DUE
                                                                </p>

                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <p className='Disc_amount current_amount_invoice'><i class="fa fa-usd" aria-hidden="true"></i>{discountAmount ? Currency((discountAmount).toString()) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {
                                                this.state.pdfGenerate
                                                    ?
                                                    <>
                                                        <p className='Hello_Inoice_Span'><span>I</span><span>N</span><span>V</span><span>O</span><span>I</span><span>C</span><span>E </span><span>#</span>{invoicepdfSave}</p>
                                                    </>
                                                    :
                                                    <>
                                                        <p className="Invoice_ID">INVOICE #{data.invoiceId ? data.invoiceId : ''}</p>
                                                    </>
                                            } */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                {spinner &&
                    <div className="common_loader">
                        <img className='loader_img_style_common' src='../../assets/images/banner-logo.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    invoiceState: state.invoice,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ViewInvoice: AC_VIEW_INVOICE,
            AC_SPINNER_INVOICE_ALL
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoicegenerate);