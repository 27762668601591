const initialState = {
    listkycdocument: [],
    kycdocument: {
        name: '',
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LIST_KYC':
            return {
                ...state,
                listkycdocument: action.payload,
            }
            break;
        case 'VIEW_KYC':
            return {
                ...state,
                kycdocument: action.payload,
            }
        case 'RESET_KYC':
            return Object.assign({}, state, {
                kycdocument: initialState.kycdocument
            })
        case 'HANDLE_KYC_CHANGE':
            return Object.assign({}, state, {
                kycdocument: {
                    ...state.kycdocument,
                    [action.name]: action.value
                }
            })
        default:
            return state;
    }
}