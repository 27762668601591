import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import ImportedUrl from '../../common/api';
import { Success, Error } from '../../common/swal';

const initialState = {
  password: '',
  newpassword: '',
  confirmpassword: '',
  passwordError: false,
  newpasswordError: false,
  confirmpasswordError: false,
  eye: false,
  eyeCon: false
}
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  onChange = e => {
    const { name, value } = e.target;
    const Error = name + "Error";
    this.setState({ [Error]: false, [name]: value });
    if (name === 'newpassword') {
      var newPasswordValue = value;
      if (newPasswordValue) { this.setState({ [Error]: false, [name]: value }); } else { this.setState({ [Error]: true }); }
    }
    if (name === 'confirmpassword') {
      var confirmPasswordValue = value;
      if (confirmPasswordValue) { this.setState({ [Error]: false, [name]: value }); } else { this.setState({ confirmpasswordError: "Confirm Password is required" }); }
    }
  }
  submit = (e) => {
    const { newpassword, password, confirmpassword } = this.state;
    // if (!password) this.setState({ passwordError: true });
    if (!newpassword) this.setState({ newpasswordError: true });
    if (!confirmpassword) this.setState({ confirmpasswordError: "Confirm Password is required" });
    if (newpassword !== confirmpassword) this.setState({ confirmpasswordError: "Password doesn't match" });
    if (newpassword && (newpassword === confirmpassword)) {
      const formData = { newpassword: newpassword }
      axios.post(ImportedUrl.API.changePassword, formData)
        .then((res) => {
          Success(res.statusText);
          this.setState(initialState)
        }).catch(({ response }) => { if (response) Error(response.statusText) });
    } else {
    }
  }
  viewClick = () => {
    this.setState({ eye: !this.state.eye })
  }
  viewClickCon = () => {
    this.setState({ eyeCon: !this.state.eyeCon })
  }
  render() {
    // const { fixNavbar } = this.props;
    return (
      <>
        <div className="section-body pt-3">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <div className='rounded_icon'><i class="fa-solid fa-key mr-2"></i></div>
                    <h3 className="card-title">Change Password</h3>
                  </div>
                  <div className="card-body">
                    {/* <form>
                      <div className="form-group">
                        <label className="form-label">New Password<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                        <input type='password' className="form-control" placeholder="New Password" name={'newpassword'} onChange={this.onChange} value={this.state.newpassword} />
                        <div className="invalid-feedback" style={{ display: this.state.newpasswordError ? "block" : 'none' }}>Password is required</div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Confirm New Password<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                        <input type='password' className="form-control" placeholder="Confirm New Password" name={'confirmpassword'} onChange={this.onChange} value={this.state.confirmpassword} />
                        <div className="invalid-feedback" style={{ display: this.state.confirmpasswordError ? "block" : 'none' }}>{this.state.confirmpasswordError}</div>
                      </div>
                    </form> */}

                    <form>
                      <div className="form-group ">
                        <label className="form-label">New Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                        <div className='site_pass'>
                          <input type={this.state.eye ? 'text' : 'password'} className="form-control" placeholder="New Password" name={'newpassword'} onChange={this.onChange} value={this.state.newpassword} />
                          <i class={this.state.eye ? "fa fa-eye"  : "fa fa-eye-slash"}  data-toggle="tooltip" title={this.state.eye ? "Hide" :"Unhide"} style={{ cursor: 'pointer', fontSize: '20px' }} onClick={this.viewClick}></i>
                        </div>
                        <div className="invalid-feedback" style={{ display: this.state.newpasswordError ? "block" : 'none' }}>Password is required</div>
                      </div>
                      <div className="form-group site_pass">
                        <label className="form-label">Confirm Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                        <div className='site_pass'>
                          <input type={this.state.eyeCon ? 'text' : 'password'} className="form-control" placeholder="Confirm  Password" name={'confirmpassword'} onChange={this.onChange} value={this.state.confirmpassword} />
                          <i class={this.state.eyeCon ? "fa fa-eye" : "fa fa-eye-slash"}  data-toggle="tooltip" title={this.state.eyeCon ? "Hide" :"Unhide"} style={{ cursor: 'pointer', fontSize: '20px' }} onClick={this.viewClickCon}></i>
                        </div>
                        <div className="invalid-feedback" style={{ display: this.state.confirmpasswordError ? "block" : 'none' }}>{this.state.confirmpasswordError}</div>
                      </div>
                    </form>

                  </div>
                  <div className="card-footer text-right mandatory">
                    <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                    <div className="text-right">
                      <div className="text-right">
                        <button type="submit" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);