import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';



const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
const LIST_EMPLOYEE = 'LIST_EMPLOYEE';
const VIEW_EMPLOYEE = 'VIEW_EMPLOYEE';
const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
const RESET_EMPLOYEE = 'RESET_EMPLOYEE';



export function AC_LIST_EMPLOYEE(params = {}) {
    return function (dispatch) {
        axios.get(ImportedURL.API.listEmployee, { params: params })
            .then(({ data }) => {
                dispatch({ type: LIST_EMPLOYEE, payload: data });
            })
    }
}

export function AC_LIST_HR(params = {}) {
    return function (dispatch) {
        axios.get(ImportedURL.API.listEmployeeHr, { params: params })
            .then(({ data }) => {
                dispatch({ type: "LIST_HR", payload: data });
            })
    }
}
export function AC_LIST_EMPLOYEE_ROLE(params = {}) {
    return function (dispatch) {
        axios.get(ImportedURL.API.listEmployeeManager, { params: params })
            .then(({ data }) => {
                dispatch({ type: "LIST_EMPLOYEE_ROLE", payload: data });
            })
    }
}
// export function AC_HANDLE_EMPLOYEE_SEARCH(value) {
//     return function (dispatch) {
//         dispatch({ type: "HANDLE_EMPLOYEE_SEARCH", value: value })
//     };
// }
export function AC_VIEW_EMPLOYEE(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewEmployee + "/" + id)
            .then(({ data }) => {
                dispatch({ type: VIEW_EMPLOYEE, payload: data });
            });
    }
}

export function AC_RESET_EMPLOYEE() {
    return function (dispatch) {
        dispatch({ type: "RESET_EMPLOYEE" })
    };
}

export function AC_HANDLE_EMPLOYEE_INFO_CHANGE(name, value, i) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMPLOYEE_INFO_CHANGE", name: name, value: value, i: i })
    };
}
export function AC_HANDLE_EMPLOYEE_INFO_MULTIPLE_UPDATE(name, value, id) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMPLOYEE_INFO_MULTIPLE_UPDATE", name: name, value: value, id: id })
    };
}

export function AC_HANDLE_EDUCATION_INFO_CHANGE(name, value, i) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EDUCATION_INFO_CHANGE", name: name, value: value, i: i })
    };
}
export function AC_HANDLE_EDUCATION_INFO_MULTIPLE_UPDATE(name, value, id) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EDUCATION_INFO_MULTIPLE_UPDATE", name: name, value: value, id: id })
    };
}

export function AC_HANDLE_EXPERIENCE_INFO_CHANGE(name, value, i) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EXPERIENCE_INFO_CHANGE", name: name, value: value, i: i })
    };
}
export function AC_HANDLE_EXPERIENCE_INFO_MULTIPLE_UPDATE(name, value, id) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EXPERIENCE_INFO_MULTIPLE_UPDATE", name: name, value: value, id: id })
    };
}

export function AC_HANDLE_EMPLOYEE_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_EMPLOYEE_CHANGE", name: name, value: value })
    };
}


