const initialState = {
    listinvoicecdetail: [],
    invoicedetail: {
        name: '',
        image: '',
        address: '',
        city: '',
        state: '',
        code: '',
        email: [],
        cc:'',
        nametype:'',
        phonenumber: '',

    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_INVOICE_DETAIL_CHANGE':
            return Object.assign({}, state, {
                invoicedetail: {
                    ...state.invoicedetail,
                    [action.name]: action.value
                }
            })
        case 'RESET_INVOICE_DETAIL':
            return Object.assign({}, state, {
                invoicedetail: initialState.invoicedetail,
            })
        case 'LIST_INVOICE_DETAIL':
            return {
                ...state,
                listinvoicecdetail: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_INVOICE_DETAIL':
            return Object.assign({}, state, {
                invoicedetail: action.payload,
                spinner: false,
            })

        case 'EMPTY_INVOICE_DETAIL':
            return Object.assign({}, state, {
                invoicedetail: {
                    name: '',
                    image: '',
                    address: '',
                    city: '',
                    state: '',
                    code: '',
                    email: [],
                    cc:'',
                    nametype:'',
                    phonenumber: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}