const initialState = {
    listLeaves: [],
    leaves: {
        employee: '',
        leavetype: '',
        from: '',
        to: '',
        numberofdays: '',
        remainingleaves: '',
        reason: '',
    },
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_LEAVES_CHANGE':
            return Object.assign({}, state, {
                leaves: {
                    ...state.leaves,
                    [action.name]: action.value
                }
            })
        case 'RESET_LEAVES':
            return Object.assign({}, state, {
                leaves: initialState.leaves,
            })
        case 'LIST_LEAVES':
            return {
                ...state,
                listLeaves: action.payload,
                spinner: action.spinner
            }
        case 'VIEW_LEAVES':
            return Object.assign({}, state, {
                leaves: action.payload,
                spinner: false,
            })

        case 'AC_EMPTY_LEAVES':
            return Object.assign({}, state, {
                leaves: {
                    employee: '',
                    leavetype: '',
                    from: '',
                    to: '',
                    numberofdays: '',
                    remainingleaves: '',
                    reason: '',
                },
                spinner: true
            })
        default:
            return state;
    }
}