import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Success, Error } from '../../common/swal';
import axios from 'axios';
import ImportedUrl from '../../common/api';
import Carousel from 'react-bootstrap/Carousel';


export default class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			emailError: false,
		}
	}
	onChange = e => {
		const { name, value } = e.target;
		const Error = name + "Error";
		this.setState({ [Error]: false, [name]: value });
	}
	submit = (e) => {
		e.preventDefault()
		const { email } = this.state;
		if (!email) this.setState({ emailError: true });
		if (email) {
			const formData = { email: email }
			axios.post(ImportedUrl.API.forgotPassword, formData)
				.then((res) => {
					Success(res.statusText);
					setTimeout(() => { window.location.href = "/" }, 2000);
				}).catch(({ response }) => { Error(response.statusText) });
		}
	}
	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center mb-5">
							<Link className="header-brand login_log" to="/">
								<img className="avatar" src="../assets/images/banner-logo.png" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title">Forgot password</div>
							<p className="text-muted">
								Enter your email address and your password will be reset and emailed to you.
							</p>
							<div className="form-group">
								<label className="form-label" htmlFor="exampleInputEmail1">
									Email address
								</label>
								<input
									type="email"
									className="form-control"
									id="exampleInputEmail1"
									aria-describedby="emailHelp"
									placeholder="Enter email"
									onChange={this.onChange}
									name="email"
								/>
								<div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
							</div>
							<div className="form-footer">
								<button className="btn btn-primary btn-block" onClick={this.submit}>Send</button>
								{/* <Link className="btn btn-primary btn-block" to="/login" >
								</Link> */}
							</div>
						</div>
						<div className="text-center text-muted">
							Forget it, <Link to="/login">Send me Back</Link>
						</div>
					</div>
				</div>
				<div className="auth_right">
					<Carousel>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider1.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Effective Property Maintenance simplified with myhotelai.com</h5>
								<p>Manage the day-to-day operations of your hotels with ease using AI-supported software.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider2.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Managing multiple hotels? myhotelai.com is here to assist you.</h5>
								<p>Ensuring the flawless operation of multiple hotels at the same time could be challenging.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider3.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Track and monitor the performance of your hotels with real-time data.</h5>
								<p>Enhance the efficiency and profitability of your hotels by gauging their <br /> performance with accurate, auto-fetched data.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider4.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Fetch accurate data on the effectiveness of ticket resolution in your hotels</h5>
								<p>Catalyze the ticket resolution process with an automated workflow.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider5.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Assess the performance of your assets accurately with periodic reports.</h5>
								<p>Build a culture of data-based decision-making to give your customers a great stay.</p>
							</div>
						</Carousel.Item>
					</Carousel>
				</div>
			</div>
		);
	}
}
