import axios from 'axios';
import ImportedURL from '../common/api';
import Swal from 'sweetalert2';
import { Success, Error } from '../common/swal';
import { Link, Redirect } from 'react-router-dom';


// const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
// const LIST_DEPARTMENT = 'LIST_DEPARTMENT';
// const VIEW_DEPARTMENT = 'VIEW_DEPARTMENT';

export function AC_ADD_DIVISION(data) {
    return function (dispatch) {
        axios.post(ImportedURL.API.addDepartment,data)
            .then((data) => {
                Success('Added successfully');
                dispatch({ type: "ADD_DIVISION", payload: data});
				// window.location='/listinventory'
            }).catch(function (error) {
                if (error) {
                    Error('Data already exists')
                }
            });
    }
}

export function AC_LIST_DIVISION() {
    return function (dispatch) {
        axios.get(ImportedURL.API.listDivision)
            .then(({ data }) => {
                dispatch({ type: "LIST_DIVISION", payload: data });
            })
    }
}

export function AC_VIEW_DIVISION(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewDivision + "/" + id)
            .then(({ data }) => { 
                dispatch({ type: "VIEW_DIVISION", payload: data });
            });
    }
}

export function AC_RESET_DIVISION() {
	return function (dispatch) {
		dispatch({ type: "RESET_DIVISION" })
	};
}

export function AC_HANDLE_DIVISION_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_DIVISION_CHANGE", name: name, value: value })
    };
}



