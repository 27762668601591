import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import { AC_HANDLE_EMPLOYEE_SALARY_CHANGE, AC_LIST_EMPLOYEE_SALARY, AC_VIEW_EMPLOYEE_SALARY, AC_RESET_EMPLOYEE_SALARY, AC_LIST_EMPLOYEE_PAYROLL__SALARY, AC_HANDLE_PAYROLL_STATUS_CHANGE, AC_HANDLE_EMPLOYEE_SALARY_SEARCH } from '../../../actions/employeesalaryAction';
import { AC_LIST_EMPLOYEE, } from '../../../actions/employeeAction';
import { AC_LIST_PAYROLL_ITEM } from '../../../actions/payrollitemAction';
import moment from 'moment';
import { AC_HANDLE_LEAVE_TRANSECTION_CHANGE, AC_LIST_LEAVE_TRANSECTION, AC_VIEW_LEAVE_TRANSECTION, AC_RESET_LEAVE_TRANSECTION } from '../../../actions/leavetransectionAction';




const initialState = {
    id: '',
    empid: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    viewFlag: false,
    flagError: false,
    setChecked: '',
    pushValue: '',
    SearchValue: '',
    role: '',
    leave: '',
    empname: '',
    fromdate: '',
    basicValue: '',
    spealValue: '',
    pfValue: '',
    todate: '',
    isFilterShow: false,
    columnDefs: [
        { headerName: 'Name', field: 'employeeName', width: 130, floatingFilter: true },
        {
            headerName: 'Image', field: 'image', width: 100, filter: false, cellStyle: { textAlign: 'center' },
            cellRendererFramework: function (params) {
                return <div>
                    <img style={{ width: '40px', height: '40px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + params.data.image} alt="" />
                </div>
            }
        },
        {
            headerName: 'Role', field: 'role', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Employee ID', field: 'employeeId', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Email', field: 'email', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Salary', field: 'salary', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Date', field: 'joindate', width: 130, floatingFilter: true,
            cellRenderer: function (params) {
                return moment(params.data.joindate).format('DD/MM/YYYY')
            }
        },

        {
            headerName: 'View CTC', width: 150, cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return `<button type="button" class="btn payslip_btn" data-action-type="Payslip">
               Genarate CTC
              </button>`;
            }
        },
        {
            headerName: 'Status', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class PayrollItem extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.props.resetEmployeesalary();
        this.props.listEmppayrollitem();

    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.props.resetEmployeesalary();
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.viewEmployeesalary(event.data._id);

        }
        if (value === 'Payslip') {
            this.setState({ id: event.data._id, viewRedirect: true });

        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteEmployeesalary + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            this.props.listEmployeesalary();
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'employeesalaries' })
                        .then((data) => {
                            this.props.listEmployeesalary();
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'salary', 'itemvalue'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { employeesalaryState } = this.props;
        const exportData = employeesalaryState.listemployeesalary
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Employee Details";
        const headers = [['#', 'Name', 'Salary', 'Payroll Item']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.name,
            elt.salary,
            elt.itemvalue
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("EmployeeSalary.pdf")
    }


    componentDidMount() {
        this.props.listEmployeesalary();
        this.props.listEmployee();
        this.props.listPayrollitem();
        this.props.listEmppayrollitem();
        this.props.listleavetransection();
        localStorage.setItem('invoicetoken', "");
    }
    onChange = (e) => {
        const { leavetransectionState } = this.props;
        const listLeavestransection = leavetransectionState.listLeavestransection;
        this.setState({ spinner: false })
        const { name, value, netsalary } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.handleChange(name, value);
        this.props.handleChange("salary", netsalary);
    }
    handleChangesalary = (e) => {
        this.setState({ spinner: false })
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false, basicValue: value / 2, spealValue: value });
        this.props.handleChange(name, value);
    }
    submit = () => {
        const { leavetransectionState } = this.props;
        const listLeavestransection = leavetransectionState.listLeavestransection;

        const data = this.props.employeesalaryState.employeesalary;
        const listpayrollitem = this.props.payrollitemState.listpayrollitem;
        let specialvalue = 0
        {
            (data.itemvalue != undefined && data.itemvalue.length > 0) &&
                data.itemvalue.map((item, i) => {
                    if (item.itemstatus === true) {
                        let value = listpayrollitem.find((e) => { return e._id == item.name })
                        if (value) {
                            specialvalue += Number(value.amount)
                        }
                    }
                })
        }
        if (data.salary) {
            specialvalue = specialvalue + Math.round(data.salary / 2)
            specialvalue = specialvalue + Math.round(data.salary / 4)
            specialvalue = specialvalue + Math.round(data.salary && (data.salary / 2) < 21001 ? 584 : 0)
        }
        let specialvalCount = 0;
        specialvalCount = Math.round(data.salary) - Math.round(specialvalue)
        if (!data.name) {
            this.setState({ nameError: true });
        }
        if (!data.salary) {
            this.setState({ salaryError: true });
        }
        let payrollitemValue = [];
        data['basicsalary'] = data.salary ? data.salary / 2 : " ";
        data['hra'] = data.salary ? data.salary / 4 : ' ';
        data['statutorybonus'] = data.salary && data.salary < 21001 ? 584 : 0;
        data['specialallowance'] = specialvalCount ? specialvalCount : 0;
        data['esiepmloyer'] = data.salary && data.salary < 21001 ? data.salary * 3.25 / 100 : 0;
        data['esi'] = data.salary && data.salary < 21001 ? data.salary * 0.0075 : 0;
        // data['professionaltax'] = this.state.spealValue < 75000 ? 208 : 0;
        data['professionaltax'] = this.state.spealValue ? 208 : 0;

        data['gratuity'] = data.salary ? data.basicsalary * 15 / 26 / 12 : 0;

        let pf = 0;
        pf = (data.salary) - data.hra - data.statutorybonus;
        data['pf'] = pf > 15000 ? 15000 * 0.12 : pf * 0.12;
        let pfEmployer = 0;
        pfEmployer = (this.state.spealValue) - data.hra - data.statutorybonus;
        data['pfemployer'] = pfEmployer > 15000 ? 15000 * 0.13 : pfEmployer * 0.13;
        let salaryValue = '';
        listLeavestransection.length && listLeavestransection.map(item => {
            if (item._id === data.name) {
                salaryValue = item.netsalary
            }
        })
        var formdata = {
            name: data.name,
            salary: data.salary,
            itemvalue: data.itemvalue,
            basicsalary: data.basicsalary,
            hra: data.hra,
            statutorybonus: data.statutorybonus,
            specialallowance: data.specialallowance,
            pf: data.pf,
            esi: data.esi,
            professionaltax: data.professionaltax,
            esiepmloyer: data.esiepmloyer,
            gratuity: data.gratuity,
            pfemployer: data.pfemployer,

        }

        if (formdata.name && formdata.salary && formdata.itemvalue) {
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addEmployeesalary, formdata)
                    .then((formdata) => {
                        Success(formdata.statusText);
                        this.setState({ save: false });
                        this.props.listEmployeesalary();
                        this.props.resetEmployeesalary();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateEmployeesalary + '/' + this.state.id, formdata)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.listEmployeesalary();
                        this.props.resetEmployeesalary();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }
    ItemvalueOnchange = (e) => {
        this.setState({ spinner: false })
        const { name, value } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.handleChange(name, value);
    }
    ItemStatusChange = (i, e) => {
        const { name, checked } = e.target;
        this.props.handlePayrollstatus(checked, i);
    }
    handleSearch = (e) => {
        var value = e.target.value;
        this.setState({ SearchValue: value })
        const rowData = this.props.employeesalaryState.listEmpfilter;
        this.setState({ isFilterShow: true });
        if (value) {
            if (rowData != undefined && rowData.length > 0) {
                let data = []
                rowData.map((item) => {
                    if (item.employeeName.toLowerCase().includes(value.toLowerCase())) {
                        data.push(item)
                    }
                }
                )

                this.props.handlesearch(data)
            }
            else {
                this.props.handlesearch([])

            }
        }
        else {
            this.props.listEmployeesalary()
        }

    }
    handleSelect = (e) => {
        var value = e.value;
        const rowData = this.props.employeesalaryState.listEmpfilter;
        this.setState({ role: value })
        this.setState({ isFilterShow: true });
        if (value) {
            if (rowData != undefined && rowData.length > 0) {
                const data = rowData.filter((item) =>
                    item.role.toLowerCase().includes(value.toLowerCase())
                )
                this.props.handlesearch(data)
            }
            else {
                this.props.handlesearch([])

            }
        }
        else {
            this.props.listEmployeesalary()
        }
    }
    handlesSelectdate = (e) => {
        var { value, name } = e.target;
        let newtime = new Date(e.target.value);
        const result = this.props.employeesalaryState.listEmpfilter;
        this.setState({ isFilterShow: true });
        if (name == "fromdate") {
            this.setState({
                fromdate: value,
                fromdateValue: newtime
            });
            const resultfromDate = result.filter(d => {
                var date = new Date(d.joindate);
                var d = date.getDate();
                var m = date.getMonth() + 1;
                var y = date.getFullYear();
                var dateString = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
                var time = new Date(dateString);
                return this.state.todate ? (newtime <= time && time <= this.state.todateValue) : (newtime <= time);
            });
            this.props.handlesearch(resultfromDate)
        }
        if (name == 'todate') {
            this.setState({
                todate: value,
                todateValue: newtime
            });
            const resulttoDate = result.filter(d => {
                var date = new Date(d.joindate);
                var d = date.getDate();
                var m = date.getMonth() + 1;
                var y = date.getFullYear();
                var dateString = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
                var time = new Date(dateString);
                return this.state.fromdate ? (this.state.fromdateValue <= time && time <= newtime) : (time <= newtime);
            });
            this.props.handlesearch(resulttoDate)
        }
    }
    filterReset = (e) => {
        this.setState({
            SearchValue: '',
            role: '',
            fromdate: '',
            todate: '',
            isFilterShow: false
        });
        this.props.listEmployeesalary()
    }
    render() {
        if (this.state.viewRedirect) {
            return <Redirect to={'/viewpayslip/' + this.state.id} />
        }
        const { payrollitemState, employeeReducer, employeesalaryState, leavetransectionState } = this.props;
        const listemployee = employeeReducer.listEmployee;
        const listpayrollitem = payrollitemState.listpayrollitem;
        const data = employeesalaryState.employeesalary;
        const rowData = employeesalaryState.listemployeesalary;
        const listLeavestransection = leavetransectionState.listLeavestransection;

        let specialvalue = 0
        {
            (data.itemvalue != undefined && data.itemvalue.length > 0) &&
                data.itemvalue.map((item, i) => {
                    if (item.itemstatus === true) {
                        let value = listpayrollitem.find((e) => { return e._id == item.name })
                        if (value) {
                            specialvalue += Number(value.amount)
                        }
                    }
                })
        }
        if (data.salary) {
            specialvalue = specialvalue + Math.round(data.salary / 2)
            specialvalue = specialvalue + Math.round(data.salary / 4)
            specialvalue = specialvalue + Math.round(data.salary && (data.salary / 2) < 21001 ? 584 : 0)
        }
        let specialvalCount = 0;
        specialvalCount = Math.round(data.salary) - Math.round(specialvalue)
        // data['specialallowance'] = data.salary ? specialvalCount : 0;
        data['basicsalary'] = data.salary ? Math.round(data.salary / 2) : " ";
        data['hra'] = data.salary ? Math.round(data.salary / 4) : ' ';
        data['statutorybonus'] = data.salary && Math.round(data.basicsalary < 21001) ? 584 : 0;
        data['specialallowance'] = specialvalCount ? specialvalCount : 0;
        data['esi'] = data.salary && data.salary < 21001 ? data.salary * 0.0075 : 0;
        // data['professionaltax'] = data.salary < 75000 ? 208 : 0;
        data['professionaltax'] = this.state.spealValue ? 208 : 0;
        let pfValue = 0;
        pfValue = (data.salary) - data.hra - data.statutorybonus;
        data['pf'] = pfValue > 15000 ? 15000 * 0.12 : pfValue * 0.12;
        let pfEmployer = 0;
        pfEmployer = (data.salary) - data.hra - data.statutorybonus;
        data['pfemployer'] = pfEmployer > 15000 ? 15000 * 0.13 : pfEmployer * 0.13;

        data['esiepmloyer'] = data.salary && data.salary < 21001 ? data.salary * 3.25 / 100 : 0;
        data['gratuity'] = data.salary ? data.basicsalary * 15 / 26 / 12 : 0;

        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });

        let selectedName = '';
        listemployee.length && listemployee.map(item => {
            if (item._id === data.name) {
                selectedName = item.employeeName
            }
        })




        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const Role = [
            { value: 'Employee', label: 'Employee', name: 'role' },
            { value: 'Manager', label: 'Manager', name: 'role' },
        ]
        // const LeaveStatus = [
        //     { value: 'Pending', label: 'Pending', name: 'leave' },
        //     { value: 'Approved', label: 'Approved', name: 'leave' },
        //     { value: 'Rejected', label: 'Rejected', name: 'leave' },

        // ]


        // var pfValue = pf * 0.12;

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listemployeesalary"><h6>Employee Salary List</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">List Employee Salary</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='filter_section'>
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <div class="form-group">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Name" value={this.state.SearchValue} onChange={this.handleSearch} />
                                                    </div>
                                                </div>
                                                <div className='col-lg-2'>
                                                    <label className="form-label">Role Status</label>
                                                    <Select options={Role} onChange={this.handleSelect} value={this.state.role ? { label: this.state.role } : ''} />
                                                </div>
                                                {/* <div className='col-lg-2'>
                                                    <label className="form-label">Leave Status</label>
                                                    <Select options={LeaveStatus} />
                                                </div> */}
                                                <div className='col-lg-3'>
                                                    <div className='form-group'>
                                                        <label className="form-label"> From Date</label>
                                                        <div className='site_pass'>
                                                            <input type="date" className="form-control" name="fromdate" max={this.state.todate ? this.state.todate : ''} onChange={this.handlesSelectdate} value={this.state.fromdate} />
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                            <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}>Date is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <div className='form-group'>
                                                        <label className="form-label"> To Date</label>
                                                        <div className='site_pass'>
                                                            <input type="date" className="form-control" name="todate" min={this.state.fromdate ? this.state.fromdate : ''} value={this.state.todate} onChange={this.handlesSelectdate} />
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                            <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}>Date is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-2'>
                                                    {this.state.isFilterShow &&
                                                        <div className='emp_reset_btn'>
                                                            <button className='btn topsocial_rest_btn btn-primarys ' type='button' onClick={this.filterReset} style={{ height: '45px !important', width: '115pxpx !important' }}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>{this.state.modalType} Staff Salary</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                        <img src='../../assets/images/cancel.png' />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row clearfix add_emp_salary">
                                                    <div className='col-lg-5'>
                                                        <div className="form-group">
                                                            <label className="form-label">Select Staff<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <Select
                                                                value={selectedName ? { label: selectedName } : ''}
                                                                onChange={this.onChange}
                                                                options={listemployee ? listemployee.filter(filterItem => filterItem.status === true).map(item => {
                                                                    return {
                                                                        label: item.employeeName, value: item._id, name: 'name', _id: item._id
                                                                    }
                                                                }) : ''}
                                                            />
                                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Employee Name is required</div>
                                                        </div>
                                                        {/* <h4 className='mb-2'>Earnings</h4> */}

                                                        <div className="col-sm-6 col-md-12">
                                                            <div className="row">
                                                                <label className="form-label mt-3" style={{ textDecoration: "underline", textDecorationStyle: "double", textDecorationColor: "#2cbaec" }}>Payroll Item</label>
                                                                <div className="col-sm-6 col-md-12">
                                                                    {(data.itemvalue != undefined && data.itemvalue.length > 0) &&
                                                                        data.itemvalue.map((item, i) => {
                                                                            var listPayrollItem = '';
                                                                            if (item) {
                                                                                listpayrollitem.map(item1 => {
                                                                                    if (item1._id === item.name) {
                                                                                        listPayrollItem = item1.name
                                                                                    }
                                                                                })
                                                                            }
                                                                            return (
                                                                                <div>
                                                                                    <div className='row'>
                                                                                        <div className="col-sm-6 col-md-10 mt-2">
                                                                                            <div>
                                                                                                {listPayrollItem}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6 col-md-2 mt-2">
                                                                                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                                                                                <div style={{ marginBottom: "12px", marginRight: '20px' }}>
                                                                                                    <label className="custom-control custom-switch mr-3">
                                                                                                        <input type="checkbox" className="custom-control-input" checked={item.itemstatus} onChange={(e) => this.ItemStatusChange(i, e)} />
                                                                                                        <span className="custom-control-label swith_toggle_salary" style={{ cursor: 'pointer' }}></span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>

                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className='col-lg-7'>
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group">
                                                                <label className="form-label"> Net Salalry (<i class="fa-solid fa-indian-rupee-sign"></i>)<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                <input type="text" className="form-control" name='salary' onChange={this.handleChangesalary} placeholder="Net Salary" value={data.salary} />
                                                                <div className="invalid-feedback" style={{ display: this.state.salaryError ? "block" : 'none' }}>Salary is required</div>
                                                            </div>
                                                        </div>
                                                        {data.salary ?
                                                            <>
                                                                <div className="col-sm-12 col-md-12 mt-5">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label"> Basic Salary </label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(data.salary / 2) : " "}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">  HRA </label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(data.salary / 4) : ' '}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    {(data.itemvalue != undefined && data.itemvalue.length > 0) &&
                                                                        data.itemvalue.map((item, i) => {
                                                                            if (item.itemstatus === true) {
                                                                                let value = listpayrollitem.find((e) => { return e._id == item.name })
                                                                                if (value) {
                                                                                    return (
                                                                                        <>
                                                                                            <div className='row'>
                                                                                                <div className='col-lg-5'>
                                                                                                    <label className="form-label">{value.name}</label>
                                                                                                </div>
                                                                                                <div className='col-lg-1'>
                                                                                                    :
                                                                                                </div>
                                                                                                <div className='col-lg-6'>
                                                                                                    <span className="ml-1">{value.amount}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }

                                                                            }
                                                                        })}
                                                                </div>
                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">Statutory Bonus </label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary && Math.round(data.salary < 21001 ? 584 : 0)}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">Special Allowance </label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(specialvalCount) : 0} <i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* =======deduction====== */}
                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">EPF Employee Contribution</label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(data.pf) : 0}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">ESI Employee Contribution</label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(data.esi) : 0}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">Professional Tax</label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(data.professionaltax) : 0}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* =====employer====== */}
                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">EPF Employer Contribution</label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(data.pfemployer) : 0}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">ESI Employer Contribution</label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(data.esiepmloyer) : 0}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className='row'>
                                                                        <div className='col-lg-5'>
                                                                            <label className="form-label">Gratuity**</label>
                                                                        </div>
                                                                        <div className='col-lg-1'>
                                                                            :
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <span className="ml-1">{data.salary ? Math.round(data.gratuity) : 0}<i class="fa-solid fa-indian-rupee-sign ml-2"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    employeeReducer: state.employeeReducer,
    payrollitemState: state.payrollitem,
    employeesalaryState: state.employeesalary,
    leavetransectionState: state.leavetransection
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            handleChange: AC_HANDLE_EMPLOYEE_SALARY_CHANGE,
            listEmployeesalary: AC_LIST_EMPLOYEE_SALARY,
            viewEmployeesalary: AC_VIEW_EMPLOYEE_SALARY,
            resetEmployeesalary: AC_RESET_EMPLOYEE_SALARY,
            listEmployee: AC_LIST_EMPLOYEE,
            listPayrollitem: AC_LIST_PAYROLL_ITEM,
            listEmppayrollitem: AC_LIST_EMPLOYEE_PAYROLL__SALARY,
            handlePayrollstatus: AC_HANDLE_PAYROLL_STATUS_CHANGE,
            handlesearch: AC_HANDLE_EMPLOYEE_SALARY_SEARCH,
            listleavetransection: AC_LIST_LEAVE_TRANSECTION,

        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PayrollItem);


