
const initialState = {
    addCountry: [],
    listCountry: [],
    deleteCountry: [],
    listTax: [],
    Country: {
        countryName: '',
        countryCode: '',
        flag: '',
        isoCode: '',
        currency: '',
        // previleges: CONSTANTS.previleges,
        status: 1,
    },
}
function countryReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_COUNTRY':
            return {
                ...state,
                Country: action.payload
            }
            break;

        case 'LIST_COUNTRY':
            return {
                ...state,
                listCountry: action.payload
            }
            break;
        case 'LIST_TAX':
            return {
                ...state,
                listTax: action.payload
            }
            break;
        case 'VIEW_COUNTRY':
            return {
                ...state,
                Country: action.payload
            }
        case 'RESET_COUNTRY':
            return Object.assign({}, state, {
                Country: initialState.Country
            })
        case 'DELETE_COUNTRY':
            return {
                ...state,
                deleteCountry: action.payload
            }
            break;
        case 'HANDLE_COUNTRY_CHANGE':
            return Object.assign({}, state, {
                Country: {
                    ...state.Country,
                    [action.name]: action.value
                }
            })
        default:
            return state;
            break;
    }
}

export default countryReducer;