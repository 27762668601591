import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import moment from 'moment';
import { Currency, Emailvalidate, capitalizeFirstLetter } from '../../common/validate';
import { AC_HANDLE_INVOICE_CHANGE, AC_LIST_INVOICE, AC_VIEW_INVOICE, AC_RESET_INVOICE, AC_EMPTY_INVOICE, AC_SPINNER_INVOICE_ALL, AC_SET_LIST_INVOICE } from '../../actions/invoiceAction';
import { Urlvalidate, } from '../../common/validate';
import { AC_LIST_INVOICE_DETAIL } from '../../actions/invoicedetail';
import { AC_LIST_INVOICE_CATEGORY } from '../../actions/invoicecategoryAction';
import { AC_LIST_INVOICE_SUB_CATEGORY } from '../../actions/invoicesubcategoryAction';
import ImportedUrl from '../../common/api';
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_TAX } from '../../actions/countryAction';
import exampleImage from '../../assets/images/Indian_Rupee_symbol.png'; // Path to your image relative to the current file

const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    image: '',
    email: '',
    eye: false,
    changepwdshow: false,
    changepwdinvoice: false,
    password: '',
    newpassword: '',
    confirmpassword: '',
    perPage: 25,
    tocompanyValue: '',
    passwordError: false,
    newpasswordError: false,
    confirmpasswordError: false,
    nameError: false,
    categoryError: false,
    subcategoryError: false,
    addressError: false,
    toaddressError: false,
    amountError: false,
    invoicedateError: false,
    duedateError: false,
    urlError: false,
    discountError: false,
    companyError: false,
    tocompanyError: false,
    categoryError: false,
    subcategoryError: false,
    invalidurlError: false,
    communicationemailValidError: false,
    invoicepasswordError: false,
    companyData: [],
    companydropdown: [],
    listinvoiceData: [],
    companyDrop: false,
    editSpi: false,
    allOption: true,
    pdfList: [],
    columnDefs: [
        {
            headerName: 'Image', field: 'tocompanyimage', width: 50, filter: false, cellStyle: { textAlign: 'center' },
            cellRendererFramework: function (params) {
                if (params.data) {
                    return <div>
                        <img style={{ width: '40px', height: '40px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + params.data.tocompanyimage} alt="" />
                    </div>
                }
            }
        },
        { headerName: 'Invoice #', field: 'invoicenum', width: 80, filter: true, floatingFilter: true },
        { headerName: 'Company Name', field: 'company.name', width: 130, hide: true },
        {
            headerName: 'Address', field: 'company.address', width: 150, hide: true
        },
        { headerName: 'Client Acc #', field: 'tocompanyaccount', width: 100, hide: false, floatingFilter: true },
        { headerName: 'Client', field: 'tocompany', width: 130, hide: false, floatingFilter: true },
        {
            headerName: 'To Address', field: 'toaddress', width: 130, hide: true
        },
        {
            headerName: 'Invoice Amount', field: 'amount', width: 100, floatingFilter: false, floatingFilter: true
        },
        {
            headerName: 'Due Date', field: 'duedate', width: 130, hide: true
        },
        {
            headerName: 'Invoice Date', field: 'invoicedate', width: 97, filter: true,
            filter: 'agDateColumnFilter', floatingFilter: true, filterParams: {
                suppressAndOrCondition: true,// Optionally suppress AND/OR buttons
                resetButton: true,
                filterOptions: ['inRange'],
            },
            //  filterParams: filterParams,
            valueGetter: function (params) {
                if (params.data && params.data.invoicedate) {
                    return moment.utc(params.data.invoicedate).format('MM-DD-YYYY');
                }
            }
        },
        {
            headerName: 'Company URL', field: 'url', width: 130, hide: true,
        },
        {
            headerName: 'Discount', field: 'discount', width: 130, hide: true,
        },
        {
            headerName: 'Category', field: 'categoryName', width: 130, hide: true,
        },
        {
            headerName: 'Sub Category', field: 'subcategoryName', width: 130, hide: true,
        },
        {
            headerName: 'Email', width: 60, cellStyle: { textAlign: 'center' }, filter: false, sorting: false,
            cellRenderer: function (params) {
                if (params.data)
                    //         return `<button type="button" class="btn payslip_btn" data-action-type="Invoice">
                    //     Genarate Invoice
                    //   </button>`;

                    if (params.data.communicationemail && params.data.communicationemail.length) {
                        return `<span  class="tag  tag-default" style="background-color: #63b38a">
                        <i class="fe fe-check p-1" data-toggle="tooltip" title="Email exist" style="font-size:15px;color:white"></i>
                           </span>`;
                    } else {
                        return `<span  class="tag  tag-default" style="background-color: #E8759F;
                        border: #E8759F;">
                        <i class="fe fe-x p-1" data-toggle="tooltip" title="Email is empty" style="font-size:15px;color:white"></i>
                           </span>`;
                    }
                else return '';
            }
        },
        {
            headerName: 'Status', width: 70, field: 'status', cellStyle: { textAlign: 'center' }, filter: false, sorting: false,
            cellRenderer: function (params) {
                if (params.data)
                    if (params.data.status) {
                        return `<span type="button" class="tag tag-green" style="width: 73px !important" data-action-type="Status">Valid</span>`;
                    } else {
                        return '<span type="button" class="tag tag-danger" style="width: 73px !important" data-action-type="Status">Invalid</span>';
                    }
                else return ''
            }
        },
        {
            headerName: 'Actions', width: 100, field: 'actions', cellStyle: { textAlign: 'center' }, filter: false,
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": false,
        "resizable": true
    },
    allOptionCategory: true,
    allOptionSubCategory: true,
    pdfData: '',
    datefilter: [],
    companyfilter: [],
    datefilterdata: '',
    companyfilterdata: [],
    clientsList: [],
    settledinvoice: 'unpaid'

}
class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({
            modalType: e.target.id, editSpi: false,
            taxamountlocal: '', taxtypelocal: '', taxamounttypelocal: "",
        });
        this.props.resetinvioce();
        // this.props.listEmppayrollitem();

        let date = new Date();
        let nextmonth = date.setMonth(date.getMonth() + 1);
        let invoicedate = moment(new Date()).format("YYYY-MM") + "-25"
        let duedate = moment(nextmonth).format("YYYY-MM") + "-05"
        this.props.handleChange('invoicedate', invoicedate)
        this.props.handleChange('duedate', duedate)

        const ListInvoice = this.props.invoicedetailState.listinvoicecdetail;
        let defaultCompany = ListInvoice.find(o => o.default && o.status)
        if (defaultCompany)
            this.props.handleChange('company', defaultCompany._id)

        this.setState({ nameError: false, discountnameError: false, previousbalance: 0 })
        this.setState({ categoryError: false })
        this.setState({ subcategoryError: false })
        this.setState({ addressError: false })
        this.setState({ toaddressError: false })
        this.setState({ amountError: false })
        this.setState({ invoicedateError: false })
        this.setState({ duedateError: false })
        this.setState({ urlError: false })
        this.setState({ discountError: false })
        this.setState({ companyError: false })
        this.setState({ tocompanyError: false })
        this.setState({ categoryError: false })
        this.setState({ subcategoryError: false })
        this.setState({ invalidurlError: false })
        this.setState({ tocompanyimageError: false })
        this.setState({ newpasswordError: false })
        this.setState({ confirmpasswordError: false })
        this.setState({ image: '' })
        // document.getElementById('imageSet').value = '';
        this.setState({ newpassword: '' });
        this.setState({ confirmpassword: '' })

    }


    // onGridReady = (params) => {
    //     this.gridColumnApi = params.columnApi;
    //     this.gridApi = params.api;
    //     this.gridApi.sizeColumnsToFit();
    // }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };
    invoiceviewClick = () => {
        this.setState({ eye: !this.state.eye })
    }
    invoiceviewClickCon = () => {
        this.setState({ eyeCon: !this.state.eyeCon })
    }
    InvoicepwdonChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false, [name]: value });
        if (name === 'newpassword') {
            this.setState({ NewPwd: value })
            var newPasswordValue = value;
            if (newPasswordValue) { this.setState({ [Error]: false, [name]: value }); } else { this.setState({ [Error]: true }); }
        }
        if (name === 'confirmpassword') {
            this.setState({ ConfirmPwd: value })
            var confirmPasswordValue = value;
            if (confirmPasswordValue) { this.setState({ [Error]: false, [name]: value }); } else { this.setState({ confirmpasswordError: "Confirm Password is required" }); }
        }
    }


    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        this.setState({ pdfList: [event.data] })
        if (value === 'Edit') {
            this.setState({
                taxamountlocal: '', taxtypelocal: '', taxamounttypelocal: "",
                modalType: "Edit", id: event.data._id, editSpi: true, nameError: false,
                discountnameError: false, previousbalance: 0, categoryError: false,
                subcategoryError: false, addressError: false, toaddressError: false,
                amountError: false, invoicedateError: false, duedateError: false,
                urlError: false, discountError: false, companyError: false,
                tocompanyError: false, categoryError: false, subcategoryError: false,
                invalidurlError: false, tocompanyimageError: false,
                image: '', newpasswordError: false, confirmpasswordError: false
            })
            this.props.viewinvioce(event.data._id);

        }
        if (value == 'Payment') {
            // this.props.history.push('/invoice/' + event.data._id)
            navigator.clipboard.writeText(`${ImportedURL.SITEURL}invoice/${event.data._id}`);
            Swal.fire({ title: 'Copied', showConfirmButton: false, timer: 500 })
        }
        if (value == 'Attachment') {
            this.props.viewinvioce(event.data._id);
            this.setState({
                modalType: "Edit",
                id: event.data._id,
                editSpi: true,
                paymentmethodError: false,
                bankaccountnameError: false,
                paymentattachmentError: false,
                amountpaidError: false,
                amountpaiddateError: false,
                banknameError: false,
                bankroutingnumberError: false,
                bankaccountnumberError: false,
                chequenumberError: false,
                creditcardnameError: false,
                creditcardissuerError: false,
                creditcardnumberError: false,
                creditcardexpirydateError: false,
                creditcardcvvError: false
            });
        }
        if (value === 'Invoice') {
            this.setState({ id: event.data._id, viewRedirect: true });
        }
        if (value === 'Pdf') {
            // this.setState({ printspinner: true })
            const pdfData = event.data;
            const d = new Date(pdfData.invoicemonth ? pdfData.invoicemonth : pdfData.invoicedate);
            let year = d.getFullYear();


            var doc = new jsPDF('p', 'pt', 'A4');
            const content = document.getElementById('print_pagepdf0')
            doc.html(content, {
                callback(doc) {
                    doc.save(pdfData.invoicenum + "_" + pdfData.tocompany + '_' + moment.utc(d).format('MMMM') + " " + year);
                },
                margin: [10, 0, 10, 12],
                html2canvas: { scale: 0.465 }
            });



            // const d = new Date(pdfData.invoicemonth ? pdfData.invoicemonth : pdfData.invoicedate);
            // let year = d.getFullYear();
            // var discountAmount = 0;
            // var discountedAmount = pdfData.discounttype === 'percentage' ? (pdfData.discount / 100) * pdfData.amount : pdfData.discount;

            // discountAmount = pdfData.amount - discountedAmount;
            // const listCountry = this.props.countryReducer.listCountry;

            // let taxcountrydata = listCountry.find(obj => obj._id === pdfData.taxcountry);
            // let countrydata = listCountry.find(obj => obj._id === pdfData.country);
            // let taxarray = []
            // if (pdfData.taxapplied) {
            //     pdfData.taxdata.forEach(tax => {
            //         let taxedamount = tax.taxamounttype === 'percentage' ? (tax.taxamount / 100) * pdfData.amount : tax.taxamount;
            //         discountAmount = discountAmount + taxedamount;

            //         if (tax.taxamount) {
            //             taxarray.push({
            //                 taxshow: `${tax.taxtype} ${tax.taxamounttype == 'percentage' ? `${(taxcountrydata && taxcountrydata.countryName && taxcountrydata.countryName.trim() == 'INDIA' ? ' OUTPUT' : '')} @ ${tax.taxamount}%` : ''}`,
            //                 amount: Currency((taxedamount).toString())
            //             })
            //         }
            //     })
            // }
            // let catarr = []
            // if (pdfData.previousbalance)
            //     discountAmount += pdfData.previousbalance

            // if (pdfData.usecustomcategory) {
            //     if (pdfData.customcategory) pdfData.customcategory.forEach((item) => {
            //         catarr.push({ val: item, type: 'category' })
            //         if (pdfData.customsubcategory)
            //             pdfData.customsubcategory.map((item1) => {
            //                 if (item == item1.customcategorysub)
            //                     catarr.push({ val: item1.customsubcategory, type: 'subcategory' })
            //             })

            //     })
            // }
            // else {
            //     if (pdfData.category) pdfData.category.forEach((item) => {
            //         catarr.push({ val: item.name, type: 'category' })
            //         if (pdfData.subcategory)
            //             pdfData.subcategory.map((item1) => {
            //                 if (item._id == item1.category)
            //                     catarr.push({ val: item1.subcategory, type: 'subcategory' })
            //             })

            //     })
            // }
            // axios.post(ImportedURL.API.downloadPdf, {
            //     data: pdfData,
            //     taxarray: taxarray,
            //     currency: (countrydata && countrydata.currency && countrydata.currency.trim()) ? countrydata.currency.trim() : '$',
            //     discountAmount: discountAmount,
            //     discountedAmount: discountedAmount,
            //     categoryarr: catarr,
            //     image: ImportedURL.LIVEURL + pdfData.company.image,
            //     month: moment.utc(d).format('MMMM') + " - " + year,
            //     year: moment.utc(d).format('MMMM').toUpperCase() + " " + year
            // }, { responseType: 'blob' })
            //     .then((res) => {
            //         const blob = new Blob([res.data], { type: 'application/pdf' });
            //         const link = document.createElement('a');
            //         link.href = window.URL.createObjectURL(blob);
            //         link.download = pdfData.invoicenum + "_" + pdfData.tocompany + '_' + moment.utc(d).format('MMMM') + " " + year + ".pdf"
            //         link.click();
            //         link.remove();
            //         window.URL.revokeObjectURL(link.href);
            //         this.setState({ printspinner: false });
            //     }).catch((err) => {
            //         this.setState({ printspinner: false })
            //     })
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteInvoice + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'invoices' })
                        .then((data) => {
                            // this.props.listinvioce();
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success('Status updated successfully')
                            this.setState({ datefilterdata: '', })

                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['company.name', 'company.address', 'invoicenum', 'tocompany', 'toaddress', 'amount', 'duedate', 'invoicedate', 'url', 'discount', 'categoryName', 'subcategoryName'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { invoiceState } = this.props;
        const exportData = invoiceState.listinvoice;

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Invoice";
        const headers = [['#', 'Company Name', 'Address', 'Invoice Amount', 'Due Date', 'Invoice Date', 'Company URL', 'Discount', 'Category', 'Sub Category']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.company.name,
            elt.address,
            elt.amount,
            elt.duedate,
            elt.invoicedate,
            elt.url,
            elt.discount,
            elt.category.name,
            elt.subcategory.subcategory,

        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Invioce.pdf")
    }
    handleSelectChange = (e) => {
        this.setState({ spinner: false })
        const { name, value } = e;

        const data = this.props.invoiceState.invoice;
        this.props.handleChange(name, value)
        if (name == "company") {
            this.setState({ companyError: false })

            // const ListInvoice = this.props.invoicedetailState.listinvoicecdetail;
            // ListInvoice.map((item) => {
            //     if (item._id == value) {
            //         this.setState({ addressError: false })
            //     }
            // })
        }

        if (name == "tocompany") {
            this.setState({
                tocompanyError: false,
                amountError: false,
                discountError: false,
                urlError: false,
                categoryError: false,
                subcategoryError: false,
            })


            this.props.handleChange('amount', e.data.amount)
            this.props.handleChange('discount', e.data.discount)
            this.props.handleChange('discounttype', e.data.discounttype)
            this.props.handleChange('paymentmethod', e.data.paymentmethod)
            this.props.handleChange('url', e.data.url)
            this.props.handleChange('category', e.data.category)
            this.props.handleChange('subcategory', e.data.subcategory)
            this.props.handleChange('notes', e.data.notes)
            this.props.handleChange('taxapplied', e.data.taxapplied)
            this.props.handleChange('usecustomcategory', e.data.usecustomcategory ? e.data.usecustomcategory : false)
            this.props.handleChange('customcategory', e.data.customcategory ? e.data.customcategory : [])
            this.props.handleChange('customsubcategory', e.data.customsubcategory ? e.data.customsubcategory : [])
            if (e.data.taxapplied) {
                this.props.handleChange('taxcountry', e.data.taxcountry)
                this.props.handleChange('taxdata', e.data.taxdata)
            }
            if (e.data.communicationemail) this.props.handleChange('communicationemail', e.data.communicationemail)
            if (e.data.invoicedate) {
                let now = new Date()
                let val = moment(now).format("YYYY-MM") + '-' + moment.utc(e.data.invoicedate).format("DD")
                // now.setHours(0,0,0,0)
                if (moment.utc(val).format("YYYY-MM-DD") != 'Invalid date') {
                    if (new Date(moment.utc(val).format("YYYY-MM-DD")) < now)
                        this.props.handleChange('invoicedate', moment(new Date()).format("YYYY-MM-DD"))
                    else
                        this.props.handleChange('invoicedate', moment.utc(val).format("YYYY-MM-DD"))
                }
            }
            if (e.data.duedate) {
                let date = new Date();
                let nextmonth = date.setMonth(date.getMonth() + 1);
                let val1 = moment(nextmonth).format("YYYY-MM") + '-' + moment.utc(e.data.duedate).format("DD")
                if (moment.utc(val1).format("YYYY-MM-DD") != 'Invalid date') {
                    this.props.handleChange('duedate', moment.utc(val1).format("YYYY-MM-DD"))
                }
            }

            axios.post(ImportedURL.API.getpreviousinvoice, { tocompany: value })
                .then((res) => {
                    if (res.data.totalbal)
                        this.setState({ previousbalance: res.data.totalbal })
                    if (res.data.exist) {
                        Swal.fire({
                            title: "You have invoice that are not updated yet",
                            text: 'Do you want to continue to create?',
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: "Continue",
                            denyButtonText: `Don't Continue`
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            // if (result.isConfirmed) {
                            // } else
                            if (result.isDenied) {
                                Error('Cancelled')
                                this.props.resetinvioce();
                                let btn = document.getElementById("closeModal");
                                btn.click();
                                this.setState({ save: false });
                            }
                        });
                    } else {
                        this.setState({ save: false });
                    }

                }).catch((err) => {
                    Error('Bad request')
                });

        }

        if (name == "address") {
            if (data.address) {
                this.setState({ addressError: false })
            }
            else {
                this.setState({ addressError: false })
            }
        }
    }
    handleMultiSelectChange = (e, name) => {
        const data = this.props.invoiceState.invoice;
        const listinvoicesubcategory = this.props.invoicesubcategoryState.listinvoicesubcategory;

        this.setState({ spinner: false })
        if (name == 'category') {
            const listinvoicecategory = this.props.invoicecategoryState.listinvoicecategory;
            let findAll = e.find((item) => item.value == "")
            let pushedArr = []
            if (findAll) {
                this.setState({ allOptionCategory: false })
                let category = (data.category && data.category.length > 0) ? [...data.category] : []
                let allData = []
                listinvoicecategory.map((e) => {
                    allData.push(e._id)
                })
                let conact = allData.concat(category)
                pushedArr = [...new Set(conact)]
            } else {
                this.setState({ allOptionCategory: true })
                if (e && e.length > 0) {
                    e.map((item) => {
                        const { value } = item;
                        pushedArr.push(value)
                    })
                }
            }
            if (pushedArr) {
                this.setState({ categoryError: false })
            }
            else {
                this.setState({ categoryError: true })
            }
            this.props.handleChange("category", pushedArr)
            this.props.handleChange("subcategory", [])
        } else {
            const listinvoicesubcategory = this.props.invoicesubcategoryState.listinvoicesubcategory;
            let findAll = e.find((item) => item.value == "")
            let pushedArr = []
            if (findAll) {
                this.setState({ allOptionSubCategory: false })
                let subcategory = (data.subcategory && data.subcategory.length > 0) ? [...data.subcategory] : []
                let allData = []
                listinvoicesubcategory.map((e) => {
                    let status = (data.category && data.category.length > 0) ? data.category.find((i) => i == e.category._id) : undefined
                    if (status) allData.push(e._id)
                })
                let conact = allData.concat(subcategory)
                pushedArr = [...new Set(conact)]
            } else {
                this.setState({ allOptionSubCategory: true })
                if (e && e.length > 0) {
                    e.map((item) => {
                        const { value } = item;
                        pushedArr.push(value)
                    })
                }
            }
            if (pushedArr) {
                this.setState({ subcategoryError: false })
            }
            else {
                this.setState({ subcategoryError: true })
            }
            this.props.handleChange("subcategory", pushedArr)
        }
    }
    handleOnchange = (e) => {
        this.setState({ spinner: false })
        this.setState({ editSpi: false })
        const { name, value } = e.target;
        const Error = name + "Error";
        if (name == 'invoicepwd') {
            if (value) {
                this.setState({ invoicepasswordError: false });
            } else {
                this.setState({ invoicepasswordError: true })
            }
        }
        if (name == 'address', 'invoicedate', 'duedate') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }

        if (name == 'url') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ invalidurlError: true })
                } else {
                    this.setState({ invalidurlError: false })
                }
            } else {
                this.setState({ invalidurlError: false })
            }
        }


        if (name == 'tocompany') {
            if (value.length == 0) {
                this.props.handleChange('toaddress', ' ')
            }
            this.setState({ tocompanyValue: value })
            var companydropdown = []
            const ListInvoice = this.props.invoicedetailState.listinvoicecdetail;

            ListInvoice.map((item) => {
                if (item.name.toLowerCase().includes((value).toLowerCase())) {
                    companydropdown.push(item)
                }
            })
            this.setState({ companydropdown: companydropdown })
            if (companydropdown) {
                this.setState({ companyDrop: true })
            }
            else {
                this.setState({ companyDrop: false })
            }
        }

        if (name === 'amount') {
            if (!isNaN(value)) {
                this.props.handleChange(name, value)

                this.setState({ numbermustError: false })
            }

        }
        else if (name === 'discount') {
            if (!isNaN(value)) {
                this.props.handleChange(name, value)

                this.setState({ discountnumberError: false })
            }

        } else if (name === 'creditcardexpirydate') {
            const expdate = '0421';
            const expDateFormatter = value.replace(/\//g, "").substring(0, 2) +
                (value.length > 2 ? '/' : '') +
                value.replace(/\//g, "").substring(2, 6);

            this.props.handleChange(name, expDateFormatter)
        } else if (name === 'paymentattachment') {
            this.props.handleChange(name, e.target.files[0])
            this.setState({ paymentattachment: e.target.files[0], paymentattachmentError: false })
        }
        else {
            this.props.handleChange(name, value)
        }

    }

    handleKeyDown = e => {
        this.setState({ spinner: false })
        const { value } = e.target;
        const { invoiceState, AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = invoiceState.invoice;
        if (e.key === 'Enter' && this.state.communicationemail !== '' && !this.state.communicationemailValidError) {
            let newData = data.communicationemail ? [...data.communicationemail] : []
            if (newData.includes(value)) {
                Error((languageData && languageData.ALREADY_ADDED ? languageData.ALREADY_ADDED : " Email Already Added"))
            } else {
                newData.push(value);
                this.props.handleChange("communicationemail", newData);
                this.setState({ communicationemail: '' });
            }

        }
    }

    changeinclude = e => {
        const { invoiceState, AccountState } = this.props;
        const data = invoiceState.invoice;
        if (e.target.checked) {
            this.props.handleChange("previousbalance", this.state.previousbalance);
        } else {
            this.props.handleChange("previousbalance", 0);
            this.setState({ previousbalance: data.previousbalance })

        }
    }
    removeEmail = (i) => {
        this.setState({ spinner: false })
        const { invoiceState } = this.props;
        const data = invoiceState.invoice.communicationemail;
        data.splice(i, 1);
        this.props.handleChange("communicationemail", data);
    }
    onChange = e => {
        const { name, value } = e.target;

        if (name === 'communicationemail') {
            if (value) {
                if (Emailvalidate(value)) {
                    this.setState({ communicationemailValidError: false })
                } else {
                    this.setState({ communicationemailValidError: true })
                }
                this.setState({ communicationemail: value });
            } else {
                this.setState({ communicationemailValidError: false })
                this.setState({ communicationemail: value });
            }
        }
    }

    uploadhandleChange = (event) => {
        this.setState({ spinner: false })
        const { name, value } = event.target;
        if (name === 'tocompanyimage') {
            this.setState({ image: event.target.files[0], tocompanyimageError: false })
        }
        this.props.handleChange(name, event.target.files[0]);
    }
    componentDidMount() {
        // this.props.listinvioce();
        this.props.AC_SPINNER_INVOICE_ALL();
        this.props.AC_LIST_TAX();
        this.props.emptyinvoice();
        this.props.listinvoicedetail();
        this.props.listinvoicesubcategory();
        this.props.listinvoicecategory();
        // var invoiceCode = localStorage.getItem("invoicetoken") ? localStorage.getItem("invoicetoken") : '';
        // axios.post(ImportedURL.API.listInvoice, { invoicecode: invoiceCode })
        //     .then((res) => {
        //         this.setState({ companyData: res.data.data })
        //     }).catch((err) => { console.log(err); })

        axios.post(ImportedURL.API.getListFilter)
            .then((res) => {
                if (res.data)
                    this.setState({ datefilter: res.data.date, companyfilter: res.data.company })
            }).catch((err) => { console.log(err); })


    }

    InvoicepwdSubmit = () => {
        const { newpassword, password, confirmpassword } = this.state;
        // if (!password) this.setState({ passwordError: true });
        if (!newpassword) this.setState({ newpasswordError: true });
        if (!confirmpassword) this.setState({ confirmpasswordError: "Confirm Password is required" });
        if (newpassword !== confirmpassword) this.setState({ confirmpasswordError: "Password doesn't match" });
        if (newpassword && (newpassword === confirmpassword)) {
            const formData = { newpassword: newpassword }
            axios.post(ImportedUrl.API.invoicechangePassword, formData)
                .then((res) => {
                    let btn = document.getElementById("chnagepwd");
                    btn.click();
                    Success(res.statusText);
                    this.setState(initialState)
                }).catch(({ response }) => { if (response) Error(response.statusText) });
        } else {
        }
    }

    addinvoicepost = (formData) => {
        axios.post(ImportedURL.API.addInvoice, formData)
            .then((formdata) => {
                Success(formdata.statusText ? formdata.statusText : 'Invoice added successfully');
                this.setState({ save: false });
                this.props.AC_SPINNER_INVOICE_ALL();
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
                this.props.resetinvioce();
                let btn = document.getElementById("closeModal");
                btn.click();
                axios.post(ImportedURL.API.getListFilter)
                    .then((res) => {
                        if (res.data)
                            this.setState({ datefilter: res.data.date, companyfilter: res.data.company })
                    }).catch((err) => { console.log(err); })
            }).catch(({ response }) => {
                Error(response.statusText)
                this.setState({ save: false });
            });
    }
    submit = () => {
        const { invoiceState } = this.props;
        const rowData = invoiceState.listinvoice;
        const data = invoiceState.invoice;

        let valid = 1

        if (!data.discountname) {
            this.setState({ discountnameError: true });
            valid = 0
        }
        if (!data.amount) {
            this.setState({ amountError: true });
            valid = 0
        }
        if (!data.invoicedate) {
            this.setState({ invoicedateError: true });
            valid = 0
        }
        if (!data.duedate) {
            this.setState({ duedateError: true });
            valid = 0
        }
        // if (!data.url) {
        //     this.setState({ urlError: true });
        // }
        // if (!data.discount) {
        //     this.setState({ discountError: true });
        //     valid = 0
        // }
        if (!data.company) {
            this.setState({ companyError: true });
            valid = 0
        }
        if (!data.tocompany) {
            this.setState({ tocompanyError: true });
            valid = 0
        }
        // if (data.category.length == 0) {
        //     this.setState({ categoryError: true });
        //     valid = 0
        // } if (data.subcategory.length == 0) {
        //     this.setState({ subcategoryError: true });
        //     valid = 0
        // }
        if (this.state.invalidurlError) {
            valid = 0
        }

        if (valid) {
            var formData = new FormData();
            formData.append("company", data.company);
            formData.append("tocompany", data.tocompany);
            formData.append("discountname", data.discountname);
            if (data.discounttype) formData.append("discounttype", data.discounttype);
            if (data.paymentmethod) formData.append("paymentmethod", data.paymentmethod);
            if (data.notes) formData.append("notes", data.notes);
            if (data.taxapplied) {
                if (data.taxcountry) formData.append("taxcountry", data.taxcountry);
                if (data.taxdata) formData.append("taxdata", JSON.stringify(data.taxdata));
                // formData.append("taxtype", data.taxtype ? data.taxtype : 'CGST');
                // formData.append("taxamount", data.taxamount ? data.taxamount : 0);
                // formData.append("taxamounttype", data.taxamounttype ? data.taxamounttype : 'percentage');
            }
            formData.append("taxapplied", data.taxapplied ? data.taxapplied : false);
            formData.append("amount", data.amount);
            formData.append("invoicedate", data.invoicedate);
            formData.append("duedate", data.duedate);
            formData.append("url", data.url ? data.url : '');
            formData.append("discount", data.discount ? data.discount : 0);
            formData.append("invoicemonth", data.invoicemonth ? data.invoicemonth : moment.utc(data.invoicedate).format('YYYY-MM'));
            if (data.previousbalance) formData.append("previousbalance", data.previousbalance);
            if (data.usecustomcategory) formData.append("usecustomcategory", data.usecustomcategory);
            formData.append("category", JSON.stringify(data.category));
            formData.append("subcategory", JSON.stringify(data.subcategory));
            if (data.customcategory) formData.append("customcategory", JSON.stringify(data.customcategory));
            if (data.customsubcategory) formData.append("customsubcategory", JSON.stringify(data.customsubcategory));
            if (data.communicationemail) formData.append("communicationemail", JSON.stringify(data.communicationemail));


            if (this.state.modalType === "Add") {
                this.setState({ save: true });
                this.addinvoicepost(formData);


            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateInvoice + '/' + this.state.id, formData)
                    .then((res) => {
                        Success(res.statusText ? res.statusText : 'Invoice updated successfully');
                        this.setState({ save: false });
                        this.props.AC_SPINNER_INVOICE_ALL();
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.props.resetinvioce();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                        axios.post(ImportedURL.API.getListFilter)
                            .then((res) => {
                                if (res.data)
                                    this.setState({ datefilter: res.data.date, companyfilter: res.data.company })
                            }).catch((err) => { console.log(err); })
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }

    phoneDropdownClick = (temp) => {
        const data = this.props.invoiceState.invoice;
        this.setState({ companyDrop: false })
        if (temp) {
            this.props.handleChange('tocompany', temp.name)


            // if (data.tocompany === temp.tocompany) {
            if (temp.name) {
                this.props.handleChange('toaddress', `${temp.address}, ${temp.city}, ${temp.state}, ${temp.code}`)
                this.setState({ toaddressError: false })
            }
            // }
        }
        else {
            this.props.handleChange('tocompany', '')
            this.props.handleChange('toaddress', '')
        }

    }


    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const page = params.endRow / this.state.perPage;
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                var invoiceCode = localStorage.getItem("invoicetoken") ? localStorage.getItem("invoicetoken") : '';
                this.setState({ spinner: true });

                axios.post(ImportedURL.API.listInvoice, {
                    settledinvoice: this.state.settledinvoice,
                    invoicecode: invoiceCode,
                    perPage: this.state.perPage,
                    page: page, filter: filter,
                    sort: sortModel.length ? sortModel[0] : {},
                    ...query
                })
                    .then((res) => {
                        if (res.data) {

                            this.props.setlistinvioce(res.data);
                            const rowData = res.data.data;
                            this.setState({ listinvoiceData: rowData, spinner: false });
                            const total = res.data.total;
                            const previleges = this.props.AccountState.previleges;
                            const Previlege = previleges.find(obj => { return obj.name == "Invoice" });
                            const editOption = Previlege.edit ? '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon p-2" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>' : '';
                            const attachbutton = Previlege.edit ? '<button type="button" data-toggle="modal" data-target="#amountpaidupdate" class="btn btn-icon p-2" data-action-type="Attachment"><i class="fa fa-paperclip" style="color: #2D3377 !important;font-size:20px;" data-action-type="Attachment" data-toggle="tooltip" title="Edit"></i></button>' : '';
                            const downloadOption = '<button type="button"  class="btn btn-icon p-2" data-action-type="Pdf"><i class="fa fa-download" style="color: #2196F3" data-action-type="Pdf" data-toggle="tooltip" title="PDF"></i></button>';
                            // + '<button type="button" data-toggle="modal" data-target="#amountpaidupdate" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-paperclip" style="color: #2196F3 !important" data-action-type="Edit" ></i></button>'

                            const template =
                                editOption
                                + downloadOption
                                + attachbutton
                                + '<button type="button"  class="btn btn-icon p-2" data-action-type="Payment"><i class="fa fa-link" style="color: #2D3377 !important;font-size:20px;" data-action-type="Payment" data-toggle="tooltip" title="Edit"></i></button>'
                                + '</div>'

                            rowData.forEach(object => {
                                object.actions = template;
                            });
                            params.successCallback(rowData, total);
                        } else {
                            this.props.listinvioce({ data: [], total: 0 });
                            params.successCallback([], 0);
                        }

                    }).catch(({ response }) => {
                        if (response.status == 405) {
                            localStorage.setItem('invoicetoken', "");
                            window.location.href = "/invoicepwd";
                        } else {
                            console.log(response);
                        }
                        this.props.listinvioce({ data: [], total: 0 });

                        params.successCallback([], 0);

                    })


            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }

        if (!this.state.clientcalled) {
            var invoiceCode = localStorage.getItem("invoicetoken") ? localStorage.getItem("invoicetoken") : '';
            if (invoiceCode) {
                this.setState({ clientcalled: true });

                axios.post(ImportedURL.API.listClient, { invoicecode: invoiceCode })
                    .then((res) => {
                        if (res.data) {
                            this.setState({ clientsList: res.data.data, clientcalled: true });
                        }
                    }).catch(({ response }) => {
                    });
            }
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };

    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    SendEmail = async () => {
        if (this.state.datefilterdata) {
            Swal.fire({
                title: 'Are you sure to send email?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    const rowData = this.props.invoiceState.listinvoice;
                    this.setState({
                        pdfList: rowData,
                    }, async () => {
                        Success("Sending emails...")
                        for (let i = 0; i < this.state.pdfList.length; i++) {
                            try {
                                const content = document.getElementById('print_pagepdf' + i);
                                let data = this.state.pdfList[i];
                                var doc = new jsPDF('p', 'pt', 'A4');
                                await doc.html(content, {
                                    margin: [10, 0, 10, 12],
                                    html2canvas: { scale: 0.465 }
                                });
                                let blob = doc.output('datauristring')
                                const d = new Date(data.invoicemonth ? data.invoicemonth : data.invoicedate);
                                // const d = new Date(data.invoicedate);
                                let year = d.getFullYear();
                                var discountAmount = 0;
                                if (data.discounttype && data.discounttype === 'percentage')
                                    discountAmount = data.amount - ((data.discount / 100) * data.amount);
                                else
                                    discountAmount = data.amount - data.discount;

                                if (data.taxapplied) {
                                    data.taxdata.forEach(tax => {
                                        let taxedamount = tax.taxamounttype === 'percentage' ? (tax.taxamount / 100) * data.amount : tax.taxamount;
                                        discountAmount = discountAmount + taxedamount;
                                    })
                                }


                                if (data.previousbalance)
                                    discountAmount += data.previousbalance
                                // discountAmount = data.amount - data.discount;
                                var formData = {
                                    data: data,
                                    amount: discountAmount,
                                    filename: data.invoicenum + "_" + data.tocompany + '_' + moment.utc(d).format('MMMM') + " " + year,
                                    company: data.tocompany,
                                    communicationemail: JSON.stringify(data.communicationemail),
                                    date: moment.utc(d).format('MMMM') + " " + year,
                                    pdf: blob.split('base64,')[1],
                                };
                                await axios.post(ImportedURL.API.sendEmail, formData)
                            }
                            catch (err) {
                                console.log(err);
                            }
                        }
                        Success("Email sent")

                    })
                }
            })


        } else {
            Error("Please select month before sending email");
        }

    }

    handleFilterChange = (e, name, options = []) => {
        this.gridApi.setFilterModel(null);
        let { datefilterdata, companyfilterdata } = this.state
        if (name == 'company') {
            let findAll = e.find((item) => item.value == "")
            let result = e;
            if (findAll) {
                this.setState({ companyfilterdata: options, resetshow: true })
                result = options
            }
            else this.setState({ companyfilterdata: e, resetshow: true })

            if (findAll || (e.length == options.length)) this.setState({ noshowallinfilter: true })
            else this.setState({ noshowallinfilter: false })



            let arr = []
            // this.setState({ companyfilterdata: e, resetshow: true })
            result.map((item) => {
                if (item.value) arr.push(item.value)
            })
            const dataSource = this.getDataSource({ tocompany: arr, invoicemonth: datefilterdata ? datefilterdata.value.month : '', invoiceyear: datefilterdata ? datefilterdata.value.year : '' })
            this.gridApi.setDatasource(dataSource);
        }
        else if (name == 'date') {
            this.setState({ datefilterdata: e, resetshow: true })
            let arr = []
            // this.setState({ companyfilterdata: e, resetshow: true })
            companyfilterdata.map((item) => {
                if (item.value) arr.push(item.value)
            })

            const dataSource = this.getDataSource({ tocompany: arr, invoicemonth: e.value.month, invoiceyear: e.value.year })
            this.gridApi.setDatasource(dataSource);
        }

    }

    ResetFilters = () => {
        this.gridApi.setFilterModel(null);
        this.setState({ resetshow: false, datefilterdata: '', companyfilterdata: [] })
        const dataSource = this.getDataSource({})
        this.gridApi.setDatasource(dataSource);
    }


    multipleGenerate = () => {
        Swal.fire({
            title: 'Are you sure to create multiple invoice?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Ok',
            imageUrl: '../../assets/images/status.png',
            customClass: {
                popup: 'swal_pop',
                title: 'swal_title',
                image: 'swal_image',
                actions: 'swal_action',
                confirmButton: 'swal_confirm',
                cancelButton: 'swal_close',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const { companyMultipleInvoice, tocompanyMultipleInvoice } = this.state;

                let arr = [];
                for (let i of tocompanyMultipleInvoice) {
                    let date1 = new Date();
                    let nextmonth = date1.setMonth(date1.getMonth() + 1);
                    let invoicedate = moment(new Date()).format("YYYY-MM") + "-25"
                    let duedate = moment(nextmonth).format("YYYY-MM") + "-05"
                    if (i.data.invoicedate) {
                        let now = new Date()
                        let val = moment(now).format("YYYY-MM") + '-' + moment.utc(i.data.invoicedate).format("DD")
                        // now.setHours(0,0,0,0)
                        if (moment.utc(val).format("YYYY-MM-DD") != 'Invalid date') {
                            if (new Date(moment.utc(val).format("YYYY-MM-DD")) < now)
                                invoicedate = moment(new Date()).format("YYYY-MM-DD")
                            else
                                invoicedate = moment.utc(val).format("YYYY-MM-DD")
                        }
                    }
                    if (i.data.duedate) {
                        let date = new Date();
                        let nextmonth = date.setMonth(date.getMonth() + 1);
                        let val1 = moment(nextmonth).format("YYYY-MM") + '-' + moment.utc(i.data.duedate).format("DD")
                        if (moment.utc(val1).format("YYYY-MM-DD") != 'Invalid date') {
                            duedate = moment.utc(val1).format("YYYY-MM-DD")
                        }
                    }
                    let obj = {
                        company: companyMultipleInvoice.value,
                        tocompany: i.value,
                        discountname: 'Multiple Property Discount',
                        amount: i.data.amount,
                        invoicedate: invoicedate,
                        duedate: duedate,
                        url: i.data.url,
                        discount: i.data.discount,
                        discounttype: i.data.discounttype,
                        category: i.data.category,
                        subcategory: i.data.subcategory,
                        notes: i.data.notes,
                        taxapplied: i.data.taxapplied ? i.data.taxapplied : false,
                        usecustomcategory: i.data.usecustomcategory ? i.data.usecustomcategory : false,
                        customcategory: i.data.customcategory ? i.data.customcategory : [],
                        customsubcategory: i.data.customsubcategory ? i.data.customsubcategory : [],
                        communicationemail: i.data.communicationemail ? i.data.communicationemail : []
                    }
                    if (i.data.taxapplied) {
                        obj['taxcountry'] = i.data.taxcountry
                        obj['taxdata'] = i.data.taxdata ? i.data.taxdata : []
                    }
                    arr.push(obj)
                }

                axios.post(ImportedURL.API.addmultipleinvoice, { data: arr, company: companyMultipleInvoice.value, nametype: companyMultipleInvoice.data.nametype })
                    .then((formdata) => {
                        Success("Multiple invoice created successfully");
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        let btn = document.getElementById("closeModalMultiple");
                        btn.click();
                        axios.post(ImportedURL.API.getListFilter)
                            .then((res) => {
                                if (res.data)
                                    this.setState({ companyMultipleInvoice: '', tocompanyMultipleInvoice: '' })
                            }).catch((err) => { console.log(err); })
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        })




    }

    handleMultipleInvoice = (e, name, options = []) => {
        if (name == "company") {
            this.setState({ companyMultipleInvoice: e })
        }

        if (name == "tocompany") {
            let findAll = e.find((item) => item.value == "all")
            if (findAll) this.setState({ tocompanyMultipleInvoice: options, })
            else this.setState({ tocompanyMultipleInvoice: e })

            if (findAll || (e.length == options.length)) this.setState({ noshowallinmultiple: true })
            else this.setState({ noshowallinmultiple: false })
        }

    }

    phonenumberformat = (number) => {
        if (number) {

            var newArray = number.match
                (/^(91|)?(\d{3})(\d{3})(\d{4})$/);

            // Checking the international code
            var intlCountryCode = (newArray[1] ? '+91' : '');
            // the international number
            var internationalNumber = intlCountryCode +
                ' (' + newArray[2] + ') ' + newArray[3]
                + '-' + newArray[4];

            return internationalNumber
        } else return ''
    }

    submitattachment = () => {
        let valid = 1;
        const { invoiceState } = this.props;
        let data = invoiceState.invoice;
        let reuqireddatachequeach = ['bankaccountname', 'bankname', 'bankroutingnumber', 'bankaccountnumber'];
        let reuqireddatacredit = ['creditcardname', 'creditcardissuer', 'creditcardnumber', 'creditcardexpirydate', 'creditcardcvv'];
        if (data.paymentmethod) {
            // if (data.paymentmethod === 'cheque' || data.paymentmethod === 'ach')
            //     for (let item of reuqireddatachequeach)
            //         if (!data[item]) {
            //             this.setState({ [item + 'Error']: true });
            //             valid = 0
            //         }
            // if (data.paymentmethod === 'creditcard')
            //     for (let item of reuqireddatacredit)
            //         if (!data[item]) {
            //             this.setState({ [item + 'Error']: true });
            //             valid = 0
            //         }
            // if (data.paymentmethod === 'cheque')
            //     if (!data.chequenumber) {
            //         this.setState({ chequenumberError: true });
            //         valid = 0
            //     }
            if (!data.paymentattachment) {
                this.setState({ paymentattachmentError: true });
                valid = 0
            }
            if (!data.amountpaid) {
                this.setState({ amountpaidError: true });
                valid = 0
            }
            if (!data.amountpaiddate) {
                this.setState({ amountpaiddateError: true });
                valid = 0
            }
            if (valid) {
                var formData = new FormData();
                formData.append("paymentmethod", data.paymentmethod);
                formData.append("amountpaid", data.amountpaid);
                formData.append("amountpaiddate", data.amountpaiddate);
                formData.append("paymentattachment", data.paymentattachment);
                if (data.bankaccountname) formData.append("bankaccountname", data.bankaccountname);
                if (data.bankname) formData.append("bankname", data.bankname);
                if (data.bankroutingnumber) formData.append("bankroutingnumber", data.bankroutingnumber);
                if (data.bankaccountnumber) formData.append("bankaccountnumber", data.bankaccountnumber);
                if (data.chequenumber) formData.append("chequenumber", data.chequenumber);
                if (data.creditcardname) formData.append("creditcardname", data.creditcardname);
                if (data.creditcardissuer) formData.append("creditcardissuer", data.creditcardissuer);
                if (data.creditcardnumber) formData.append("creditcardnumber", data.creditcardnumber);
                if (data.creditcardexpirydate) formData.append("creditcardexpirydate", data.creditcardexpirydate);
                if (data.creditcardcvv) formData.append("creditcardcvv", data.creditcardcvv);

                axios.post(ImportedURL.API.updateattachment + '/' + this.state.id, formData)
                    .then((res) => {
                        this.getDataSource()
                        this.ResetFilters()
                        let btn = document.getElementById("closeModalAttachment");
                        btn.click();
                        this.setState({ paymentattachment: '' })
                    }).catch((err) => { console.log(err); })

            }
        }
        else {
            this.setState({ 'paymentmethodError': true });

        }


    }

    getsettled = (status) => {
        this.setState({ settledinvoice: status })
        this.getDataSource({ settledinvoice: status })
        this.ResetFilters()
    }

    addCustomCategory = () => {
        if (this.state.customcategory && this.state.customcategory.trim()) {
            const data = this.props.invoiceState.invoice;
            let category = data.customcategory && data.customcategory ? [...data.customcategory] : [];
            if (category.includes(this.state.customcategory.trim())) {
                Error(`Category already exists.`);
            } else {
                category.push(this.state.customcategory.trim());
                this.setState({ customcategory: '' })
                this.props.handleChange('customcategory', category)
            }
        } else {
            Error('Enter category to add');
        }
    }

    removeCutomCategory = (indexToRemove) => {
        const data = this.props.invoiceState.invoice;
        let category = [...data.customcategory];
        let customsubcategory = [...data.customsubcategory]
        let categoryname = category[indexToRemove]
        let newArray = customsubcategory.filter(obj => obj.customcategorysub !== categoryname);
        category.splice(indexToRemove, 1);
        this.props.handleChange('customcategory', category)
        this.props.handleChange('customsubcategory', newArray)
    }

    addCustomSubCategory = () => {
        if (this.state.customsubcategory && this.state.customcategorysub) {
            const data = this.props.invoiceState.invoice;
            let category = data.customsubcategory && data.customsubcategory ? [...data.customsubcategory] : [];
            if (category.some(item => item.customsubcategory === this.state.customsubcategory)) {
                Error(`Subcategory already exists.`);
            } else {

                category.push({ customcategorysub: this.state.customcategorysub, customsubcategory: this.state.customsubcategory });
                this.setState({ customsubcategory: '', customcategorysub: '' })
                this.props.handleChange('customsubcategory', category)
            }
        } else {
            Error('Enter category and subcategory to add');
        }
    }

    addTaxData = () => {
        if (this.state.taxamountlocal && this.state.taxtypelocal) {
            const data = this.props.invoiceState.invoice;
            let taxdata = data.taxdata && data.taxdata ? [...data.taxdata] : [];
            if (taxdata.some(item => item.taxtype === this.state.taxtypelocal)) {
                Error(`Tax already exists.`);
            } else {
                taxdata.push({ taxamount: this.state.taxamountlocal, taxtype: this.state.taxtypelocal, taxamounttype: this.state.taxamounttypelocal ? this.state.taxamounttypelocal : 'percentage' });
                this.setState({ taxamountlocal: '', taxtypelocal: '', taxamounttypelocal: "" })
                this.props.handleChange('taxdata', taxdata)
            }
        } else {
            Error('Enter Tax and Tax type to add');
        }
    }


    removeCutomSubCategory = (indexToRemove) => {
        const data = this.props.invoiceState.invoice;
        let category = [...data.customsubcategory]
        category.splice(indexToRemove, 1);
        this.props.handleChange('customsubcategory', category)
    }

    removeTax = (indexToRemove) => {
        const data = this.props.invoiceState.invoice;
        let taxdata = [...data.taxdata]
        taxdata.splice(indexToRemove, 1);
        this.props.handleChange('taxdata', taxdata)
    }

    handleDragStart = (e, index) => {
        const data = this.props.invoiceState.invoice;
        let category = [...data.customcategory]
        this.setState({
            draggedItem: category[index],
            dragtype: 'category'
        });
        e.dataTransfer.effectAllowed = 'move';
    };

    handleDragOver = (e, index) => {
        e.preventDefault();
        if (this.state.dragtype !== 'category') return;
        const data = this.props.invoiceState.invoice;
        let category = [...data.customcategory]
        const draggedOverItem = category[index];

        // If the dragged item is the same as the dragged over item, return
        if (this.state.draggedItem === draggedOverItem) {
            return;
        }

        // Remove the dragged item from the list
        const items = category.filter(item => item !== this.state.draggedItem);

        // Insert the dragged item at the new position
        items.splice(index, 0, this.state.draggedItem);

        this.props.handleChange('customcategory', items)

    };

    handleDragStartSubcategory = (e, index) => {
        const data = this.props.invoiceState.invoice;
        let category = [...data.customsubcategory]
        this.setState({
            draggedItem: category[index],
            dragtype: 'subcategory'
        });
        e.dataTransfer.effectAllowed = 'move';
    };

    handleDragOverSubcategory = (e, index) => {
        e.preventDefault();
        if (this.state.dragtype !== 'subcategory') return;
        const data = this.props.invoiceState.invoice;
        let category = [...data.customsubcategory]
        const draggedOverItem = category[index];

        // If the dragged item is the same as the dragged over item, return
        if (this.state.draggedItem === draggedOverItem) {
            return;
        }

        // Remove the dragged item from the list
        const items = category.filter(item => item !== this.state.draggedItem);

        // Insert the dragged item at the new position
        items.splice(index, 0, this.state.draggedItem);

        this.props.handleChange('customsubcategory', items)

    };

    handleDragStartTax = (e, index) => {
        const data = this.props.invoiceState.invoice;
        let taxdata = [...data.taxdata]
        this.setState({
            draggedItem: taxdata[index],
            dragtype: 'tax'
        });
        e.dataTransfer.effectAllowed = 'move';
    };

    handleDragOverTax = (e, index) => {
        e.preventDefault();
        if (this.state.dragtype !== 'tax') return;
        const data = this.props.invoiceState.invoice;
        let taxdata = [...data.taxdata]
        const draggedOverItem = taxdata[index];

        // If the dragged item is the same as the dragged over item, return
        if (this.state.draggedItem === draggedOverItem) {
            return;
        }

        // Remove the dragged item from the list
        const items = taxdata.filter(item => item !== this.state.draggedItem);

        // Insert the dragged item at the new position
        items.splice(index, 0, this.state.draggedItem);

        this.props.handleChange('taxdata', items)

    };

    handleDragEnd = () => {
        this.setState({
            draggedItem: null,
            dragtype: ''
        });
    }

    onChangeSelect = e => {
        if (e.name == 'country') {
            this.props.handleChange('taxcountry', e.value)
            this.props.handleChange('taxdata', [])
            this.setState({ taxamountlocal: '', taxtypelocal: '', taxamounttypelocal: "" })
        } else {
            this.setState({ taxtypelocal: e.value });
        }
    }

    render() {

        if (this.state.viewRedirect) {
            return <Redirect to={'/listinvoicegenerate/' + this.state.id} />
        }

        const { invoiceState, invoicedetailState, countryReducer, invoicesubcategoryState, invoicecategoryState } = this.props;
        // const rowData = invoiceState.listinvoice;
        const spinner = invoiceState.spinner;
        const ListInvoice = invoicedetailState.listinvoicecdetail;
        const listinvoicesubcategory = invoicesubcategoryState.listinvoicesubcategory;
        const listinvoicecategory = invoicecategoryState.listinvoicecategory;
        const data = invoiceState.invoice;
        if (data !== undefined) {
            var imageData = data.tocompanyimage;
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]


        const companynameOption = [];
        ListInvoice.filter(filterstatus => filterstatus.status === true).map((item) => {
            companynameOption.push({ label: item.name, value: item._id, name: 'company', data: item })
        })

        const tocompanyOption = [];
        this.state.clientsList.filter(filterstatus => filterstatus.status === true).map((item) => {
            tocompanyOption.push({ label: item.name, value: item._id, data: item, name: 'tocompany' })
        })

        const subcategoryValue = [];
        const subcategoryOption = [];
        if (data.category && data.category.length > 0) {
            if (this.state.allOptionSubCategory) subcategoryOption.push(({ label: "All", value: '', name: 'subcategory' }))
        }
        if (listinvoicesubcategory && listinvoicesubcategory.length > 0) {
            listinvoicesubcategory.filter(e => e.status === true).map((item) => {
                if (listinvoicesubcategory.length > 0) {

                    let status = data.category.find(e => e == item.category._id);
                    if (status) {
                        subcategoryOption.push({ label: item.subcategory, value: item._id, name: 'subcategory' })
                    }
                }

                if (data.subcategory && data.subcategory.length > 0) {
                    let statusCat = data.subcategory.find(e => e == item._id);
                    if (statusCat) {
                        subcategoryValue.push(
                            { label: item.subcategory, value: item._id, name: 'subcategory' }
                        )
                    }
                }
            })
        }



        let selectedCompany = '';
        if (data.company) {
            selectedCompany = ListInvoice.find(e => e._id === data.company)
        }
        let selectedToCompany = '';
        if (data.tocompany) {
            selectedToCompany = this.state.clientsList.find(e => e._id === data.tocompany)
        }

        let address = '';
        ListInvoice.map((item) => {
            if (item._id == data.company) {

                address = item.address + ",\n" + item.city + ",\n" + item.state + ",\n" + item.code + "."
            }
        })
        data.address = address
        let selectedCategory = [];
        if (data.category && data.category.length > 0) {
            listinvoicecategory.map((item) => {
                let status = data.category.find(e => e == item._id)
                if (status) {
                    selectedCategory.push(
                        { label: item.name, value: item._id, name: item.name }
                    )
                }
            })
        }

        let listoptionCategory = []
        if (listinvoicecategory.length > 0) {
            if (this.state.allOptionCategory) listoptionCategory.push(({ label: "All", value: '', name: 'category' }))
            listinvoicecategory.filter(filterstatus => filterstatus.status === true).map((item) => {
                listoptionCategory.push(({ label: item.name, value: item._id, name: 'category' }))
            })
        }

        let selectedCountry = '';
        let selectedTax = '';
        const countryOptions = [];
        const TaxOptions = [];
        const listCountry = countryReducer.listCountry.filter(filterItem => filterItem.status === true);
        const listTax = countryReducer.listTax.filter(filterItem => filterItem.status === true);
        listCountry.map(item => {
            if (item._id == data.taxcountry) selectedCountry = { label: item.countryName, value: item._id, name: 'country' }
            countryOptions.push({ label: item.countryName, value: item._id, name: 'country' });
        })
        listTax.map(item => {
            if (data.taxcountry && data.taxcountry == item.country) {
                if (item.name == this.state.taxtypelocal) selectedTax = { label: item.name, value: item.name, name: 'tax' }
                TaxOptions.push({ label: item.name, value: item.name, name: 'tax' });
            }
        })
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6>Invoice List</h6></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div class="container-fluid"><div class="d-flex justify-content-between align-items-center">
                            <ul class="nav nav-tabs page-header-tab">
                                <li class="nav-item"><a class="nav-link active" id="user-tab" data-toggle="tab" href="#user-list" onClick={() => { this.getsettled('unpaid') }}>Unpaid invoice</a></li>
                                <li class="nav-item"><a class="nav-link " id="user-tab" data-toggle="tab" href="#user-add" onClick={() => this.getsettled('paid')}>Paid invoice</a></li>
                            </ul>
                            <div class="header-action"><Link to='/transactions'>
                                <button type="button" class="btn btn-primary change_btn_invoice "><i class="fa fa-history mr-2"></i>Transactions</button>
                            </Link>
                            </div>
                        </div>
                        </div>
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">List Invoice</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                    <div className="header-action">
                                                        {this.state.settledinvoice === 'paid' ? "" :
                                                            <>
                                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                                <button type="button" className="btn btn-primary change_btn_invoice mx-3" data-toggle="modal" data-target="#generate_multiple" id='Add' onClick={(e) => { this.setState({ companyMultipleInvoice: '', tocompanyMultipleInvoice: '', noshowallinmultiple: false }) }}><i className="fe fe-plus mr-2" id='Add' />Multiple Invoice</button>
                                                            </>
                                                        }
                                                        <button type="button" className="btn btn-primary change_btn_invoice mx-3" data-toggle="modal" data-target="#chnagepwd" onClick={(e) => this.changeModal(e)} id='Add'><i class="fa fa-key mx-2" aria-hidden="true"></i>Change Password</button>

                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    {/* <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a> */}
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6">
                                                    <label className="form-label">Company </label>
                                                    <Select
                                                        onChange={(e) => this.handleFilterChange(e, 'company', this.state.companyfilter)}
                                                        isMulti
                                                        value={this.state.companyfilterdata}
                                                        options={this.state.noshowallinfilter ? this.state.companyfilter : [{ label: 'All', value: '', name: 'company' }, ...this.state.companyfilter]}
                                                    />
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Month</label>
                                                    <Select
                                                        onChange={(e) => this.handleFilterChange(e, 'date')}
                                                        value={this.state.datefilterdata}
                                                        options={this.state.datefilter}
                                                    />
                                                </div>
                                                {/* {this.state.listinvoiceData && this.state.listinvoiceData.length && this.state.settledinvoice == 'unpaid' ?
                                                    <button type="button" className="btn btn-primary change_btn_invoice mx-3 mt-4" onClick={() => this.SendEmail()}>Send Email</button>
                                                    :
                                                    ''} */}
                                                <div className="col-lg-6">
                                                    {this.state.resetshow &&
                                                        <button type="button" className="btn btn-primary change_btn_invoice mx-2 float-left" onClick={() => this.ResetFilters()}>Reset</button>

                                                    }
                                                </div>
                                                {/* <div className="col-3">
                                                    <label className="form-label">Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='timesheetdate' onChange={this.datechange} placeholder="Module" value={this.state.timesheetdate} />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <label className="form-label">To Date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control" name='totimesheetdate' onChange={this.datechange} placeholder="Module" value={this.state.totimesheetdate} />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>


                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={this.state.perPage}
                                                        cacheBlockSize={this.state.perPage}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        onGridReady={this.onGridReady}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        wrapText={true}
                                                        animateRows={true}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        rowModelType={'infinite'}
                                                        onFilterChanged={(e) => { if (e.api.isAnyFilterPresent()) this.setState({ resetshow: true }) }}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                {spinner ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/banner-logo.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                                {this.state.printspinner ?
                                                    <div className='common_loader_ag_grid' style={{ position: 'fixed' }}>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/banner-logo.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
                {/* ==============detail============== */}
                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ zIndex: 1030 }}>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>{this.state.modalType} Invoice</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Invoice Raised By <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <Select
                                                onChange={this.handleSelectChange}
                                                value={selectedCompany ? { label: selectedCompany.name, value: selectedCompany._id, name: "company" } : ''}
                                                options={companynameOption}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.companyError ? "block" : 'none' }}>Company Name is required</div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Client <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <Select
                                                onChange={this.handleSelectChange}
                                                value={selectedToCompany ? { label: selectedToCompany.name, value: selectedToCompany._id, name: "tocompany" } : ''}
                                                options={tocompanyOption}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.tocompanyError ? "block" : 'none' }}>Company Name is required</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Client URL </label>
                                            <input type="text" className="form-control" placeholder="URL" name="url" onChange={this.handleOnchange} value={data.url} />
                                            <div className="invalid-feedback" style={{ display: this.state.urlError ? "block" : 'none' }}>Url is required</div>
                                            <div className="invalid-feedback" style={{ display: this.state.invalidurlError ? "block" : 'none' }}>Invalid Url</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Invoice Amount <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" placeholder="Invoice Amount" name="amount" onChange={this.handleOnchange} value={data.amount ? data.amount : ''} />
                                            <div className="invalid-feedback" style={{ display: this.state.amountError ? "block" : 'none' }}>Invoice Amount is required</div>
                                            <div className="invalid-feedback" style={{ display: this.state.numbermustError ? "block" : 'none' }}> Only accept  number </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Discount Name <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" placeholder="Discount name" name="discountname" onChange={this.handleOnchange} value={data.discountname ? data.discountname : ''} />
                                            <div className="invalid-feedback" style={{ display: this.state.discountnameError ? "block" : 'none' }}>Discount Name is required</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="form-group">
                                            <label className="form-label">Discount Type</label>
                                            <select type="text" className="form-control" placeholder="Discount" name="discounttype" onChange={this.handleOnchange} value={data.discounttype ? data.discounttype : 'flat'} >
                                                <option value='flat'>Flat</option>
                                                <option value='percentage'>Percentage</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="form-group">
                                            <label className="form-label">Discount <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" placeholder="Discount" name="discount" onChange={this.handleOnchange} value={data.discount} />
                                            <div className="invalid-feedback" style={{ display: this.state.discountError ? "block" : 'none' }}>Discount is required</div>
                                            <div className="invalid-feedback" style={{ display: this.state.discountnumberError ? "block" : 'none' }}>Only accept number</div>

                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="form-group">
                                            <label className="form-label">Invoice Date <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <div className='site_pass'>
                                                <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="invoicedate" onChange={this.handleOnchange} value={moment.utc(data.invoicedate).format('YYYY-MM-DD')} />
                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                <div className="invalid-feedback" style={{ display: this.state.invoicedateError ? "block" : 'none' }}>Date required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-3'>
                                        <div className="form-group">
                                            <label className="form-label">Due Date <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <div className='site_pass'>
                                                <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="duedate" onChange={this.handleOnchange} value={moment.utc(data.duedate).format('YYYY-MM-DD')} />
                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                <div className="invalid-feedback" style={{ display: this.state.duedateError ? "block" : 'none' }}>Date required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Invoice Month </label>
                                            <div className='site_pass'>
                                                <input type="month" disabled={this.state.modalType == "View"} className="form-control" name="invoicemonth" onChange={this.handleOnchange} value={data.invoicemonth ? data.invoicemonth : moment.utc(data.invoicedate).format('YYYY-MM')} />
                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                <div className="invalid-feedback" style={{ display: this.state.duedateError ? "block" : 'none' }}>Date required</div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className='col-lg-12'>
                                        <div className="form-group">
                                            <label className="form-label "> Use custom category  {data.usecustomcategory ? <i class="fa fa-toggle-on pl-2 cursor-pointer" onClick={() => this.props.handleChange('usecustomcategory', false)} style={{ fontSize: '20px', cursor: 'pointer' }}></i> : <i class="fa fa-toggle-off pl-2 " style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => this.props.handleChange('usecustomcategory', true)} ></i>}</label>
                                        </div>
                                    </div>
                                    {data.usecustomcategory ?
                                        <>
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label"> Custom Category </label>
                                                    {/* <label className="form-label float-right">  <i class="fe fe-chevron-up"></i> </label> */}
                                                    <div class="row gutters-xs">

                                                        <div class="col-10">
                                                            <input type="text" class="form-control" placeholder="Category" name='customcategory' value={this.state.customcategory} onChange={(e) => { this.setState({ customcategory: capitalizeFirstLetter(e.target.value) }) }} />
                                                        </div>
                                                        <span class="col-2">
                                                            <button class="btn btn-default" onClick={this.addCustomCategory} type="button" style={{ width: '60px', color: 'white', background: '#00ccff' }}>
                                                                <i class="fe fe-plus"></i>
                                                            </button>
                                                        </span>
                                                    </div>


                                                </div>
                                                {data.customcategory && data.customcategory.length ?
                                                    <table class="card-table table table-center table-md mt-4">
                                                        <tbody>
                                                            {data.customcategory.map((item, i) => {
                                                                return <tr key={i} draggable onDragStart={(e) => this.handleDragStart(e, i)}
                                                                    onDragOver={(e) => this.handleDragOver(e, i)}
                                                                    onDragEnd={this.handleDragEnd}>
                                                                    <td>{item}</td>
                                                                    <td><button class="card-options-remove" onClick={() => this.removeCutomCategory(i)} style={{
                                                                        width: '25px', height: '23px', color: 'white', background: '#00ccff', border: 'none', borderRadius: '5px'
                                                                    }}><i class="fe fe-x"></i></button></td>
                                                                </tr>
                                                            }
                                                            )}

                                                        </tbody>
                                                    </table> : ''}
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Custom Sub Category </label>

                                                    <div class="row gutters-xs">
                                                        <div class="col-5">
                                                            <select type="text" name='customcategorysub' class="form-control" placeholder="Search for..." value={this.state.customcategorysub} onChange={(e) => { this.setState({ customcategorysub: e.target.value }) }}>
                                                                <option value=''>Select a category</option>
                                                                {
                                                                    data.customcategory ? data.customcategory.map((item, i) => {
                                                                        return <option value={item}>{item}</option>
                                                                    })
                                                                        : ''
                                                                }

                                                            </select>
                                                        </div>
                                                        <div class="col-5">
                                                            <input type="text" name='customsubcategory' value={this.state.customsubcategory} onChange={(e) => { this.setState({ customsubcategory: capitalizeFirstLetter(e.target.value) }) }} class="form-control" placeholder="Subcategory" />
                                                        </div>
                                                        <span class="col-2">
                                                            <button class="btn btn-default" onClick={this.addCustomSubCategory} type="button" style={{ width: '60px', color: 'white', background: '#00ccff' }}>
                                                                <i class="fe fe-plus"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                                {data.customsubcategory && data.customsubcategory.length ? <table class="card-table table table-center table-md mt-4">
                                                    <tbody>
                                                        {data.customsubcategory.map((item, i) => {
                                                            return <tr key={i} draggable onDragStart={(e) => this.handleDragStartSubcategory(e, i)}
                                                                onDragOver={(e) => this.handleDragOverSubcategory(e, i)}
                                                                onDragEnd={this.handleDragEnd}>
                                                                <td>{item.customcategorysub}</td>
                                                                <td>{item.customsubcategory}</td>
                                                                <td><button class="card-options-remove" onClick={() => this.removeCutomSubCategory(i)} style={{
                                                                    width: '25px', height: '23px', color: 'white', background: '#00ccff', border: 'none', borderRadius: '5px'
                                                                }}><i class="fe fe-x"></i></button></td>
                                                            </tr>
                                                        }
                                                        )}
                                                    </tbody>
                                                </table> : ''}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label"> Category </label>
                                                    {/* <label className="form-label float-right">  <i class="fe fe-chevron-up"></i>Use custom category </label> */}
                                                    <Select
                                                        value={selectedCategory}
                                                        onChange={e => this.handleMultiSelectChange(e, "category")}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        options={listoptionCategory}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Category is required</div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Sub Category </label>
                                                    <Select
                                                        onChange={e => this.handleMultiSelectChange(e, "subcategory")}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        options={subcategoryOption}
                                                        value={subcategoryValue}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.subcategoryError ? "block" : 'none' }}>Sub Category is required</div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {data.taxapplied ?
                                        <>
                                            <div className='col-lg-3'>
                                                <div className="form-group">
                                                    <label className="form-label"> Tax Applied <i class="fa fa-toggle-on pl-2 cursor-pointer" onClick={() => this.props.handleChange('taxapplied', false)} style={{ fontSize: '20px', cursor: 'pointer' }}></i></label>
                                                    {/* <label className="form-label float-right">  <i class="fe fe-chevron-up"></i> </label> */}
                                                    <Select
                                                        onChange={this.onChangeSelect}
                                                        value={selectedCountry}
                                                        options={countryOptions}
                                                        placeholder="Select country"
                                                    />


                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="form-group">
                                                    <label className="form-label"> Tax Type</label>
                                                    {/* <select name='taxtype' class="form-control" value={this.state.taxtypelocal} onChange={(e) => { this.setState({ taxtypelocal: e.target.value }) }}>
                                                                        <option>CGST </option>
                                                                        <option>SGST </option>
                                                                    </select> */}
                                                    <Select
                                                        onChange={this.onChangeSelect}
                                                        value={selectedTax}
                                                        options={TaxOptions}
                                                        placeholder="Select Tax"
                                                    />

                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <div class="row gutters-xs">
                                                        <div class="col-5">
                                                            <label className="form-label">Percentage / Flat</label>
                                                            <select type="text" className="form-control" placeholder="Discount" name="taxamounttype" value={this.state.taxamounttypelocal} onChange={(e) => { this.setState({ taxamounttypelocal: e.target.value }) }} >
                                                                <option value='percentage'>Percentage</option>
                                                                <option value='flat'>Flat</option>
                                                            </select>

                                                        </div>
                                                        <div class="col-5">
                                                            <label className="form-label">Tax </label>
                                                            <input type="number" class="form-control" placeholder="Tax" name='taxamount' value={this.state.taxamountlocal} onChange={(e) => { this.setState({ taxamountlocal: e.target.value }) }} />
                                                        </div>
                                                        <span class="col-2">
                                                            <label className="form-label">&nbsp;</label>
                                                            <button class="btn btn-default" onClick={this.addTaxData} type="button" style={{ width: '60px', color: 'white', background: '#00ccff' }}>
                                                                <i class="fe fe-plus"></i>
                                                            </button>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                            </div>
                                            <div className='col-lg-9'>
                                                {data.taxdata && data.taxdata.length ?
                                                    <table class="card-table table table-center table-md mb-4">
                                                        <tbody>
                                                            {data.taxdata.map((item, i) => {
                                                                return <tr key={i} draggable onDragStart={(e) => this.handleDragStartTax(e, i)}
                                                                    onDragOver={(e) => this.handleDragOverTax(e, i)}
                                                                    onDragEnd={this.handleDragEnd}>
                                                                    <td>{item.taxtype}</td>
                                                                    <td>{item.taxamounttype}</td>
                                                                    <td>{item.taxamount}</td>
                                                                    <td><button class="card-options-remove" onClick={() => this.removeTax(i)} style={{
                                                                        width: '25px', height: '23px', color: 'white', background: '#00ccff', border: 'none', borderRadius: '5px'
                                                                    }}><i class="fe fe-x"></i></button></td>
                                                                </tr>
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table> : ''}
                                            </div>

                                        </>
                                        :
                                        <div className='col-lg-12'>
                                            <div className="form-group">
                                                <label className="form-label "> Tax Applied  <i class="fa fa-toggle-off pl-2 " style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => this.props.handleChange('taxapplied', true)} ></i></label>
                                            </div>
                                        </div>
                                    }

                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Notes </label>
                                            <textarea type="text" className="form-control" placeholder="URL" name="notes" onChange={this.handleOnchange} value={data.notes} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">
                                                Communication Email List<span> (Type and Press Enter to add Email)</span>
                                            </label>
                                            <div class="input-group">
                                                <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='communicationemail' onKeyPress={this.handleKeyDown} value={this.state.communicationemail} onChange={this.onChange} placeholder="Add More Email By Hitting Enter Button" />
                                                <div className="invalid-feedback" style={{ display: this.state.communicationemailValidError ? "block" : 'none' }}>Enter valid email</div>
                                            </div>
                                        </div>
                                        {data.communicationemail && data.communicationemail.length ? <div className="form-group">
                                            <div className="selectgroup selectgroup-pills">
                                                {
                                                    data.communicationemail.map((data, i) => {
                                                        return <label className="selectgroup-item">
                                                            <input type="checkbox" name="value" className="selectgroup-input" value="CSS" checked={true} disabled />
                                                            <span className="selectgroup-button">{data} {this.state.modalType !== 'View' && <button type="button" className="close" style={{ outline: 'none' }} onClick={() => this.removeEmail(i)}></button>}</span>
                                                        </label>
                                                    })
                                                }
                                            </div>
                                        </div> : ''}
                                    </div>
                                    {this.state.previousbalance || data.previousbalance ? <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Previous balance <span class="float-right"><input type="checkbox" className='p-0' style={{ height: '16px', width: '22px' }} onChange={this.changeinclude} id="autoSizingCheck" checked={data.previousbalance} />
                                                <span>Include previous balance ({this.state.previousbalance ? this.state.previousbalance : data.previousbalance})</span></span></label>
                                            <div className='site_pass'>
                                                <input type="number" disabled={this.state.modalType == "View"} placeholder='Previous balance' className="form-control" name="previousbalance" onChange={this.handleOnchange} value={data.previousbalance} />
                                                <div className="invalid-feedback" style={{ display: this.state.duedateError ? "block" : 'none' }}>Date required</div>
                                            </div>
                                        </div>
                                    </div> : ''}

                                </div>
                            </div>
                            <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                <div className="text-right payrollgen_btn">
                                    {this.state.save ?
                                        <button type="button" className="btn btn-primary"><i class="fa fa-spinner fa-spin mr-2"></i>Generating</button>
                                        :
                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Generate</button>
                                    }
                                </div>
                            </div>
                            <div id='overlay-model' style={{ display: this.state.editSpi && Object.keys(data.company && data.address).length <= 0 && (this.state.modalType === 'Edit') ? 'block' : 'none' }}></div>
                            {this.state.editSpi && Object.keys(data.company && data.address).length <= 0 ?
                                <div className='model_loader_poss'>
                                    <img className='loader_img_style_model' src='../../assets/images/banner-logo.png' />
                                    <Spinner className='spinner_load_model' animation="border" variant="info" >
                                    </Spinner>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                {/* ==========changepwd=========== */}
                <div class="modal fade" id="chnagepwd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title pt-2" id="exampleModalLabel"><i class="fa fa-key mx-2" aria-hidden="true"></i>Change Invoice Password</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div className="form-group ">
                                        <label className="form-label">New Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                        <div className='site_pass'>
                                            <input type={this.state.eye ? 'text' : 'password'} className="form-control" placeholder="New Password" name={'newpassword'} onChange={this.InvoicepwdonChange} value={this.state.newpassword} />
                                            <i class={this.state.eye ? "fa fa-eye" : "fa fa-eye-slash"} style={{ cursor: 'pointer', fontSize: '20px' }} onClick={this.invoiceviewClick}></i>
                                        </div>
                                        <div className="invalid-feedback" style={{ display: this.state.newpasswordError ? "block" : 'none' }}>New Password is required</div>
                                    </div>
                                    <div className="form-group site_pass">
                                        <label className="form-label">Confirm Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                        <div className='site_pass'>
                                            <input type={this.state.eyeCon ? 'text' : 'password'} className="form-control" placeholder="Confirm  Password" name={'confirmpassword'} onChange={this.InvoicepwdonChange} value={this.state.confirmpassword} />
                                            <i class={this.state.eyeCon ? "fa fa-eye" : "fa fa-eye-slash"} style={{ cursor: 'pointer', fontSize: '20px' }} onClick={this.invoiceviewClickCon}></i>
                                        </div>
                                        <div className="invalid-feedback" style={{ display: this.state.confirmpasswordError ? "block" : 'none' }}>{this.state.confirmpasswordError}</div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={this.InvoicepwdSubmit}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ============== */}
                {/* =============generate================== */}


                <div className="modal fade" id="generate_multiple" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ zIndex: 1030 }}>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>Multiple Invoice</h5>
                                <button type="button" id="closeModalMultiple" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Company <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <Select
                                                onChange={(e) => this.handleMultipleInvoice(e, 'company', companynameOption)}
                                                value={this.state.companyMultipleInvoice}
                                                options={companynameOption}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.companyError ? "block" : 'none' }}>Company Name is required</div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">To Company <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                            <Select
                                                onChange={(e) => this.handleMultipleInvoice(e, 'tocompany', tocompanyOption)}
                                                isMulti
                                                value={this.state.tocompanyMultipleInvoice}
                                                options={this.state.noshowallinmultiple ? tocompanyOption : [{ label: 'All', value: 'all' }, ...tocompanyOption]}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.tocompanyError ? "block" : 'none' }}>Company Name is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                <div className="text-right payrollgen_btn">
                                    {this.state.tocompanyMultipleInvoice && this.state.companyMultipleInvoice ?
                                        <button type="button" className="btn btn-primary" onClick={this.multipleGenerate}><i className="fe fe-save mr-2"></i>Generate</button>
                                        :
                                        <button type="button" disabled className="btn btn-primary">Generate</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ==================== Update paid amount ===================== */}
                <div className="modal fade" id="amountpaidupdate" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ zIndex: 1030 }}>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>Update Receipt</h5>
                                <button type="button" id="closeModalAttachment" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="form-group">
                                            <label className="form-label">Payment Method<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <select type="text" className="form-control" placeholder="Discount" name="paymentmethod" onChange={this.handleOnchange} value={data.paymentmethod ? data.paymentmethod : ''} >
                                                <option value=''>Select Payment Method</option>
                                                <option value='cash'>Cash</option>
                                                <option value='cheque'>Cheque</option>
                                                <option value='creditcard'>Credit Card</option>
                                                <option value='ach'>ACH</option>
                                            </select>
                                            <div className="invalid-feedback" style={{ display: this.state.paymentmethodError ? "block" : 'none' }}>Payment method is required</div>
                                        </div>
                                    </div>
                                    {
                                        data.paymentmethod == 'cheque' || data.paymentmethod == 'ach' ?
                                            <>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Name of account
                                                            {/* <span className="ml-1" style={{ color: 'red' }}>*</span> */}
                                                        </label>
                                                        <input type="text" className="form-control" placeholder="Name of account" name="bankaccountname" onChange={this.handleOnchange} value={data.bankaccountname} />
                                                        <div className="invalid-feedback" style={{ display: this.state.bankaccountnameError ? "block" : 'none' }}>Name of account is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Name of bank </label>
                                                        <input type="text" className="form-control" placeholder="Name of bank" name="bankname" onChange={this.handleOnchange} value={data.bankname} />
                                                        <div className="invalid-feedback" style={{ display: this.state.banknameError ? "block" : 'none' }}>Name of bank is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Routing number </label>
                                                        <input type="text" className="form-control" placeholder="Routing number" name="bankroutingnumber" onChange={this.handleOnchange} value={data.bankroutingnumber} />
                                                        <div className="invalid-feedback" style={{ display: this.state.bankroutingnumberError ? "block" : 'none' }}>Routing number is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Checking account number</label>
                                                        <input type="text" className="form-control" placeholder="Checking account number" name="bankaccountnumber" onChange={this.handleOnchange} value={data.bankaccountnumber} />
                                                        <div className="invalid-feedback" style={{ display: this.state.bankaccountnumberError ? "block" : 'none' }}>Checking account number is required</div>
                                                    </div>
                                                </div>
                                            </>
                                            : ''
                                    }
                                    {
                                        data.paymentmethod == 'creditcard' ?
                                            <>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Credit card name </label>
                                                        <input type="text" className="form-control" placeholder="Name of account" name="creditcardname" onChange={this.handleOnchange} value={data.creditcardname} />
                                                        <div className="invalid-feedback" style={{ display: this.state.creditcardnameError ? "block" : 'none' }}>Name of account is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Credit card issuer </label>
                                                        <input type="text" className="form-control" placeholder="Credit card issuer" name="creditcardissuer" onChange={this.handleOnchange} value={data.creditcardissuer} />
                                                        <div className="invalid-feedback" style={{ display: this.state.creditcardissuerError ? "block" : 'none' }}>Credit card issuer  is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Credit card number </label>
                                                        <input type="text" className="form-control" placeholder="Credit card number" name="creditcardnumber" onChange={this.handleOnchange} value={data.creditcardnumber} />
                                                        <div className="invalid-feedback" style={{ display: this.state.creditcardnumberError ? "block" : 'none' }}>Credit card number is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Expiry date</label>
                                                        <input type="text" className="form-control" placeholder="MM/YYYY" name="creditcardexpirydate" onChange={this.handleOnchange} value={data.creditcardexpirydate} />
                                                        <div className="invalid-feedback" style={{ display: this.state.creditcardexpirydateError ? "block" : 'none' }}>Expiry date is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">CVV</label>
                                                        <input type="text" className="form-control" placeholder="Credit card cvv" name="creditcardcvv" onChange={this.handleOnchange} value={data.creditcardcvv} />
                                                        <div className="invalid-feedback" style={{ display: this.state.creditcardcvvError ? "block" : 'none' }}>CVV is required</div>
                                                    </div>
                                                </div>
                                            </>
                                            : ''
                                    }
                                    {
                                        data.paymentmethod == 'cheque' ?
                                            <>
                                                <div className='col-lg-6'>
                                                    <div className="form-group">
                                                        <label className="form-label">Cheque number</label>
                                                        <input type="text" className="form-control" placeholder="Cheque number" name="chequenumber" onChange={this.handleOnchange} value={data.chequenumber} />
                                                        <div className="invalid-feedback" style={{ display: this.state.chequenumberError ? "block" : 'none' }}>Cheque number is required</div>
                                                    </div>
                                                </div>
                                            </>
                                            : ''
                                    }
                                    {data.paymentmethod ?
                                        <>
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Amount Received<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="number" className="form-control" placeholder="Amount Received" name="amountpaid" onChange={this.handleOnchange} value={data.amountpaid ? data.amountpaid : ''} />
                                                    <div className="invalid-feedback" style={{ display: this.state.amountpaidError ? "block" : 'none' }}>Invoice Amount is required</div>
                                                    <div className="invalid-feedback" style={{ display: this.state.amountpaidnumbermustError ? "block" : 'none' }}> Only accept  number </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Amount received Date <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <div className='site_pass'>
                                                        <input type="date" disabled={this.state.modalType == "View"} className="form-control" name="amountpaiddate" onChange={this.handleOnchange} value={data.amountpaiddate ? moment.utc(data.amountpaiddate).format('YYYY-MM-DD') : ''} />
                                                        <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        <div className="invalid-feedback" style={{ display: this.state.amountpaiddateError ? "block" : 'none' }}>Date required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Attachment <span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="file" disabled={this.state.modalType == "View"} className="form-control" name="paymentattachment" onChange={this.handleOnchange} />
                                                    <div className="invalid-feedback" style={{ display: this.state.paymentattachmentError ? "block" : 'none' }}>Attachment is required</div>
                                                </div>
                                                <div className="form-group ml-2">

                                                    {
                                                        this.state.paymentattachment || data.paymentattachment
                                                            ?
                                                            <div style={{ width: '200px', height: '100px' }}>
                                                                <a href={this.state.paymentattachment ? URL.createObjectURL(this.state.paymentattachment) : ImportedURL.LIVEURL + data.paymentattachment} target='_blank' ><i style={{ fontSize: '50px' }} className='fa fa-file-text'></i></a>
                                                                {/* <img style={{ width: '100px', objectFit: 'cover' }} src={this.state.paymentattachment ? URL.createObjectURL(this.state.paymentattachment) : ImportedURL.LIVEURL + data.paymentattachment} alt="" /> */}
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        : ''

                                    }

                                </div>
                            </div>
                            <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                <div className="text-right payrollgen_btn">
                                    {this.state.save ?
                                        <button type="button" className="btn btn-primary"><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                        :
                                        <button type="button" className="btn btn-primary" onClick={this.submitattachment}><i className="fe fe-save mr-2"></i>Save</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* =============================== */}


                {/* <div > */}
                <div style={{ visibility: 'hidden', overflow: "hidden", height: 0 }}>
                    {this.state.pdfList && this.state.pdfList.length ?
                        this.state.pdfList.map((pdfData, i) => {
                            const d = new Date(pdfData.invoicemonth ? pdfData.invoicemonth : pdfData.invoicedate);
                            let year = d.getFullYear();
                            var discountAmount = 0;
                            var discountedAmount = pdfData.discounttype === 'percentage' ? (pdfData.discount / 100) * pdfData.amount : pdfData.discount;

                            discountAmount = pdfData.amount - discountedAmount;

                            let taxcountrydata = listCountry.find(obj => obj._id === pdfData.taxcountry);
                            let countrydata = listCountry.find(obj => obj._id === pdfData.country);
                            let taxarray = []
                            if (pdfData.taxapplied) {
                                pdfData.taxdata.forEach(tax => {
                                    let taxedamount = tax.taxamounttype === 'percentage' ? (tax.taxamount / 100) * pdfData.amount : tax.taxamount;
                                    discountAmount = discountAmount + taxedamount;

                                    if (tax.taxamount) {
                                        taxarray.push(<div className='invoice_dis subtotal_invoice'>
                                            <div className='row justify-content-end' style={{ border: "none" }}>
                                                <div className='col-lg-3' style={{ border: '1px solid #000' }}>
                                                    <p className='dis_Name'>
                                                        {tax.taxtype} {tax.taxamounttype == 'percentage' ? `${(taxcountrydata && taxcountrydata.countryName && taxcountrydata.countryName.trim() == 'INDIA' ? ' OUTPUT' : '')} @ ${tax.taxamount}%` : ''}
                                                    </p>

                                                </div>
                                                <div className='col-lg-2'>
                                                    <p className='Disc_amount'>{Currency((taxedamount).toString())}</p>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                })
                            }

                            if (pdfData.previousbalance)
                                discountAmount += pdfData.previousbalance
                            return <div className="container-fluid">
                                <div className="tab-content pt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel"></div>
                                    <div className='card' id={'print_pagepdf' + i} style={{ fontFamily: 'Arial' }}>
                                        <div className='card-body invoice_generate'>
                                            <div className='row print_design' >
                                                <div className='payslip_content'>
                                                    <p className='payslip_heading'>
                                                        INVOICE FOR THE MONTH OF  {moment.utc(d).format('MMMM').toUpperCase() + " " + year}
                                                    </p>
                                                </div>
                                                <div className='col-lg-8'>
                                                    <div className='invoice_address' style={{ fontSize: '20px' }}>
                                                        <h4 style={{ fontSize: '35px' }}>{pdfData.company.name}</h4>
                                                        <p>{pdfData.company.address}</p>
                                                        <p>{pdfData.company.city}</p>
                                                        <p>{pdfData.company.state + " " + pdfData.company.code}</p>
                                                        <p>
                                                            <a href='#' style={{ color: '#0096FF' }}>{pdfData.invoiceurl}</a>
                                                        </p>
                                                        <a href={`mailto:${pdfData.company.email}`} style={{ color: '#0096FF' }}>{pdfData.company.email}</a>
                                                        <p className='pt-1'>
                                                            {/* <a href={'tel:' + pdfData.company.phonenumber}>{pdfData.company.phonenumber}</a> */}
                                                            {this.phonenumberformat(pdfData.company.phonenumber)}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className='col-lg-4 pt-4'>
                                                    <div className='payslip_content pt-4'>
                                                        <div className='payslip_company_intro'>
                                                            <div className='company_logo'>
                                                                <img src={ImportedURL.LIVEURL + pdfData.company.image} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='invoice_detail_billto print_design mt-3'>
                                                <div className='row'>
                                                    <div className='col-lg-6 ' style={{ fontSize: '20px', lineHeight: '1.2' }}>
                                                        <h4 className='invoice_detail_billto_heading'>BILL TO</h4>
                                                        <p className='dynaic_tex_invoice mb-1'>{pdfData.tocompany}</p>
                                                        <p className='dynaic_tex_invoice'>{pdfData.toaddress}</p>
                                                    </div>
                                                    <div className='col-lg-6 ' style={{ fontSize: '20px', lineHeight: '1.2' }}>
                                                        <h4 className='invoice_detail_billto_heading inv_det'>INVOICE DETAILS</h4>
                                                        <div className='detslin'>
                                                            <p className='invoice_heading_tex'>Invoice #</p>
                                                            <p className='dynaic_tex_invoice'>{pdfData.invoiceId}</p>
                                                        </div>
                                                        <div className='detslin mt-2'>
                                                            <p className='invoice_heading_tex'>Invoice Date</p>
                                                            <p className='dynaic_tex_invoice'>{moment.utc(pdfData.invoicedate).format('MM/DD/YYYY')}</p>
                                                        </div>
                                                        <div className='detslin mt-2'>
                                                            <p className='invoice_heading_tex'>Due Date</p>
                                                            <p className='dynaic_tex_invoice_due'>{moment.utc(pdfData.duedate).format('MM/DD/YYYY')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='invoice_detail print_design mt-5' style={{ fontSize: '20px' }}>
                                                <div className='table_invoice'>
                                                    <div className='row tab_inv'>
                                                        <div className='col-lg-5 col_border_right'>
                                                            <h6 className='desc_h6'>Description</h6>
                                                            {pdfData.usecustomcategory ?
                                                                pdfData.customcategory && pdfData.customcategory.map((item) => {
                                                                    return (
                                                                        <>
                                                                            <h5 className='desc_h5'>{item}</h5>
                                                                            <>
                                                                                {
                                                                                    (pdfData.customsubcategory)
                                                                                    &&
                                                                                    pdfData.customsubcategory.map((item1) => {
                                                                                        if (item == item1.customcategorysub)
                                                                                            return (
                                                                                                <>
                                                                                                    <p className='desc_p'>{item1.customsubcategory}</p>
                                                                                                </>
                                                                                            )
                                                                                    })
                                                                                }
                                                                            </>

                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                pdfData.category && pdfData.category.map((item) => {
                                                                    return (
                                                                        <>
                                                                            <h5 className='desc_h5'>{item.name}</h5>
                                                                            <>
                                                                                {
                                                                                    (pdfData.subcategory)
                                                                                    &&
                                                                                    pdfData.subcategory.map((item1) => {
                                                                                        if (item._id == item1.category)
                                                                                            return (
                                                                                                <>
                                                                                                    <p className='desc_p'>{item1.subcategory}</p>
                                                                                                </>
                                                                                            )
                                                                                    })
                                                                                }
                                                                            </>

                                                                        </>
                                                                    )
                                                                })
                                                            }


                                                        </div>
                                                        <div className='col-lg-2 col_border_right'>
                                                            <h6 className='desc_h6'>Month</h6>
                                                            <p className='next_tab_div'>{moment.utc(d).format('MMMM') + " - " + year}</p>
                                                        </div>
                                                        <div className='col-lg-3 col_border_right'>
                                                            <h6 className='desc_h6'>Url</h6>
                                                            <a href='#' className='next_tab_div' style={{ color: '#0096FF' }}>{pdfData.url ? pdfData.url : '-'}</a>
                                                        </div>
                                                        <div className='col-lg-2 col_border_right'>
                                                            <h6 className='desc_h6'>Total</h6>
                                                            <p className='next_tab_div'>

                                                                {(countrydata && countrydata.countryName && countrydata.countryName.trim() == 'INDIA' ? <img src={exampleImage} width='10px' style={{ marginTop: '-2px' }} /> : "$")} {Currency((pdfData.amount).toString())}</p>

                                                        </div>
                                                    </div>
                                                    {pdfData.discount && Number(pdfData.discount) ?
                                                        <div className='invoice_dis print_subtotal'>
                                                            <div className='row'>
                                                                <div className='col-lg-10'>
                                                                    <p className='dis_Name'>
                                                                        {pdfData.discountname ? pdfData.discountname : 'Multiple Property Discount Applied'}
                                                                    </p>

                                                                </div>
                                                                <div className='col-lg-2'>
                                                                    <p className='Disc_amount'>{pdfData.discount ? " - " + Currency((discountedAmount).toString()) : 0}</p>
                                                                </div>
                                                            </div>
                                                        </div> : ''}
                                                    {pdfData.previousbalance && Number(pdfData.previousbalance) ?
                                                        <div className='invoice_dis print_subtotal'>
                                                            <div className='row'>
                                                                <div className='col-lg-10'>
                                                                    <p className='dis_Name'>
                                                                        Outstanding balance
                                                                    </p>

                                                                </div>
                                                                <div className='col-lg-2'>
                                                                    <p className='Disc_amount'>{Currency((pdfData.previousbalance).toString())}</p>
                                                                </div>
                                                            </div>
                                                        </div> : ''}
                                                    {pdfData.taxapplied ?
                                                        taxarray
                                                        // <div className='invoice_dis subtotal_invoice'>
                                                        //     <div className='row justify-content-end' style={{ border: "none" }}>
                                                        //         <div className='col-lg-2'>
                                                        //             <p className='dis_Name'>
                                                        //                 {pdfData.taxtype}
                                                        //             </p>

                                                        //         </div>
                                                        //         <div className='col-lg-2'>
                                                        //             <p className='Disc_amount'>{Currency((taxedamount).toString())}</p>
                                                        //         </div>
                                                        //     </div>
                                                        // </div>
                                                        : ''}
                                                    <div className='invoice_dis subtotal_invoice'>
                                                        <div className='row justify-content-end' style={{ border: "none" }}>
                                                            <div className='col-lg-3' style={{ border: '1px solid #000' }}>
                                                                <p className='dis_Name'>
                                                                    SUBTOTAL
                                                                </p>

                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <p className='Disc_amount'>{discountAmount ? Currency((discountAmount).toString()) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='invoice_dis subtotal_invoice'>
                                                        <div className='row justify-content-end' style={{ border: "none" }}>
                                                            <div className='col-lg-3' style={{ border: '1px solid #000' }}>
                                                                <p className='balance_name_invoice'>
                                                                    BALANCE DUE
                                                                </p>

                                                            </div>
                                                            <div className='col-lg-2' >
                                                                <p className='Disc_amount current_amount_invoice'>
                                                                    {/* <i class="fa fa-inr" aria-hidden="true"></i> */}
                                                                    {/* <img src={exampleImage} width='10px' /> */}

                                                                    {(countrydata && countrydata.countryName && countrydata.countryName.trim() == 'INDIA' ? <img src={exampleImage} width='10px' style={{ marginTop: '-2px' }} /> : "$")} {(discountAmount ? Currency((discountAmount).toString()) : 0)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        pdfData.notes ? <div className='mt-2'>
                                                            <p>*Notes: {pdfData.notes}</p>
                                                        </div> : ''
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })


                        : ''
                    }
                </div>

            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    invoiceState: state.invoice,
    invoicedetailState: state.invoicedetail,
    invoicesubcategoryState: state.invoicesubcategory,
    invoicecategoryState: state.invoicecategory,
    AccountState: state.account,
    countryReducer: state.countryReducer
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            handleChange: AC_HANDLE_INVOICE_CHANGE,
            listinvioce: AC_LIST_INVOICE,
            setlistinvioce: AC_SET_LIST_INVOICE,
            viewinvioce: AC_VIEW_INVOICE,
            resetinvioce: AC_RESET_INVOICE,
            listinvoicedetail: AC_LIST_INVOICE_DETAIL,
            listinvoicecategory: AC_LIST_INVOICE_CATEGORY,
            listinvoicesubcategory: AC_LIST_INVOICE_SUB_CATEGORY,
            emptyinvoice: AC_EMPTY_INVOICE,
            AC_SPINNER_INVOICE_ALL,
            AC_LIST_TAX
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);


