import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import { Link, Redirect } from 'react-router-dom';
import { AC_LIST_PROJECT } from '../../actions/projectAction';

class ProjectCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "",
            taskBoardRedirect: false,
            id: ''
        }
    }
    componentDidMount() {
        this.props.ListProject();
        localStorage.setItem('invoicetoken', "");
    }
    taskboardRedirect = (id) => {
        this.setState({ taskBoardRedirect: true, id: id })
    }
    render() {
        if (this.state.taskBoardRedirect) {
            return <Redirect to={'/task-board/' + this.state.id} />
        }
        const { ProjectState, AccountState } = this.props;
        const account = AccountState?.account;
        const projectListData = ProjectState.listProject;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Project" });
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <Link to="/project-category"><h6 className="highlights_breadcrump"> Tasks</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <Link to="/view-project"><h6 className="highlights_breadcrump">View Tasks</h6></Link></p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title d-flex "> <div className='rounded_icon'><i className="fa fa-tasks mr-2"></i></div><span className='pt-2'> Tasks</span> </h3>
                                        {/* <div className="card-options">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <ul className="nav nav-tabs page-header-tab">
                                                </ul>
                                                <div className="header-action">
                                                    <Link to='/list-project'><button type="button" className="btn btn-primary mr-3" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            {projectListData && projectListData.map((data) => {
                                                const slicedArray = (data.teamImage).slice(0, 4);
                                                return <div class="col-lg-4 col-md-12 mb-3">
                                                    <div class="card project_category" onClick={(e) => this.taskboardRedirect(data._id)}>
                                                        <div class="card-header">
                                                            <h3 class="card-title">{data.name}</h3>
                                                        </div>
                                                        <div class="card-body">
                                                            <span class="tag tag-blue mb-3">Web Design</span>
                                                            <div class="row">
                                                                <div class="col-4 py-1">
                                                                    <strong>Created:</strong>
                                                                </div>
                                                                <div class="col-8 py-1">{data.date}</div>
                                                                <div class="col-4 py-1">
                                                                    <strong>Lead:</strong>
                                                                </div>
                                                                <div class="col-8 py-1">{data.leader}</div>
                                                                <div class="col-4 py-1">
                                                                    <strong>Team:</strong>
                                                                </div>
                                                                <div class="col-8 py-1">
                                                                    <div class="avatar-list avatar-list-stacked">
                                                                        {slicedArray && slicedArray.map((img) => {
                                                                            return <img class="avatar avatar-sm" src={ImportedURL.LIVEURL + img} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" />
                                                                        })}
                                                                        {/* <span class="avatar avatar-sm">+8</span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    ProjectState: state.project,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            ListProject: AC_LIST_PROJECT
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCategory);