import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import './assets/css/style.css'

// add token to a request
axios.interceptors.request.use(
    (req) => {
        const accessToken = localStorage.getItem("mytechaitoken");
        req.headers.Authorization = accessToken;
        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);

// For any requests
axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        if (err.response) {
            if (err.response.status === 403) {
                localStorage.removeItem('mytechaitoken');
                window.location = '/';
            }
        }
        return Promise.reject(err);
    }
);

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
