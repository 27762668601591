import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import moment from 'moment';
import { AC_HANDLE_INVOICE_DETAIL_CHANGE, AC_LIST_INVOICE_DETAIL, AC_VIEW_INVOICE_DETAIL, AC_RESET_INVOICE_DETAIL } from '../../../actions/invoicedetail';
import DatePicker from "react-multi-date-picker"
import { Emailvalidate, Phonenumber } from '../../../common/validate';
import { Row } from 'react-bootstrap';
import { AC_LIST_COUNTRY } from '../../../actions/countryAction';
import { AC_ACCOUNT_DETAILS, AC_SETTING_DETAIL } from '../../../actions/accountAction';





const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    image: '',
    email: '',
    nameError: false,
    imageError: false,
    addressError: false,
    emailError: false,
    phonenumberError: false,
    phonenumberInvalid: false,
    cityError: false,
    ccError: false,
    stateError: false,
    code: false,
    ccLocal: true,




    communicationemailValidError: false,
    columnDefs: [
        { headerName: 'Name', field: 'name', width: 130, floatingFilter: true },
        {
            headerName: 'Image', field: 'image', width: 130, floatingFilter: true,
            cellRendererFramework: function (params) {
                return <div>
                    <img style={{ width: '40px', height: '40px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + params.data.image} alt="" />
                </div>
            }
        },
        {
            headerName: 'Address', field: 'address', width: 130, floatingFilter: true,
            valueGetter: function (params) {
                return params.data.address + " " + params.data.city + " " + params.data.state + " " + params.data.code;
            }
        },
        {
            headerName: 'Email', field: 'email', width: 130, floatingFilter: true,
        },
        {
            headerName: 'PhoneNumber', field: 'phonenumber', width: 130, floatingFilter: true,
        },
        {
            headerName: 'NameType', field: 'nametype', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Default', width: 150, field: 'status', cellStyle: { textAlign: 'center' }, filter: false, sortable: false,
            cellRenderer: function (params) {
                if (params.data.default) {
                    return `<i class="fa fa-toggle-on" data-toggle="tooltip" style="font-size: 30px;color: #2CBAEC; cursor:pointer" data-action-type="Default"></i>`;
                } else {
                    return '<i class="fa fa-toggle-off" data-toggle="tooltip" style="font-size: 30px;color: #2CBAEC;cursor:pointer" data-action-type="Default"></i>';
                }
            }
        },

        {
            headerName: 'Status', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class Inviocedetail extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.props.resetinviocedetail();
        // this.props.listEmppayrollitem();
        const account = this.props.AccountState.account.phonecode;
        this.props.handleChange('cc', account)

        this.setState({ nameError: false })
        this.setState({ addressError: false })
        this.setState({ cityError: false })
        this.setState({ stateError: false })
        this.setState({ codeError: false })
        this.setState({ emailError: false })
        this.setState({ imageError: false })
        this.setState({ phonenumberError: false })
        this.setState({ nametypeError: false })
        this.setState({ image: '' })
        document.getElementById('imageSet').value = '';
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.viewinviocedetail(event.data._id);
            this.setState({ nameError: false })
            this.setState({ addressError: false })
            this.setState({ cityError: false })
            this.setState({ stateError: false })
            this.setState({ codeError: false })
            this.setState({ emailError: false })
            this.setState({ imageError: false })
            this.setState({ phonenumberError: false })
            this.setState({ nametypeError: false })
        }
        if (value === 'Payslip') {
            this.setState({ id: event.data._id, viewRedirect: true });

        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteInvoicedetail + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            this.props.listinviocedetail();
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'invoicedetails' })
                        .then((data) => {
                            this.props.listinviocedetail();
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
        if (value === 'Default') {
            Swal.fire({
                title: 'Are you sure to set this Default?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.setDefault, { id: event.data._id, default: event.data.default ? 0 : 1 })
                        .then((data) => {
                            this.props.listinviocedetail();
                            Success('Default Detail updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'address', 'email', 'phonenumber'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { invoicedetailState } = this.props;
        const exportData = invoicedetailState.listinvoicecdetail;
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Invoice Details";
        const headers = [['#', 'Name', 'Address', 'Email', 'Phonenumber']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.name,
            elt.address + " " + elt.city + " " + elt.state + " " + elt.code,
            elt.email,
            elt.phonenumber,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("InvioceDetail.pdf")
    }
    handleSelectChange = (e) => {
        this.setState({ spinner: false })
        const { name, value } = e;
    }
    handleOnchange = (e) => {
        const { name, value } = e.target;
        const Error = name + "Error";
        const { invoicedetailState } = this.props;
        const data = invoicedetailState.invoicedetail;
        if (name == 'email') {
            if (value) {
                if (Emailvalidate(value)) {
                    this.setState({ communicationemailValidError: false })
                    // this.props.handleChange('email', value)
                } else {
                    this.setState({ communicationemailValidError: true })
                }
                this.setState({ email: value });
            } else {
                this.setState({ communicationemailValidError: false })
                this.setState({ email: value });
            }
        }
        else if (name == 'phonenumber') {
            if (!isNaN(value)) {
                this.props.handleChange(name, value);
                if (Phonenumber(value)) {
                    this.setState({ [Error]: false, phonenumberInvalid: false })
                } else this.setState({ [Error]: false, phonenumberInvalid: true })
            }

        }
        else {
            this.props.handleChange(name, value);

            if (name == 'name', 'address', 'city', 'state', 'email', 'phonenumber', 'code') {
                if (value) {
                    this.setState({ [Error]: false });
                } else {
                    this.setState({ [Error]: true })
                }
            }
        }

    }
    uploadhandleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'image') {
            this.setState({ image: event.target.files[0], imageError: false })
        }
        this.props.handleChange(name, event.target.files[0]);

    }
    componentDidMount() {
        this.props.listinviocedetail();
        this.props.listcountry();
        this.props.AC_ACCOUNT_DETAILS();
        this.props.AC_SETTING_DETAIL();
        // localStorage.setItem('invoicetoken', "");
    }
    submit = () => {
        const { invoicedetailState, AccountState } = this.props;
        const rowData = invoicedetailState.listinvoicecdetail;
        const data = invoicedetailState.invoicedetail;
        var fields = this.props.AccountState.fields;

        // var countryCode = localStorage.getItem("countryCode");
        let valid = 1
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0
        }
        if (!data.image) {
            this.setState({ imageError: true });
            valid = 0
        }
        if (!data.address) {
            this.setState({ addressError: true });
            valid = 0
        }
        if (!data.city) {
            this.setState({ cityError: true });
            valid = 0
        }
        if (!data.state) {
            this.setState({ stateError: true });
        }
        if (!data.code) {
            this.setState({ codeError: true });
        }
        if (!data.nametype) {
            this.setState({ nametypeError: true });
        }
        if (data.email.length == 0) {
            this.setState({ emailError: true });
            valid = 0
        }
        if (!data.phonenumber) {
            this.setState({ phonenumberError: true });
            valid = 0
        }
        if (this.state.phonenumberInvalid) {
            valid = 0
        }
        // let array = data.email ? [...data.email] : [];
        // if (this.state.email) {
        //     data['email'] = array.push(this.state.email)
        // }

        // if (!this.state.communicationemailValidError && this.state.email) {
        //     data['email'] = (data.email && data.email.length > 0) ? data.email.push(this.state.email) : [data.email]
        // }

        if (valid) {
            var formData = new FormData();
            formData.append("name", data.name);
            formData.append("address", data.address);
            formData.append("city", data.city);
            formData.append("state", data.state);
            formData.append("code", data.code);
            formData.append("nametype", data.nametype);
            formData.append("email", JSON.stringify(data.email));
            formData.append("cc", data.cc);
            formData.append("phonenumber", data.phonenumber);
            formData.append("image", data.image);
            formData.append("url", data.url ? data.url : '');

            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addInvoicedetail, formData)
                    .then((formdata) => {
                        Success(formdata.statusText);
                        this.setState({ save: false });
                        this.props.listinviocedetail();
                        this.props.resetinviocedetail();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateInvoicedetail + '/' + this.state.id, formData)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.listinviocedetail();
                        this.props.resetinviocedetail();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });


            }
        }
    }
    handleKeyDown = e => {
        this.setState({ spinner: false })
        const { value } = e.target;
        const { invoicedetailState } = this.props;
        const data = invoicedetailState.invoicedetail;
        if (e.key === 'Enter' && this.state.email !== '' && !this.state.communicationemailValidError) {
            let newData = [...data.email];
            newData.push(value);
            this.props.handleChange("email", newData);
            this.setState({ email: '' });
            this.setState({ emailError: false })
        }
    }
    removeEmail = (i) => {
        this.setState({ spinner: false })
        const { invoicedetailState } = this.props;
        const data = invoicedetailState.invoicedetail.email;
        data.splice(i, 1);
        this.props.
            handleChange("email", data);
    }
    handlePhoneCode = e => {
        const { name, value, label } = e;
        this.setState({ ccLocal: false });
        this.props.handleChange('cc', value);
        const Error = name + "Error";
        this.setState({ [Error]: false });
    }


    render() {
        const { invoicedetailState, AccountState } = this.props;
        const rowData = invoicedetailState.listinvoicecdetail;
        const data = invoicedetailState.invoicedetail;
        var phoneData = this.props.countryReducer.listCountry;

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        if (data !== undefined) {
            var imageData = data.image;
        }
        var ccData = '';
        const account = AccountState.account;
        if (phoneData) {
            phoneData.map(item => {
                if (data.cc) {
                    if (item._id == data.cc) {
                        ccData = { label: item.countryCode, value: item._id, flag: item.flag }
                    }
                }
            })
        }
        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            // '<div><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/listinvoicedetail"><h6>Invoice Details List</h6></Link></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">List Invoice Detail</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>{this.state.modalType} Invioce Detail</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                        <img src='../../assets/images/cancel.png' />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row'>

                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Name" name="name" onChange={this.handleOnchange} value={data.name ? data.name : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Address<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Address" name="address" onChange={this.handleOnchange} value={data.address ? data.address : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.addressError ? "block" : 'none' }}>Address is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">City<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="City" name="city" onChange={this.handleOnchange} value={data.city ? data.city : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.cityError ? "block" : 'none' }}>City is required</div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">State<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="State" name="state" onChange={this.handleOnchange} value={data.state ? data.state : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.stateError ? "block" : 'none' }}>State is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Postal code<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Postal Code" name="code" onChange={this.handleOnchange} value={data.code ? data.code : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.codeError ? "block" : 'none' }}>Code is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">URL</label>
                                                            <input type="text" className="form-control" placeholder="URL" name="url" onChange={this.handleOnchange} value={data.url ? data.url : ''} />
                                                            {/* <div className="invalid-feedback" style={{ display: this.state.codeError ? "block" : 'none' }}>Code is required</div> */}
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='form-group'>
                                                            <Row style={{ marginBottom: '-15px' }}>
                                                                <label className="form-label">Phone Number<span className="" style={{ color: 'red' }}>*</span></label>
                                                                <div className="form-group col-lg-4 pr-1">
                                                                    <Select
                                                                        isDisabled={this.state.modalType == "View"}
                                                                        value={ccData ? { label: <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + ccData.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + ccData.label }}></span> } : ''}
                                                                        placeholder='+'
                                                                        onChange={this.handlePhoneCode}
                                                                        options={phoneData && phoneData.filter(filterItem => filterItem.status === true).map(item => {
                                                                            return {
                                                                                label: <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.countryCode }} />
                                                                                , value: item._id, name: 'cc'
                                                                            }
                                                                        })} />
                                                                    <div className="invalid-feedback" style={{ display: this.state.ccError ? "block" : 'none' }}>CC required</div>
                                                                </div>
                                                                <div className="form-group col-lg-8 ">
                                                                    <input type="text" className="form-control" placeholder="Phone Number" name="phonenumber" onChange={this.handleOnchange} value={data.phonenumber ? data.phonenumber : ''} maxLength={10} />
                                                                    <div className="invalid-feedback" style={{ display: this.state.phonenumberError || this.state.phonenumberInvalid ? "block" : 'none' }}>{this.state.phonenumberError ? 'Phone Number is required' : 'Phone Number is invalid'}</div>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Name Type<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" placeholder="Name Type" name="nametype" onChange={this.handleOnchange} value={data.nametype ? data.nametype : ''} />
                                                            <div className="invalid-feedback" style={{ display: this.state.nametypeError ? "block" : 'none' }}>Name Type is required</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Email <span className="ml-1" style={{ color: 'red' }}>*</span><span> (Type and Press Enter to add Email)</span></label>
                                                            <input type="text" className="form-control" placeholder="Email" name="email" onKeyPress={this.handleKeyDown} onChange={this.handleOnchange} value={this.state.email} />
                                                            <div className="invalid-feedback" style={{ display: this.state.communicationemailValidError ? "block" : 'none' }}>Enter valid email</div>
                                                            <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="selectgroup selectgroup-pills">
                                                                {
                                                                    data.email.map((data, i) => {
                                                                        return <label className="selectgroup-item">
                                                                            <input type="checkbox" name="value" className="selectgroup-input" value="CSS" checked={true} disabled />
                                                                            <span className="selectgroup-button">{data} {this.state.modalType !== 'View' && <button type="button" className="close" style={{ outline: 'none' }} onClick={() => this.removeEmail(i)}></button>}</span>
                                                                        </label>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Image<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input name="image" type="file" accept="image/png,image/jpg,image/jpeg" className="form-control" onChange={this.uploadhandleChange} id='imageSet' />
                                                            <div className="invalid-feedback" style={{ display: this.state.imageError ? "block" : 'none' }}>Please upload image</div>
                                                        </div>
                                                        <div className="form-group">
                                                            {
                                                                this.state.modalType == "Add"
                                                                &&
                                                                (this.state.image
                                                                    ?
                                                                    <div style={{ width: '200px', height: '100px' }}>
                                                                        <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : null} alt='12' />
                                                                    </div>
                                                                    : '')
                                                            }
                                                            {
                                                                this.state.modalType == "Edit"
                                                                    ?
                                                                    <div className="form-group">
                                                                        <div style={{ width: '200px', height: '100px' }}>
                                                                            <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : ImportedURL.LIVEURL + imageData} alt="" />
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right payrollgen_btn">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary"><i class="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    invoicedetailState: state.invoicedetail,
    countryReducer: state.countryReducer,
    AccountState: state.account,

});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            handleChange: AC_HANDLE_INVOICE_DETAIL_CHANGE,
            listinviocedetail: AC_LIST_INVOICE_DETAIL,
            viewinviocedetail: AC_VIEW_INVOICE_DETAIL,
            resetinviocedetail: AC_RESET_INVOICE_DETAIL,
            listcountry: AC_LIST_COUNTRY,
            AC_ACCOUNT_DETAILS,
            AC_SETTING_DETAIL
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Inviocedetail);


