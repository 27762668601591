import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Success, Error } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Select from 'react-select';
import jsPDF from "jspdf";
import moment from 'moment';
import { AC_HANDLE_EMPLOYEE_SALARY_CHANGE, AC_LIST_EMPLOYEE_SALARY, AC_VIEW_EMPLOYEE_SALARY, AC_RESET_EMPLOYEE_SALARY, AC_LIST_EMPLOYEE_PAYROLL__SALARY, AC_HANDLE_PAYROLL_STATUS_CHANGE, AC_HANDLE_EMPLOYEE_SALARY_SEARCH } from '../../actions/employeesalaryAction';
import { AC_LIST_EMPLOYEE, } from '../../actions/employeeAction';
import { AC_HANDLE_LEAVE_TRANSECTION_CHANGE, AC_LIST_LEAVE_TRANSECTION, AC_VIEW_LEAVE_TRANSECTION, AC_RESET_LEAVE_TRANSECTION } from '../../actions/leavetransectionAction';
import DatePicker from "react-multi-date-picker"
import { AC_LIST_EMPLOYEE_LEAVE } from '../../actions/employeeLeaveAction';
import { AC_LIST_LEAVES } from '../../actions/leavesAction';
import { AC_ACCOUNT_DETAILS } from '../../actions/accountAction';





const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    empId: '',
    nameError: false,
    salaryError: false,
    workingdaysError: false,
    dateError: false,
    lopdaysError: false,
    columnDefs: [
        { headerName: 'Name', field: 'name.employeeName', width: 130, floatingFilter: true },
        {
            headerName: 'Employee ID', field: 'employeeId', width: 130, floatingFilter: true,
        },
        // {
        //     headerName: 'Salary', field: 'salary', width: 130, floatingFilter: true,
        // },
        {
            headerName: 'Workingdays', field: 'workingdays', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Date', field: 'date', width: 130, floatingFilter: true,
            cellRenderer: function (params) {
                return moment(params.data.date).format('MM/YYYY')
            }
        },
        // {
        //     headerName: 'Date', field: 'date', width: 130, floatingFilter: true,
        // },
        {
            headerName: 'Lopdays', field: 'lopdays', width: 130, floatingFilter: true,
        },
        // {
        //     headerName: 'Netsalary', field: 'netsalary', width: 130, floatingFilter: true,
        // }, {
        //     headerName: 'Lopamount', field: 'lopamount', width: 130, floatingFilter: true,
        // },
        {
            headerName: 'Status', width: 150, field: 'status', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true
    },
}
class Leavetransection extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.props.resetleavetransection();
        // this.props.listEmppayrollitem();

    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.viewleavetransection(event.data._id);
        }
        if (value === 'Payslip') {
            this.setState({ id: event.data._id, viewRedirect: true });

        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteLeavetransection + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            this.props.listleavetransection();
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'leavetransections' })
                        .then((data) => {
                            this.props.listleavetransection();
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name.employeeName', 'employeeId', 'salary', 'workingdays', 'date', 'lopdays', 'netsalary', 'lopamount'],
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { leavetransectionState } = this.props;
        const exportData = leavetransectionState.listLeavestransection;
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Leave Transection";
        const headers = [['#', 'Name', 'Employee ID', 'Salary', 'Workingdays', 'Date', 'Lopdays', 'Netsalary', 'Lopamount']];

        const data = exportData.map((elt, i) => [
            i + 1,
            elt.name.employeeName,
            elt.employeeId,
            elt.salary,
            elt.workingdays,
            elt.date,
            elt.lopdays,
            elt.netsalary,
            elt.lopamount,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Leavetransection.pdf")
    }
    handleSelectChange = (e) => {
        this.setState({ spinner: false })
        const { name, value } = e;
        this.setState({ empId: value })
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.handleChange(name, value);

        axios.get(ImportedURL.API.viewempsalary + "/" + value)
            .then(({ data }) => {
                this.props.handleChange('empsalary', data.salary);
                // dispatch({ type: "VIEW_DEDUCTION", payload: data });
            });
    }
    handleOnchange = (e) => {
        const { name, value } = e.target;
        this.props.handleChange(name, value);
        if (name == 'name') {
            if (value) {
                this.setState({ nameError: false })
            }
            else {
                this.setState({ nameError: true })
            }
        }
        if (name == 'salary') {
            if (value) {
                this.setState({ salaryError: false })
            }
            else {
                this.setState({ salaryError: true })
            }
        }

        if (name == 'workingdays') {
            if (value) {
                this.setState({ workingdaysError: false })
            }
            else {
                this.setState({ workingdaysError: true })
            }
        }
        if (name == 'lopdays') {
            if (value) {
                this.setState({ lopdaysError: false })
            }
            else {
                this.setState({ lopdaysError: true })
            }
        }
    }
    componentDidMount() {
        this.props.listemployee();
        this.props.listleavetransection();
        this.props.listemployeeleave();
        this.props.ListLeaves();
        this.props.AC_ACCOUNT_DETAILS();
        localStorage.setItem('invoicetoken', "");

    }
    submit = () => {
        const { leavetransectionState, employeeReducer, EmployeeLeaveState, LeaveState } = this.props;
        const data = leavetransectionState.leavetransection;
        const listemployee = employeeReducer.listEmployee;
        const employeeLeavedata = EmployeeLeaveState.listEmployeeLeave;
        const account = this.props.account.account;
        const listLeaves = LeaveState.listLeaves;
        var EmloyeeId = '';
        listemployee.length && listemployee.map(item => {
            if (item._id === data.name) {
                EmloyeeId = item.employeeId
            }
        })
        let Paiddays = data.workingdays - data.lopdays
        let netsalarycalc = 0;
        netsalarycalc = data.empsalary / data.workingdays * data.lopdays;
        let NetSalary = 0;
        NetSalary = data.empsalary - netsalarycalc;
        // let totalleave = 0;
        // employeeLeavedata != undefined && employeeLeavedata.length && employeeLeavedata.map((item) => {
        //     totalleave += parseInt(item.totalLeave)
        // })

        // let remainingValue = 0
        // {
        //     employeeLeavedata && employeeLeavedata.map((item) => {
        //         const scores = item.leave;
        //         // const sum = scores.reduce((result, number) => parseInt(result) + parseInt(number));
        //         remainingValue = scores
        //     })
        // }

        // ========
        let totalleave = 0;
        employeeLeavedata != undefined && employeeLeavedata.length && employeeLeavedata.map((item) => {
            totalleave += parseInt(item.leave)
        })
        let takeleave = 0;
        listLeaves != undefined && listLeaves.length && listLeaves.map((item) => {
            takeleave += parseInt(item.numberofdays)
        })
        let numberdays = totalleave - takeleave;

        // ========

        // const leaveArray = []
        // if (listLeaves.length > 0) {
        //     listLeaves.filter(trackingStatus => trackingStatus.trackingStatus == "200").map((data1) => {
        //         if (data1.employee == account._id) {
        //             let index = leaveArray.findIndex(e => e.id == data1.leavetypeId)
        //             if (index != -1) {
        //                 leaveArray[index] = { id: data1.leavetypeId, count: leaveArray[index].count + Number(data1.numberofdays) }
        //             } else {
        //                 leaveArray.push({ id: data1.leavetypeId, count: Number(data1.numberofdays) })
        //             }
        //         }
        //     })
        // }

        // let dashboardArray = []

        // if (employeeLeavedata.length > 0) {
        //     employeeLeavedata.map((item) => {
        //         if (item.leavemaxArr && item.leavemaxArr.length > 0) {
        //             item.leavemaxArr.map((item1) => {
        //                 if (item1.leavetype) {
        //                     let status = leaveArray.find((e) => e.id == item1.leavetype._id)
        //                     if (status) {
        //                         dashboardArray.push({
        //                             name: item1.leavetype.leavetype,
        //                             count: Number(item1.max) - Number(status.count),
        //                         })
        //                     } else {
        //                         dashboardArray.push({
        //                             name: item1.leavetype.leavetype,
        //                             count: Number(item1.max),
        //                         })
        //                     }
        //                 }
        //             })
        //         }
        //     })
        // }
        // let yourBalanceLeaveCount = 0;
        // if (dashboardArray.length > 0) {
        //     dashboardArray.map((data) => {
        //         yourBalanceLeaveCount += data.count
        //     })
        // }

        // let leavesList = 0
        // dashboardArray.length > 0 && dashboardArray.map((item) => {
        //     leavesList += item.count
        // })


        let valid = 1
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0
        }
        // if (!data.salary) {
        //     this.setState({ salaryError: true });
        //     valid = 0
        // }
        if (!data.date) {
            this.setState({ dateError: true });
            valid = 0
        }
        if (!data.workingdays) {
            this.setState({ workingdaysError: true });
            valid = 0
        }
        // if (!data.lopdays) {
        //     this.setState({ lopdaysError: true });
        // }
        // if (!data.salary) {
        //     this.setState({ salaryError: true });
        //     valid = 0
        // }


        var formdata = {
            name: data.name,
            employeeId: EmloyeeId,
            salary: NetSalary,
            date: data.date,
            workingdays: data.workingdays,
            lopdays: data.lopdays,
            // netsalary: NetSalary,
            // lopamount: netsalarycalc,
            paiddays: Paiddays,
            totalleave: totalleave,
            takenleave: takeleave,
            remainingleave: numberdays,
        }

        if (valid) {
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addLeavetransection, formdata)
                    .then((formdata) => {
                        Success(formdata.statusText);
                        this.setState({ save: false });
                        this.props.listleavetransection();
                        // this.props.resetEmployeesalary();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateLeavetransection + '/' + this.state.id, formdata)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.listleavetransection();
                        // this.props.resetEmployeesalary();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }

    onChangeDate = (e) => {
        const { name, value } = e;
        // const data = this.props.leavetransectionState.leavetransection;
        this.props.handleChange('date', e);
        if (e) {
            if (e) {
                this.setState({ dateError: false })
            }
            else {
                this.setState({ dateError: true })
            }
        }
        // this.props.handleChange('workingdays', moment(data.date).format("DD"));
    }


    render() {
        const { employeesalaryState, employeeReducer, leavetransectionState, EmployeeLeaveState, LeaveState } = this.props;
        const listemployeesalary = employeesalaryState.listemployeesalary;
        const listemployee = employeeReducer.listEmployee;
        const data = leavetransectionState.leavetransection;
        const account = this.props.account.account;
        const rowData = leavetransectionState.listLeavestransection;
        const employeeLeavedata = EmployeeLeaveState.listEmployeeLeave;
        const listLeaves = LeaveState.listLeaves;
        let Paiddays = data.workingdays - data.lopdays
        let netsalarycalc = 0;
        netsalarycalc = data.empsalary / data.workingdays * data.lopdays;
        let NetSalary = 0;
        NetSalary = data.empsalary - netsalarycalc;
        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });

        let totalleave = 0;
        employeeLeavedata != undefined && employeeLeavedata.length && employeeLeavedata.map((item) => {
            totalleave += parseInt(item.leave)
        })
        let takeleave = 0;
        listLeaves != undefined && listLeaves.length && listLeaves.map((item) => {
            takeleave += parseInt(item.numberofdays)
        })
        let numberdays = totalleave - takeleave;
        let remainingValue = 0
        {
            employeeLeavedata && employeeLeavedata.map((item) => {
                const scores = item.leave;
                // const sum = scores.reduce((result, number) => parseInt(result) + parseInt(number));
                remainingValue = scores
            })
        }


        const leaveArray = []
        if (listLeaves.length > 0) {
            listLeaves.filter(trackingStatus => trackingStatus.trackingStatus == "200").map((data1) => {
                if (data1.employee == account._id) {
                    let index = leaveArray.findIndex(e => e.id == data1.leavetypeId)
                    if (index != -1) {
                        leaveArray[index] = { id: data1.leavetypeId, count: leaveArray[index].count + Number(data1.numberofdays) }
                    } else {
                        leaveArray.push({ id: data1.leavetypeId, count: Number(data1.numberofdays) })
                    }
                }
            })
        }

        let dashboardArray = []

        if (employeeLeavedata.length > 0) {
            employeeLeavedata.map((item) => {
                if (item.leavemaxArr && item.leavemaxArr.length > 0) {
                    item.leavemaxArr.map((item1) => {
                        if (item1.leavetype) {
                            let status = leaveArray.find((e) => e.id == item1.leavetype._id)
                            if (status) {
                                dashboardArray.push({
                                    name: item1.leavetype.leavetype,
                                    count: Number(item1.max) - Number(status.count),
                                })
                            } else {
                                dashboardArray.push({
                                    name: item1.leavetype.leavetype,
                                    count: Number(item1.max),
                                })
                            }
                        }
                    })
                }
            })
        }
        let yourBalanceLeaveCount = 0;
        if (dashboardArray.length > 0) {
            dashboardArray.map((data) => {
                yourBalanceLeaveCount += data.count
            })
        }

        var EmloyeeId = '';
        listemployee.length && listemployee.map(item => {
            if (item._id === data.name) {
                EmloyeeId = item.employeeId
            }
        })

        let selectedName = '';
        listemployee.length && listemployee.map(item => {
            if (item._id === data.name) {
                selectedName = item.employeeName
            }
        })
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const StartDate = data.date;

        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6>Leave Transection List</h6></p>
                </div>
                <div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content pt-3">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                            <h3 className="card-title">List Leave Transection</h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                                    <div className="header-action">
                                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                    </div>
                                                </div>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>{this.state.modalType} Leave Transection</h5>
                                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                        <img src='../../assets/images/cancel.png' />
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Employee Name</label>
                                                                <Select
                                                                    value={selectedName ? { label: selectedName } : ''}
                                                                    onChange={this.handleSelectChange}
                                                                    options={listemployee ? listemployee.filter(filterItem => filterItem.status === true).map(item => {
                                                                        return {
                                                                            label: item.employeeName, value: item._id, name: 'name', _id: item._id
                                                                        }
                                                                    }) : ''}
                                                                />
                                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Salary</label>
                                                                <input type="text" className="form-control" placeholder="Salary" name="salary" onChange={this.handleOnchange} value={data.salary} />
                                                                <div className="invalid-feedback" style={{ display: this.state.salaryError ? "block" : 'none' }}>Salary is required</div>
                                                            </div>
                                                        </div> */}
                                                        <div className='col-lg-12'>
                                                            <div className="form-group leave_transec_date">
                                                                <label className="form-label">Date</label>
                                                                {/* <input type="date" className="form-control" name="date" onChange={this.handleOnchange} value={data.date ? moment(data.date).format('YYYY-MM-DD') : ''} /> */}
                                                                <DatePicker
                                                                    onlyMonthPicker
                                                                    name="date"
                                                                    value={StartDate}
                                                                    format="MMMM YYYY"
                                                                    placeholder='MM-YYYY'
                                                                    onChange={this.onChangeDate}
                                                                />
                                                                <div className="invalid-feedback" style={{ display: this.state.dateError ? "block" : 'none' }}>Date is required</div>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Loss of  Pay (day)</label>
                                                                <input type="text" className="form-control" placeholder="Loss of Pays" name="lopdays" onChange={this.handleOnchange} value={data.lopdays ? data.lopdays : ''} />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Total Leaves</label>
                                                                <input type="text" className="form-control" placeholder="Working  Days" name="workingdays" value={totalleave ? totalleave : ''} disabled="disabled" />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Remaining Leaves</label>
                                                                <input type="text" className="form-control" placeholder="Working  Days" name="workingdays" value={numberdays ? numberdays : ''} disabled="disabled" />
                                                            </div>
                                                        </div>
                                                        {/* <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">LOP  dAYS</label>
                                                                <input type="text" className="form-control" placeholder="Working  Days" name="workingdays" value={takeleave ? takeleave : ''} disabled="disabled" />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label"> LOP Amount</label>
                                                                <input type="text" className="form-control" placeholder="LOP Amount" name="lopamount" disabled="disabled" onChange={this.handleOnchange} value={netsalarycalc ? Math.round(netsalarycalc) : ''} />
                                                                <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Employee ID</label>
                                                                <input type="text" className="form-control" placeholder="Employee ID" name="empid" value={EmloyeeId ? EmloyeeId : ''} disabled="disabled" />
                                                                <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Working  Days</label>
                                                                <input type="text" className="form-control" placeholder="Working  Days" name="workingdays" onChange={this.handleOnchange} value={data.workingdays ? data.workingdays : ""} />
                                                                <div className="invalid-feedback" style={{ display: this.state.workingdaysError ? "block" : 'none' }}>Working days is required</div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label" >Paid  Days</label>
                                                                <input type="text" className="form-control" placeholder="Paid Days" name="paiddays" disabled="disabled" value={Paiddays ? Paiddays : ''} />
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                {/* {dashboardArray.length > 0 && dashboardArray.map((data) => {
                                                                    return (
                                                                        <>
                                                                            <div className='col-lg-12'>
                                                                                <div className="form-group">
                                                                                    <label className="form-label">{data.name}</label>
                                                                                    <input type="text" className="form-control" placeholder="Working  Days" name="workingdays" value={data.count ? data.count : ''} disabled="disabled" />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })} */}
                                                                <label className="form-label" >leave Availed</label>
                                                                <input type="text" className="form-control" placeholder="Paid Days" name="paiddays" disabled="disabled" value={takeleave ? takeleave : ''} />

                                                            </div>
                                                        </div>


                                                        {/* <div className='col-lg-12'>
                                                            <div className="form-group">
                                                                <label className="form-label"> Net Salary</label>
                                                                <input type="text" className="form-control" placeholder="Net Salary" name="netsalary" disabled="disabled" onChange={this.handleOnchange} value={NetSalary ? Math.round(NetSalary) : ''} />
                                                                <div className="invalid-feedback" style={{ display: this.state.employeeCodeError ? "block" : 'none' }}>Employee Code is required</div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label mt-2"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                                <div className="text-right payrollgen_btn">
                                                    {this.state.save ?
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i class="fa fa-spinner fa-spin mr-2"></i>Genarating</button>
                                                        :
                                                        <button type="button" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Genarate</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    employeeReducer: state.employeeReducer,
    payrollitemState: state.payrollitem,
    employeesalaryState: state.employeesalary,
    leavetransectionState: state.leavetransection,
    EmployeeLeaveState: state.employeeleave,
    LeaveState: state.leaves,
    account: state.account,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            listEmployeesalary: AC_LIST_EMPLOYEE_SALARY,
            listemployee: AC_LIST_EMPLOYEE,
            handleChange: AC_HANDLE_LEAVE_TRANSECTION_CHANGE,
            listleavetransection: AC_LIST_LEAVE_TRANSECTION,
            viewleavetransection: AC_VIEW_LEAVE_TRANSECTION,
            resetleavetransection: AC_RESET_LEAVE_TRANSECTION,
            listemployeeleave: AC_LIST_EMPLOYEE_LEAVE,
            ListLeaves: AC_LIST_LEAVES,
            AC_ACCOUNT_DETAILS
        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Leavetransection);


