import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_ACCOUNT_DETAILS() {

	return function (dispatch) {
		return axios.get(ImportedURL.API.accountDetails)
			.then((res) => {

				dispatch({ type: "ACCOUNT_DETAILS", payload: res.data })
			}).catch(({ response }) => { console.log(response); });
	};
}

export function AC_PREVILEGE_DETAILS() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.getPrevileges)
			.then((res) => {
				dispatch({ type: "PREVILEGE_DETAILS", payload: res.data })
			}).catch(({ response }) => { console.log(response); });
	};
}

export function AC_SETTING_DETAIL() {
	return function (dispatch) {
		axios.get(ImportedURL.API.accountDetails)
			.then(({ data }) => {
				dispatch({ type: 'SETTING_DETAIL', payload: data });
			})
	}
}

// export function AC_UPDATE_DETAIL(formData) {
// 	return function (dispatch) {
// 		axios.post(ImportedURL.API.updateAdmin, formData)
// 			.then(({ data }) => {
// 				Success(' Updated Successfully');
// 			})
// 	}
// }

export function AC_HANDLE_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_CHANGE", name: name, value: value })
	};
}


export function AC_HANDLE_CHANGE_INVOICE_STATUS(value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_CHANGE_INVOICE_STATUS", value: value })
	};
}