
const initialState = {
    addInventory: [],
    listInventory: [],
    deleteInventory: [],
    Inventory: {
		name: '',
		// previleges: CONSTANTS.previleges,
		status: 1,
	},
}
function inventoryReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_INVENTORY':
            return {
                ...state,
                addInventory: action.payload
            }
            break;

        case 'LIST_INVENTORY':
            return {
                ...state,
                listInventory: action.payload
            }
            break;
        case 'VIEW_INVENTORY':
            return {
                ...state,
                Inventory: action.payload
            }
        case 'RESET_INVENTORY':
			return Object.assign({}, state, {
				Inventory: initialState.Inventory
			})
        case 'DELETE_INVENTORY':
            return {
                ...state,
                deleteInventory: action.payload
            }
            break;
        case 'HANDLE_INVENTORY_CHANGE': 
            return Object.assign({}, state, {
                Inventory: {
                    ...state.Inventory,
                    [action.name]: action.value
                }
            })
        default:
            return state;
            break;
    }
}

export default inventoryReducer;