import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Link, Redirect } from 'react-router-dom';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { bindActionCreators } from "redux";
import { data } from "jquery";
import CONSTANTS from "../../common/constants";
import ImportedURL from "../../common/api";
import { Error, Success } from "../../common/swal";
import axios from "axios";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "Add",
      nameError: false,
      locationError: false,
      emailError: false,
      hotelidError: false,
      id: "",
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    if (name === 'confirmpassword') this.setState({ confirmpassword: value })
    else this.props.HandleInputChange(name, value);
  }
  changePrevilege = (index, key) => {
    this.props.ChangePrevilege(index, key);
  }

  setAll = e => {
    const { SubadminState } = this.props;
    const { checked } = e.target;
    let previleges = SubadminState.subadmin.previleges;
    previleges = checked ? CONSTANTS.allprevileges : CONSTANTS.previleges;
    // previleges = previleges.map((data) => {
    //   return {
    //     name: data.name,
    //     view: checked,
    //     add: checked,
    //     edit: checked,
    //     delete: checked,
    //     share: checked,
    //   }
    // });
    this.props.SetAllPrevileges(previleges);
  }

  SubmitForm = () => {
    const email = this.props.match.params.email;
    const { history } = this.props;
  }
  componentDidMount() {
    const email = this.props.match.params.email;
    localStorage.setItem('invoicetoken', "");
  }
  render() {

    return (
      <>
        <div>

        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
  SubadminState: state.subadmin,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({});
}
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
