import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import CKEditor from "react-ckeditor-component";
import axios from 'axios';
import moment from 'moment';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { AC_LIST_LEAVE_TEMPLATE } from '../../actions/leaveTemplateAction';
import { AC_HANDLE_EMAIL_CHANGE, AC_LIST_EMAIL, AC_RESET_EMAIL } from '../../actions/emailAction';
import { Emailvalidate } from '../../common/validate';
import { AC_LIST_EMPLOYEE } from '../../actions/employeeAction';

class Compose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            toError: false,
            toValidError: false,
            ccError: false,
            ccValidError: false,
            bccError: false,
            bccValidError: false,
            subjectError: false,
            redirect: false,
        }
    }
    componentDidMount() {
        this.props.ListLeaveTemplate();
        this.props.ListEmployee();
        localStorage.setItem('invoicetoken', "");
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'to') {
            if (value) {
                this.setState({ toError: false });
                if (Emailvalidate(value)) {
                    this.props.HandleInputChange('to', value);
                    this.setState({ toValidError: false });
                } else {
                    this.setState({ toValidError: true });
                    this.props.HandleInputChange('to', value);
                }
            } else {
                this.setState({ toError: true, toValidError: false })
                this.props.HandleInputChange('to', value);
            }
        }
        if (name == 'cc') {
            if (value) {
                this.setState({ ccError: false })
                if (Emailvalidate(value)) {
                    this.props.HandleInputChange('cc', value);
                    this.setState({ ccValidError: false });
                } else {
                    this.setState({ ccValidError: true });
                    this.props.HandleInputChange('cc', value);
                }
            } else {
                this.setState({ ccError: true, ccValidError: false })
                this.props.HandleInputChange('cc', value);
            }
        }
        if (name == 'bcc') {
            if (value) {
                this.setState({ bccError: false })
                if (Emailvalidate(value)) {
                    this.props.HandleInputChange('bcc', value);
                    this.setState({ bccValidError: false });
                } else {
                    this.setState({ bccValidError: true });
                    this.props.HandleInputChange('bcc', value);
                }
            } else {
                this.setState({ bccError: true, bccValidError: false })
                this.props.HandleInputChange('bcc', value);
            }
        }
    }
    handleChange = (e) => {
        const leaveData = this.props.LeaveTemplateState.listLeaveTemplate;
        const { name, value, label } = e;
        if (name == 'subject') {
            if (value) {
                this.props.HandleInputChange('subject', value);
                this.setState({ leaderError: false });
                let description = ''
                if (leaveData.length > 0) {
                    leaveData.map((item) => {
                        if (item._id == value) {
                            description = item.description
                        }
                    })
                }
                this.props.HandleInputChange('description', description)
            } else {
                this.setState({ departmentError: true })
            }
        }
    }
    onChangeEditor = e => {
        this.props.HandleInputChange("description", e.editor.getData());
    }
    handleChangeTo = (e) => {
        const valueArray = [];
        e && e.map((val) => {
            valueArray.push(val.value)
        })
        this.props.HandleInputChange('to', valueArray)
    }
    handleChangeCc = (e) => {
        const valueArray = [];
        e && e.map((val) => {
            valueArray.push(val.value)
        })
        this.props.HandleInputChange('cc', valueArray)
    }

    submit = () => {
        const account = this.props.AccountState?.account;
        const data = this.props.EmailState.emailData;
        let valid = 1
        if (!data.to) {
            this.setState({ to: true })
            valid = 0;
        };
        if (!data.cc) {
            this.setState({ toError: true })
            valid = 0;
        };
        if (!data.subject) {
            this.setState({ bccError: true })
            valid = 0;
        };
        var today = new Date()
        var todayDate = moment(today).format("DD-MM-YYYY");
        data["date"] = todayDate;

        data["from"] = account.email

        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addEmail, data)
                    .then((res) => {
                        this.setState({ saving: false, redirect: true })
                        Success('Email send successfully');
                        // Success(res.statusText);
                        this.props.ResetEmail();
                        this.props.ListEmail();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Email already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            } else {
                axios.post(ImportedURL.API.updateLeaveTemplate + "/" + this.state.id, data)
                    .then((res) => {
                        this.setState({ searchhoteloption: '', searchhotel: '' })
                        this.setState({ saving: false, closed: true })
                        Success('Email updated successfully');
                        // Success(res.statusText);
                        this.props.ListEmail();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        // Error(response.statusText)
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else if (response.status == 409) {
                            Error('Email already exist')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    });
                // this.props.UpdateJobTitle(data, this.state.id);
            }
        }
    }
    deleteEmail = () => {
        this.props.ResetEmail();
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={'/list-email'} />
        }

        const { AccountState, EmailState, LeaveTemplateState, employeeState } = this.props;
        const account = AccountState?.account;
        const previleges = AccountState.previleges;
        const data = EmailState.emailData;
        const leaveData = LeaveTemplateState.listLeaveTemplate;
        const employeeData = employeeState.listEmployee;

        // ------------ subject --------------
        let selectedLeaveTemplateData = '';
        if (data.subject) {
            selectedLeaveTemplateData = leaveData.find(e => e._id === data.subject);
        }
        const leaveTemplateOption = [];
        {
            leaveData && leaveData.map((item) => {
                leaveTemplateOption.push({ label: item.title, value: item._id, name: 'subject' })
            })
        }

        // ------------ to --------------
        let selectedToValue = []
        if (employeeData) {
            employeeData.filter(filterItem => filterItem.status === true).map((item) => {
                (data.to) && (data.to).map((data) => {
                    if (item._id === data) {
                        selectedToValue.push({ label: item.email, value: item._id, name: 'to' })
                    }
                })
            })
        }
        const toOption = [];
        if (employeeData) {
            employeeData.filter(filterItem => filterItem.status === true).map((item) => {
                if (item.email != account.email) {
                    toOption.push({ label: item.email, value: item._id, name: 'to' })
                }
            })
        }
        // ------------ cc --------------
        let selectedCcValue = []
        if (employeeData) {
            employeeData.filter(filterItem => filterItem.status === true).map((item) => {
                (data.cc) && (data.cc).map((data) => {
                    if (item._id === data) {
                        selectedCcValue.push({ label: item.email, value: item._id, name: 'cc' })
                    }
                })
            })
        }
        // -------------cc ===========

        const ccOption = [];
        const uncommonCcData = employeeData.filter(item => item.status === true && !(data.to).includes(item._id));
        if (uncommonCcData) {
            uncommonCcData.map((item) => {
                if (item.email != account.email) {
                    ccOption.push({ label: item.email, value: item._id, name: 'cc' })
                }
            })
        }


        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-email"><h6 className="highlights_breadcrump">Email List</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/compose"><h6 className="highlights_breadcrump">Compose</h6></Link></p>
                </div>
                <div className="section-body user_sec email_sec">
                    <div className="container-fluid">
                        <div className="tab-content pt-3">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='card-title d-flex'>
                                            <div className='rounded_icon'><i className="fa fa-envelope mr-2"></i></div>
                                            <h3 className="card-title " style={{ marginTop: '10px' }}> Compose</h3>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div className='row'>
                                            <div className='col-xl-6 col-lg-6 col-12 col-sm-12 col-12'>
                                                <label className="form-label">To<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        isMulti
                                                        value={selectedToValue}
                                                        onChange={this.handleChangeTo}
                                                        options={toOption}
                                                    />
                                                    {/* <div className="invalid-feedback" style={{ display: this.state.toError ? "block" : 'none' }}> To is required</div> */}
                                                    {/* <div className="invalid-feedback" style={{ display: this.state.toValidError ? "block" : 'none' }}> Enter valid email</div> */}
                                                </div>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-12 col-sm-12 col-12 '>
                                                <label className="form-label">Cc</label>
                                                <div className="form-group">
                                                    <Select
                                                        isMulti
                                                        value={selectedCcValue}
                                                        onChange={this.handleChangeCc}
                                                        options={ccOption}
                                                    />
                                                    {/* <div className="invalid-feedback" style={{ display: this.state.ccError ? "block" : 'none' }}> Cc is required</div> */}
                                                    {/* <div className="invalid-feedback" style={{ display: this.state.ccValidError ? "block" : 'none' }}> Enter valid email</div> */}
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <label className="form-label">Subject</label>
                                                <div className="form-group">
                                                    <Select
                                                        value={selectedLeaveTemplateData ? { label: selectedLeaveTemplateData.title, value: selectedLeaveTemplateData._id, name: "subject" } : ''}
                                                        onChange={this.handleChange}
                                                        options={leaveTemplateOption}

                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.subjectError ? "block" : 'none' }}>Add Project Leader is required</div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <label className="form-label" >Description</label>
                                                <CKEditor
                                                    activeclassName="p10"
                                                    content={data.description}
                                                    events={{
                                                        "change": this.onChangeEditor
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>fields are mandatory </label>
                                        <div className="text-right">
                                            <button type="button" className="btn commor_save " onClick={this.deleteEmail}><i className="fa fa-trash-o mr-2"></i>Delete</button>
                                            <button type="button" className="btn commor_save mr-3 " onClick={this.submit}  ><i className="fa fa-paper-plane mr-2"></i>Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    EmailState: state.email,
    LeaveTemplateState: state.leavetemplate,
    employeeState: state.employeeReducer,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            HandleInputChange: AC_HANDLE_EMAIL_CHANGE,
            ListLeaveTemplate: AC_LIST_LEAVE_TEMPLATE,
            ListEmployee: AC_LIST_EMPLOYEE,
            ListEmail: AC_LIST_EMAIL,
            ResetEmail: AC_RESET_EMAIL,

        }, dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Compose);