import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_LEAVE_TRANSECTION_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_LEAVE_TRANSECTION_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_LEAVE_TRANSECTION() {
    return function (dispatch) {
        dispatch({ type: "RESET_LEAVE_TRANSECTION" })
    };
}


export function AC_LIST_LEAVE_TRANSECTION() {
    return async function (dispatch) {
        return (
            await axios.get(ImportedURL.API.listLeavetransection)
                .then((res) => {
                    dispatch({ type: "LIST_LEAVE_TRANSECTION", payload: res.data, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_LEAVE_TRANSECTION(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewLeavetransection + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_LEAVE_TRANSECTION", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


export function AC_EMPTY_LEAVE_TRANSECTION() {
    return function (dispatch) {
        dispatch({ type: "AC_EMPTY_LEAVE_TRANSECTION" })
    }
}