const initialState = {
    listpayrollitem: [],
    payrollitem: {
        name: '',
        amount: '',
        salarystructure: '',
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LIST_PAYROLL_ITEM':
            return {
                ...state,
                listpayrollitem: action.payload,
            }
            break;
        case 'VIEW_PAYROLL_ITEM':
            return {
                ...state,
                payrollitem: action.payload,
            }
        case 'RESET_PAYROLL_ITEM':
            return Object.assign({}, state, {
                payrollitem: initialState.payrollitem
            })
        case 'HANDLE_PAYROLL_ITEM_CHANGE':
            return Object.assign({}, state, {
                payrollitem: {
                    ...state.payrollitem,
                    [action.name]: action.value
                }
            })
        default:
            return state;
    }
}